<div >
    <h1 mat-dialog-title class="dialog-title">Fault Alarm Closing</h1>
    <div mat-dialog-content class="dialog-content">
      <p class="dialog-text">Please Provide a comment for closing this alarm:</p>
      <mat-form-field appearance="outline" class="dialog-input">
        <mat-label>Enter Your Comment</mat-label>
        <textarea matInput [(ngModel)]="comment" rows="5"></textarea>
      </mat-form-field>
      <!-- <div class="file-upload">
        Attach files by dragging & dropping or <a href="#">Select file</a>
      </div> -->
    </div>
    <div mat-dialog-actions class="dialog-actions">
      <button mat-button (click)="onNoClick()" class="cancel-button">Cancel</button>
      <button mat-button (click)="submitComment()" class="submit-button" cdkFocusInitial>Submit</button>
    </div>
</div>