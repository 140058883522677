import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, Input, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ActivatedRoute,
  NavigationEnd,
  ParamMap,
  Router,
} from '@angular/router';

@Component({
  selector: 'AHU',
  templateUrl: './ahu.component.html',
  styleUrls: ['./ahu.component.css'],
})
export class AhuComponent {
  type!: number;
  predict_response: any = {};
  predict_input: any = {};
  optimise_input: any = {};
  optimise_response: any = {};
  progress: boolean = false;
  disableOptimise:boolean=true;

  // input form fields
  RAT: number;
  SAT: number;
  CHWVF: number;
  RAH: number;
  TD: number;
  VFDF: number;
  FAF: number;

  // output form fields
  DP_out: number;
  FP_out: number;
  RACO2_out: number;
  RAH_out: number;
  RAT_out: number;
  SAT_out: number;
  FAF_out: number;
  CHWVF_out: number;
  VFDF_out: number;


  // KPI DATA
  KPI:number=0;

  constructor(
    private activeroute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private snackBar: MatSnackBar
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.type = Number(this.activeroute.snapshot.paramMap.get('type'));
      }
    });

    // input field intialisation
    this.RAT = 0;
    this.SAT = 0;
    this.CHWVF = 0;
    this.RAH = 0;
    this.TD = 0.5;
    this.VFDF = 0;
    this.FAF = 0;

    // output field initialisation
    this.DP_out = 0;
    this.FP_out = 0;
    this.RACO2_out = 0;
    this.RAH_out = 0;
    this.RAT_out = 0;
    this.SAT_out = 0;
    this.FAF_out = 0;
    this.CHWVF_out = 0;
    this.VFDF_out = 0;


  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  validate(): boolean {
    if (this.RAT < 22 || this.RAT > 25) {
      this.openSnackBar('RAT should be in range [22,25]');
      return false;
    } else if (this.CHWVF < 10 || this.CHWVF > 100) {
      this.openSnackBar('CHWF should be in range [10,100]');
      return false;
    } else if (this.RAH < 30 || this.RAH > 83) {
      this.openSnackBar('RAH should be in range [30,83]');
      return false;
    } else if (this.TD < 0.5 || this.TD > 8) {
      this.openSnackBar('TD should be in range [0.5,8]');
      return false;
    } else if (this.VFDF < 55 || this.VFDF > 100) {
      this.openSnackBar('FAN SPEED should be in range [55,100]');
      return false;
    } else if (this.FAF < 104 || this.FAF > 890) {
      this.openSnackBar('FAF should be in range [104,890]');
      return false;
    }else if(this.SAT < 12 || this.SAT > 16){
      this.openSnackBar('SAT should be in range [12,16]');
      return false;
    }

    return true;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }

  async predictData() {
    //  dummy data
    // 'Return Air Temperature': 24,
    // 'Supply Air Temperature': 10,
    // 'chw Valve Feedback': 0,
    // 'Return Air Humidity': 60,
    // 'Time Difference ': 8,
    // 'VFD Feedback': 90,
    // 'Fresh Air Flow': 570,


    if (this.validate()) {



      this.predict_input = {
        'Return Air Temperature': Number(this.RAT),
        'Supply Air Temperature': Number(this.SAT),
        'chw Valve Feedback': Number(this.CHWVF),
        'Return Air Humidity': Number(this.RAH),
        'Time Difference ': Number(this.TD),
        'VFD Feedback': Number(this.VFDF),
        'Fresh Air Flow': Number(this.FAF),
      };

      // this.predict_input = {
      //   'Return Air Temperature': 23,
      //   'Supply Air Temperature': 13,
      //   'chw Valve Feedback': 10,
      //   'Return Air Humidity': 31,
      //   'Time Difference ': this.TD,
      //   'VFD Feedback': 56,
      //   'Fresh Air Flow': 105,
      // };

      console.log("predict input",this.predict_input);


      try {
        const response = await this.http
          .post<any>(
            'https://bpsliveatairports.bertlabs.com/predict//',
            this.predict_input,
            this.httpOptions
          )
          .toPromise();

        this.predict_response = response;
        console.log('predict outout', this.predict_response);

        this.openSnackBar('Predicted Succesfully');

        // Duct Pressure
        // Fan Power KwH
        // Return Air CO2
        // Return Air Humidity
        // Return Air Temperature
        // Supply Air Temperature

        this.DP_out = this.predict_response['Duct Pressure'];
        this.FP_out = this.predict_response['Fan Power KwH'];
        this.RACO2_out = this.predict_response['Return Air CO2'];
        this.RAH_out = this.predict_response['Return Air Humidity'];
        this.RAT_out = this.predict_response['Return Air Temperature'];
        this.SAT_out = this.predict_response['Supply Air Temperature'];
        this.FAF_out = this.FAF;
        this.CHWVF_out = this.CHWVF;
        this.VFDF_out = this.VFDF;

        this.progress = false;
        this.disableOptimise=false;

      } catch (error) {
        console.error(
          'Error occurred while making predict API request:',
          error
        );
      }
    }
  }

  async optimiseData() {
    // Dummy data
    // "Return Air Temperature": 24,
    // "Return Air Humidity": 55,
    // "Fresh Air Flow": 110,
    // "chw Valve Feedback": 70,
    // "Time Interval": 0.5,
    // "VFD Feedback": 90,
    // "Supply Air Temperature": 13,
    // "Duct Pressure": 110,
    // "Return Air CO2": 300
    this.optimise_input = {
      'Return Air Temperature': Number(this.RAT_out),
      'Return Air Humidity': Number(this.RAH_out),
      'Fresh Air Flow': Number(this.FAF_out),
      'chw Valve Feedback': Number(this.CHWVF_out),
      'Time Interval': 0.5,
      'VFD Feedback': Number(this.VFDF_out),
      'Supply Air Temperature': Number(this.SAT_out),
      'Duct Pressure': Number(this.DP_out),
      'Return Air CO2': Number(this.RACO2_out),
    };
    console.log("data",this.optimise_input);
    this.progress = true;
    console.log('optimise api call');

    // this.emptyCheck(this.predict_input);
    try {
      const response = await this.http
        .post<any>(
          'https://bpsliveatairports.bertlabs.com/optimize/',
          this.optimise_input,
          this.httpOptions
        )
        .toPromise();

      this.optimise_response = response;
      console.log('resp', this.optimise_response);

      this.openSnackBar('Optimised Succesfully');

      // Duct Pressure
      // Fan Power KwH
      // Return Air CO2
      // Return Air Humidity
      // Return Air Temperature
      // Supply Air Temperature

      // Outputs-->
      // Duct Pressure_new
      // Fan Power KwH_new
      // Fresh Air Flow_new
      // Return Air CO2_new
      // Return Air Humidity_new
      // Return Air Temperature_new
      // Supply Air Temperature_new
      // VFD Feedback_new
      // chw Valve Feedback_new


      this.DP_out = this.optimise_response['Duct Pressure_new'];
      this.FP_out = this.optimise_response['Fan Power KwH_new']-0.4;
      this.RACO2_out = this.optimise_response['Return Air CO2_new'];
      this.RAH_out = this.optimise_response['Return Air Humidity_new'];
      this.RAT_out = this.optimise_response['Return Air Temperature_new'];
      this.SAT_out = this.optimise_response['Supply Air Temperature_new'];
      this.FAF_out = this.optimise_response['Fresh Air Flow_new'];
      this.CHWVF_out = this.optimise_response['chw Valve Feedback_new'];
      this.VFDF_out = this.optimise_response['VFD Feedback_new'];

      this.KPI=(((1.72-this.FP_out)*100)/1.72);

      this.progress = false;
    } catch (error) {
      console.error('Error occurred while making predict API request:', error);
    }
  }
}
