import { Component } from '@angular/core';

@Component({
  selector: 'app-cooling-tower-b',
  templateUrl: './cooling-tower-b.component.html',
  styleUrls: ['./cooling-tower-b.component.css']
})
export class CoolingTowerBComponent {
  CT3_C1_FAN_SPEED_GR: any = 0;
  CT3_C1_FAN_SPEED_RED: any = 0;
  CT3_C2_FAN_SPEED_GR: any = 0;
  CT3_C2_FAN_SPEED_RED: any = 0;
  CT3_C3_FAN_SPEED_GR: any = 0;
  CT3_C3_FAN_SPEED_RED: any = 0;
  CT4_C1_FAN_SPEED_GR: any = 0;
  CT4_C1_FAN_SPEED_RED: any = 0;
  CT4_C2_FAN_SPEED_GR: any = 0;
  CT4_C2_FAN_SPEED_RED: any = 0;
  CT_Water_in: any = 0;
  CT3_C1_Water_out: any = 0;
  CT3_C2_Water_out: any = 0;
  CT3_C3_Water_out: any = 0;
  CT4_C1_Water_out: any = 0;
  CT4_C2_Water_out: any = 0;
  KPI: any = 0;
}
