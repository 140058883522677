import { Component, Input } from '@angular/core';

@Component({
  selector: 'Air-cooled-chiller',
  templateUrl: './air-cooled-chiller.component.html',
  styleUrls: ['./air-cooled-chiller.component.css']
})
export class AirCooledChillerComponent {
  @Input() state!:string;
  urlActive:string;
  urlInActive:string;
  urlTrip:string;
  constructor(){
    this.urlActive="/assets/bert-components/active-air-cooled-chiller.svg";
    this.urlInActive="/assets/bert-components/inactive-air-cooled-chiller.svg";
    this.urlTrip="/assets/bert-components/triped-air-cooled-chiller.svg";
  }
}
