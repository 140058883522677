<div class="d-five-block-comp">
  <svg id="d-five-block-comp" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%"
    height="100%" viewBox="0 0 159 101.061">
    <defs>
      <linearGradient id="linear-gradient-d5" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#ccdfe8" />
        <stop offset="1" stop-color="#8ea6b2" />
      </linearGradient>
      <linearGradient id="linear-gradient-d5-2" x1="0.745" y1="0.351" x2="-0.389" y2="0.592"
        gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#fff" />
        <stop offset="1" stop-color="#e4f4fc" />
      </linearGradient>
      <filter id="Rectangle_1277" x="0" y="0" width="159" height="64" filterUnits="userSpaceOnUse">
        <feOffset dx="3" dy="3" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="4.5" result="blur" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Component_76_7" data-name="Component 76 – 7" transform="translate(10.5 10.5)">
      <path id="Path_4284" data-name="Path 4284" d="M813.129,146.769l.111-33.079,33.573,18.531L818.487,149.93Z"
        transform="translate(-796.407 -59.954)" stroke="#427894" stroke-miterlimit="10" stroke-width="1"
        fill="url(#linear-gradient-d5)" />
      <path id="Path_4286" data-name="Path 4286" d="M814.96,117.183,863.133,89.36,894.5,107.473l-48.173,27.811Z"
        transform="translate(-796.103 -64.259)" fill="url(#linear-gradient-d5-2)" />
      <path id="Path_4287" data-name="Path 4287"
        d="M863.552,91.2l28.588,16.477-45.395,26.211-28.588-16.477Zm0-3.2L812.59,117.424l34.131,19.714,50.962-29.424Z"
        transform="translate(-796.522 -64.5)" fill="#96bdd1" />
      <g transform="matrix(1, 0, 0, 1, -10.5, -10.5)" filter="url(#Rectangle_1277)">
        <rect id="Rectangle_1277-2" data-name="Rectangle 1277" width="132" height="37" rx="10"
          transform="translate(10.5 10.5)" fill="#529ec6" />
      </g>
      <text id="D5_Block" data-name="D5 Block" transform="translate(0 8)" fill="#fff" font-size="18"
        font-family="Mulish-Regular, Mulish">
        <tspan x="31.388" y="16">D5 Block</tspan>
      </text>
    </g>
  </svg>
</div>