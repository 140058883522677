import { Component } from '@angular/core';

@Component({
  selector: 'e-block-comp',
  templateUrl: './e-block-comp.component.html',
  styleUrls: ['./e-block-comp.component.css']
})
export class EBlockCompComponent {

}
