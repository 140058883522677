<div class="main-container">
    <h2>Alarms Log</h2>
<div
style="
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2vw;
"
>
<app-loading-spinner *ngIf="isloading" ></app-loading-spinner>

<div style="display: flex; align-items: center">
  <label
    style="
      color: var(--blue-two);
      font-size: 2.25vh;
      font-weight: 550;
      padding-right: 0.5vw;
    "
    >From Date :</label
  >
  <input readonly
    matInput
    [matDatepicker]="fromDatePicker"
    [(ngModel)]="fromDate"
    placeholder="Choose a date"
    style="
      background-color: white;
      border: 1.2px solid var(--blue-two);
      height: 32px;
      border-radius: 7px;
      padding-left: 10px;
    "
    readonly
  />
  <mat-datepicker-toggle
    matSuffix
    [for]="fromDatePicker"
  ></mat-datepicker-toggle>
  <mat-datepicker #fromDatePicker></mat-datepicker>
</div>
<div>
  <label
    style="
      color: var(--blue-two);
      font-size: 2.25vh;
      font-weight: 550;
      padding-right: 0.5vw;
    "
    >Time :</label
  >
  <!-- <input readonly
    matInput
    [ngxTimepicker]="fromTimePicker"
    [(ngModel)]="fromTime"
    placeholder="Select time"
    readonly
  />
  <ngx-material-timepicker #fromTimePicker></ngx-material-timepicker> -->
  <select
    [(ngModel)]="fromTime"
    style="background-color: white; border-color: var(--blue-two)"
  >
    <option *ngFor="let time of times" [value]="time.value">
      {{ time.name }}
    </option>
  </select>
</div>

<div style="display: flex; align-items: center">
  <label
    style="
      color: var(--blue-two);
      font-size: 2.25vh;
      font-weight: 550;
      padding-right: 0.5vw;
    "
    >To Date :</label
  >
  <input readonly
    matInput
    [matDatepicker]="toDatePicker"
    [(ngModel)]="toDate"
    placeholder="Choose a date"
    style="
      background-color: white;
      border: 1.2px solid var(--blue-two);
      height: 32px;
      border-radius: 7px;
      padding-left: 10px;
    "
    readonly
  />
  <mat-datepicker-toggle
    matSuffix
    [for]="toDatePicker"
  ></mat-datepicker-toggle>
  <mat-datepicker #toDatePicker></mat-datepicker>
</div>

<div>
  <label
    style="
      color: var(--blue-two);
      font-size: 2.25vh;
      font-weight: 550;
      padding-right: 0.5vw;
    "
    >Time :</label
  >
  <!-- <input readonly
    matInput
    [ngxTimepicker]="fromTimePicker"
    [(ngModel)]="fromTime"
    placeholder="Select time"
    readonly
  />
  <ngx-material-timepicker #fromTimePicker></ngx-material-timepicker> -->
  <select
    [(ngModel)]="toTime"
    style="background-color: white; border-color: var(--blue-two)"
  >
    <option
      *ngFor="let time of times"
      [value]="time.value"
      style="max-height: 1000px; overflow-y: auto"
    >
      {{ time.name }}
    </option>
  </select>
</div>

</div>

<div class="button-group">
    <button class="save-btn" (click) =SendData()>Show Alarms</button>
    <button *ngIf="isresponse()"
    (click)="downloadPDF()"
    style="margin-left: 20px;"
    >
    Download
    </button>
  </div>

 


  
  <div class="form-container">
    <div class="alarms-container">
      <!-- Search Input -->
      <mat-form-field >
        <mat-label>Search</mat-label>
        <input  matInput (keyup)="applyFilter($event)" placeholder="Search alarms" />
      </mat-form-field>
  
      <!-- Data Table -->
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
        <!-- Equipment Column -->
        <ng-container matColumnDef="equipment">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Equipment </th>
          <td mat-cell *matCellDef="let alarm"> {{ alarm.equipment }} </td>
        </ng-container>
  
        <!-- Alarm Title Column -->
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Alarm </th>
          <td mat-cell *matCellDef="let alarm"> {{ alarm.title }} </td>
        </ng-container>
  
        <!-- Start Time Column -->
        <ng-container matColumnDef="time">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Start Time </th>
          <td mat-cell *matCellDef="let alarm"> {{ alarm.time }} </td>
        </ng-container>
  
        <!-- Description Column -->
        <!-- <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef> Description </th>
          <td mat-cell *matCellDef="let alarm"> {{ alarm.description }} </td>
        </ng-container> -->
  
        <!-- Comment Column -->
        <ng-container matColumnDef="comment">
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let alarm"> {{ isalarmOff(alarm) ? alarm.Comment : '-' }} </td>
        </ng-container>
  
        <!-- Closing Time Column -->
        <ng-container matColumnDef="closingTime">
          <th mat-header-cell *matHeaderCellDef> Close Time </th>
          <td mat-cell *matCellDef="let alarm"> {{ isalarmOff(alarm) ? alarm.Alarm_Closing_Time_from_frontend : '-' }} </td>
        </ng-container>
  
        <!-- Header Row -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <!-- Data Rows -->
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
  
      <!-- Paginator -->
      <mat-paginator [length]="dataSource?.data?.length || 0" [pageSize]="10" [pageSizeOptions]="[5, 10, 25]" aria-label="Select page"></mat-paginator>
    </div>
  </div>
  

</div>







