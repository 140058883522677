<div class="main-container">
    <div class="sidebar">
      <div class="menu-items">
        <div class="menu-item" routerLink="../command">AHU Command</div>
        <div class="menu-item" routerLink="../pid-tuning">PID Tuning Command </div>
        <div class="menu-item" routerLink="../chillerplant-command">Chiller Plant Command</div>
        <div class="menu-item" routerLink="../ahuschedule-command">AHU Schedule Command</div>
      </div>
    </div>
    <app-loading-spinner *ngIf="isloading" ></app-loading-spinner>

    <div class="content">
      <div class="command-section">
        <h2>CHILLER PLANT COMMAND</h2>
        <div class="command-input-container">
          <input  [(ngModel)]="command" placeholder="Please enter Command key!" type="password" class="password-input" />
          <button class="show-command-btn" (click)="showCommand()">Show Command</button>
        </div>
        <div *ngIf="errorMessage" class="error-message" >{{errorMessage}}</div>

        <div *ngIf="showDetails">
          <label>Plant Components</label>
          <select [(ngModel)]="selectedComponent" (change)="onComponentChange()">
            <option value="Chillers">Chillers</option>
            <option value="Cooling Towers">Cooling Towers</option>
            <option value="Condenser Pumps">Condenser Pumps</option>
            <option value="Primary Pumps">Primary Pumps</option>
            <option value="Secondary Pumps">Secondary Pumps</option>
          </select>

          <div *ngIf="selectedComponent=='Chillers'">
            <div *ngFor="let component of components" class="main-radio">
           
              <div class="radio-group">
                <label>{{ component.name }} On/Off</label>
                <label>
                  <input  type="radio" name="{{ component.name }}_status" value="ON" [(ngModel)]="selectedStatuses[component.name + '_status']" /> ON
                </label>
                <label>
                  <input  type="radio" name="{{ component.name }}_status" value="OFF" [(ngModel)]="selectedStatuses[component.name + '_status']" /> OFF
                </label>
              </div>
    
              <div class="radio-group" *ngIf="component.hasValves">
                <label>{{ component.name }} Evap Valve</label>
                <label>
                  <input  type="radio" name="{{ component.name }}_evap_valve" value="OPEN" [(ngModel)]="selectedStatuses[component.name + '_evap_valve']" /> OPEN
                </label>
                <label>
                  <input  type="radio" name="{{ component.name }}_evap_valve" value="CLOSE" [(ngModel)]="selectedStatuses[component.name + '_evap_valve']" /> CLOSE
                </label>
              </div>
    
              <div class="radio-group" *ngIf="component.hasCondValves">
                <label>{{ component.name }} Cond Valve</label>
                <label>
                  <input  type="radio" name="{{ component.name }}_cond_valve" value="OPEN" [(ngModel)]="selectedStatuses[component.name + '_cond_valve']" /> OPEN
                </label>
                <label>
                  <input  type="radio" name="{{ component.name }}_cond_valve" value="CLOSE" [(ngModel)]="selectedStatuses[component.name + '_cond_valve']" /> CLOSE
                </label>
              </div>
            </div>

          </div>

           <div *ngIf="selectedComponent=='Cooling Towers'" >
            <div *ngFor="let component of components" class="main-radio">
           
              <div class="radio-group">
                <label>{{ component.name }} Valve</label>
                <label>
                  <input  type="radio" name="{{ component.name }}_val" value="OPEN" [(ngModel)]="selectedStatuses[component.name + '_val']" /> OPEN
                </label>
                <label>
                  <input  type="radio" name="{{ component.name }}_val" value="CLOSE" [(ngModel)]="selectedStatuses[component.name + '_val']" /> CLOSE
                </label>
              </div>
    
              <div class="radio-group">
                <label>{{ component.name }} Fan1 On/Off</label>
                <label>
                  <input  type="radio" name="{{ component.name }}_fan1_on_off" value="ON" [(ngModel)]="selectedStatuses[component.name + '_fan1_on_off']" /> ON
                </label>
                <label>
                  <input  type="radio" name="{{ component.name }}_fan1_on_off" value="OFF" [(ngModel)]="selectedStatuses[component.name + '_fan1_on_off']" /> OFF
                </label>
              </div>
    
              <div class="radio-group" >
                <label>{{ component.name }} Fan1 Speed</label>
                <label>  <input  class="radio-group-input" [(ngModel)]="selectedStatuses[component.name + '_fan1_speed']" type="number">
                </label>
              </div>

              <div class="radio-group">
                <label>{{ component.name }} Fan2 On/Off</label>
                <label>
                  <input  type="radio" name="{{ component.name }}_fan2_on_off" value="ON" [(ngModel)]="selectedStatuses[component.name + '_fan2_on_off']"/> ON
                </label>
                <label>
                  <input  type="radio" name="{{ component.name }}_fan2_on_off" value="OFF"[(ngModel)]="selectedStatuses[component.name + '_fan2_on_off']" /> OFF
                </label>
              </div>
    
              <div class="radio-group" >
                <label>{{ component.name }} Fan2 Speed</label>
                <label>  <input  class="radio-group-input" [(ngModel)]="selectedStatuses[component.name + '_fan2_speed']" type="number">
                </label>
              </div>
            </div>


          </div>

          <div  *ngIf="selectedComponent=='Secondary Pumps'">
            <div *ngFor="let component of components" class="main-radio">
              <div class="radio-group">
                <label>{{ component.name }}  On/Off</label>
                <label>
                  <input  type="radio" name="{{ component.name }}_on_off" value="ON" [(ngModel)]="selectedStatuses[component.name + '_on_off']" /> ON
                </label>
                <label>
                  <input  type="radio" name="{{ component.name }}_on_off" value="OFF"  [(ngModel)]="selectedStatuses[component.name + '_on_off']"> OFF
                </label>
              </div>
    
              <div class="radio-group" >
                <label>{{ component.name }}  Speed</label>
                <label>  <input  class="radio-group-input" [(ngModel)]="selectedStatuses[component.name + '_speed']" type="number">
                </label>
                
              </div>
            </div>


          </div>
          
          <div *ngIf="selectedComponent=='Primary Pumps'" >
            <div *ngFor="let component of components" class="main-radio">
              <div class="radio-group">
                <label>{{ component.name }} On/Off</label>
                <label>
                  <input  type="radio" name="{{ component.name }}_on_off" value="ON" [(ngModel)]="selectedStatuses[component.name + '_on_off']"/> ON
                </label>
                <label>
                  <input  type="radio" name="{{ component.name }}_on_off" value="OFF" [(ngModel)]="selectedStatuses[component.name + '_on_off']" /> OFF
                </label>
              </div>
    
              <div class="radio-group" >
                <label>{{ component.name }} Speed</label>
                <label>  <input  class="radio-group-input" [(ngModel)]="selectedStatuses[component.name + '_speed']" type="number">
                </label>
                
              </div>
          </div>
          </div>

          <div *ngIf="selectedComponent=='Condenser Pumps'" >
            <div *ngFor="let component of components" class="main-radio">
              <div class="radio-group">
                <label>{{ component.name }} On/Off</label>
                <label> 
                  <input  type="radio" name="{{ component.name }}_on_off" value="ON" [(ngModel)]="selectedStatuses[component.name + '_on_off']" /> ON
                </label>
                <label>
                  <input  type="radio" name="{{ component.name }}_on_off" value="OFF" [(ngModel)]="selectedStatuses[component.name + '_on_off']" /> OFF
                </label>
              </div>
          </div>


          </div>
         
  
          <div class="button-group">
            <button class="clear-btn" (click)="clearSelection()">Clear</button>
            <button class="send-btn" (click)="sendCommand()" >Send</button>
          </div>
        </div>
        <p class="waiting-text" *ngIf="isLoading">Waiting for the Command!</p>
        <p *ngIf="responseMessage"  
        [ngClass]="{
          'response-message': responseMessage.startsWith('Command sent successfully!!'), 
          'error-message': !responseMessage.startsWith('Command sent successfully!!')
        }">
       {{responseMessage}}
     </p>    </div>
  </div>
  