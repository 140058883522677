import { Component, Inject, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { LogoutDialogComponent } from '../logout-dialog/logout-dialog.component';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'Nav-bar',
  standalone: true,
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css'],
  imports: [MatButtonModule, MatDialogModule],
})
export class NavBarComponent {
  title: any;

  constructor(public dialog: MatDialog, private router: Router) {


    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        var endpoint = this.cropUrl(event.url);
        this.title = this.url_dictionary.get(endpoint);
      }
    });
  }

  showLogoutDialog() {
    const dialogRef = this.dialog.open(LogoutDialogComponent, {
      width: '350px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  cropUrl(url: string): string {
    var splitted = url.split('/');
    console.log(splitted);
    return splitted[splitted.length - 1];
  }


  url_dictionary = new Map<string, string>([
    ['home', 'Home'],
    ['home-two', 'Home'],
    ['level0', 'Level 0'],
    ['level1', 'Level 1'],
    ['level2', 'Level 2'],
    ['level3', 'Level 3'],
    ['level4', 'Level 4'],
    ['level5', 'Level 5'],
    ['account-office-ahu', 'Account Office AHU'],
    ['banquet-hall-1-ahu', 'BANQUET HALL 1'],
    ['banquet-hall-2-ahu', 'BANQUET HALL 2'],
    ['banquet-hall-3-ahu', 'BANQUET HALL 3'],
    ['banquet-hall-4-ahu', 'BANQUET HALL 4'],
    ['banquet-hall-5-ahu', 'BANQUET HALL 5'],
    ['banquet-hall-6-ahu', 'BANQUET HALL 6'],
    ['banquet-hall-7-ahu', 'BANQUET HALL 7'],
    ['banquet-hall-8-ahu', 'BANQUET HALL 8'],
    ['banquet-hall-9-ahu', 'BANQUET HALL 9'],
    ['banquet-hall-10-ahu', 'BANQUET HALL 10'],
    ['banquet-hall-11-ahu', 'BANQUET HALL 11'],
    ['banquet-hall-12-ahu', 'BANQUET HALL 12'],
    ['banquet-hall-13-ahu', 'BANQUET HALL 13'],
    ['banquet-hall-14-ahu', 'BANQUET HALL 14'],
    ['banquet-hall-15-ahu', 'BANQUET HALL 15'],
    ['banquet-sales-ahu', 'BANQUET SALES'],
    ['banquet-lobby-ahu', 'BANQUET LOBBY'],
    ['banquet-toilet-ahu', 'BANQUET TOILET'],
    ['restaurant-2-ahu', 'RESTAURANT 2'],
    ['cafeteria-ahu', 'CAFETERIA'],
    ['restaurant-1-ahu', 'RESTAURANT 1'],
    ['ladies-locker-room-ahu', 'LADIES LOCKER ROOM'],
    ['barber-shop-ahu', 'BARBER SHOP'],
    ['laundry-ahu', 'LAUNDRY'],
    ['shahjahan-hall', 'Shahjahan Hall'],
    ['durbar-hall', 'Durbar Hall'],
    ['alamgir-hall', 'Alamgir Hall'],
    ['jahangir-hall', 'Jahangir Hall'],
    ['banquet-hall', 'Banquet Hall'],
    ['cafeteria-hall', 'Cafeteria Hall'],
    ['publicarea-hall', 'Public Area Hall'],
    ['vazir-hall', 'Vazir Hall'],
    // shahjahan hall ahus
    ['ahu1', 'Shahjahan Hall AHU-1'],
    ['ahu2', 'Shahjahan Hall AHU-2'],
    ['ahu3', 'Shahjahan Hall AHU-3'],
    ['ahu4', 'Shahjahan Hall AHU-4'],
    ['ahu5', 'Shahjahan Hall AHU-5'],
    ['ahu6', 'Shahjahan Hall AHU-6'],
    // durbar hall ahus
    ['ahudurbar1', 'Durbar Hall AHU-1'],
    ['ahudurbar2', 'Durbar Hall AHU-2'],
    ['ahudurbar3', 'Durbar Hall AHU-3'],
    ['ahudurbar4', 'Durbar Hall AHU-4'],
    ['ahudurbar5', 'Durbar Hall AHU-5'],
    ['ahudurbar6', 'Durbar Hall AHU-6'],
    // Alamgir ahus
    ['ahualamgir1', 'Alamgir Hall AHU-1'],
    // Jahangir ahus
    ['ahujahangir1', 'Jahangir Hall AHU-1'],
    // Banquet ahus
    ['ahubanquet1', 'Banquet Sales AHU'],
    ['ahubanquet2', 'Banquet Lobby AHU'],
    ['ahubanquet3', 'Banquet Toilet AHU'],
    // Cafeteria ahus
    ['ahucafe1', 'Prep Kitchen AHU'],
    ['ahucafe2', 'Halwai Section AHU'],
    ['ahucafe3', 'Big Cafeteria AHU'],
    ['ahucafe4', 'Khazana AHU'],
    // Public area ahus
    ['ahupublic1', 'Interface/Gym AHU'],
    ['ahupublic2', 'Ladies Locker/Time Office AHU'],
    ['ahupublic3', 'Barber Shop AHU'],
    ['ahupublic4', 'Laundry AHU'],
    // Vazir ahus
    ['ahuvazir1', 'Vazir AHU-1'],



    // Blocks
    ["block-a", "A Block"],
    ["block-b1", "B1 Block"],
    ["block-b2w", "B2W Block"],
    ["block-b2e", "B2E Block"],
    ["block-d1", "D1 Block"],
    ["block-d2", "D2 Block"],
    ["block-d3", "D3 Block"],
    ["block-d4", "D4 Block"],
    ["block-d5", "D5 Block"],
    ["block-e", "E Block"],
    ["block-f", "F Block"],

    ["chiller-plant", "Chiller Plant"],
    // 47 AHU
    ["AL2", "AL2"],
    ["AMezz", "AMezz"],
    ["AUB", "AUB"],
    ["AL0", "AL0 "],
    ["AL3E", "AL3E"],
    ["AL3W", "AL3W"],
    ["B1GS", "B1GS"],
    ["B1L0", "B1L0"],
    ["B1L1", "B1L1"],
    ["B1L2", "B1L2"],
    ["B1L3", "B1L3"],
    ["B1L4", "B1L4"],
    ["B2WUB", "Sodexo Canteen AHU"],
    ["B2EFC", "B2EFC"],
    ["B2EL1", "B2EL1"],
    ["B2EL2", "B2EL2"],
    ["B2EL3", "B2EL3"],
    ["B2EL4", "B2EL4"],
    ["B2WFC", "B2WFC"],
    ["B2WKK", "B2WKK"],
    ["B2WL1", "B2WL1"],
    ["B2WL2", "B2WL2"],
    ["B2WL3", "B2WL3"],
    ["B2WL4", "B2WL4"],
    ["B2WMR", "B2WMR"],
    ["B2EGS", "B2EGS"],
    ["B2ETFA", "B2ETFA"],
    ["B2WGS", "B2WGS"],
    ["D1L0", "D1L0"],
    ["D1L1", "D1L1"],
    ["D1L2", "D1L2"],
    ["D1L3", "D1L3"],
    ["D1L4", "D1L4"],
    ["D2L0", "D2L0"],
    ["D2L1", "D2L1"],
    ["D2L2", "D2L2"],
    ["D2L3", "D2L3"],
    ["D2L4", "D2L4"],
    ["D3L0", "D3L0"],
    ["D3L1", "D3L1"],
    ["D3L2", "D3L2"],
    ["D3L4", "D3L4"],
    ["D4GS", "D4GS"],
    ["D4L0", "D4L0"],
    ["D4L1", "D4L1"],
    ["D4L2", "D4L2"],
    ["D4L3", "D4L3"],
    ["D4L4", "D4L4"],
    ["D5GS", "D5GS"],
    ["ESquash", "ESquash"],
    ["EAudi1", "EAudi1"],
    ["EAudi2", "EAudi2"],
    ["EDayE", "EDayE"],
    ["EDayW", "EDayW"],
    ["ERec", "ERec"],
    ["EGame", "EGame"],
    ["ELounge", "ELounge"],
    ["EL2Lobby", "EL2Lobby"],
    ["EL3Lobby", "EL3Lobby"],
    ["F_LG_A&B%20Wing", "A & B Wing-LG AHU"],
    ["F_LG_C&D%20Wing", "C & D Wing-LG AHU"],
    ["F_LG_LB_LR%203", "Life Boy Training Hall (LR 3) AHU -B Wing-LG AHU"],
    ["F_L_A&B%20Wing", "A&B Wing-L AHU"],
    ["F_L_C&D%20Wing", "C&D Wing--L AHU"],
    ["F_L_SE_LR%201", "Serf Excle Training Hall (LR 1) AHU-B Wing-L AHU"],
    ["F_L_TM_LR_2", "Taj Mahal Training Hall (LR 2) AHU-C Wing-L AHU"],
    ["F_L_Rec", "Reception-L AHU"],
    ["F_L_Back_O", "Back Office-L AHU"],
    ["F_L2_TFA", "TFA-Second AHU"],
    ["F_L3_TFA", "TFA-Third AHU"],
    ["F_L4_TFA", "TFA-Fourth AHU"],
    ["f-chiller-plant", "Chiller(AC)"],

    //Chiller Plant
    ["primary-pump", "Primary Pump"],
    ["chiller", "Chiller"],
    ["cooling-tower", "Cooling Tower"],
    ["cooling-tower-b", "Cooling Tower"],
    ["condenser-pump", "Condenser Pump"],
    ["secondary-pump", "Secondary Pump"],
    ["chiller1", "Chiller 1"],
    ["chiller2", "Chiller 2"],
    ["chiller3", "Chiller 3"],
    ["chiller4", "Chiller 4"],
    ["zone-a", "Secondary Pump"],
    ["zone-b", "Secondary Pump"],
    ["zone-c", "Secondary Pump"],
    ["cooling-tower1", "Cooling Tower 1"],
    ["cooling-tower2", "Cooling Tower 2"],
    ["cooling-tower3", "Cooling Tower 3"],
    ["cooling-tower4", "Cooling Tower 4"],

    // PMV 
    ['pmv', 'L5 PMV'],
    ['ahu', 'L5 AHU'],
    ['pmv-bar', 'BAR'],
    ['pmv-entrance', 'ENTRANCE'],
    ['pmv-female-washroom', 'FEMALE WASHROOM'],
    ['pmv-restaurant-lobby', 'RESTAURANT LOBBY'],
    ['pmv-restaurant-one', 'RESTAURANT ONE'],
    ['pmv-restaurant-two', 'RESTAURANT TWO']

  ]);
}
