<div class="b-one-comp">
  <svg id="b-one-comp" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%"
    height="100%" viewBox="0 0 250.267 237.927">
    <defs>
      <linearGradient id="linear-gradient-b1" x1="1" y1="0.429" x2="-0.322" y2="0.6" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#f2fafd" />
        <stop offset="1" stop-color="#e4f4fc" />
      </linearGradient>
      <linearGradient id="linear-gradient-b1-2" x1="1.041" y1="0.417" x2="-0.14" y2="0.597"
        xlink:href="#linear-gradient-b1" />
      <linearGradient id="linear-gradient-b1-3" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#ccdfe8" />
        <stop offset="1" stop-color="#8ea6b2" />
      </linearGradient>
      <linearGradient id="linear-gradient-b1-4" x1="0.696" y1="0.5" x2="-0.179" y2="0.698"
        gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#fff" />
        <stop offset="1" stop-color="#e4f4fc" />
      </linearGradient>
      <filter id="Rectangle_1269" x="54.42" y="40.957" width="159" height="64" filterUnits="userSpaceOnUse">
        <feOffset dx="3" dy="3" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="4.5" result="blur" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Component_68_5" data-name="Component 68 – 5" transform="translate(0.5)">
      <g id="Group_3618" data-name="Group 3618" transform="translate(-13680.536 2130.104)">
        <g id="Group_3617" data-name="Group 3617">
          <path id="Path_4642" data-name="Path 4642"
            d="M1002.376,279.571l-135.45-76.149L753.918,268.8v91.113l133.61,77.743,48.35-27.37v-3.892l14.8-9.141,2.558,1.729,49.142-27.9Z"
            transform="translate(12927.593 -2331.241)" fill="#ababab" />
          <path id="Path_4546" data-name="Path 4546" d="M738.8,489.744v-92.72l17.548-10.134v92.72Z"
            transform="translate(13121.469 -2413.917)" fill="#c4c4c4" stroke="#427894" stroke-miterlimit="10"
            stroke-width="1" />
          <path id="Path_4547" data-name="Path 4547" d="M700.14,518.862v-92.72l48.278-27.882v92.72Z"
            transform="translate(13114.628 -2411.905)" fill="url(#linear-gradient-b1)" stroke="#79a7bf"
            stroke-miterlimit="10" stroke-width="1" />
          <path id="Path_4548" data-name="Path 4548" d="M756.86,485.77V393.05l48.279-27.87v92.708Z"
            transform="translate(13124.664 -2417.759)" fill="url(#linear-gradient-b1-2)" stroke="#79a7bf"
            stroke-miterlimit="10" stroke-width="1" />
          <path id="Path_4549" data-name="Path 4549" d="M720.262,525.767,586.09,448.3V355.58l134.172,77.467Z"
            transform="translate(13094.446 -2419.458)" fill="url(#linear-gradient-b1-3)" stroke="#427894"
            stroke-miterlimit="10" stroke-width="1" />
          <path id="Path_4550" data-name="Path 4550"
            d="M588.54,365.405,700.586,300.72,832.3,376.763l-45.513,26.27-3.9-2.26-21.032,12.146,3.907,2.26-45.513,26.27Z"
            transform="translate(13094.88 -2429.165)" fill="url(#linear-gradient-b1-4)" stroke="#427894" />
          <path id="Path_4551" data-name="Path 4551"
            d="M700.994,302.57l128.923,74.489-42.723,24.716-2.507-1.448-1.389-.8-1.389.8-18.231,10.428-4.166,2.413,3.9,2.248-42.723,24.716L591.726,365.655l43.665-25.175.518-.294,18.243-10.593.518-.294,46.384-26.776Zm.059-3.26L653.269,326.9l-.506.294-18.254,10.534-.506.294L586.23,365.608l134.49,77.679L768.975,415.4l-3.907-2.248,18.255-10.534,3.9,2.248,48.255-27.811L700.994,299.381Z"
            transform="translate(13094.472 -2429.415)" fill="#96bdd1" />
          <path id="Path_4552" data-name="Path 4552" d="M761.86,397.477l38.133-22.127-.165,14.017L762.06,411.176Z"
            transform="translate(13125.549 -2415.959)" fill="#accee0" />
          <path id="Path_4553" data-name="Path 4553" d="M761.86,414.807l38.133-22.127-.165,14.017L762.06,428.506Z"
            transform="translate(13125.549 -2412.893)" fill="#accee0" />
          <path id="Path_4554" data-name="Path 4554" d="M761.86,431.347l38.133-22.127-.165,14.018L762.06,445.046Z"
            transform="translate(13125.549 -2409.966)" fill="#accee0" />
          <path id="Path_4555" data-name="Path 4555" d="M761.86,448.677l38.133-22.127-.165,14.029-37.768,21.8Z"
            transform="translate(13125.549 -2406.899)" fill="#accee0" />
          <path id="Path_4556" data-name="Path 4556" d="M705.14,431.347l38.133-22.127-.165,14.018-37.78,21.809Z"
            transform="translate(13115.513 -2409.966)" fill="#accee0" />
          <path id="Path_4557" data-name="Path 4557" d="M705.14,448.677l38.133-22.127-.165,14.029-37.78,21.8Z"
            transform="translate(13115.513 -2406.899)" fill="#accee0" />
          <path id="Path_4558" data-name="Path 4558" d="M705.14,465.227,743.273,443.1l-.165,14.018-37.78,21.809Z"
            transform="translate(13115.513 -2403.971)" fill="#accee0" />
          <path id="Path_4559" data-name="Path 4559" d="M705.14,482.557l38.133-22.127-.165,14.017-37.78,21.809Z"
            transform="translate(13115.513 -2400.904)" fill="#accee0" />
          <path id="Path_4560" data-name="Path 4560" d="M602.147,388.087l-9.627-5.567V369.68l9.627,5.555Z"
            transform="translate(13095.584 -2416.962)" fill="#87abbe" />
          <path id="Path_4561" data-name="Path 4561" d="M602.147,404.457l-9.627-5.567V386.05l9.627,5.555Z"
            transform="translate(13095.584 -2414.066)" fill="#87abbe" />
          <path id="Path_4562" data-name="Path 4562" d="M602.147,420.827l-9.627-5.555V402.42l9.627,5.567Z"
            transform="translate(13095.584 -2411.169)" fill="#87abbe" />
          <path id="Path_4563" data-name="Path 4563" d="M602.147,437.207l-9.627-5.567V418.8l9.627,5.555Z"
            transform="translate(13095.584 -2408.271)" fill="#87abbe" />
          <path id="Path_4564" data-name="Path 4564" d="M620.568,398.717l-9.627-5.555V380.31l9.627,5.567Z"
            transform="translate(13098.844 -2415.082)" fill="#87abbe" />
          <path id="Path_4565" data-name="Path 4565" d="M620.568,415.1l-9.627-5.567V396.68l9.627,5.567Z"
            transform="translate(13098.844 -2412.185)" fill="#87abbe" />
          <path id="Path_4566" data-name="Path 4566" d="M620.568,431.467,610.94,425.9V413.06l9.627,5.555Z"
            transform="translate(13098.844 -2409.287)" fill="#87abbe" />
          <path id="Path_4567" data-name="Path 4567" d="M620.568,447.837l-9.627-5.555V429.43L620.568,435Z"
            transform="translate(13098.844 -2406.39)" fill="#87abbe" />
          <path id="Path_4568" data-name="Path 4568" d="M638.987,409.359l-9.627-5.567V390.94l9.627,5.567Z"
            transform="translate(13102.104 -2413.201)" fill="#87abbe" />
          <path id="Path_4569" data-name="Path 4569" d="M638.987,425.727l-9.627-5.567V407.32l9.627,5.567Z"
            transform="translate(13102.104 -2410.302)" fill="#87abbe" />
          <path id="Path_4570" data-name="Path 4570" d="M638.987,442.1l-9.627-5.555V423.69l9.627,5.567Z"
            transform="translate(13102.104 -2407.405)" fill="#87abbe" />
          <path id="Path_4571" data-name="Path 4571" d="M638.987,458.478l-9.627-5.567V440.07l9.627,5.555Z"
            transform="translate(13102.104 -2404.507)" fill="#87abbe" />
          <path id="Path_4572" data-name="Path 4572" d="M657.419,419.988l-9.639-5.555V401.58l9.639,5.567Z"
            transform="translate(13105.362 -2411.318)" fill="#87abbe" />
          <path id="Path_4573" data-name="Path 4573" d="M657.419,436.369,647.78,430.8V417.95l9.639,5.567Z"
            transform="translate(13105.362 -2408.421)" fill="#87abbe" />
          <path id="Path_4574" data-name="Path 4574" d="M657.419,452.737l-9.639-5.567V434.33l9.639,5.555Z"
            transform="translate(13105.362 -2405.523)" fill="#87abbe" />
          <path id="Path_4575" data-name="Path 4575" d="M657.419,469.107l-9.639-5.555V450.7l9.639,5.567Z"
            transform="translate(13105.362 -2402.626)" fill="#87abbe" />
          <path id="Path_4576" data-name="Path 4576" d="M675.827,430.629l-9.627-5.567V412.21l9.627,5.567Z"
            transform="translate(13108.622 -2409.437)" fill="#87abbe" />
          <path id="Path_4577" data-name="Path 4577" d="M675.827,447l-9.627-5.567V428.59l9.627,5.567Z"
            transform="translate(13108.622 -2406.539)" fill="#87abbe" />
          <path id="Path_4578" data-name="Path 4578" d="M675.827,463.367l-9.627-5.555V444.96l9.627,5.567Z"
            transform="translate(13108.622 -2403.642)" fill="#87abbe" />
          <path id="Path_4579" data-name="Path 4579" d="M675.827,479.747l-9.627-5.567V461.34l9.627,5.555Z"
            transform="translate(13108.622 -2400.743)" fill="#87abbe" />
          <path id="Path_4580" data-name="Path 4580" d="M694.247,441.258l-9.627-5.567V422.85l9.627,5.567Z"
            transform="translate(13111.881 -2407.554)" fill="#87abbe" />
          <path id="Path_4581" data-name="Path 4581" d="M694.247,457.639l-9.627-5.567V439.22l9.627,5.567Z"
            transform="translate(13111.881 -2404.657)" fill="#87abbe" />
          <path id="Path_4582" data-name="Path 4582" d="M694.247,474.008l-9.627-5.567V455.6l9.627,5.555Z"
            transform="translate(13111.881 -2401.759)" fill="#87abbe" />
          <path id="Path_4583" data-name="Path 4583" d="M694.247,490.378l-9.627-5.555V471.97l9.627,5.567Z"
            transform="translate(13111.881 -2398.862)" fill="#87abbe" />
        </g>
        <g transform="matrix(1, 0, 0, 1, 13680.04, -2130.1)" filter="url(#Rectangle_1269)">
          <rect id="Rectangle_1269-2" data-name="Rectangle 1269" width="132" height="37" rx="10"
            transform="translate(64.92 51.46)" fill="#529ec6" />
        </g>
        <text id="B1_Block" data-name="B1 Block" transform="translate(13744.956 -2070.648)" fill="#fff" font-size="18"
          font-family="Mulish-Regular, Mulish">
          <tspan x="28.584" y="16">B1 Block</tspan>
        </text>
      </g>
    </g>
  </svg>
</div>