import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { LoadingService } from 'src/app/loading.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  loading$ = this.loadingService.loading$;

  constructor(private loadingService: LoadingService, private router: Router) {}


  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        const url = event.url;
        if (url.startsWith('/dashboard/') && url!=='/dashboard/logout') {
          // Check if the page is either cooling-tower or chiller
          const page = url.split('/').pop();
          if (page !== 'home') {
            this.loadingService.showLoading();
          }
        }
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
        setTimeout(() => {
          this.loadingService.hideLoading();
        }, 1500); // Ensure loading is shown for at least 2 seconds
      }
    });
  }
}