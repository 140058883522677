import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { WebSocketService } from '../services/web-socket.service';
import { environment } from 'src/environments/environment';
import { ErrorMonitoringService } from '../services/error-monitoring.service';
import { CustomSnackbarComponent } from '../pages/custom-snackbar/custom-snackbar.component';
@Component({
  selector: 'app-ahu-template',
  templateUrl: './ahu-template.component.html',
  styleUrls: ['./ahu-template.component.css'],
})
export class AhuTemplateComponent {
  @Input() data!: string;
  jsonData: any = {};
  alarmlog : any[]=[];
   aliasMap: { [key: string]: string } = {};
  room_name: string="room";

  // input form fields
  RAT_real: any = 0;
  // AMBIENT_TEMP: number = 0;
  SAT_real: any = 0;
  CHW_VALVE_real: any = 0;
  FAN_SPEED_real: any = 0;
  STASP_ZONE_A: any = 0;
  supply_dpt_real:any=0;
  fan_power_real:any=0;
  damper_co2_relay:any=0;
amb_temp_relay:any=0;
  // output form fields
  RAT_out: any = 0;
  CHW_VALVE_out: any = 0;
  POWER_out: any = 0;
  FAN_SPEED_out: any = 0;
  SDPT_out: any = 0;
  SAT_out: any = 0;

  RAT_in: any = 0;
  CHW_VALVE_in: any = 0;
  POWER_in: any = 0;
  FAN_SPEED_in: any = 0;
  SDPT_in: any = 0;
  SAT_in: any = 0;

  // KPI DATA
  KPI: number = 0;
  state: string = 'active';

  // variables inside
  predict_input: any = {};
  predict_response: any = {};
  predict_url: string = '';
  optimise_input: any = {};
  optimise_response: any = {};
  tag: string = '';
  model_no: number = 0;
  id: string = '';
  name: string = '';
  type: number = 1;
  isDisabled: boolean = false;
  capsule_group = 'input-group';
  stap_zone_label: string = '';
  predictionResult: any;
  optiResult:any;
  interfaceResult: any;
  envResult: any;
  realResult:any;
  AMBIENT_TEMP_out: any = 0;

  cassandra_data: any = {};

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private webSocketService: WebSocketService,
    private errorMonitoringService:ErrorMonitoringService

  ) {
    this.route.params.subscribe((params) => {
      const myVariable = params['id'];
      this.id = myVariable;
      // // console.log(myVariable);
     // console.log('id: ', this.id);
    this.room_name=this.id;
        

      

      try {
        this.data = String(localStorage.getItem(this.id));

        this.jsonData = JSON.parse(this.data);
        this.tag = this.jsonData['tag'];
        this.model_no = this.jsonData['model_no.'];
        this.name = this.jsonData['name'];
        this.type = this.jsonData['type'];
        this.isDisabled = this.jsonData['isDisabled'];
        this.stap_zone_label = this.jsonData['formLabelSTAP'];
        // console.log("umaaaaaaaa",this.tag);

        // if (this.isDisabled) {
        //   this.capsule_group = 'input-group2';
        // } else {
        //   this.capsule_group = 'input-group';
        // }

        // // console.log("data: ", this.data);
        // console.log('jsonData: ', this.jsonData);

        if (this.jsonData.hasOwnProperty('cassandra_data')) {
          this.cassandra_data = this.jsonData.cassandra_data
        } else {
          this.cassandra_data = {}
        }
// Retrieve aliasMap from localStorage
// Retrieve aliasMap from localStorage
const storedAliasMap = localStorage.getItem('aliasMap');
if (storedAliasMap) {
  this.aliasMap = JSON.parse(storedAliasMap);
} else {
  // Initialize aliasMap as an empty object if nothing is in localStorage
  this.aliasMap = {};
}


// Retrieve realResult from localStorage
if (localStorage.getItem('realData')) {
  this.realResult = localStorage.getItem('realData');
  this.realResult = JSON.parse(this.realResult);

  // Now assign the values from realResult using aliasMap
  if (this.aliasMap && this.aliasMap[`${this.tag}`]) {
    this.supply_dpt_real = this.realResult[this.aliasMap[`${this.tag}`]]['data']['supply_dpt'];
    this.RAT_real = this.realResult[this.aliasMap[`${this.tag}`]]['data']['rat'];
    this.SAT_real = this.realResult[this.aliasMap[`${this.tag}`]]['data']['sat'];
    this.fan_power_real = this.realResult[this.aliasMap[`${this.tag}`]]['data']['T_power'];
    this.FAN_SPEED_real = this.realResult[this.aliasMap[`${this.tag}`]]['data']['fan_speed'];
    this.CHW_VALVE_real = this.realResult[this.aliasMap[`${this.tag}`]]['data']['chw_opening'] || this.realResult[this.aliasMap[`${this.tag}`]]['data']['chw_valve_opening'];
    this.amb_temp_relay = this.realResult[this.aliasMap[`${this.tag}`]]['data']['Ambient Temp'] || 28;
  } else {
    // Handle case when aliasMap is not available or doesn't have the tag
    this.supply_dpt_real = 0;
    this.RAT_real = 0;
    this.SAT_real = 0;
    this.fan_power_real = 0;
    this.FAN_SPEED_real = 0;
    this.CHW_VALVE_real = 0;
    this.amb_temp_relay = 28; // default value
  }
} else {
  // Default values if realData is not present in localStorage
  this.supply_dpt_real = 0;
  this.RAT_real = 0;
  this.SAT_real = 0;
  this.fan_power_real = 0;
  this.FAN_SPEED_real = 0;
  this.CHW_VALVE_real = 0;
  this.amb_temp_relay = 28; // default value
}

        if (this.jsonData.isDisabled) {
          this.RAT_out = this.cassandra_data['rat'];
          this.CHW_VALVE_out = this.cassandra_data['chwv'];
          this.POWER_out = this.cassandra_data['power'];
          this.FAN_SPEED_out = this.cassandra_data['fan_speed'];
          this.SDPT_out = this.cassandra_data['sdpt'];
          this.SAT_out = this.cassandra_data['sat'];
        }

        if(localStorage.getItem('predictionResult')){
          this.predictionResult = localStorage.getItem('predictionResult');
          this.predictionResult = JSON.parse(this.predictionResult);
          this.CHW_VALVE_out = this.predictionResult[`chw_valve_opening_${this.tag}`];
          this.FAN_SPEED_out = this.predictionResult[`fan_speed_${this.tag}`];
          // this.AMBIENT_TEMP_out = this.predictionResult['Ambient temp'];
        }
        if(localStorage.getItem('interfaceResult')){
          this.interfaceResult = localStorage.getItem('interfaceResult');
          this.interfaceResult = JSON.parse(this.interfaceResult);
          this.CHW_VALVE_in = this.interfaceResult[`chw_opening_${this.tag}`];
          this.RAT_in = this.interfaceResult[`rat_${this.tag}`];
          this.SAT_in = this.interfaceResult[`sat_${this.tag}`];
          this.SDPT_in = this.interfaceResult[`supply_dpt_${this.tag}`];
          // this.FAN_SPEED_in = this.interfaceResult[`fan_speed_${this.tag}`];
          this.POWER_in = this.interfaceResult[`Power_${this.tag}`] || this.interfaceResult[`T_power_${this.tag}`];
        }else{
          this.CHW_VALVE_in = 0;
          this.RAT_in = 0;
          this.SAT_in = 0;
          this.SDPT_in = 0;
          this.FAN_SPEED_in = 0;
          this.POWER_in = 0;
        }
        if (!this.isDisabled){
          // Check if the key exists
          if (this.jsonData.hasOwnProperty('predict_input')) {
            this.predict_input = this.jsonData['predict_input'];

            // this.RAT = this.predict_input[`rat_${this.tag}`];
            // this.AMBIENT_TEMP = this.predict_input['Ambient Temp'];
            // this.SAT = this.predict_input[`Supply Air Temp_${this.tag}`];
            // this.CHW_VALVE = this.predict_input[`chw_valve_opening_${this.tag}`];
            // this.FAN_SPEED = this.predict_input[`fan_speed_${this.tag}`];
            // this.STASP_ZONE_A =
            //   this.predict_input['Supply temp After Sec Pump Zone A'];
            // this.model_no = this.predict_input['model_no.'];
          } else {
            // this.RAT = 0;
            // this.AMBIENT_TEMP = 0;
            // this.SAT = 0;
            // this.CHW_VALVE = 0;
            // this.FAN_SPEED = 0;
            // this.STASP_ZONE_A = 0;
          }
          
          if(localStorage.getItem('interfaceResult')){
            this.interfaceResult = localStorage.getItem('interfaceResult');
            this.interfaceResult = JSON.parse(this.interfaceResult);
            this.RAT_in = this.interfaceResult[`rat_${this.tag}`];
            // this.CHW_VALVE_in = this.interfaceResult[`chw_opening_${this.tag}`];
            this.POWER_in = this.interfaceResult[`Power_${this.tag}`] || this.interfaceResult[`T_power_${this.tag}`];
            // this.FAN_SPEED_in = this.interfaceResult[`fan_speed_${this.tag}`];
            this.SDPT_in = this.interfaceResult[`supply_dpt_${this.tag}`];
            this.SAT_in = this.interfaceResult[`sat_${this.tag}`];
          }else{
            this.RAT_in = 0;
            this.CHW_VALVE_in = 0;
            this.POWER_in = 0;
            this.FAN_SPEED_in = 0;
            this.SDPT_in = 0;
            this.SAT_in = 0;
          }
         
          if(localStorage.getItem('predictionResult')){
            this.predictionResult = localStorage.getItem('predictionResult');
            this.predictionResult = JSON.parse(this.predictionResult);
            this.CHW_VALVE_out = this.predictionResult[`chw_valve_opening_${this.tag}`];
            this.FAN_SPEED_out = this.predictionResult[`fan_speed_${this.tag}`];
            // this.AMBIENT_TEMP_out = this.predictionResult['Ambient temp'];
          } else if (localStorage.getItem('optimise-response')){
            this.optimise_response = localStorage.getItem('optimise-response');
            this.optimise_response = JSON.parse(this.optimise_response);

            this.predict_response = this.jsonData['predict_response'];
            this.RAT_out = this.predict_response[`rat_${this.tag}`];
            this.CHW_VALVE_out = this.optimise_response[`chw_valve_opening_${this.tag}`];
            this.POWER_out = this.optimise_response[`Power_${this.tag}`];
            this.FAN_SPEED_out = this.optimise_response[`fan_speed_${this.tag}`];
            this.SDPT_out = this.predict_response[`supply_dpt_${this.tag}`];
            this.SAT_out = this.predict_response[`Supply Air Temp_${this.tag}`];
          } else {
            if (this.jsonData.hasOwnProperty('predict_response')) {
              this.predict_response = this.jsonData['predict_response'];
              this.RAT_out = this.predict_response[`rat_${this.tag}`];
              this.CHW_VALVE_out = this.predict_response['CHW_VALVE'];
              this.POWER_out = this.predict_response[`Power_${this.tag}`];
              this.FAN_SPEED_out = this.predict_response['FAN_SPEED'];
              this.SDPT_out = this.predict_response[`supply_dpt_${this.tag}`];
              this.SAT_out = this.predict_response[`Supply Air Temp_${this.tag}`];
            } else {
              this.RAT_out = 0;
              this.CHW_VALVE_out = 0;
              this.POWER_out = 0;
              this.FAN_SPEED_out = 0;
              this.SDPT_out = 0;
              this.SAT_out = 0;
            }
          }
        }

        if (localStorage.getItem('kpi')) {
          this.KPI = Number(localStorage.getItem('kpi'));
        } else {
          this.KPI = 0;
        }

        if (this.jsonData.hasOwnProperty('predict_url')) {
          this.predict_url = this.jsonData['predict_url'];
        } else {
          this.predict_url = '';
        }
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }

      if (this.jsonData.hasOwnProperty('cassandra_data')) {
        this.cassandra_data = this.jsonData.cassandra_data;
      } else {
        this.cassandra_data = {};
      }



    })
  }



  ngOnInit(): void {
    this.viewalarmlogs();
    this.getInputResult();
    this.getPredictionResult();
    this.webSocketService.ahuData$.subscribe(data => {
      // Get data for AHU_01

      // console.log("hello ahu1",data);
      const ahuData = data[`${this.tag}`];
      if (ahuData) {
        // console.log('AHU_01 Data:', ahuData);
        this.supply_dpt_real=ahuData['supply_dpt'];
        this.RAT_real=ahuData['rat'];
          this.SAT_real=ahuData['sat'];
          this.fan_power_real=ahuData['']
          this.FAN_SPEED_real=ahuData['fan_speed'];
this.damper_co2_relay=ahuData['damper_co2'];
          this.CHW_VALVE_real=ahuData['chw_valve_opening'];
this.amb_temp_relay=ahuData[''];
        


      }
    });
    // this.router.events.subscribe((event) => {
    //   this.viewalarmlogs();
    //   this.getInputResult();
    //   this.getPredictionResult();
    //     });

    setInterval(()=>{
      this.getInputResult();
      this.getPredictionResult();
    },600000);
  }

  async viewalarmlogs(){
    this.errorMonitoringService.alarms$.subscribe(alarms => {
      this.alarmlog = alarms[`${this.tag}`];
      // console.log("from servbicve",this.alarmlog)
      if(this.alarmlog.length>0 && this.router.url==`/dashboard/ahu/${this.tag}` ){
        this.openSnackBar1();
      }
    });
    
  }
  openSnackBar1() {
    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      data: {message:this.alarmlog},
      horizontalPosition: 'start',
      duration: undefined,
    });
  }
  // http://hul-reinforcement:6100/get_prediction_ret
  async getPredictionResult() {
    this.http.get<any>(`${environment.apiBaseUrl}/auth/get_prediction_result`)
      .subscribe(
        (response: { result: any }) => {
          try {
            this.optiResult = response.result; // Directly assign the nested object
            // // console.log('Prediction result:', this.predictionResult);
  
            this.FAN_SPEED_out = this.optiResult[`fan_speed_${this.tag}`];
            // // console.log("FAN-Speed-out", this.FAN_SPEED_out);
  
            this.CHW_VALVE_out = this.optiResult[`chw_valve_opening_${this.tag}`];
            this.jsonData['predictionResult'] = this.optiResult;
  
            // console.log(this.jsonData);
            localStorage.setItem('predictionResult', JSON.stringify(this.optiResult));
            // console.log('Optimised Output', this.optiResult);
          } catch (error) {
            console.error('Error processing prediction result:', error);
          }
        },
        (error) => {
          console.error('Error fetching optimized result:', error);
          this.openSnackBar('error in fetching predicted values')
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
            this.openSnackBar('Session expired')

          }
        }
      );
  }
  
  extractTag(s:any){
    var arr=s.split('/');
    var identifier=arr[0];
    for(var i=1;i<arr.length;i++){
      identifier=identifier+arr[i];
    }
    if(identifier=='FL2TFA'){
      identifier='F_L2_TFA'

    }
    else if(identifier=='FL3TFA'){
      identifier='F_L3_TFA'

    }
    else if(identifier=='FL4TFA'){
      identifier='F_L4_TFA'

    }
    else if(identifier=='FLA&B_Wing'){
      identifier='F_L_A&B Wing'
    }
    else if(identifier=='FLBack_o'){
      identifier='FLBack_O'

    }
    else if(identifier=='FLC&D_Wing'){
      identifier='F_L_C&D Wing'
    }
    else if(identifier=='FLGA&B_Wing'){
      identifier='F_LG_A&B Wing'

    }
    else if(identifier=='FLGC&D_Wing'){
      identifier='F_LG_C&D Wing'

    }
    else if(identifier=='FLGLB_LR_3'){
      identifier='F_LG_LB_LR 3'

    }
    else if(identifier=='FLRec'){
      identifier='F_L_Rec'

    }
    else if(identifier=='FLSE_LR_1'){
      identifier='F_L_SE_LR 1'

    }
    else if(identifier=='FLTM_LR_2'){
      identifier='F_L_SE_LR 2'

    }
    return identifier;
  }
  async getInputResult() {
    this.http.get<any>(`${environment.apiBaseUrl}/auth/get_result`)
      .subscribe(
        (response: any) => {
          try {
            this.envResult = response.environment; // Directly assign the nested object
            this.realResult=response.interface;
            // console.log('Interface result:', this.envResult);
            Object.keys(this.realResult).forEach(ipv6key=>{
              if(ipv6key!='AHU' && ipv6key!='fd00::212:4b00:1889:e010_1'){
                // console.log(this.realResult[ipv6key]);
                const datapacket=this.realResult[ipv6key].data;
                // console.log(datapacket,"vijau")
                if(datapacket.alias_minnie){
                  const tag=this.extractTag(datapacket.alias_minnie);
                this.aliasMap[tag]=ipv6key;
                }
                
              }
             


            });
            localStorage.setItem('aliasMap', JSON.stringify(this.aliasMap));

            // console.log(this.aliasMap,"vijayyyy");
            // console.log(this.realResult[this.aliasMap[`${this.tag}`]],"umaaaaaa");
            this.supply_dpt_real=this.realResult[this.aliasMap[`${this.tag}`]]['data']['supply_dpt'];
        this.RAT_real=this.realResult[this.aliasMap[`${this.tag}`]]['data']['rat'];
        console.log(this.RAT_real);
          this.SAT_real=this.realResult[this.aliasMap[`${this.tag}`]]['data']['sat'];
          this.fan_power_real=this.realResult[this.aliasMap[`${this.tag}`]]['data']['T_power']
          this.FAN_SPEED_real=this.realResult[this.aliasMap[`${this.tag}`]]['data']['fan_speed'];
          this.CHW_VALVE_real=this.realResult[this.aliasMap[`${this.tag}`]]['data']['chw_opening'] || this.realResult[this.aliasMap[`${this.tag}`]]['data']['chw_valve_opening'];
this.amb_temp_relay=this.realResult[this.aliasMap[`${this.tag}`]]['data']['Ambient Temp'] || 28;
            // this.FAN_SPEED_in = this.interfaceResult[`fan_speed_${this.tag}`];
            // // console.log("FAN-Speed-out", this.FAN_SPEED_in);
            this.POWER_in = this.envResult[`Power_${this.tag}`] || this.envResult[`T_power_${this.tag}`];

            // this.CHW_VALVE_in = this.interfaceResult[`chw_opening_${this.tag}`];
            this.RAT_in = this.envResult[`rat_${this.tag}`];
            this.SAT_in = this.envResult[`sat_${this.tag}`];
            this.SDPT_in = this.envResult[`supply_dpt_${this.tag}`];
            this.jsonData['interfaceResult'] = this.envResult;
            this.jsonData['realData']=this.realResult;
            // // console.log(this.jsonData);

            localStorage.setItem('realData',JSON.stringify(this.realResult));
            localStorage.setItem('interfaceResult', JSON.stringify(this.envResult));
            // // console.log('Optimised Output', this.predictionResult);
          } catch (error) {
            console.error('Error processing interface result:', error);
          }
        },
        (error) => {
          this.openSnackBar('error in fetching environment values')
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
            this.openSnackBar('Session expired')

          }
          console.error('Error fetching interface result:', error);
        }
      );
  }
  








  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }

}
