<div class="d-two-comp">
  <svg id="d-two-comp" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%"
    height="100%" viewBox="0 0 195.033 160.568">
    <defs>
      <linearGradient id="linear-gradient-d2" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#ebf7fd" />
        <stop offset="1" stop-color="#8ea6b2" />
      </linearGradient>
      <linearGradient id="linear-gradient-d2-2" x1="0.886" y1="0.459" x2="0.588" y2="1.041"
        gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#fff" />
        <stop offset="1" stop-color="#e4f4fc" />
      </linearGradient>
      <filter id="Rectangle_1274" x="0" y="20.834" width="159" height="64" filterUnits="userSpaceOnUse">
        <feOffset dx="3" dy="3" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="4.5" result="blur" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Component_73_4" data-name="Component 73 – 4" transform="translate(10.499)">
      <g id="Group_3624" data-name="Group 3624" transform="translate(-13358.128 3530.748)">
        <path id="Path_4342" data-name="Path 4342" d="M560.37,314l-9.846,5.551L489.67,284.408V192.88L560.608,233.6Z"
          transform="translate(12868.819 -3698.113)" stroke="#427894" stroke-miterlimit="10" stroke-width="1"
          fill="url(#linear-gradient-d2)" />
        <path id="Path_4343" data-name="Path 4343" d="M505.849,224.688l-9.639-5.567V206.28l9.639,5.555Z"
          transform="translate(12869.45 -3697.465)" fill="#87abbe" />
        <path id="Path_4344" data-name="Path 4344" d="M505.849,241.057,496.21,235.5V222.65l9.639,5.567Z"
          transform="translate(12869.45 -3694.568)" fill="#87abbe" />
        <path id="Path_4345" data-name="Path 4345" d="M505.849,257.439l-9.639-5.567V239.02l9.639,5.567Z"
          transform="translate(12869.45 -3691.672)" fill="#87abbe" />
        <path id="Path_4346" data-name="Path 4346" d="M505.849,273.807l-9.639-5.567V255.4l9.639,5.555Z"
          transform="translate(12869.45 -3688.773)" fill="#87abbe" />
        <path id="Path_4347" data-name="Path 4347" d="M525.179,235.848l-9.639-5.567V217.44L525.179,223Z"
          transform="translate(12872.871 -3695.49)" fill="#87abbe" />
        <path id="Path_4348" data-name="Path 4348" d="M525.179,252.217l-9.639-5.555V233.81l9.639,5.567Z"
          transform="translate(12872.871 -3692.594)" fill="#87abbe" />
        <path id="Path_4349" data-name="Path 4349" d="M525.179,268.6l-9.639-5.567V250.18l9.639,5.567Z"
          transform="translate(12872.871 -3689.697)" fill="#87abbe" />
        <path id="Path_4350" data-name="Path 4350" d="M525.179,284.967,515.54,279.4V266.56l9.639,5.555Z"
          transform="translate(12872.871 -3686.798)" fill="#87abbe" />
        <path id="Path_4355" data-name="Path 4355"
          d="M529.774,241.431l8.909-5.143,13.747,7.933L563,238.112l-71.029-41,40.746-23.527,138.127,79.762-60.224,34.767Z"
          transform="translate(12868.7 -3703.251)" fill="url(#linear-gradient-d2-2)" />
        <path id="Path_4356" data-name="Path 4356"
          d="M533.137,175.433l135.349,78.149L629.141,276.3l-7.791,4.5-10.31,5.955-78.067-45.077,6.132-3.531,12.358,7.132,1.389.812,1.389-.812,7.791-4.5,4.178-2.354-4.178-2.354-66.851-38.6,37.957-21.915Zm0-3.1L489.59,197.466l71.1,40.84-7.791,4.508-13.747-7.944-11.7,6.756,83.634,48.255,11.7-6.744,7.791-4.5,43.547-25.128L533.137,172.22Z"
          transform="translate(12868.538 -3702.968)" fill="#96bdd1" />
        <path id="Path_4357" data-name="Path 4357" d="M532.907,322.135,522.591,316.3l-.131-84.17,83.5,48.22Z"
          transform="translate(12874.096 -3692.891)" stroke="#427894" stroke-miterlimit="10" stroke-width="1"
          fill="url(#linear-gradient-d2)" />
        <path id="Path_4358" data-name="Path 4358" d="M541.717,267.078l-9.627-5.567V248.67l9.627,5.555Z"
          transform="translate(12875.8 -3689.964)" fill="#87abbe" />
        <path id="Path_4359" data-name="Path 4359" d="M541.717,283.447l-9.627-5.567V265.04l9.627,5.567Z"
          transform="translate(12875.8 -3687.067)" fill="#87abbe" />
        <path id="Path_4360" data-name="Path 4360" d="M541.717,299.829l-9.627-5.567V281.41l9.627,5.567Z"
          transform="translate(12875.8 -3684.171)" fill="#87abbe" />
        <path id="Path_4361" data-name="Path 4361" d="M532.09,309.437V297.79l9.627,5.555.533.3Z"
          transform="translate(12875.8 -3681.272)" fill="#87abbe" />
        <path id="Path_4362" data-name="Path 4362" d="M561.059,278.238l-9.639-5.567V259.83l9.639,5.555Z"
          transform="translate(12879.22 -3687.989)" fill="#87abbe" />
        <path id="Path_4363" data-name="Path 4363" d="M561.059,294.607l-9.639-5.555V276.2l9.639,5.567Z"
          transform="translate(12879.22 -3685.093)" fill="#87abbe" />
        <path id="Path_4364" data-name="Path 4364" d="M551.42,297.345V292.57l3.706,2.14.435.27Z"
          transform="translate(12879.22 -3682.196)" fill="#87abbe" />
        <path id="Path_4366" data-name="Path 4366" d="M574.219,286.152l-3.469-2.322V270.99l9.627,5.555v6.132Z"
          transform="translate(12882.641 -3686.014)" fill="#87abbe" />
      </g>
      <g transform="matrix(1, 0, 0, 1, -10.5, 0)" filter="url(#Rectangle_1274)">
        <rect id="Rectangle_1274-2" data-name="Rectangle 1274" width="132" height="37" rx="10"
          transform="translate(10.5 31.33)" fill="#529ec6" />
      </g>
      <text id="D2_Block" data-name="D2 Block" transform="translate(0.001 39.334)" fill="#fff" font-size="18"
        font-family="Mulish-Regular, Mulish">
        <tspan x="30.975" y="16">D2 Block</tspan>
      </text>
    </g>
  </svg>
</div>