import { Component, Input } from '@angular/core';

@Component({
  selector: 'Primary-pump',
  templateUrl: './primary-pump.component.html',
  styleUrls: ['./primary-pump.component.css'],
})
export class PrimaryPumpComponent {
  @Input() state!: string;

  urlActive: string;
  urlInActive: string;
  urlTrip: string;

  constructor() {
    this.urlActive =
      '/assets/bert-components/active-primary-pump.svg';
    this.urlInActive =
      '/assets/bert-components/inactive-primary-pump.svg';
    this.urlTrip =
      '/assets/bert-components/triped-primary-pump.svg';
  }
}
