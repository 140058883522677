import { Component } from '@angular/core';

@Component({
  selector: 'Damper',
  templateUrl: './damper.component.html',
  styleUrls: ['./damper.component.css']
})
export class DamperComponent {

}
