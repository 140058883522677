<div style="position: relative; height: 100%; width: 100%;">
    <div class="main_container">
        <div class="content-container">
            <div class="upcontainer">
                <button routerLink="/dashboard/cooling-tower"
                    style="z-index: 99999; height: 60px; width: 60px; border-radius: 30px; border: 3px solid; cursor: pointer;">
                    <img src="assets/logos/left-arrow.png" alt="" style="width: 30px;">
                </button>
                <div class="map-container">
                    <img src="/assets/tower/Cooling Towers(b).svg" class="fmap">

                    <div class="component" style="width: 7.5%; height: 13%; top: 58%;left: 29%;  font-size: 120%;">
                        <button style="
                            border-radius: 5px;
                            background-color: #427894;
                            border: none;
                            height: 70%;
                            width: 100%;
                            font-size: 70%;       
                            color: white;
                            cursor: pointer;
                          
                            z-index: 999;
                            " routerLink="../cooling-tower3">
                            COOLING TOWER-3
                        </button>
                    </div>
                    <div class="component" style="width: 7.5%; height: 13%; top: 58%;left: 76%;  font-size: 120%;">
                        <button style="
                            border-radius: 5px;
                            background-color: #427894;
                            border: none;
                            height: 70%;
                            width: 100%;
                            font-size: 70%;       
                            color: white;
                            cursor: pointer;
                          
                            z-index: 999;
                            " routerLink="../cooling-tower4">
                            COOLING TOWER-4
                        </button>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 27%; left: 4%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #00A578; font-weight:200">CT 3/C1 F S</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 27%; left: 14%; width: 3.5%; height: 3%;">
                        <input readonly style="color:#00A578; font-weight:600" [ngModel]=" CT3_C1_FAN_SPEED_GR | number: '1.2-2'"
                            (ngModelChange)=" CT3_C1_FAN_SPEED_GR = $event" />
                        <span style="font-size: 80%; color: #00A578; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 23.5%; left: 4%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200">CT 3/C1 F S</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 23.5%; left: 14%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600"
                            [ngModel]=" CT3_C1_FAN_SPEED_RED | number: '1.2-2'"
                            (ngModelChange)=" CT3_C1_FAN_SPEED_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 27%; left: 18%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #00A578; font-weight:200">CT 3/C2 F S</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 27%; left: 28%; width: 3.5%; height: 3%;">
                        <input readonly style="color:#00A578; font-weight:600" [ngModel]=" CT3_C2_FAN_SPEED_GR | number: '1.2-2'"
                            (ngModelChange)=" CT3_C2_FAN_SPEED_GR = $event" />
                        <span style="font-size: 80%; color: #00A578; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 23.5%; left: 18%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200">CT 3/C2 F S</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 23.5%; left: 28%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600"
                            [ngModel]=" CT3_C2_FAN_SPEED_RED | number: '1.2-2'"
                            (ngModelChange)=" CT3_C2_FAN_SPEED_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 27%; left: 32.5%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #00A578; font-weight:200">CT 3/C3 F S</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 27%; left: 42.5%; width: 3.5%; height: 3%;">
                        <input readonly style="color:#00A578; font-weight:600" [ngModel]=" CT3_C3_FAN_SPEED_GR | number: '1.2-2'"
                            (ngModelChange)=" CT3_C3_FAN_SPEED_GR = $event" />
                        <span style="font-size: 80%; color: #00A578; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 23.5%; left: 32.5%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200">CT 3/C3 F S</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 23.5%; left: 42.5%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600"
                            [ngModel]=" CT3_C3_FAN_SPEED_RED | number: '1.2-2'"
                            (ngModelChange)=" CT3_C3_FAN_SPEED_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 27%; left: 58%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #00A578; font-weight:200">CT 4/C1 F S</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 27%; left: 68%; width: 3.5%; height: 3%;">
                        <input readonly style="color:#00A578; font-weight:600" [ngModel]=" CT4_C1_FAN_SPEED_GR | number: '1.2-2'"
                            (ngModelChange)=" CT4_C1_FAN_SPEED_GR = $event" />
                        <span style="font-size: 80%; color: #00A578; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 23.5%; left: 58%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200">CT 4/C1 F S</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 23.5%; left: 68%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600"
                            [ngModel]=" CT4_C1_FAN_SPEED_RED | number: '1.2-2'"
                            (ngModelChange)=" CT4_C1_FAN_SPEED_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 27%; left: 72%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #00A578; font-weight:200">CT 4/C2 F S</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 27%; left: 82%; width: 3.5%; height: 3%;">
                        <input readonly style="color:#00A578; font-weight:600" [ngModel]=" CT4_C2_FAN_SPEED_GR | number: '1.2-2'"
                            (ngModelChange)=" CT4_C2_FAN_SPEED_GR = $event" />
                        <span style="font-size: 80%; color: #00A578; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 23.5%; left: 72%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200">CT 4/C2 F S</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 23.5%; left: 82%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600"
                            [ngModel]=" CT4_C2_FAN_SPEED_RED | number: '1.2-2'"
                            (ngModelChange)=" CT4_C2_FAN_SPEED_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 85%; left: 2%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200">CT 3/C1 water out</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 85%; left: 13.5%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" CT3_C1_Water_out | number: '1.2-2'"
                            (ngModelChange)=" CT3_C1_Water_out = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">°C</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 95.5%; left: 23%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200">CT 3/C2 water out</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 95.5%; left: 34.5%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" CT3_C2_Water_out | number: '1.2-2'"
                            (ngModelChange)=" CT3_C2_Water_out = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">°C</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 95.5%; left: 40%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200">CT 3/C3 water out</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 95.5%; left: 51.5%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" CT3_C3_Water_out | number: '1.2-2'"
                            (ngModelChange)=" CT3_C3_Water_out = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">°C</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 95.5%; left: 63%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200">CT 4/C1 water out</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 95.5%; left: 74.5%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" CT4_C1_Water_out | number: '1.2-2'"
                            (ngModelChange)=" CT4_C1_Water_out = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">°C</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 95.5%; left: 79.5%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200">CT 4/C2 water out</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 95.5%; left: 91%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" CT4_C2_Water_out | number: '1.2-2'"
                            (ngModelChange)=" CT4_C2_Water_out = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">°C</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top:0%; left: 4%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200">CT water in.</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 0%; left: 14%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" CT_Water_in | number: '1.2-2'"
                            (ngModelChange)=" CT_Water_in = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">°C</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>