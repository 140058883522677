<!-- <div style="width: 100%;height:100%;display:  flex; justify-content: center;align-items: center;">
  <div mat-dialog-content
    style="border-radius: 20px;width: 30px;width: 300px;display: flex;flex-direction: column;gap: 20px;">
    <span class="span-text-dark">Are you sure you want to Logout?</span>
    <div style="display: flex;flex-direction: row;gap: 15px;">
      <button class="auth-button" style="border: none;outline: none;" matDialogClose>Cancel</button>
      <button class="auth-button" style="border: none;outline: none;" matDialogClose (click)="logout()">Logout</button>
    </div>
  </div>
</div>
 -->


 <div class="logout-container">
  <div class="logout-content">
    <span class="span-text-dark text-logout">Are you sure you want to logout?</span>
    <div class="button-container">
      <button class="auth-button-fill" (click)="goback()">Cancel</button>
      <button class="auth-button-empty" (click)="logout()">Logout</button>
    </div>
  </div>
</div>
