


<div class="container">
    <div class="main-body">
      <div class="content">
        <div class="map-container">
          <img src="/assets/blocks/D3.jpg" alt="A Block" />
        </div>
        <!-- KPI FORM -->
        <div class="kpi-form">
          <span class="kpi-title">KEY PERFORMANCE INDICATOR</span>
          <div class="form-content">
            <span class="form-label">D3 Block AHU Energy Saving</span>
            <div class="input-container">
              <input readonly 
                type="text" 
                class="kpi-input" 
                [ngModel]="KPI | number : '1.2-2'" 
                (ngModelChange)="KPI = $event" 
              />
            </div>
            <span class="percentage-symbol">%</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  