<div style="position: relative; height: 100%; width: 100%;">
    <div class="main_container">
        <div class="content-container">
            <div class="upcontainer">
                <button routerLink="/dashboard/cooling-tower3"
                    style="z-index: 99999; height: 60px; width: 60px; border-radius: 30px; border: 3px solid; cursor: pointer;">
                    <img src="assets/logos/left-arrow.png" alt="" style="width: 30px;">
                </button>
                <div class="map-container">
                    <img src="/assets/tower/Cooling Tower4.svg" class="fmap">
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 27%; left: 27%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #00A578; font-weight:200">CT 4/C1 F S</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 27%; left: 39%; width: 3.5%; height: 3%;">
                        <input readonly style="color:#00A578; font-weight:600" [ngModel]=" CT4_C1_FAN_SPEED_GR | number: '1.2-2'"
                            (ngModelChange)=" CT4_C1_FAN_SPEED_GR = $event" />
                        <span style="font-size: 80%; color: #00A578; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 23.5%; left: 27%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200">CT 4/C1 F S</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 23.5%; left: 39%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600"
                            [ngModel]=" CT4_C1_FAN_SPEED_RED | number: '1.2-2'"
                            (ngModelChange)=" CT4_C1_FAN_SPEED_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 27%; left: 46%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #00A578; font-weight:200">CT 4/C2 F S</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 27%; left: 58%; width: 3.5%; height: 3%;">
                        <input readonly style="color:#00A578; font-weight:600" [ngModel]=" CT4_C2_FAN_SPEED_GR | number: '1.2-2'"
                            (ngModelChange)=" CT4_C2_FAN_SPEED_GR = $event" />
                        <span style="font-size: 80%; color: #00A578; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 23.5%; left: 46%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200">CT 4/C2 F S</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 23.5%; left: 58%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600"
                            [ngModel]=" CT4_C2_FAN_SPEED_RED | number: '1.2-2'"
                            (ngModelChange)=" CT4_C2_FAN_SPEED_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 95.5%; left: 29%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200">CT 4/C1 water out</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 95.5%; left: 42.5%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" CT4_C1_Water_out | number: '1.2-2'"
                            (ngModelChange)=" CT4_C1_Water_out = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">°C</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 95.5%; left: 51%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200">CT 4/C2 water out</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 95.5%; left: 64.5%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" CT4_C2_Water_out | number: '1.2-2'"
                            (ngModelChange)=" CT4_C2_Water_out = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">°C</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top:0%; left: 23%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200">CT water in.</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 0%; left: 34%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" CT_Water_in | number: '1.2-2'"
                            (ngModelChange)=" CT_Water_in = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">°C</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>