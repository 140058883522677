import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-primary-pump',
  templateUrl: './primary-pump.component.html',
  styleUrls: ['./primary-pump.component.css']
})
export class PrimaryPumpComponent {
  PP1_VFD_GR: any = 0;
  PP1_VFD_RED: any = 0;
  PP2_VFD_GR: any = 0;
  PP2_VFD_RED: any = 0;
  PP3_VFD_GR: any = 0;
  PP3_VFD_RED: any = 0;
  PP4_VFD_GR: any = 0;
  PP4_VFD_RED: any = 0;
  POWER1_IN: any = 0;
  POWER1_RED: any = 0;
  POWER2_IN: any = 0;
  POWER2_RED: any = 0;
  POWER3_IN: any = 0;
  POWER3_RED: any = 0;
  POWER4_IN: any = 0;
  POWER4_RED: any = 0;
  KPI: any = 0;
  realResult:any=0;
  envResult: any;
  optiResult: any;


  constructor(    
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router){}

    ngOnInit(): void {
    
      this.getInputResult();
      this.getPredictionResult();
      setInterval(()=>{
        this.getInputResult();
        this.getPredictionResult();
      },600000);
    }
  
    
    // http://hul-reinforcement:6100/get_prediction_ret
    async getPredictionResult() {
      this.http.get<any>(`${environment.apiBaseUrl}/auth/get_prediction_result`)
        .subscribe(
          (response: { result: any }) => {
            try {
              this.optiResult = response.result; // Directly assign the nested object
              //green
              // console.log('Prediction result:', this.predictionResult);
              this.PP1_VFD_GR=this.optiResult['VFD Feedback Pr Pump 1'];
              this.PP2_VFD_GR=this.optiResult['VFD Feedback Pr Pump 2'];
              this.PP3_VFD_GR=this.optiResult['VFD Feedback Pr Pump 1']; //VFD Feedback Pr Pump 3
              this.PP4_VFD_GR=this.optiResult['VFD Feedback Pr Pump 2']; //VFD Feedback Pr Pump 4
              // this.FAN_SPEED_out = this.optiResult[`fan_speed_${this.tag}`];
              // // console.log("FAN-Speed-out", this.FAN_SPEED_out);
              // this.A1_VFD_GR=this.optiResult['VFD Feedback Zone A Sec Pump 1'];

              // this.CHW_VALVE_out = this.optiResult[`chw_valve_opening_${this.tag}`];
              // this.jsonData['predictionResult'] = this.optiResult;
    
              // console.log(this.jsonData);
              localStorage.setItem('predictionResult', JSON.stringify(this.optiResult));
              console.log('Optimised Output', this.optiResult);
            } catch (error) {
              console.error('Error processing prediction result:', error);
            }
          },
          (error) => {
            console.error('Error fetching optimized result:', error);
            this.openSnackBar('error in fetching predicted values')
          }
        );
    }
    
  
    async getInputResult() {
      this.http.get<any>(`${environment.apiBaseUrl}/auth/get_result`)
        .subscribe(
          (response: any) => {
            try {
              this.envResult = response.environment; // Directly assign the nested object
              //blue
              this.realResult=response.interface;
              this.PP1_VFD_RED=this.realResult['fd00::212:4b00:1957:d1af_1']['data']['VFD Feedback Pr Pump 1']
              this.PP2_VFD_RED=this.realResult['fd00::212:4b00:1957:d1af_1']['data']['VFD Feedback Pr Pump 2']

              this.PP3_VFD_RED=this.realResult['fd00::212:4b00:1957:d1af_1']['data']['VFD Feedback Pr Pump 3']
              this.PP4_VFD_RED=this.realResult['fd00::212:4b00:1957:d1af_1']['data']['VFD Feedback Pr Pump 4']

              console.log('Interface result:', this.envResult);
              // this.POWERA1_IN=this.envResult[''];
              this.POWER1_IN=this.envResult[''];
              // this.FAN_SPEED_in = this.interfaceResult[`fan_speed_${this.tag}`];
              // console.log("FAN-Speed-out", this.FAN_SPEED_in);
              // this.POWER_in = this.envResult[`Power_${this.tag}`];
              // // this.CHW_VALVE_in = this.interfaceResult[`chw_opening_${this.tag}`];
              // this.RAT_in = this.envResult[`rat_${this.tag}`];
              // this.SAT_in = this.envResult[`Supply Air Temp_${this.tag}`];
              // this.SDPT_in = this.envResult[`supply_dpt_${this.tag}`];
              // this.jsonData['interfaceResult'] = this.envResult;
              // // console.log(this.jsonData);
              localStorage.setItem('interfaceResult', JSON.stringify(this.envResult));
              // console.log('Optimised Output', this.predictionResult);
            } catch (error) {
              console.error('Error processing interface result:', error);
            }
          },
          (error) => {
            this.openSnackBar('error in fetching environment values')
            if (error.status === 401) {
              // Redirect to the login page
              this.router.navigate(['/login']); // Adjust the path as necessary
              sessionStorage.removeItem('accessToken');
              this.openSnackBar('Session expired')
  
            }
            console.error('Error fetching interface result:', error);
          }
        );
    }
  
    openSnackBar(message: string) {
      this.snackBar.open(message, 'OK', {
        panelClass: ['snackbar'],
        horizontalPosition: 'start',
        duration: 5000,
      });
    }
}
