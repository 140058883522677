import { Component } from '@angular/core';

@Component({
  selector: 'app-zone-a',
  templateUrl: './zone-a.component.html',
  styleUrls: ['./zone-a.component.css']
})
export class ZoneAComponent {

}
