<div style="position: relative; height: 100%; width: 100%;">
  <!-- <Water-mark></Water-mark> -->

  <div style="
  width: 100%;
  height: 100%;
  min-width: 1200px;
  display: flex;
  flex-direction: column;
  position: absolute;">
    <div style="display: flex; flex-direction: row; height: 100%; width: 100%;">
      <!-- main body -->
      <div style="
      height: auto;
      width: 100%;
      display: flex;
      flex-direction: row;
      padding: 10px;
      align-items: start;
      gap: 10px;
      justify-content: center;
      overflow-y: scroll;
      padding-left: 200px;">
        <div style="
        height: 600px;
        width: 1000px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;">
          <!-- map -->
          <div style="
          height: 500px;
          display: flex;
          width: 1000px;
          align-items: center;
          justify-content: center;
          position: absolute;
          margin-top:8%;
          margin-left:-15%;">
            <!-- <img src="/assets/home/UL-home.svg" width="1000px" height="600px" /> -->
          </div>
          <!-- componets -->
          <div style="height: 600px; width: 1000px; position: absolute; ">
            <div style="margin-top: 154px;
            margin-left: 674.5px;
            width: 135.9px;
            position: absolute;
            z-index: 1;">
              <d-five-block-comp routerLink="../block-d5"></d-five-block-comp>
            </div>

            <div style="margin-top: 187.45px;
            margin-left: 566px;
            width: 143px;
            position: absolute; z-index:1">
              <d-four-block-comp routerLink="../block-d4"></d-four-block-comp>
            </div>

            <div style="margin-top: 198.5px;
            margin-left: 455px;
            width: 177px;
            position: absolute;
            z-index: 1;">
              <d-three-block-comp routerLink="../block-d3"></d-three-block-comp>
            </div>

            <div style="    margin-top: 262.5px;
            margin-left: 386.5px;
            width: 160px;
            position: absolute;
            z-index: 1;">
              <d-two-block-comp routerLink="../block-d2"></d-two-block-comp>
            </div>

            <div style="margin-top: 340.5px;
            margin-left: 297px;
            width: 144px;
            position: absolute;
            z-index: 1;">
              <d-one-block-comp routerLink="../block-d1"></d-one-block-comp>
            </div>

            <div style="margin-top: 198.5px;
            margin-left: 384px;
            width: 466px;
            position: absolute;
            z-index: 0; ">
              <tunnel-comp></tunnel-comp>
            </div>

            <div style="margin-top: 400.5px;
            margin-left: 280px;
            width: 179px;
            position: absolute;
            z-index: 1; ">
              <a-block-comp routerLink="../block-a"></a-block-comp>
            </div>

            <div style="
            margin-top: 539px;
            margin-left: 242px;
            height: 213.9px;
            position: absolute; z-index:1 ">
              <Chiller-comp routerLink="../chiller"></Chiller-comp>
            </div>

            <div style="margin-top: 385.5px;
            margin-left: 485px;
            width: 203px;
            position: absolute;
            z-index: 1;">
              <b-one-block-comp routerLink="../block-b1"></b-one-block-comp>
            </div>

            <div style="margin-top: 234.5px;
            margin-left: 795px;
            width: 161px;
            position: absolute;
            z-index: 1;">
              <b-two-e-block-comp routerLink="../block-b2e"></b-two-e-block-comp>
            </div>

            <div style="margin-top: 256.5px;
            margin-left: 642px;
            width: 264px;
            position: absolute;
            z-index: 1;">
              <b-two-w-block-comp routerLink="../block-b2w"></b-two-w-block-comp>
            </div>

            <div style="margin-top: 450px;
            margin-left: 113px;
            width: 140px;
            position: absolute;
            z-index: 1;">
              <e-block-comp ></e-block-comp>
            </div>

            <div style="margin-top: 327px;
            margin-left: -74px;
            width: 174px;
            position: absolute;
            z-index: 1;">
              <f-block-comp ></f-block-comp>
            </div>

            <!-- <div style="margin-top: 374px;
            margin-left: 406px;
            height: 136px;
            position: absolute;">
              <Main-lobby-comp routerLink="../main-lobby"></Main-lobby-comp>
            </div>

            <div style="margin-top: 342.5px;
            margin-left: 531px;
            height: 100.5px;
            position: absolute;">
              <Mall-two-comp routerLink="../mall-two"></Mall-two-comp>
            </div>

            <div style="margin-top: 398px;
            margin-left: 710px;
            height: 173.5px;
            position: absolute;">
              <Zone-seven-comp routerLink="../zone-seven"></Zone-seven-comp>
            </div> -->

          </div>


          <!-- buttons  -->
          <!-- <div style="height: 500px; width:1000px; position: relative; ">
            <div style="display:flex; margin-top: -70px; margin-left: -240px;">
              <button style="
                  border-radius: 10px;
                  background-color: var(--blue-one);
                  border: none;
                  height: auto;
                  height: 30px;
                  width: 100px;
                  color: white;
                  cursor: pointer;
                  margin-top: 185px;
                  margin-left: 515px;
                  transition: background-color 0.3s ease; /* Add transition for smooth effect */
              " routerLink="../chiller-plant" onmouseover="this.style.backgroundColor='#005FEE'" onmouseout="this.style.backgroundColor='var(--blue-one)'">
                  Chiller Plant
              </button>
          </div>
          

            <div style="display:flex; margin-top: -345px;
                margin-left: 170px;">
              <button style="
                  border-radius: 10px;
                  background-color: var(--blue-one);
                  border: none;
                  height: auto;
                  height: 30px;
                  width: 100px;
                  color: white;
                  cursor: pointer;margin-top: 315px;
                  margin-left: 515px;
                  " routerLink="../block-d5">
                  
                D5 Block
              </button>
            </div>

            <div style="display:flex; margin-top: -310px;
                margin-left: 65px;">
              <button style="
                  border-radius: 10px;
                  background-color: var(--blue-one);
                  border: none;
                  height: auto;
                  height: 30px;
                  width: 100px;
                  color: white;
                  cursor: pointer;margin-top: 315px;
                  margin-left: 515px;
                  " routerLink="../block-d4">
                  
                  D4 Block
              </button>
            </div>

            <div style="display:flex; margin-top: -307px;
                margin-left: -35px;">
              <button style="
                  border-radius: 10px;
                  background-color: var(--blue-one);
                  border: none;
                  height: auto;
                  height: 30px;
                  width: 100px;
                  color: white;
                  cursor: pointer;margin-top: 315px;
                  margin-left: 515px;
                  " routerLink="../block-d3">
                  D3 Block
              </button>
            </div>

            <div style="display:flex; margin-top: -161px;
                margin-left: -107px;">
              <button style="
                  border-radius: 10px;
                  background-color: var(--blue-one);
                  border: none;
                  height: auto;
                  height: 30px;
                  width: 100px;
                  color: white;
                  cursor: pointer;
                  margin-top: 185px;
                  margin-left: 515px;
                   " routerLink="../block-d2">
                  D2 Block
              </button>
            </div>

            <div style="display:flex; margin-top: -170px;
                margin-left: -212px;">
              <button style="
                  border-radius: 10px;
                  background-color: var(--blue-one);
                  border: none;
                  height: auto;
                  height: 30px;
                  width: 100px;
                  color: white;
                  cursor: pointer;
                  margin-top: 185px;
                  margin-left: 515px;
                   " routerLink="../block-d1">
                D1 Block
              </button>
            </div>

            <div style="display:flex; margin-top: -290px;
                margin-left: 285px;">
              <button style="
                  border-radius: 10px;
                  background-color: var(--blue-one);
                  border: none;
                  height: auto;
                  height: 30px;
                  width: 100px;
                  color: white;
                  cursor: pointer;
                  margin-top: 185px;
                  margin-left: 515px;
                   " routerLink="../block-b2e">
                B2E Block
              </button>
            </div>

            <div style="display:flex; margin-top: -142px;
                margin-left: 175px;">
              <button style="
                  border-radius: 10px;
                  background-color: var(--blue-one);
                  border: none;
                  height: auto;
                  height: 30px;
                  width: 100px;
                  color: white;
                  cursor: pointer;
                  margin-top: 185px;
                  margin-left: 515px;
                   " routerLink="../block-b2w">
                B2W Block
              </button>
            </div>

            <div style="display:flex; margin-top: -175px;
                margin-left: -560px;">
              <button style="
                  border-radius: 10px;
                  background-color: var(--blue-one);
                  border: none;
                  height: auto;
                  height: 30px;
                  width: 100px;
                  color: white;
                  cursor: pointer;
                  margin-top: 185px;
                  margin-left: 515px;
                   " routerLink="../block-f">
                F Block
              </button>
            </div>

            <div style="display:flex; margin-top: -130px;
                margin-left: -381px;">
              <button style="
                  border-radius: 10px;
                  background-color: var(--blue-one);
                  border: none;
                  height: auto;
                  height: 30px;
                  width: 100px;
                  color: white;
                  cursor: pointer;
                  margin-top: 185px;
                  margin-left: 515px;
                   " routerLink="../block-e">
                E Block
              </button>
            </div>

            <div style="display:flex; margin-top: -220px;
                margin-left: -193px;">
              <button style="
                  border-radius: 10px;
                  background-color: var(--blue-one);
                  border: none;
                  height: auto;
                  height: 30px;
                  width: 100px;
                  color: white;
                  cursor: pointer;
                  margin-top: 185px;
                  margin-left: 515px;
                   " routerLink="../block-a">
                A Block
              </button>
            </div>

            <div style="display:flex; margin-top: -240px;
                margin-left: 15px;">
              <button style="
                  border-radius: 10px;
                  background-color: var(--blue-one);
                  border: none;
                  height: auto;
                  height: 30px;
                  width: 100px;
                  color: white;
                  cursor: pointer;
                  margin-top: 185px;
                  margin-left: 515px;
                   " routerLink="../block-b1">
                B1 Block
              </button>
            </div> 
          </div> -->
        </div>


        <!-- KPI FORM  -->

        <div class="form"
          style="height: 150px; width: 380px; background:#F0F6FF; margin-top:30px; z-index:1; margin-right:50px; ">
          <span class="span-text-dark" style="font-weight: bold; font-size: 20px; text-align: center">KEY PERFORMANCE
            INDICATOR</span>

          <div style="height: 30px;   "></div>
          <div style="
              display: flex;
              flex-direction: row;
              width: 350px;
              justify-content: space-between;
              align-items: center;
              padding:15px;
              z-index:1; background:white;
              border-radius: 10px;
              gap:20px;
            ">
            <span class="span-text-dark" style="font-size: 16px; font-weight: 500">Overall Energy Saving</span>

            <div style="width:70px">

              <input readonly
                style="width:70px; font-size: 20px; text-align:center; border-radius:8px; border: 2px solid #C4C4C4  "
                [ngModel]="KPI | number : '1.2-2'" (ngModelChange)="KPI = $event" />

            </div>
            <span style="margin-left:-55px; font-size: 20px;">%</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>