<div class="navbar-container">
  <!-- logos -->
  <div class="logo">
    <!-- <img src="/assets/logos/client-logo-bg.png" height="50px">
    <div style="height: 100%; width: 1px;background-color: #000;"></div> -->
    <img src="/assets/logos/UL-logo.png" width="30%">
  </div>

  <!-- title -->
  <div class="title-dark block" style="font-size: 20px; text-transform: uppercase;">{{title}}</div>


  <!-- watermark -->

 
     <div class="watermark" >

      <!-- <div class="powered-by">Powered by</div> -->
      <img src="/assets/logos/poweredBy.png" width="40%" />
      <!-- <div>
      </div> -->
    
    </div>
  
  </div>

  <!-- account -->
 
  <!-- <span class="span-text-dark" style="font-weight: 500;">User</span> -->
    <!-- <div class="account-section" (click)="showLogoutDialog()">
 
     <img src="/assets/logos/sign-out-logo.png" style="height: 40px; border-radius: 20px;"> 
  
   </div> -->
  
