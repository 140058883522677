<div style="position: relative; height: 100%; width: 100%">
    <!-- <Water-mark></Water-mark> -->
  
  
    <div *ngIf="type==1">
      <div style="
          width: 100%;
          height: 100%;
          min-width: 1200px;
          display: flex;
          flex-direction: column;
          position: absolute; ">
        <!-- <mat-progress-bar mode="indeterminate" [ngClass]="{ 'show-progress': progress, 'hide-progress': !progress }" ></mat-progress-bar> -->
        <div style="
            height: auto;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 10px;
            align-items: center;
            gap: 10px;
          " >
          <div style="
              height: 350px;
              width: 1000px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
            " >
            <!-- AHU Map  -->
            <div
              style="
                height: 350px;
                width: 1000px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
              " >
              <div style="height: 350px; width: 1000px;">
                <img src="/assets/images/ahu-type-one.svg" width="1000px" height="350px" />
                <div style="position: absolute; top: 280px; left: 375px; height: 50px; width: 250px;">
                  <div style="display: flex; width: 100%; height: 100%; justify-content: end;">
                    <p style="font-family: 'Inter', sans-serif; font-size: 14px; font-weight:520; text-align: center; line-height:25px; ">{{ name }}</p>
                  </div>
                </div>
              </div>
            </div>
  
            <!-- componets -->
            <div style="height: 350px; width: 1000px; position: absolute">
              <!-- FAN -->
              <div style="
                  height: 72px;
                  width: 72px;
                  margin-top: 170px;
                  margin-left: 422px;
                " >
                <Fan [state]="state"></Fan>
              </div>
  
              <!-- 1. RAT -->
              <div
                [className]="capsule_group"
                style="position:absolute;width: 80px; margin-top: -188px; margin-left: 100px" >
                <img src="/assets/images/temperature-celcius.png" />
                <ng-container *ngIf="RAT_in !== false; else emptyInput">
                  <input readonly
                    [ngModel]="RAT_in | number : '1.2-2'"
                    (ngModelChange)="RAT_in = $event"
                  />
                </ng-container>
                <ng-template #emptyInput>
                  <input readonly [ngModel]="''" />
                </ng-template>
              </div>
  
              
  
              <!-- 2.1. CHW_VALVE  -->
              <div
                class="capsule-rl"
                style="position:absolute;width: 70px;
                margin-top: 30px;
                margin-left: 136px;"
              >
                <img src="/assets/images/percentage.png" />
                <ng-container *ngIf="CHW_VALVE_out !== false; else emptyInput">
                  <input readonly
                    [ngModel]="CHW_VALVE_out | number : '1.2-2'"
                    (ngModelChange)="CHW_VALVE_out = $event"
                  />
                </ng-container>
                <ng-template #emptyInput>
                  <input readonly [ngModel]="''" />
                </ng-template>
              </div>
              <!-- 2.2. CHW_VALVE  -->
              <div
                [className]="capsule_group"
                style="position:absolute;width: 70px;
                margin-top: 30px;
                margin-left: 242px; color:#1E5BAF"
              >
                <img src="/assets/images/percentage.png" />
                <ng-container *ngIf="CHW_VALVE_in !== false; else emptyInput">
                  <input readonly
                    [ngModel]="CHW_VALVE_in | number : '1.2-2'"
                    (ngModelChange)="CHW_VALVE_in = $event"
                  />
                </ng-container>
                <ng-template #emptyInput>
                  <input readonly [ngModel]="''" />
                </ng-template>
              </div>
  
  
             
  
              <!-- 3.1. Fan Speed-->
              <div
                [className]="capsule_group"
                style="position:absolute; width: 85px;
                margin-top: -160px;
                margin-left: 321px;"
              >
                <img src="/assets/images/fan-speed.png" />
                <ng-container *ngIf="FAN_SPEED_out !== false; else emptyInput">
                  <input readonly
                    [ngModel]="FAN_SPEED_out | number : '1.2-2'"
                    (ngModelChange)="FAN_SPEED_out = $event"
                  />
                </ng-container>
                <ng-template #emptyInput>
                  <input readonly [ngModel]="''" />
                </ng-template>
              </div>
              <!-- 3.2. Fan Speed-->
              <div
                
                class="position:absolute; capsule-rl"
                style="width: 85px;
                margin-top: -160px;
                margin-left: 409px;"
              >
                <img src="/assets/images/fan-speed.png" />
                <ng-container *ngIf="FAN_SPEED_in !== false; else emptyInput">
                  <input readonly
                    [ngModel]="FAN_SPEED_in | number : '1.2-2'"
                    (ngModelChange)="FAN_SPEED_in = $event"
                  />
                </ng-container>
                <ng-template #emptyInput>
                  <input readonly [ngModel]="''" />
                </ng-template>
              </div>
               <!-- 4. Fan Power -->
               <div
               [className]="capsule_group"
               style="position:absolute;width: 110px;
               margin-top: -160px;
               margin-left: 497px;"
             >
               <img src="/assets/images/power.png" />
               <ng-container *ngIf="POWER_in !== false; else emptyInput">
                 <input readonly
                   [ngModel]="POWER_in | number : '1.2-2'"
                   (ngModelChange)="POWER_in = $event"
                 />
               </ng-container>
               <ng-template #emptyInput>
                 <input readonly [ngModel]="''" />
               </ng-template>
               <span>Wh</span>
             </div>
  
                  <!-- 5. SDPT_in -->
              <div
              [className]="capsule_group"
              style="position:absolute; width: 94px;
              margin-top: -53px;
              margin-left: 625px;"
              >
                  <img src="/assets/images/differential-pressure-transmitter.png" />
                  <ng-container *ngIf="SDPT_in !== false; else emptyInput">
                    <input readonly
                      [ngModel]="SDPT_in | number : '1.2-2'"
                      (ngModelChange)="SDPT_in = $event"
                    />
                  </ng-container>
                  <ng-template #emptyInput>
                    <input readonly [ngModel]="''" />
                  </ng-template>
              <span>Pa</span>
            </div>
  
              <!-- 6. SAT_in -->
              <div
                [className]="capsule_group"
                style="position:absolute; width: 90px;
                margin-top: -110px;
                margin-left: 710px;"
              >
                <img src="/assets/images/temperature-celcius.png" />
                <ng-container *ngIf="SAT_in !== false; else emptyInput">
                  <input readonly
                    [ngModel]="SAT_in | number : '1.2-2'"
                    (ngModelChange)="SAT_in = $event"
                  />
                </ng-container>
                <ng-template #emptyInput>
                  <input readonly [ngModel]="''" />
                </ng-template>
              </div>
  
              <!-- <div
                class="input-group2"
                style="width: 110px;
                margin-top: 5px;
                margin-left: 20px;"
              >
                <img src="/assets/images/CO2.png" />
                <ng-container *ngIf="cassandra_data['co2'] !== false; else emptyInput">
                  <input readonly
                    [ngModel]="cassandra_data['co2'] | number : '1.2-2'"
                    (ngModelChange)="cassandra_data['co2'] = $event"
                  />
                </ng-container>
                <ng-template #emptyInput>
                  <input readonly [ngModel]="''" />
                </ng-template>
                <span>ppm</span>
              </div> -->
  
              <!-- <div
                class="input-group2"
                style="width: 110px;
                margin-top: -10px;
                margin-left: 210px;"
              >
                <img src="/assets/images/DP.png" />
                <ng-container *ngIf="cassandra_data['pfp'] !== false; else emptyInput">
                  <input readonly
                    [ngModel]="cassandra_data['pfp'] | number : '1.2-2'"
                    (ngModelChange)="cassandra_data['pfp'] = $event"
                  />
                </ng-container>
                <ng-template #emptyInput>
                  <input readonly [ngModel]="''" />
                </ng-template>
                <span>mbar</span>
              </div> -->
            </div>
          </div>
        </div>
  
        <div
        style="
          height: auto;
          width: 100%;
          min-width: 1000px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 7px;
          align-items: center;
          gap: 10px;
        "
      >
        <div
          style="
            width: 100%;
            display: flex;
            flex-direction: row;
            height: auto;
            justify-content: center;
          "
        >
          <div style="width: 475px; height: 100%; margin: 0px 20px 0px 50px">
            <div
              class="form"
              [ngClass]="{'disabled': isDisabled}"
              style="
                padding: 20px;
                flex-direction: row;
                gap: 30px;
                align-items: start;
              "
            >
              <div
                style="
                  width: 50%;
                  display: flex;
                  flex-direction: column;
                  gap: 10px;
                "
              >
                <!-- RAT -->
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 12px; font-weight: 500"
                    >RAT</span
                  >
  
                  <div [ngClass]="{ 'input-groupD': isDisabled, 'input-group': !isDisabled }" style="width: 100px">
                    <img src="/assets/images/temperature-celcius.png" />
                    <input readonly [(ngModel)]="RAT" [disabled]="isDisabled" 
                    style="background-color: white; color: {{ !isDisabled ? 'black' : 'white' }};"/>
                  </div>
                </div>
  
                <!-- CHW Valve -->
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 12px; font-weight: 500"
                    >CHW VALVE</span
                  >
  
                  <div [ngClass]="{ 'input-groupD': isDisabled, 'input-group': !isDisabled }" style="width: 100px">
                    <img src="/assets/images/percentage.png" />
                    <input readonly [(ngModel)]="CHW_VALVE" [disabled]="isDisabled" 
                    style="background-color: white; color: {{ !isDisabled ? 'black' : 'white' }};"/>
                  </div>
                </div>
  
                <!-- FAN SPEED -->
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 12px; font-weight: 500"
                    >FAN SPEED</span
                  >
  
                  <div [ngClass]="{ 'input-groupD': isDisabled, 'input-group': !isDisabled }" style="width: 100px">
                    <img src="/assets/images/percentage.png" />
                    <input readonly [(ngModel)]="FAN_SPEED" [disabled]="isDisabled" 
                    style="background-color: white; color: {{ !isDisabled ? 'black' : 'white' }};"/>
                  </div>
                </div>
  
               
              </div>
  
              <div
                style="
                  width: 50%;
                  display: flex;
                  flex-direction: column;
                  gap: 10px;
                  justify-content: space-between;
                "
              >
                <div style="display: flex; flex-direction: column; gap: 10px">
                  <!-- <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <span
                      class="span-text-dark"
                      style="font-size: 12px; font-weight: 500"
                      >SUPPLY DPT</span
                    >
  
                    <div class="input-group" style="width: 100px">
                      <img src="/assets/images/differential-pressure-transmitter.png" />
                      <input readonly [(ngModel)]="SAT" />
                      <span>Pa</span>
                    </div>
                  </div> -->
  
                   <!-- SAT -->
                <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <span
                  class="span-text-dark"
                  style="font-size: 12px; font-weight: 500"
                  >SAT</span
                >
  
                <div [ngClass]="{ 'input-groupD': isDisabled, 'input-group': !isDisabled }" style="width: 100px">
                  <img src="/assets/images/temperature-celcius.png" />
                  <input readonly [(ngModel)]="SAT" [disabled]="isDisabled" 
                  style="background-color: white; color: {{ !isDisabled ? 'black' : 'white' }};"/>
                </div>
              </div>
  
  
  
                  <!-- chws temp. -->
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <span
                      class="span-text-dark"
                      style="font-size: 12px; font-weight: 500"
                      >{{ stap_zone_label }}</span
                    >
  
                    <div [ngClass]="{ 'input-groupD': isDisabled, 'input-group': !isDisabled }" style="width: 100px">
                      <img src="/assets/images/temperature-celcius.png" />
                      <input readonly [(ngModel)]="STASP_ZONE_A" [disabled]="isDisabled" 
                      style="background-color: white; color: {{ !isDisabled ? 'black' : 'white' }};"/>
                    </div>
                  </div>
  
                  <!-- CHW VALVE -->
  
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <span
                      class="span-text-dark"
                      style="font-size: 12px; font-weight: 500"
                      >AMBIENT TEMP.</span
                    >
  
                    <div [ngClass]="{ 'input-groupD': isDisabled, 'input-group': !isDisabled }" style="width: 100px">
                      <img src="/assets/images/temperature-celcius.png" />
                      <input readonly [(ngModel)]="AMBIENT_TEMP" [disabled]="isDisabled" 
                      style="background-color: white; color: {{ !isDisabled ? 'black' : 'white' }};"/>
                    </div>
                  </div>
                </div>
  
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    gap: 10px;
                    justify-content: end;
                    margin-top: 10px;
                  "
                >
                  <button
                    style="
                      width: 50%;
                      background-color: var(--blue-one);
                      border-radius: 20px;
                      border: none;
                      height: auto;
                      padding: 7px;
                      color: white;
                      cursor: pointer;
                    "
                    [disabled]="isDisabled"
                    (click)="predictData()"
                  >
                    Predict
                  </button>
                </div>
              </div>
            </div>
            <!-- <div *ngIf="isDisabled" style="height: 100%; width: 100%; background-color: gray; position:relative; top: -100%; opacity:0.1; border-radius: 8px;"></div> -->
          </div>
  
          <!-- KPI -->
  
          <div class="form" style="height: 200px; width: 250px">
            <span
              class="span-text-dark"
              style="font-weight: bold; text-align: center"
              >KEY PERFORMANCE INDICATOR</span
            >
  
            <div style="height: 30px"></div>
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 12px; font-weight: 500"
                >OVERALL HVAC SAVING</span
              >
  
              <div class="input-group" style="width: 120px">
                <img src="/assets/images/power.png" />
                <input readonly
                  [ngModel]="KPI | number : '1.2-2'"
                  (ngModelChange)="KPI = $event"
                />
                <span>%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  
  
    <div *ngIf="type==2">
      <div style="
          width: 100%;
          height: 100%;
          min-width: 1200px;
          display: flex;
          flex-direction: column;
          position: absolute; ">
        <!-- <mat-progress-bar mode="indeterminate" [ngClass]="{ 'show-progress': progress, 'hide-progress': !progress }" ></mat-progress-bar> -->
        <div style="
            height: auto;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 10px;
            align-items: center;
            gap: 10px;
          " >
          <div style="
              height: 350px;
              width: 1000px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
            " >
            <!-- AHU Map  -->
            <div
              style="
                height: 350px;
                width: 1000px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
              " >
              <img src="/assets/images/ahu-type-two.svg" width="1000px" height="350px" />
              <div style="position: absolute; top: 270px; left: 400px; height: 50px; width: 250px;">
                <div style="display: flex; width: 100%; height: 100%; justify-content: end;">
                  <p style="font-family: 'Inter', sans-serif; font-size: 14px; font-weight:520; text-align: center; line-height:25px; ">{{ name }}</p>
                </div>
              </div>
            </div>
  
            <!-- componets -->
            <div style="height: 350px; width: 1000px; position: absolute">
              <!-- FAN -->
              <div style="
                  height: 70px;
                  width: 70px;
                  margin-top: 169.5px;
                  margin-left: 453px;
                " >
                <Fan [state]="state"></Fan>
              </div>
  
              <!-- 1. RAT -->
              <div
                [className]="capsule_group"
                style="position:absolute; width: 80px; margin-top: -183px; margin-left: 155px" >
                <img src="/assets/images/temperature-celcius.png" />
                <ng-container *ngIf="RAT_in !== false; else emptyInput">
                  <input readonly
                    [ngModel]="RAT_in | number : '1.2-2'"
                    (ngModelChange)="RAT_in = $event"
                  />
                </ng-container>
                <ng-template #emptyInput>
                  <input readonly [ngModel]="''" />
                </ng-template>
              </div>
  
              
              <!-- 2.1. CHW_VALVE  -->
              <div
                class="capsule-rl"
                style="position:absolute; width: 70px;
                margin-top: 25px;
                margin-left: 182px;"
              >
                <img src="/assets/images/percentage.png" />
                <ng-container *ngIf="CHW_VALVE_in !== false; else emptyInput">
                  <input readonly
                    [ngModel]="CHW_VALVE_in | number : '1.2-2'"
                    (ngModelChange)="CHW_VALVE_in = $event"
                  />
                </ng-container>
                <ng-template #emptyInput>
                  <input readonly [ngModel]="''" />
                </ng-template>
              </div>
              <!-- 2.2. CHW_VALVE  -->
              <div
                [className]="capsule_group"
                style="position:absolute; width: 70px;
                margin-top: 25px;
                margin-left: 287px;"
              >
                <img src="/assets/images/percentage.png" />
                <ng-container *ngIf="CHW_VALVE_out !== false; else emptyInput">
                  <input readonly
                    [ngModel]="CHW_VALVE_out | number : '1.2-2'"
                    (ngModelChange)="CHW_VALVE_out = $event"
                  />
                </ng-container>
                <ng-template #emptyInput>
                  <input readonly [ngModel]="''" />
                </ng-template>
              </div>
  
              
  
              <!-- 3. 1. Fan Speed -->
              <div
                [className]="capsule_group"
                style="position:absolute; width: 80px;
                margin-top: -155px;
                margin-left: 357px;"
              >
                <img src="/assets/images/fan-speed.png" />
                <ng-container *ngIf="FAN_SPEED_in !== false; else emptyInput">
                  <input readonly
                    [ngModel]="FAN_SPEED_in | number : '1.2-2'"
                    (ngModelChange)="FAN_SPEED_in = $event"
                  />
                </ng-container>
                <ng-template #emptyInput>
                  <input readonly [ngModel]="''" />
                </ng-template>
              </div>
              <!-- 3.2. Fan Speed -->
              <div
                class="capsule-rl"
                style="position:absolute; width: 80px;
                margin-top: -155px;
                margin-left: 438px;"
              >
                <img src="/assets/images/fan-speed.png" />
                <ng-container *ngIf="FAN_SPEED_in !== false; else emptyInput">
                  <input readonly
                    [ngModel]="FAN_SPEED_in | number : '1.2-2'"
                    (ngModelChange)="FAN_SPEED_in = $event"
                  />
                </ng-container>
                <ng-template #emptyInput>
                  <input readonly [ngModel]="''" />
                </ng-template>
              </div>
              <!-- 4. Fan Power -->
              <div
                [className]="capsule_group"
                style="position:absolute; width: 110px;
                margin-top: -155px;
                margin-left: 519px;"
              >
                <img src="/assets/images/power.png" />
                <ng-container *ngIf="POWER_in !== false; else emptyInput">
                  <input readonly
                    [ngModel]="POWER_in | number : '1.2-2'"
                    (ngModelChange)="POWER_in = $event"
                  />
                </ng-container>
                <ng-template #emptyInput>
                  <input readonly [ngModel]="''" />
                </ng-template>
                <span>Wh</span>
              </div>
  
                  <!-- 5. SDPT_in -->
              <div
              [className]="capsule_group"
              style="position:absolute; width: 94px;
              margin-top: -53px;
              margin-left: 645px;"
              >
                  <img src="/assets/images/differential-pressure-transmitter.png" />
                  <ng-container *ngIf="SDPT_in !== false; else emptyInput">
                    <input readonly
                      [ngModel]="SDPT_in | number : '1.2-2'"
                      (ngModelChange)="SDPT_in = $event"
                    />
                  </ng-container>
                  <ng-template #emptyInput>
                    <input readonly [ngModel]="''" />
                  </ng-template>
              <span>Pa</span>
            </div>
  
              <!-- 6. SAT_in -->
              <div
                [className]="capsule_group"
                style="position:absolute; width: 90px;
                margin-top: -110px;
                margin-left: 710px;"
              >
                <img src="/assets/images/temperature-celcius.png" />
                <ng-container *ngIf="SAT_in !== false; else emptyInput">
                  <input readonly
                    [ngModel]="SAT_in | number : '1.2-2'"
                    (ngModelChange)="SAT_in = $event"
                  />
                </ng-container>
                <ng-template #emptyInput>
                  <input readonly [ngModel]="''" />
                </ng-template>
              </div>
  
              <!-- <div
                class="input-group2"
                style="width: 115px;
                margin-top: 5px;
                margin-left: 80px;"
              >
                <img src="/assets/images/CO2.png" />
                <ng-container *ngIf="cassandra_data['co2'] !== false; else emptyInput">
                  <input readonly
                    [ngModel]="cassandra_data['co2'] | number : '1.2-2'"
                    (ngModelChange)="cassandra_data['co2'] = $event"
                  />
                </ng-container>
                <ng-template #emptyInput>
                  <input readonly [ngModel]="''" />
                </ng-template>
                <span>ppm</span>
              </div> -->
  
              <!-- <div
                class="input-group2"
                style="width: 70px;
                margin-top: 5px;
                margin-left: 79px;"
              >
                <img src="/assets/images/percentage.png" />
                <ng-container *ngIf="cassandra_data['damper_co2'] !== false; else emptyInput">
                  <input readonly
                    [ngModel]="cassandra_data['damper_co2'] | number : '1.2-2'"
                    (ngModelChange)="cassandra_data['damper_co2'] = $event"
                  />
                </ng-container>
                <ng-template #emptyInput>
                  <input readonly [ngModel]="''" />
                </ng-template>
              </div> -->
  
  
              <!-- <div
                class="input-group2"
                style="width: 110px;
                margin-top: -45px;
                margin-left: 225px;"
              >
                <img src="/assets/images/DP.png" />
                <ng-container *ngIf="cassandra_data['pfp'] !== false; else emptyInput">
                  <input readonly
                    [ngModel]="cassandra_data['pfp'] | number : '1.2-2'"
                    (ngModelChange)="cassandra_data['pfp'] = $event"
                  />
                </ng-container>
                <ng-template #emptyInput>
                  <input readonly [ngModel]="''" />
                </ng-template>
                <span>mbar</span>
              </div> -->
  
            
              <!-- <div style="margin-top: -42px; margin-left: 954px">
                <button
                  style="
                    border-radius: 10px;
                    border: none;
                    height: auto;
                    height: 40px;
                    width: 70px;
                    color: white;
                    cursor: pointer;
                  "
                  [ngClass]="{
                    'button-disabled': disablePMV,
                    'button-enabled': !disablePMV
                  }"
                  
                  [disabled]="disablePMV"
                  routerLink="../pmv"
                >
                  PMV
                </button>
              </div> -->
            </div>
          </div>
        </div>
  
        <div
        style="
          height: auto;
          width: 100%;
          min-width: 1000px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 7px;
          align-items: center;
          gap: 10px;
        "
      >
        <div
          style="
            width: 100%;
            display: flex;
            flex-direction: row;
            height: auto;
            justify-content: center;
          "
        >
          <div style="width: 475px; height: 100%; margin: 0px 20px 0px 50px">
            <div
            [ngClass]="{'disabled': isDisabled}"
              class="form"
              style="
                padding: 20px;
                flex-direction: row;
                gap: 30px;
                align-items: start;
              "
            >
              <div
                style="
                  width: 50%;
                  display: flex;
                  flex-direction: column;
                  gap: 10px;
                "
              >
                <!-- RAT -->
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 12px; font-weight: 500"
                    >RAT</span
                  >
  
                  <div [ngClass]="{ 'input-groupD': isDisabled, 'input-group': !isDisabled }" style="width: 100px">
                    <img src="/assets/images/temperature-celcius.png" />
                    <input readonly [(ngModel)]="RAT" [disabled]="isDisabled" 
                    style="background-color: white; color: {{ !isDisabled ? 'black' : 'white' }};"/>
                  </div>
                </div>
  
                <!-- CHW Valve -->
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 12px; font-weight: 500"
                    >CHW VALVE</span
                  >
  
                  <div [ngClass]="{ 'input-groupD': isDisabled, 'input-group': !isDisabled }" style="width: 100px">
                    <img src="/assets/images/percentage.png" />
                    <input readonly [(ngModel)]="CHW_VALVE" [disabled]="isDisabled" 
                    style="background-color: white; color: {{ !isDisabled ? 'black' : 'white' }};"/>
                  </div>
                </div>
  
                <!-- FAN SPEED -->
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 12px; font-weight: 500"
                    >FAN SPEED</span
                  >
  
                  <div [ngClass]="{ 'input-groupD': isDisabled, 'input-group': !isDisabled }" style="width: 100px">
                    <img src="/assets/images/percentage.png" />
                    <input readonly [(ngModel)]="FAN_SPEED" [disabled]="isDisabled" 
                    style="background-color: white; color: {{ !isDisabled ? 'black' : 'white' }};"/>
                  </div>
                </div>
  
               
              </div>
  
              <div
                style="
                  width: 50%;
                  display: flex;
                  flex-direction: column;
                  gap: 10px;
                  justify-content: space-between;
                "
              >
                <div style="display: flex; flex-direction: column; gap: 10px">
                  <!-- <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <span
                      class="span-text-dark"
                      style="font-size: 12px; font-weight: 500"
                      >SUPPLY DPT</span
                    >
  
                    <div class="input-group" style="width: 100px">
                      <img src="/assets/images/differential-pressure-transmitter.png" />
                      <input readonly [(ngModel)]="SAT" />
                      <span>Pa</span>
                    </div>
                  </div> -->
  
                   <!-- SAT -->
                <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <span
                  class="span-text-dark"
                  style="font-size: 12px; font-weight: 500"
                  >SAT</span
                >
  
                <div [ngClass]="{ 'input-groupD': isDisabled, 'input-group': !isDisabled }" style="width: 100px">
                  <img src="/assets/images/temperature-celcius.png" />
                  <input readonly [(ngModel)]="SAT" [disabled]="isDisabled" 
                  style="background-color: white; color: {{ !isDisabled ? 'black' : 'white' }};"/>
                </div>
              </div>
  
  
  
                  <!-- chws temp. -->
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <span
                      class="span-text-dark"
                      style="font-size: 12px; font-weight: 500"
                      >{{ stap_zone_label }}</span
                    >
  
                    <div [ngClass]="{ 'input-groupD': isDisabled, 'input-group': !isDisabled }" style="width: 100px">
                      <img src="/assets/images/temperature-celcius.png" />
                      <input readonly [(ngModel)]="STASP_ZONE_A" [disabled]="isDisabled" 
                      style="background-color: white; color: {{ !isDisabled ? 'black' : 'white' }};"/>
                    </div>
                  </div>
  
                  <!-- CHW VALVE -->
  
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <span
                      class="span-text-dark"
                      style="font-size: 12px; font-weight: 500"
                      >AMBIENT TEMP.</span
                    >
  
                    <div [ngClass]="{ 'input-groupD': isDisabled, 'input-group': !isDisabled }" style="width: 100px">
                      <img src="/assets/images/temperature-celcius.png" />
                      <input readonly [(ngModel)]="AMBIENT_TEMP" [disabled]="isDisabled" 
                      style="background-color: white; color: {{ !isDisabled ? 'black' : 'white' }};"/>
                    </div>
                  </div>
                </div>
  
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    gap: 10px;
                    justify-content: end;
                    margin-top: 10px;
                  "
                >
                  <button
                    style="
                      width: 50%;
                      background-color: var(--blue-one);
                      border-radius: 20px;
                      border: none;
                      height: auto;
                      padding: 7px;
                      color: white;
                      cursor: pointer;
                    "
                    [disabled]="isDisabled"
                    (click)="predictData()"
                  >
                    Predict
                  </button>
                </div>
              </div>
            </div>
            <!-- <div *ngIf="isDisabled" style="height: 100%; width: 100%; background-color: gray; position:relative; top: -100%; opacity:0.5; border-radius: 8px;"></div> -->
          </div>
  
          <!-- KPI -->
  
          <div class="form" style="height: 200px; width: 250px">
            <span
              class="span-text-dark"
              style="font-weight: bold; text-align: center"
              >KEY PERFORMANCE INDICATOR</span
            >
  
            <div style="height: 30px"></div>
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 12px; font-weight: 500"
                >OVERALL HVAC SAVING</span
              >
  
              <div class="input-group" style="width: 120px">
                <img src="/assets/images/power.png" />
                <input readonly
                  [ngModel]="KPI | number : '1.2-2'"
                  (ngModelChange)="KPI = $event"
                />
                <span>%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  
  
    <div *ngIf="type==3">
      <div style="
          width: 100%;
          height: 100%;
          min-width: 1200px;
          display: flex;
          flex-direction: column;
          position: absolute; ">
        <!-- <mat-progress-bar mode="indeterminate" [ngClass]="{ 'show-progress': progress, 'hide-progress': !progress }" ></mat-progress-bar> -->
        <div style="
            height: auto;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 10px;
            align-items: center;
            gap: 10px;
          " >
          <div style="
              height: 350px;
              width: 1000px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
            " >
            <!-- AHU Map  -->
            <div
              style="
                height: 350px;
                width: 1000px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
              " >
              <img src="/assets/images/ahu-type-three.svg" width="1000px" height="350px" />
              <div style="position: absolute; top: 230px; left: 375px; height: 50px; width: 250px;">
                <div style="display: flex; width: 100%; height: 100%; justify-content: end;">
                  <p style="font-family: 'Inter', sans-serif; font-size: 14px; font-weight:520; text-align: center; line-height:25px; ">{{ name }}</p>
                </div>
              </div>
            </div>
  
            <!-- componets -->
            <div style="height: 350px; width: 1000px; position: absolute">
              <!-- FAN -->
              <div style=" position:absolute;
                  height: 70px;
                  width: 70px;
                  margin-top: 123px;
                  margin-left: 396px;
                " >
                <Fan [state]="state"></Fan>
              </div>
  
              <!-- 1. RAT -->
              <!-- <div
                class="input-group"
                style="width: 80px; margin-top: -180px; margin-left: 150px" >
                <img src="/assets/images/temperature-celcius.png" />
                <input readonly
                  [ngModel]="RAT_in | number : '1.2-2'"
                  (ngModelChange)="RAT_in = $event"
                />
              </div> -->
  
              
              <!-- 2.1. CHW_VALVE  -->
              <div
                class="capsule-rl"
                style="position:absolute; width: 70px;
                margin-top: 228px;
                margin-left: 103px;"
              >
                <img src="/assets/images/percentage.png" />
                <ng-container *ngIf="CHW_VALVE_in !== false; else emptyInput">
                  <input readonly
                    [ngModel]="CHW_VALVE_in | number : '1.2-2'"
                    (ngModelChange)="CHW_VALVE_in = $event"
                  />
                </ng-container>
                <ng-template #emptyInput>
                  <input readonly [ngModel]="''" />
                </ng-template>
              </div>
              <!-- 2.2. CHW_VALVE  -->
              <div
                [className]="capsule_group"
                style="position:absolute; width: 70px;
                margin-top: 228px;
                margin-left: 210px;"
              >
                <img src="/assets/images/percentage.png" />
                <ng-container *ngIf="CHW_VALVE_in !== false; else emptyInput">
                  <input readonly
                    [ngModel]="CHW_VALVE_in | number : '1.2-2'"
                    (ngModelChange)="CHW_VALVE_in = $event"
                  />
                </ng-container>
                <ng-template #emptyInput>
                  <input readonly [ngModel]="''" />
                </ng-template>
              </div>
            <!-- 3. Fan Power -->
              <div
                [className]="capsule_group"
                style="position:absolute; width: 110px;
                margin-top: 30px;
                margin-left: 464px;"
              >
                <img src="/assets/images/power.png" />
                <ng-container *ngIf="POWER_in !== false; else emptyInput">
                  <input readonly
                    [ngModel]="POWER_in | number : '1.2-2'"
                    (ngModelChange)="POWER_in = $event"
                  />
                </ng-container>
                <ng-template #emptyInput>
                  <input readonly [ngModel]="''" />
                </ng-template>
                <span>Wh</span>
              </div>
             
  
              <!-- 4.1. Fan Speed-->
              <div
                [className]="capsule_group"
                style="position:absolute; width: 85px;
                margin-top: 30px;
                margin-left: 288px;"
              >
                <img src="/assets/images/fan-speed.png" />
                <ng-container *ngIf="FAN_SPEED_in !== false; else emptyInput">
                  <input readonly
                    [ngModel]="FAN_SPEED_in | number : '1.2-2'"
                    (ngModelChange)="FAN_SPEED_in = $event"
                  />
                </ng-container>
                <ng-template #emptyInput>
                  <input readonly [ngModel]="''" />
                </ng-template>
              </div>
              <!-- 4.2. Fan Speed-->
              <div
                class="capsule-rl"
                style="position:absolute; width: 85px;
                margin-top: 30px;
                margin-left: 375px;"
              >
                <img src="/assets/images/fan-speed.png" />
                <ng-container *ngIf="FAN_SPEED_in !== false; else emptyInput">
                  <input readonly
                    [ngModel]="FAN_SPEED_in | number : '1.2-2'"
                    (ngModelChange)="FAN_SPEED_in = $event"
                  />
                </ng-container>
                <ng-template #emptyInput>
                  <input readonly [ngModel]="''" />
                </ng-template>
              </div>
  
                  <!-- 5. SDPT_in -->
              <!-- <div
              class="input-group"
              style="width: 94px;
              margin-top: 77px;
              margin-left: 645px;"
              >
                  <img src="/assets/images/differential-pressure-transmitter.png" />
                  <input readonly
                    [ngModel]="SDPT_in | number : '1.2-2'"
                  (ngModelChange)="SDPT_in = $event"
                  />
              <span>Pa</span>
            </div> -->
  
              <!-- 6. SAT_in -->
              <div
              [className]="capsule_group"
                style="position:absolute; width: 90px;
                margin-top: 85px;
                margin-left: 700px;"
              >
                <img src="/assets/images/temperature-celcius.png" />
                <ng-container *ngIf="SAT_in !== false; else emptyInput">
                  <input readonly
                    [ngModel]="SAT_in | number : '1.2-2'"
                    (ngModelChange)="SAT_in = $event"
                  />
                </ng-container>
                <ng-template #emptyInput>
                  <input readonly [ngModel]="''" />
                </ng-template>
              </div>
  
            
              <!-- <div style="margin-top: -42px; margin-left: 954px">
                <button
                  style="
                    border-radius: 10px;
                    border: none;
                    height: auto;
                    height: 40px;
                    width: 70px;
                    color: white;
                    cursor: pointer;
                  "
                  [ngClass]="{
                    'button-disabled': disablePMV,
                    'button-enabled': !disablePMV
                  }"
                  
                  [disabled]="disablePMV"
                  routerLink="../pmv"
                >
                  PMV
                </button>
              </div> -->
            </div>
          </div>
        </div>
  
        <div
        style="
          height: auto;
          width: 100%;
          min-width: 1000px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 7px;
          align-items: center;
          gap: 10px;
        "
      >
        <div
          style="
            width: 100%;
            display: flex;
            flex-direction: row;
            height: auto;
            justify-content: center;
          "
        >
          <div style="width: 475px; height: 100%; margin: 0px 20px 0px 50px">
            <div
              class="form"
              [ngClass]="{'disabled': isDisabled}"
              style="
                padding: 20px;
                flex-direction: row;
                gap: 30px;
                align-items: start;
              "
            >
              <div
                style="
                  width: 50%;
                  display: flex;
                  flex-direction: column;
                  gap: 10px;
                "
              >
                <!-- RAT -->
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 12px; font-weight: 500"
                    >RAT</span
                  >
  
                  <div [ngClass]="{ 'input-groupD': isDisabled, 'input-group': !isDisabled }" style="width: 100px">
                    <img src="/assets/images/temperature-celcius.png" />
                    <input readonly [(ngModel)]="RAT" [disabled]="isDisabled" 
                    style="background-color: white; color: {{ !isDisabled ? 'black' : 'white' }};"/>
                  </div>
                </div>
  
                <!-- CHW Valve -->
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 12px; font-weight: 500"
                    >CHW VALVE</span
                  >
  
                  <div [ngClass]="{ 'input-groupD': isDisabled, 'input-group': !isDisabled }" style="width: 100px">
                    <img src="/assets/images/percentage.png" />
                    <input readonly [(ngModel)]="CHW_VALVE" [disabled]="isDisabled" 
                    style="background-color: white; color: {{ !isDisabled ? 'black' : 'white' }};"/>
                  </div>
                </div>
  
                <!-- FAN SPEED -->
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <span
                    class="span-text-dark"
                    style="font-size: 12px; font-weight: 500"
                    >FAN SPEED</span
                  >
  
                  <div [ngClass]="{ 'input-groupD': isDisabled, 'input-group': !isDisabled }" style="width: 100px">
                    <img src="/assets/images/percentage.png" />
                    <input readonly [(ngModel)]="FAN_SPEED" [disabled]="isDisabled" 
                    style="background-color: white; color: {{ !isDisabled ? 'black' : 'white' }};"/>
                  </div>
                </div>
  
               
              </div>
  
              <div
                style="
                  width: 50%;
                  display: flex;
                  flex-direction: column;
                  gap: 10px;
                  justify-content: space-between;
                "
              >
                <div style="display: flex; flex-direction: column; gap: 10px">
                  <!-- <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <span
                      class="span-text-dark"
                      style="font-size: 12px; font-weight: 500"
                      >SUPPLY DPT</span
                    >
  
                    <div class="input-group" style="width: 100px">
                      <img src="/assets/images/differential-pressure-transmitter.png" />
                      <input readonly [(ngModel)]="SAT" />
                      <span>Pa</span>
                    </div>
                  </div> -->
  
                   <!-- SAT -->
                <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <span
                  class="span-text-dark"
                  style="font-size: 12px; font-weight: 500"
                  >SAT</span
                >
  
                <div [ngClass]="{ 'input-groupD': isDisabled, 'input-group': !isDisabled }" style="width: 100px">
                  <img src="/assets/images/temperature-celcius.png" />
                  <input readonly [(ngModel)]="SAT" [disabled]="isDisabled" 
                  style="background-color: white; color: {{ !isDisabled ? 'black' : 'white' }};"/>
                </div>
              </div>
  
  
  
                  <!-- chws temp. -->
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <span
                      class="span-text-dark"
                      style="font-size: 12px; font-weight: 500"
                      >{{ stap_zone_label }}</span
                    >
  
                    <div [ngClass]="{ 'input-groupD': isDisabled, 'input-group': !isDisabled }" style="width: 100px">
                      <img src="/assets/images/temperature-celcius.png" />
                      <input readonly [(ngModel)]="STASP_ZONE_A" [disabled]="isDisabled" 
                      style="background-color: white; color: {{ !isDisabled ? 'black' : 'white' }};"/>
                    </div>
                  </div>
  
                  <!-- CHW VALVE -->
  
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <span
                      class="span-text-dark"
                      style="font-size: 12px; font-weight: 500"
                      >AMBIENT TEMP.</span
                    >
  
                    <div [ngClass]="{ 'input-groupD': isDisabled, 'input-group': !isDisabled }" style="width: 100px">
                      <img src="/assets/images/temperature-celcius.png" />
                      <input readonly [(ngModel)]="AMBIENT_TEMP" [disabled]="isDisabled" 
                      style="background-color: white; color: {{ !isDisabled ? 'black' : 'white' }};"/>
                    </div>
                  </div>
                </div>
  
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    gap: 10px;
                    justify-content: end;
                    margin-top: 10px;
                  "
                >
                  <button
                    style="
                      width: 50%;
                      background-color: var(--blue-one);
                      border-radius: 20px;
                      border: none;
                      height: auto;
                      padding: 7px;
                      color: white;
                      cursor: pointer;
                    "
                    [disabled]="isDisabled"
                    (click)="predictData()"
                  >
                    Predict
                  </button>
                </div>
              </div>
            </div>
            <!-- <div *ngIf="isDisabled" style="height: 100%; width: 100%; background-color: gray; position:relative; top: -100%; opacity:0.5; border-radius: 8px;"></div> -->
          </div>
  
          <!-- KPI -->
  
          <div class="form" style="height: 200px; width: 250px">
            <span
              class="span-text-dark"
              style="font-weight: bold; text-align: center"
              >KEY PERFORMANCE INDICATOR</span
            >
  
            <div style="height: 30px"></div>
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 12px; font-weight: 500"
                >OVERALL HVAC SAVING</span
              >
  
              <div class="input-group" style="width: 120px">
                <img src="/assets/images/power.png" />
                <input readonly
                  [ngModel]="KPI | number : '1.2-2'"
                  (ngModelChange)="KPI = $event"
                />
                <span>%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  
  </div>