import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-block-d-one',
  templateUrl: './block-d-one.component.html',
  styleUrls: ['./block-d-one.component.css']
})
export class BlockDOneComponent {
KPI:number=0;
basepower:number=1109.49;
  optiResult: any;

  constructor(private http: HttpClient,private snackBar:MatSnackBar,private router:Router) { }

  ngOnInit():void{
    this.getPredictionResult();
    setInterval(()=>{
      this.getPredictionResult();
    },600000);
  }

  // async getPredictionResult() {
  //   this.http.get<any>(`${environment.apiBaseUrl}/auth/get_prediction_result`)
  //     .subscribe(
  //       (response: any) => {
  //         try {
  //           this.optiResult = response.result; // Directly assign the nested object
  //           // const totpow=this.optiResult['Power_D1L0']+this.optiResult['Power_D1L1']+this.optiResult['Power_D1L2']+this.optiResult['Power_D1L3']+this.optiResult['Power_D1L4'];
  //           // this.KPI=((this.basepower-(totpow))/(this.basepower))*100;

  //           var xd1l0=(this.optiResult['fan_speed_D1L0'])/50;
  //           var d1l0=Math.pow(xd1l0,3);
  //           d1l0=(1-d1l0)*100;
  //           console.log(d1l0);

  //           var xd1l1=(this.optiResult['fan_speed_D1L1'])/50;
  //           var d1l1=Math.pow(xd1l1,3);
  //           d1l1=(1-d1l1)*100;
  //           console.log(d1l1);

  //           var xd1l2=(this.optiResult['fan_speed_D1L2'])/50;
  //           var d1l2=Math.pow(xd1l2,3);
  //           d1l2=(1-d1l2)*100;
  //           console.log(d1l2);

  //           var xd1l3=(this.optiResult['fan_speed_D1L3'])/50;
  //           var d1l3=Math.pow(xd1l3,3);
  //           d1l3=(1-d1l3)*100;
  //           console.log(d1l3);

  //           var xd1l4=(this.optiResult['fan_speed_D1L4'])/50;
  //           var d1l4=Math.pow(xd1l4,3);
  //           d1l4=(1-d1l4)*100;
  //           console.log(d1l4);

  //           this.KPI=(d1l4+d1l3+d1l2+d1l1+d1l0)/5;
  //           this.KPI=(this.KPI)/(100);

  //         } catch (error) {
  //           console.error('Error processing prediction result:', error);
  //         }
  //       },
  //       (error) => {
  //         console.error('Error fetching optimized result:', error);
  //         this.openSnackBar('error in fetching predicted values')
  //       }
  //     );
  // }

  async getPredictionResult() {

    this.http.get<any>(`${environment.apiBaseUrl}/auth/get_result`)

      .subscribe(

        (response: any) => {

          try {

            this.optiResult = response.environment; // Directly assign the nested object

            var totpow=this.optiResult['Power_D1L0']+this.optiResult['Power_D1L1']+this.optiResult['Power_D1L2']+this.optiResult['Power_D1L3']+this.optiResult['Power_D1L4'];
            totpow=totpow/5;

            this.KPI=((this.basepower-(totpow))/(this.basepower))*100;

          } catch (error) {

            console.error('Error processing prediction result:', error);

          }

        },

        (error) => {

          console.error('Error fetching optimized result:', error);
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
            this.openSnackBar('Session expired')

          }
          this.openSnackBar('error in fetching predicted values')

        }

      );

  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }

}
