import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-block-b-one',
  templateUrl: './block-b-one.component.html',
  styleUrls: ['./block-b-one.component.css']
})
export class BlockBOneComponent {
KPI:number=0;
basepower:number=2322.50;
  optiResult: any;
  isloading:boolean=false;

  constructor(private http: HttpClient,private snackBar:MatSnackBar, private router: Router,) { }

  ngOnInit():void{
    this.getPredictionResult();
    setInterval(()=>{
      this.getPredictionResult();
    },600000);
  }

  // async getPredictionResult() {
  //   this.http.get<any>(`${environment.apiBaseUrl}/auth/get_prediction_result`)
  //     .subscribe(
  //       (response: any) => {
  //         try {
  //           this.optiResult = response.result; // Directly assign the nested object
  //           //const totpow=this.optiResult['Power_B1L0']+this.optiResult['Power_B1GS']+this.optiResult['Power_B1L1']+this.optiResult['Power_B1L2']+this.optiResult['Power_B1L3']+this.optiResult['Power_B1L4'];
  //           //this.KPI=((this.basepower-(totpow))/(this.basepower))*100;


  //           var xb1lo=(this.optiResult['fan_speed_B1L0'])/50;
  //           var b1lo=Math.pow(xb1lo,3);
  //           b1lo=(1-b1lo)*100;
  //           console.log(b1lo);

  //           var xb1gs=(this.optiResult['fan_speed_B1GS'])/50;
  //           var b1gs=Math.pow(xb1gs,3);
  //           b1gs=(1-b1gs)*100;
  //           console.log(b1gs);

  //           var xb1l1=(this.optiResult['fan_speed_B1L1'])/50;
  //           var b1l1=Math.pow(xb1l1,3);
  //           b1l1=(1-b1l1)*100;
  //           console.log(b1l1);

  //           // var xb1l2=(this.optiResult['fan_speed_B1L2'])/50;
  //           // var b1l2=Math.pow(xb1l2,3);
  //           // b1l2=(1-b1l2)*100;
  //           // console.log(b1l2);

  //           var xb1l3=(this.optiResult['fan_speed_B1L3'])/50;
  //           var b1l3=Math.pow(xb1l3,3);
  //           b1l3=(1-b1l3)*100;
  //           console.log(b1l3);

  //           var xb1l4=(this.optiResult['fan_speed_B1L4'])/50;
  //           var b1l4=Math.pow(xb1l4,3);
  //           b1l4=(1-b1l4)*100;
  //           console.log(b1l4);

  //           this.KPI=(b1lo+b1gs+b1l1+b1l3+b1l4)/6;
  //           this.KPI=(this.KPI)/(-100);

  //         } catch (error) {
  //           console.error('Error processing prediction result:', error);
  //         }
  //       },
  //       (error) => {
  //         if (error.status === 401) {
  //           // Redirect to the login page
  //           this.router.navigate(['/login']); // Adjust the path as necessary
  //           sessionStorage.removeItem('accessToken');
  //           this.openSnackBar('Session expired')

  //         }
  //         console.error('Error fetching optimized result:', error);
  //         this.openSnackBar('error in fetching predicted values')
  //       }
  //     );
  // }


  async getPredictionResult() {
    this.isloading=true;

    this.http.get<any>(`${environment.apiBaseUrl}/auth/get_result`)

      .subscribe(

        (response: any) => {

          try {
            this.isloading=false;

            this.optiResult = response.environment; // Directly assign the nested object

            var totpow=this.optiResult['Power_B1L0']+this.optiResult['Power_B1GS']+this.optiResult['Power_B1L1']+this.optiResult['Power_B1L3']+this.optiResult['Power_B1L4'];
            totpow=totpow/5;

            this.KPI=((this.basepower-(totpow))/(this.basepower))*100;

          } catch (error) {

            console.error('Error processing prediction result:', error);

          }

        },

        (error) => {
          this.isloading=false;
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
            this.openSnackBar('Session expired')

          }
          console.error('Error fetching optimized result:', error);

          this.openSnackBar('error in fetching predicted values')

        }

      );

  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }
}
