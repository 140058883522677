import { Component } from '@angular/core';

@Component({
  selector: 'd-five-block-comp',
  templateUrl: './d-five-block-comp.component.html',
  styleUrls: ['./d-five-block-comp.component.css']
})
export class DFiveBlockCompComponent {

}
