<div class="chiller-comp">
  <!-- <svg id="chiller-comp" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%"
    height="100%" viewBox="0 0 304.47 268.007">
    <defs>
      <filter id="Rectangle_1267" x="93.168" y="38.671" width="159" height="64" filterUnits="userSpaceOnUse">
        <feOffset dx="3" dy="3" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="4.5" result="blur-3" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur-3" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Component_61_3" data-name="Component 61 – 3" transform="translate(55.5 1.151)">
      <g transform="matrix(1, 0, 0, 1, -55.5, -1.15)" filter="url(#Rectangle_1267)">
        <rect id="Rectangle_1267-2" data-name="Rectangle 1267" width="132" height="37" rx="10"
          transform="translate(103.67 49.17)" fill="#529ec6" />
      </g>
      <text id="Chiller_Plant" data-name="Chiller Plant" transform="translate(113.168 56.019)" fill="#fff"
        font-size="18" font-family="Mulish-Regular, Mulish">
        <tspan x="-50.193" y="18">Chiller Plant</tspan>
      </text>
    </g>
  </svg> -->

  <svg xmlns="http://www.w3.org/2000/svg" width="355.132" height="205.996"  viewBox="0 0 304.47 268.007">
    <g id="Group_6576" data-name="Group 6576" transform="translate(-6374.599 9725.076)">
      <g id="Group_6575" data-name="Group 6575" transform="translate(254 1175)">
        <path id="Path_23261" data-name="Path 23261" d="M4956.1-9430.041s-.373,26.094,16.985,29.719c5.456,-5.178,17.759,13.729,26.976,18.916,25.891,14.57,70,9.524,70,9.524s40.828-6.528,62.969-20.74,22.2-35.987,22.2-35.987v62.808a71.366,71.366,0,0,1-29.946,33.563c-22.141,12.553-58.618,16.647-58.618,16.647s-40.71.529-66.6-12.023c-12.505-6.062-22.8-14.664-29-22.716a44.906,44.906,0,0,1-7.962-15.371Z" transform="translate(1161 -1487)" fill="none" stroke="#427894" stroke-width="3" stroke-dasharray="5"/>
        <path id="Path_23263" data-name="Path 23263" d="M7232.058-9539.055v48.094" transform="translate(-1093 -1336.418)" fill="none" stroke="#427894" stroke-width="3" stroke-dasharray="5"/>
        <path id="Path_23264" data-name="Path 23264" d="M7232.058-9539.055v48.632" transform="translate(-1065.177 -1322.76)" fill="none" stroke="#427894" stroke-width="3" stroke-dasharray="5"/>
        <path id="Path_23265" data-name="Path 23265" d="M7232.058-9539.055v47.743" transform="translate(-1030.339 -1315.371)" fill="none" stroke="#427894" stroke-width="3" stroke-dasharray="5"/>
        <path id="Path_23267" data-name="Path 23267" d="M7232.058-9539.054v51.122" transform="translate(-994.171 -1319.751)" fill="none" stroke="#427894" stroke-width="3" stroke-dasharray="5"/>
        <path id="Path_23270" data-name="Path 23270" d="M7232.058-9539.056v50.372" transform="translate(-959.004 -1330.772)" fill="none" stroke="#427894" stroke-width="3" stroke-dasharray="5"/>
        <path id="Path_23271" data-name="Path 23271" d="M7232.058-9539.055v44.955" transform="translate(-931.121 -1346.542)" fill="none" stroke="#427894" stroke-width="3" stroke-dasharray="5"/>
      </g>
    </g>
    <g id="Component_61_3" data-name="Component 61 – 3" transform="translate(-10 15.151)">
      <g transform="matrix(1, 0, 0, 1, -55.5, -1.15)" filter="url(#Rectangle_1267)">
        <rect id="Rectangle_1267-2" data-name="Rectangle 1267" width="132" height="37" rx="10"
          transform="translate(103.67 49.17)" fill="#529ec6" />
      </g>
      <text id="Chiller_Plant" data-name="Chiller Plant" transform="translate(113.168 56.019)" fill="#fff"
        font-size="18" font-family="Mulish-Regular, Mulish">
        <tspan x="-50.193" y="18">Chiller Plant</tspan>
      </text>
    </g>
  </svg>
</div>


