<div style="position: relative; height: 100%; width: 100%">

    <!-- <Water-mark></Water-mark> -->
    <div style="
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative; 
        margin-left: -1%;
        padding-top: 2%;">
        <div style="width: 1050px; height: 559px;"></div>
        <img src="/assets/images/zone-a.svg" style="width: 1050px; height: 559px; position: absolute;">
        <Secondary-pump style="width: 75px; position: absolute; margin-bottom: 142px; transform: rotate(-90deg);"></Secondary-pump>
        <Secondary-pump style="width: 75px; position: absolute; margin-top: 118px;  transform: rotate(-90deg);"></Secondary-pump>
        <Secondary-pump style="width: 75px; position: absolute; margin-top: 368px;  transform: rotate(-90deg);"></Secondary-pump>
        <button style="
            border-radius: 10px;
            background-color: var(--blue-one);
            border: none;
            height: auto;
            height: 30px;
            width: 100px;
            color: white;
            cursor: pointer;
            margin-top: -350px;
            margin-left: -180px;
            z-index: 999;
            position: absolute;
            " routerLink="../chiller">
        CHILLERS 
        </button>
        <button style="
            border-radius: 10px;
            background-color: var(--blue-one);
            border: none;
            height: auto;
            height: 40px;
            width: 102px;
            color: white;
            cursor: pointer;
            margin-top: 600px;
            margin-left: 180px;
            z-index: 999;
            position: absolute;
            " routerLink="../primary-pump">
        PRIMARY PUMP
        </button>
        <div style="width: 1100px; height: 600px; position: absolute; ">
            <div class="input-group2" style="width: 120px; margin-top: 200px;
            margin-left: 580px; position: relative;">
              <img src="/assets/images/power.png" />
              <input readonly

              />
              <span>W</span>
            </div>
            <div class="input-group2" style="width: 120px; margin-top: 115px;
            margin-left: 580px;position: relative; ">
              <img src="/assets/images/power.png" />
              <input readonly

              />
              <span>W</span>
            </div>
            <div class="input-group2" style="width: 120px; margin-top: 88px;
            margin-left: 580px; position: relative;">
              <img src="/assets/images/power.png" />
              <input readonly

              />
              <span>W</span>
            </div>
            <div
                class="input-group"
                style="width: 80px; margin-left: 430px; margin-top: -283px; position: relative;"
            >
                <img src="/assets/images/percentage.png"/>
                <input readonly

                />
            </div>
            <div
                class="input-group"
                style="width: 80px; margin-left: 430px; margin-top: 104px; position: relative;"
            >
                <img src="/assets/images/percentage.png"/>
                <input readonly

                />
            </div>
            <div
                class="input-group"
                style="width: 80px; margin-left: 430px; margin-top: 90px; position: relative;"
            >
                <img src="/assets/images/percentage.png"/>
                <input readonly

                />
            </div>
        </div>
        <button routerLink="/dashboard/zone-b" style="z-index: 99999; border: 0; height: 80px; width: 80px; border-radius: 50px; border: 4px solid; cursor: pointer;">
            <img src="assets/logos/right-arrow.png" alt="" style="width: 30px;">
        </button>
    </div>
</div>