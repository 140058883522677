import { Component } from '@angular/core';

@Component({
  selector: 'Filter-one',
  templateUrl: './filter-one.component.html',
  styleUrls: ['./filter-one.component.css']
})
export class FilterOneComponent {

}
