<div style="position: relative; height: 100%; width: 100%;">
    <div class="main_container">
        <div class="content-container">
            <div class="upcontainer">
                <div class="map-container">
                    <img src="/assets/tower/Cooling Towers(a).svg" class="fmap">

                    <div class="component" style="width: 7.5%; height: 13%; top: 58%;left: 24.5%;  font-size: 120%;">
                        <button style="
                            border-radius: 5px;
                            background-color: #427894;
                            border: none;
                            height: 70%;
                            width: 100%;
                            font-size: 70%;       
                            color: white;
                            cursor: pointer;
                          
                            z-index: 999;
                            " routerLink="../cooling-tower1">
                            COOLING TOWER-1
                        </button>
                    </div>
                    <div class="component" style="width: 7.5%; height: 13%; top: 58%;left: 72%;  font-size: 120%;">
                        <button style="
                            border-radius: 5px;
                            background-color: #427894;
                            border: none;
                            height: 70%;
                            width: 100%;
                            font-size: 70%;       
                            color: white;
                            cursor: pointer;
                          
                            z-index: 999;
                            " routerLink="../cooling-tower2">
                            COOLING TOWER-2
                        </button>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 27%; left: 2%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #00A578; font-weight:200">CT 1/C1 F S</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 27%; left: 12%; width: 3.5%; height: 3%;">
                        <input readonly style="color:#00A578; font-weight:600" [ngModel]=" CT1_C1_FAN_SPEED_GR | number: '1.2-2'"
                            (ngModelChange)=" CT1_C1_FAN_SPEED_GR = $event" />
                        <span style="font-size: 80%; color: #00A578; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 23.5%; left: 2%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200">CT 1/C1 F S</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 23.5%; left: 12%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600"
                            [ngModel]=" CT1_C1_FAN_SPEED_RED | number: '1.2-2'"
                            (ngModelChange)=" CT1_C1_FAN_SPEED_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 27%; left: 14.5%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #00A578; font-weight:200">CT 1/C2 F S</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 27%; left: 24.5%; width: 3.5%; height: 3%;">
                        <input readonly style="color:#00A578; font-weight:600" [ngModel]=" CT1_C2_FAN_SPEED_GR | number: '1.2-2'"
                            (ngModelChange)=" CT1_C2_FAN_SPEED_GR = $event" />
                        <span style="font-size: 80%; color: #00A578; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 23.5%; left: 14.5%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200">CT 1/C2 F S</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 23.5%; left: 24.5%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600"
                            [ngModel]=" CT1_C2_FAN_SPEED_RED | number: '1.2-2'"
                            (ngModelChange)=" CT1_C2_FAN_SPEED_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 27%; left: 27%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #00A578; font-weight:200">CT 1/C3 F S</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 27%; left: 37%; width: 3.5%; height: 3%;">
                        <input readonly style="color:#00A578; font-weight:600" [ngModel]=" CT1_C3_FAN_SPEED_GR | number: '1.2-2'"
                            (ngModelChange)=" CT1_C3_FAN_SPEED_GR = $event" />
                        <span style="font-size: 80%; color: #00A578; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 23.5%; left: 27%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200">CT 1/C3 F S</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 23.5%; left: 37%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600"
                            [ngModel]=" CT1_C3_FAN_SPEED_RED | number: '1.2-2'"
                            (ngModelChange)=" CT1_C3_FAN_SPEED_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 27%; left: 49.5%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #00A578; font-weight:200">CT 2/C1 F S</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 27%; left: 59.5%; width: 3.5%; height: 3%;">
                        <input readonly style="color:#00A578; font-weight:600" [ngModel]=" CT2_C1_FAN_SPEED_GR | number: '1.2-2'"
                            (ngModelChange)=" CT2_C1_FAN_SPEED_GR = $event" />
                        <span style="font-size: 80%; color: #00A578; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 23.5%; left: 49.5%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200">CT 2/C1 F S</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 23.5%; left: 59.5%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600"
                            [ngModel]=" CT2_C1_FAN_SPEED_RED | number: '1.2-2'"
                            (ngModelChange)=" CT2_C1_FAN_SPEED_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 27%; left: 62%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #00A578; font-weight:200">CT 2/C2 F S</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 27%; left: 72%; width: 3.5%; height: 3%;">
                        <input readonly style="color:#00A578; font-weight:600" [ngModel]=" CT2_C2_FAN_SPEED_GR | number: '1.2-2'"
                            (ngModelChange)=" CT2_C2_FAN_SPEED_GR = $event" />
                        <span style="font-size: 80%; color: #00A578; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 23.5%; left: 62%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200">CT 2/C2 F S</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 23.5%; left: 72%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600"
                            [ngModel]=" CT2_C2_FAN_SPEED_RED | number: '1.2-2'"
                            (ngModelChange)=" CT2_C2_FAN_SPEED_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 27%; left: 74.5%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #00A578; font-weight:200">CT 2/C3 F S</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 27%; left: 84.5%; width: 3.5%; height: 3%;">
                        <input readonly style="color:#00A578; font-weight:600" [ngModel]=" CT2_C3_FAN_SPEED_GR | number: '1.2-2'"
                            (ngModelChange)=" CT2_C3_FAN_SPEED_GR = $event" />
                        <span style="font-size: 80%; color: #00A578; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 23.5%; left: 74.5%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200">CT 2/C3 F S</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 23.5%; left: 84.5%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600"
                            [ngModel]=" CT2_C3_FAN_SPEED_RED | number: '1.2-2'"
                            (ngModelChange)=" CT2_C3_FAN_SPEED_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 85%; left: 0%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200">CT 1/C1 water out</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 85%; left: 11.5%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" CT1_C1_Water_out | number: '1.2-2'"
                            (ngModelChange)=" CT1_C1_Water_out = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">°C</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 95.5%; left: 20%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200">CT 1/C2 water out</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 95.5%; left: 31.5%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" CT1_C2_Water_out | number: '1.2-2'"
                            (ngModelChange)=" CT1_C2_Water_out = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">°C</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 95.5%; left: 35%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200">CT 1/C3 water out</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 95.5%; left: 46.5%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" CT1_C3_Water_out | number: '1.2-2'"
                            (ngModelChange)=" CT1_C3_Water_out = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">°C</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 95.5%; left: 54.5%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200">CT 2/C1 water out</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 95.5%; left: 66%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" CT2_C1_Water_out | number: '1.2-2'"
                            (ngModelChange)=" CT2_C1_Water_out = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">°C</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 95.5%; left: 69.5%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200">CT 2/C2 water out</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 95.5%; left: 81%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" CT2_C2_Water_out | number: '1.2-2'"
                            (ngModelChange)=" CT2_C2_Water_out = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">°C</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 84%; left: 88%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200">CT 2/C3 water out</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 84%; left: 99.5%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" CT2_C3_Water_out | number: '1.2-2'"
                            (ngModelChange)=" CT2_C3_Water_out = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">°C</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top:0%; left: 4%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200">CT water in.</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 0%; left: 14%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" CT_Water_in | number: '1.2-2'"
                            (ngModelChange)=" CT_Water_in = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">°C</span>
                    </div>
                </div>
                <button routerLink="/dashboard/cooling-tower-b"
                    style="z-index: 99999; height: 60px; width: 60px; border-radius: 30px; border: 3px solid; cursor: pointer;">
                    <img src="assets/logos/right-arrow.png" alt="" style="width: 30px;">
                </button>
            </div>
        </div>
    </div>
</div>