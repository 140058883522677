<div class="parent">
    <span class="text">{{ textValue }}</span>
    <ng-container *ngIf="state === 'active'; else inActiveTemplate;">

        <img src="{{ urlActive }}" alt="active vapour liquid separator" height="100%" width="100%" />


    </ng-container>



    <ng-template #inActiveTemplate>

        <ng-container>

            <img src={{urlInActive}} alt="inactive vapour liquid separator" height="100%" width="100%" />

        </ng-container>

    </ng-template>

</div>