<div class="e-block-comp">
  <svg id="e-block-comp" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%"
    height="100%" viewBox="0 0 200.102 181.878">
    <defs>
      <linearGradient id="hover-gradient-e" x1="1" y1="0.624" x2="0" y2="0.431" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#97CAE5" />
        <stop offset="1" stop-color="#387290" />
      </linearGradient>
      <filter id="Rectangle_334" x="41.102" y="7.224" width="159" height="64" filterUnits="userSpaceOnUse">
        <feOffset dx="3" dy="3" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="4.5" result="blur" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
      <linearGradient id="linear-gradient-e" x1="1" y1="0.624" x2="0" y2="0.431" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#e6f8ff" />
        <stop offset="1" stop-color="#b3cad5" />
      </linearGradient>
      <filter id="Path_4596" x="0" y="45.382" width="194.859" height="136.496" filterUnits="userSpaceOnUse">
        <feOffset dx="-10" dy="10" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="5" result="blur-2" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur-2" />
        <feComposite in="SourceGraphic" />
      </filter>
      <linearGradient id="linear-gradient-e-2" x1="0.748" y1="0.174" x2="0.455" y2="1.451"
        gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#fff" />
        <stop offset="1" stop-color="#e4f4fc" />
      </linearGradient>
      <filter id="Path_20056" x="39.509" y="6.177" width="159" height="64" filterUnits="userSpaceOnUse">
        <feOffset dx="3" dy="3" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="4.5" result="blur-3" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur-3" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Component_59_3" data-name="Component 59 – 3" transform="translate(25.911 1.344)">
      <g transform="matrix(1, 0, 0, 1, -25.91, -1.34)" filter="url(#Rectangle_334)">
        <rect id="Rectangle_334-2" data-name="Rectangle 334" width="132" height="37" rx="10"
          transform="translate(51.6 17.72)" fill="#09295f" />
      </g>
      <text id="E_Block" data-name="E Block" transform="translate(25.691 24.38)" fill="#fff" font-size="18"
        font-family="Numans-Regular, Numans">
        <tspan x="-34.488" y="16">E Block</tspan>
      </text>
      <g transform="matrix(1, 0, 0, 1, -25.91, -1.34)" filter="url(#Path_4596)">
        <path id="Path_4596-2" data-name="Path 4596"
          d="M361.569,459.416V410.29H198.4v80.292h.118l-.365.388c50.609,15.795,106.643,24.116,163.019,24.81l.294-25.2.106-2.6V460.169C361.487,460.169,361.664,459.416,361.569,459.416Z"
          transform="translate(-172.24 -359.41)" stroke="#96bdd1" stroke-miterlimit="10" stroke-width="1"
          fill="url(#linear-gradient-e)" />
      </g>
      <path id="Path_4597" data-name="Path 4597"
        d="M361.169,442.548c-56.376-.706-112.387-9.015-163.019-24.81l46.7-49.538A407.381,407.381,0,0,0,361.84,386.184Z"
        transform="translate(-198.15 -368.2)" stroke="#96bdd1" stroke-miterlimit="10" stroke-width="2.36"
        fill="url(#linear-gradient-e-2)" />
      <path id="Path_4598" data-name="Path 4598" d="M198.15,426.85c50.609,15.795,106.643,24.116,163.019,24.81"
        transform="translate(-198.15 -357.822)" fill="none" stroke="#96bdd1" stroke-miterlimit="10"
        stroke-width="1.58" />
      <path id="Path_4599" data-name="Path 4599" d="M198.15,444.97c50.609,15.795,106.643,24.116,163.019,24.81"
        transform="translate(-198.15 -354.616)" fill="none" stroke="#96bdd1" stroke-miterlimit="10"
        stroke-width="1.58" />
      <path id="Path_4600" data-name="Path 4600" d="M198.15,461.51c50.609,15.795,106.643,24.116,163.019,24.81"
        transform="translate(-198.15 -351.689)" fill="none" stroke="#96bdd1" stroke-miterlimit="10"
        stroke-width="1.58" />
      <path id="Path_4601" data-name="Path 4601" d="M220.41,416.43V497.1" transform="translate(-194.211 -359.665)"
        fill="none" stroke="#96bdd1" stroke-miterlimit="10" stroke-width="3.15" />
      <path id="Path_4602" data-name="Path 4602" d="M242.47,421.55v80.668" transform="translate(-190.308 -358.76)"
        fill="none" stroke="#96bdd1" stroke-miterlimit="10" stroke-width="3.15" />
      <path id="Path_4603" data-name="Path 4603" d="M266.1,425.49v80.668" transform="translate(-186.127 -358.062)"
        fill="none" stroke="#96bdd1" stroke-miterlimit="10" stroke-width="3.15" />
      <path id="Path_4604" data-name="Path 4604" d="M292.1,429.43V510.1" transform="translate(-181.525 -357.365)"
        fill="none" stroke="#96bdd1" stroke-miterlimit="10" stroke-width="3.15" />
      <path id="Path_4605" data-name="Path 4605" d="M314.16,431.01v80.656" transform="translate(-177.623 -357.086)"
        fill="none" stroke="#96bdd1" stroke-miterlimit="10" stroke-width="3.15" />
      <g transform="matrix(1, 0, 0, 1, -25.91, -1.34)" filter="url(#Path_20056)">
        <path id="Path_20056-2" data-name="Path 20056"
          d="M10,0H122a10,10,0,0,1,10,10V27a10,10,0,0,1-10,10H10A10,10,0,0,1,0,27V10A10,10,0,0,1,10,0Z"
          transform="translate(50.01 16.68)" fill="#529ec6" />
      </g>
      <text id="E_Block-2" data-name="E Block" transform="translate(89.098 19.333)" fill="#fff" font-size="18"
        font-family="Mulish-Regular, Mulish">
        <tspan x="-30.132" y="18">E Block</tspan>
      </text>
    </g>
  </svg>
</div>