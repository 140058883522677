<div style="position: relative; height: 100%; width: 100%">
    <div class="main_container">
        <div class="content-container">
            <div class="upcontainer">
                <div class="map-container">
                    <img class="fmap" src="/assets/updated design/SECONDARY PUMPS.svg" />
                
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 59%; left: 21%; width: 3.5%; height: 3%;">
                        <input readonly style="color:#293AC6; font-weight:600" [ngModel]=" POWERA1_IN | number: '1.2-2'"
                            (ngModelChange)=" POWERA1_IN = $event" />
                        <span style="font-size: 80%; color: #293AC6; font-weight:600">kw</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 56.5%; left: 21%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" POWERA1_RED | number: '1.2-2'"
                            (ngModelChange)=" POWERA1_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">kw</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 59%; left: 15.5%; width: 3%; height: 3%;">
                        <input readonly style="color: #00A578; font-weight:600" [ngModel]=" A1_VFD_GR | number: '1.2-2'"
                            (ngModelChange)=" A1_VFD_GR = $event" />
                        <span style="font-size: 80%; color: #00A578; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 56.5%; left: 15.5%; width: 3%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" A1_VFD_RED | number: '1.2-2'"
                            (ngModelChange)=" A1_VFD_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 31.6%; left: 21%; width: 3.5%; height: 3%;">
                        <input readonly style="color:#293AC6; font-weight:600" [ngModel]=" POWERA2_IN | number: '1.2-2'"
                        (ngModelChange)=" POWERA2_IN = $event" />
                        <span style="font-size: 80%; color: #293AC6; font-weight:200">kw</span>
                    </div>
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 29%; left: 21%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" POWERA2_RED | number: '1.2-2'"
                            (ngModelChange)=" POWERA2_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">kw</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 31.6%; left: 15.5%; width: 3%; height: 3%;">
                        <input readonly style="color: #00A578; font-weight:600" [ngModel]=" A2_VFD_GR | number: '1.2-2'"
                            (ngModelChange)=" A2_VFD_GR = $event" />
                        <span style="font-size: 80%; color: #00A578; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 29%; left: 15.5%; width: 3%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" A2_VFD_RED | number: '1.2-2'"
                            (ngModelChange)=" A2_VFD_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 25.8%; left: 35%; width: 3.5%; height: 3%;">
                        <input readonly style="color:#293AC6; font-weight:600" [ngModel]=" POWERA3_IN | number: '1.2-2'"
                            (ngModelChange)=" POWERA3_IN = $event" />
                        <span style="font-size: 80%; color: #293AC6; font-weight:600">kw</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 23.2%; left: 35%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" POWERA3_RED | number: '1.2-2'"
                            (ngModelChange)=" POWERA3_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">kw</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 25.8%; left: 29.5%; width: 3%; height: 3%;">
                        <input readonly style="color: #00A578; font-weight:600" [ngModel]=" A3_VFD_GR | number: '1.2-2'"
                            (ngModelChange)=" A3_VFD_GR = $event" />
                        <span style="font-size: 80%; color: #00A578; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 23.2%; left: 29.5%; width: 3%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" A3_VFD_RED | number: '1.2-2'"
                            (ngModelChange)=" A3_VFD_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 80.6%; left: 37%; width: 3.5%; height: 3%;">
                        <input readonly style="color:#293AC6; font-weight:600" [ngModel]=" POWERB1_IN | number: '1.2-2'"
                            (ngModelChange)=" POWERB1_IN = $event" />
                        <span style="font-size: 80%; color: #293AC6; font-weight:600">kw</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 78%; left: 37%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" POWERB1_RED | number: '1.2-2'"
                            (ngModelChange)=" POWERB1_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">kw</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 80.6%; left: 31.5%; width: 3%; height: 3%;">
                        <input readonly style="color: #00A578; font-weight:600" [ngModel]=" B1_VFD_GR | number: '1.2-2'"
                            (ngModelChange)=" B1_VFD_GR = $event" />
                        <span style="font-size: 80%; color: #00A578; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 78%; left: 31.5%; width: 3%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" B1_VFD_RED | number: '1.2-2'"
                            (ngModelChange)=" B1_VFD_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 79.6%; left: 62.5%; width: 3.5%; height: 3%;">
                        <input readonly style="color:#293AC6; font-weight:600" [ngModel]=" POWERB2_IN | number: '1.2-2'"
                            (ngModelChange)=" POWERB2_IN = $event" />
                        <span style="font-size: 80%; color: #293AC6; font-weight:600">kw</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 76.8%; left: 62.5%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" POWERB2_RED | number: '1.2-2'"
                            (ngModelChange)=" POWERB2_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">kw</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 79.6%; left: 57%; width: 3%; height: 3%;">
                        <input readonly style="color: #00A578; font-weight:600" [ngModel]=" B2_VFD_GR | number: '1.2-2'"
                            (ngModelChange)=" B2_VFD_GR = $event" />
                        <span style="font-size: 80%; color: #00A578; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 76.8%; left: 57%; width: 3%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" B2_VFD_RED | number: '1.2-2'"
                            (ngModelChange)=" B2_VFD_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 69.8%; left: 78%; width: 3.5%; height: 3%;">
                        <input readonly style="color:#293AC6; font-weight:600" [ngModel]=" POWERB3_IN | number: '1.2-2'"
                            (ngModelChange)=" POWERB3_IN = $event" />
                        <span style="font-size: 80%; color: #293AC6; font-weight:600">kw</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 67%; left: 78%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" POWERB3_RED | number: '1.2-2'"
                            (ngModelChange)=" POWERB3_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">kw</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 69.8%; left: 72.5%; width: 3%; height: 3%;">
                        <input readonly style="color: #00A578; font-weight:600" [ngModel]=" B3_VFD_GR | number: '1.2-2'"
                            (ngModelChange)=" B3_VFD_GR = $event" />
                        <span style="font-size: 80%; color: #00A578; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 67%; left: 72.5%; width: 3%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" B3_VFD_RED | number: '1.2-2'"
                            (ngModelChange)=" B3_VFD_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 59%; left: 88%; width: 3.5%; height: 3%;">
                        <input readonly style="color:#293AC6; font-weight:600" [ngModel]=" POWERC1_IN | number: '1.2-2'"
                            (ngModelChange)=" POWERC1_IN = $event" />
                        <span style="font-size: 80%; color: #293AC6; font-weight:600">kw</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 56%; left: 88%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" POWERC1_RED | number: '1.2-2'"
                            (ngModelChange)=" POWERC1_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">kw</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 59%; left: 82.5%; width: 3%; height: 3%;">
                        <input readonly style="color: #00A578; font-weight:600" [ngModel]=" C1_VFD_GR | number: '1.2-2'"
                            (ngModelChange)=" C1_VFD_GR = $event" />
                        <span style="font-size: 80%; color: #00A578; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 56%; left: 82.5%; width: 3%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" C1_VFD_RED | number: '1.2-2'"
                            (ngModelChange)=" C1_VFD_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 31.8%; left: 87.5%; width: 3.5%; height: 3%;">
                        <input readonly style="color:#293AC6; font-weight:600" [ngModel]=" POWERC2_IN | number: '1.2-2'"
                            (ngModelChange)=" POWERC2_IN = $event" />
                        <span style="font-size: 80%; color: #293AC6; font-weight:600">kw</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 28.8%; left: 87.5%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" POWERC2_RED | number: '1.2-2'"
                            (ngModelChange)=" POWERC2_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">kw</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 31.8%; left: 81.5%; width: 3%; height: 3%;">
                        <input readonly style="color: #00A578; font-weight:600" [ngModel]=" C2_VFD_GR | number: '1.2-2'"
                            (ngModelChange)=" C2_VFD_GR = $event" />
                        <span style="font-size: 80%; color: #00A578; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 28.8%; left: 81.5%; width: 3%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" C2_VFD_RED | number: '1.2-2'"
                            (ngModelChange)=" C2_VFD_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 25.8%; left: 72.5%; width: 3.5%; height: 3%;">
                        <input readonly style="color:#293AC6; font-weight:600" [ngModel]=" POWERC3_IN | number: '1.2-2'"
                            (ngModelChange)=" POWERC3_IN = $event" />
                        <span style="font-size: 80%; color: #293AC6; font-weight:600">kw</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 23.2%; left: 72.5%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" POWERC3_RED | number: '1.2-2'"
                            (ngModelChange)=" POWERC3_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">kw</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 25.8%; left: 67%; width: 3%; height: 3%;">
                        <input readonly style="color: #00A578; font-weight:600" [ngModel]=" C3_VFD_GR | number: '1.2-2'"
                            (ngModelChange)=" C3_VFD_GR = $event" />
                        <span style="font-size: 80%; color: #00A578; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 23.2%; left: 67%; width: 3%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" C3_VFD_RED | number: '1.2-2'"
                            (ngModelChange)=" C3_VFD_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">%</span>
                    </div>
                </div> 
            </div>
        </div>
    </div>
</div>