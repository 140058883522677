<div class="parent">
    <span class="text">{{ textValue }}</span>
    <ng-container *ngIf="state === 'active'; else inActiveTemplate">

        <img src="{{ urlActive }}" alt="preheater" height="100%" width="100%" />


    </ng-container>



    <ng-template #inActiveTemplate>

        <ng-container *ngIf="state === 'inActive';">

            <img src={{urlInActive}} alt="preheater" height="100%" width="100%" />

        </ng-container>

    </ng-template>

</div>