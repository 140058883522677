<div style="position: relative; height: 100%; width: 100%">
  <div class="main_container">
    <div class="content-container">
      <div class="upcontainer">
        <div class="map-container">
          <img class="fmap" src="/assets/updated design/primary pumps.svg" />

       
          <div class="input-groupR  component"
            style="display:flex; justify-content:center; align-items:center; top: 36.6%; left: 20.8%; width: 4%; height: 3%;">
            <input readonly style="color:#293AC6; font-weight:600" [ngModel]=" POWER1_IN | number: '1.2-2'"
              (ngModelChange)=" POWER1_IN = $event" />
            <span style="font-size: 80%; color: #293AC6; font-weight:600">kw</span>
          </div>

     
          <div class="input-groupR  component"
            style="display:flex; justify-content:center; align-items:center; top: 32.8%; left: 20.8%; width: 4%; height: 3%;">
            <input readonly style="color: #D90606; font-weight:600" [ngModel]=" POWER1_RED | number: '1.2-2'"
              (ngModelChange)=" POWER1_RED = $event" />
            <span style="font-size: 80%; color: #D90606; font-weight:600">kw</span>
          </div>

       
          <div class="input-groupR  component"
            style="display:flex; justify-content:center; align-items:center; top: 36.6%; left: 13.6%; width: 3.5%; height: 3%;">
            <input readonly style="color: #00A578; font-weight:600" [ngModel]=" PP1_VFD_GR | number: '1.2-2'"
              (ngModelChange)=" PP1_VFD_GR = $event" />
            <span style="font-size: 80%; color: #00A578; font-weight:600">%</span>
          </div>

      
          <div class="input-groupR  component"
            style="display:flex; justify-content:center; align-items:center; top: 32.8%; left: 13.6%; width: 3.5%; height: 3%;">
            <input readonly style="color: #D90606; font-weight:600" [ngModel]=" PP1_VFD_RED | number: '1.2-2'"
              (ngModelChange)=" PP1_VFD_RED = $event" />
            <span style="font-size: 80%; color: #D90606; font-weight:600">%</span>
          </div>

     
          <div class="input-groupR  component"
            style="display:flex; justify-content:center; align-items:center; top: 97.3%; left: 47.2%; width: 4%; height: 3%;">
            <input readonly style="color:#293AC6; font-weight:600" [ngModel]=" POWER2_IN | number: '1.2-2'"
              (ngModelChange)=" POWER2_IN = $event" />
            <span style="font-size: 80%; color: #293AC6; font-weight:600">kw</span>
          </div>

  
          <div class="input-groupR  component"
            style="display:flex; justify-content:center; align-items:center; top: 93.6%; left: 47.2%; width: 4%; height: 3%;">
            <input readonly style="color: #D90606; font-weight:600" [ngModel]=" POWER2_RED | number: '1.2-2'"
              (ngModelChange)=" POWER2_RED = $event" />
            <span style="font-size: 80%; color: #D90606; font-weight:600">kw</span>
          </div>

          <div class="input-groupR  component"
            style="display:flex; justify-content:center; align-items:center; top: 97.3%; left: 40%; width: 3.5%; height: 3%;">
            <input readonly style="color: #00A578; font-weight:600" [ngModel]=" PP2_VFD_GR | number: '1.2-2'"
              (ngModelChange)=" PP2_VFD_GR = $event" />
            <span style="font-size: 80%; color: #00A578; font-weight:600">%</span>
          </div>


          <div class="input-groupR  component"
            style="display:flex; justify-content:center; align-items:center; top: 93.6%; left: 40%; width: 3.5%; height: 3%;">
            <input readonly style="color: #D90606; font-weight:600" [ngModel]=" PP2_VFD_RED | number: '1.2-2'"
              (ngModelChange)=" PP2_VFD_RED = $event" />
            <span style="font-size: 80%; color: #D90606; font-weight:600">%</span>
          </div>

          <div class="input-groupR  component"
            style="display:flex; justify-content:center; align-items:center; top: 96.8%; left: 71.6%; width: 4%; height: 3%;">
            <input readonly style="color:#293AC6; font-weight:600" [ngModel]=" POWER3_IN | number: '1.2-2'"
              (ngModelChange)=" POWER3_IN = $event" />
            <span style="font-size: 80%; color: #293AC6; font-weight:600">kw</span>
          </div>

    
          <div class="input-groupR  component"
            style="display:flex; justify-content:center; align-items:center; top: 93%; left: 71.6%; width: 4%; height: 3%;">
            <input readonly style="color: #D90606; font-weight:600" [ngModel]=" POWER3_RED | number: '1.2-2'"
              (ngModelChange)=" POWER3_RED = $event" />
            <span style="font-size: 80%; color: #D90606; font-weight:600">kw</span>
          </div>

          <div class="input-groupR  component"
            style="display:flex; justify-content:center; align-items:center; top: 96.4%; left: 64.2%; width: 3.5%; height: 3%;">
            <input readonly style="color: #00A578; font-weight:600" [ngModel]=" PP3_VFD_GR | number: '1.2-2'"
              (ngModelChange)=" PP3_VFD_GR = $event" />
            <span style="font-size: 80%; color: #00A578; font-weight:600">%</span>
          </div>

 
          <div class="input-groupR  component"
            style="display:flex; justify-content:center; align-items:center; top: 92.6%; left: 64.2%; width: 3.5%; height: 3%;">
            <input readonly style="color: #D90606; font-weight:600" [ngModel]=" PP3_VFD_RED | number: '1.2-2'"
              (ngModelChange)=" PP3_VFD_RED = $event" />
            <span style="font-size: 80%; color: #D90606; font-weight:600">%</span>
          </div>

          <div class="input-groupR  component"
            style="display:flex; justify-content:center; align-items:center; top: 38.4%; left: 100.5%; width: 4%; height: 3%;">
            <input readonly style="color:#293AC6; font-weight:600" [ngModel]=" POWER4_IN | number: '1.2-2'"
              (ngModelChange)=" POWER4_IN = $event" />
            <span style="font-size: 80%; color: #293AC6; font-weight:600">kw</span>
          </div>


          <div class="input-groupR  component"
            style="display:flex; justify-content:center; align-items:center; top: 34.4%; left: 100.5%; width: 4%; height: 3%;">
            <input readonly style="color: #D90606; font-weight:600" [ngModel]=" POWER4_RED | number: '1.2-2'"
              (ngModelChange)=" POWER4_RED = $event" />
            <span style="font-size: 80%; color: #D90606; font-weight:600">kw</span>
          </div>
  
          <div class="input-groupR  component"
            style="display:flex; justify-content:center; align-items:center; top: 38%; left: 93%; width: 3.5%; height: 3%;">
            <input readonly style="color: #00A578; font-weight:600" [ngModel]=" PP4_VFD_GR | number: '1.2-2'"
              (ngModelChange)=" PP4_VFD_GR = $event" />
            <span style="font-size: 80%; color: #00A578; font-weight:600">%</span>
          </div>

          <div class="input-groupR  component"
            style="display:flex; justify-content:center; align-items:center; top: 34%; left: 93%; width: 3.5%; height: 3%;">
            <input readonly style="color: #D90606; font-weight:600" [ngModel]=" PP4_VFD_RED | number: '1.2-2'"
              (ngModelChange)=" PP4_VFD_RED = $event" />
            <span style="font-size: 80%; color: #D90606; font-weight:600">%</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>