<div style="height: 100%; width: 100%">

    <ng-container *ngIf="state === 'active'; else inActiveTemplate">

        <img src="{{ urlActive }}" alt="pump-active" height="100%" width="100%" />


    </ng-container>



    <ng-template #inActiveTemplate>

        <ng-container *ngIf="state === 'inActive';">

            <img src={{urlInActive}} alt="pump-inactive" height="100%" width="100%" />

        </ng-container>

    </ng-template>

</div>