<div class="main-container">
    <div class="sidebar">
      <div class="menu-items">
        <div class="menu-item" routerLink="../command">AHU Command</div>
        <div class="menu-item" routerLink="../pid-tuning">PID Tuning Command </div>
        <div class="menu-item" routerLink="../chillerplant-command">Chiller Plant Command</div>
        <div class="menu-item" routerLink="../ahuschedule-command">AHU Schedule Command</div>
      </div>
    </div>
    <app-loading-spinner *ngIf="isloading" ></app-loading-spinner>

    <div class="content">
      <div class="command-section">
        <h2>AHU SCHEDULER COMMAND</h2>
        <div class="command-input-container">
          <input  [(ngModel)]="command" placeholder="Please enter Command key!" type="password" class="password-input" />
          <button class="show-command-btn" (click)="showCommand()">Show Command</button>
        </div>
        <!-- <div *ngIf="errorMessage" class="error-message" >{{errorMessage}}</div> -->

        <div *ngIf="showDetails">
          <p>You selected: {{selectedBlock}} - {{ selectedAHU }}</p>
          <div class="form-row">
            <label>BLOCK</label>
            <select [(ngModel)]="selectedBlock">
              <option *ngFor="let block of blocks">{{block}}</option>
            </select>
          </div>
  
          <div class="form-row">
            <label>FLOOR</label>
            <select [(ngModel)]="selectedAHU">
              <option *ngFor="let ahu of ahus[blockahumap.get(selectedBlock) ?? 0]">{{ahu}}</option>
            </select>
          </div>
  
          <div class="form-row">
            <label>BODY</label>
           
          </div>
  
          <div class="form-row">
            <label>Shedule ID</label>
            <input  type="number"  [(ngModel)]="schedule_id" />
          </div>
  
          <div class="form-row">
            <label>Start Time</label>
            <div class="time-inputs">
              <input  type="number" min="0" max="23" placeholder="0" [(ngModel)]="starthour" /> <span>hr</span>
              <input  type="number" min="0" max="59" placeholder="0" [(ngModel)]="startmin" /> <span>min</span>
            </div>
          </div>
          
          <div class="form-row">
            <label>Stop Time</label>
            <div class="time-inputs">
              <input  type="number" min="0" max="23" placeholder="0" [(ngModel)]="endhour"/> <span>hr</span>
              <input  type="number" min="0" max="59" placeholder="0" [(ngModel)]="endmin" /> <span>min</span>
            </div>
          </div>

                 <div class="radio-group">
            <label>
              <input type="radio" name="selectedAHUStatus" [(ngModel)]="schedulecontrol" [value]="1" /> Add
            </label>
            <label>
              <input type="radio" name="selectedAHUStatus" [(ngModel)]="schedulecontrol" [value]="0" /> Remove
            </label>
          </div>
          
          <button  (click)="sendCommand()">Send</button>
          <p class="waiting-text" *ngIf="isLoading">Waiting for the Command!</p>
          <p *ngIf="responseMessage"  
          [ngClass]="{
            'response-message': responseMessage.startsWith('Command sent successfully!!'), 
            'error-message': !responseMessage.startsWith('Command sent successfully!!')
          }">
         {{responseMessage}}
       </p>
        </div>
        <p *ngIf="errorMessage" class="error-message">{{ errorMessage }}</p>
      </div>
    </div>
  </div>