<div *ngIf="type == 1" style="position: relative; height: 100%; width: 100%">
  <div class="main_container">
    <!-- <mat-progress-bar mode="indeterminate" [ngClass]="{ 'show-progress': progress, 'hide-progress': !progress }"></mat-progress-bar> -->
    <div class="content-container">
      <div class="upcontainer">
        <!-- FAHU Map  -->
        <div class="map-container">
          <img class="fmap" src="/assets/updated design/AHU1.svg" />

          <!--All Components -->

          <!-- Fan Component -->

          <div
            class="component fan"
            style="height: 14.7%;  top: 67.6%; left: 55.5%"
          >
            <Fan [state]="state"></Fan>
          </div>
          <div
          style="
            position: absolute;
            top: 63%; /* Adjust this value to position the text */
            left: 90%;
            transform: translateX(-50%);
            color: var(--blue-one);
            font-weight: 600;
            font-size: 15px;
            z-index: 10;
          "
        >
          {{tag}}
        </div>

          <!-- ROOM_NAME -->

          <!-- <div clas="component"  style="top: 50%; left: 90%;"> {{room_name}}</div> -->

          <!-- components for  Values  -->
          <div
          class="input-groupR teampc component"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            top: 34.2%;
            left: 18%;
            width: 48px;
            height: 3%;
          "
        >
          <!-- <ng-container *ngIf="amb_temp_relay !== false; else emptyInput"> -->
            <input readonly
              style="color: #d90606; font-weight: 600"
              [ngModel]="amb_temp_relay | number : '1.2-2'"
              (ngModelChange)="amb_temp_relay = $event"
            />
          <!-- </ng-container> -->
          <!-- <ng-template #emptyInput>
            <input readonly [ngModel]="''" />
          </ng-template> -->
          <span style="font-size: 80%; color: #d90606; font-weight: 600"
            >°C</span
          >
        </div>


          <!-- RAT  -->
          <div
          class="input-groupR teampc component"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            top: 15.2%;
            left: 44%;
            width: 48px;
            height: 3%;
          "
        >
          <ng-container *ngIf="RAT_real !== false; else emptyInput">
            <input readonly
              style="color: #d90606; font-weight: 600"
              [ngModel]="RAT_real | number : '1.2-2'"
              (ngModelChange)="RAT_real = $event"
            />
          </ng-container>
         <!-- <ng-template #emptyInput>
            <input readonly [ngModel]="''" />
          </ng-template> -->
          <span style="font-size: 80%; color: #d90606; font-weight: 600"
            >°C</span
          >
        </div>


          <div
            class="input-groupR teampc component"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              top: 18.2%;
              left: 44%;
              width: 48px;
              height: 3%;
            "
          >
            <ng-container *ngIf="RAT_in !== false; else emptyInput">
              <input readonly
                style="color: #293AC6; font-weight: 600"
                [ngModel]="RAT_in | number : '1.2-2'"
                (ngModelChange)="RAT_in = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input readonly [ngModel]="''" />
            </ng-template>
            <span style="font-size: 80%; color: #293AC6; font-weight: 600"
              >°C</span
            >
          </div>


          <!-- CO2  -->
          <div
            class="input-groupR teampc component"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              top: 24.8%;
              left: 10%;
              width: 4%;
              height: 3%;
            "
          >
            <!-- <input readonly style="color: #D90606; font-weight:600" [ngModel]="RAT_in | number: '1.2-2'" (ngModelChange)="RAT_in = $event" /> -->
            <!-- <ng-container *ngIf="damper_co2_relay !== false; else emptyInput"> -->
              <input readonly
                style="color: #d90606; font-weight: 600"
                [ngModel]="damper_co2_relay | number : '1.2-2'"
                (ngModelChange)="damper_co2_relay = $event"
              />
            <!-- </ng-container> -->
             <!-- <ng-template #emptyInput>
            <input readonly [ngModel]="''" />
          </ng-template> -->       
              <span style="font-size: 80%; color: #d90606; font-weight: 600"
              >ppm</span
            >
          </div>

          <!-- Cooling Coil Valve Out -->
          <div
            class="input-groupR RHc component"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              top: 94.5%;
              left: 43.5%;
              width: 48px;
              height: 3%;
            "
          >
            <!-- <ng-container *ngIf="CHW_VALVE_in !== false; else emptyInput"> -->
              <input readonly
                style="color: #d90606; font-weight: 600"
                [ngModel]="CHW_VALVE_real | number : '1.2-2'"
                (ngModelChange)="CHW_VALVE_real = $event"
              />
            <!-- </ng-container> -->
            <!-- <ng-template #emptyInput>
              <input readonly [ngModel]="''" />
            </ng-template> -->
            <span style="font-size: 80%; color: #d90606; font-weight: 600"
              >%</span
            >
          </div>

          <!-- Cooling Coil Valve In -->
          <div
            class="input-groupR RHc component"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              top: 97.5%;
              left: 43.5%;
              width: 48px;
              height: 3%;
            "
          >
            <ng-container *ngIf="CHW_VALVE_out !== false; else emptyInput">
              <input readonly
                style="color: #00a578; font-weight: 600"
                [ngModel]="CHW_VALVE_out | number : '1.2-2'"
                (ngModelChange)="CHW_VALVE_out = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input readonly [ngModel]="''" />
            </ng-template>
            <span style="font-size: 80%; color: #00a578; font-weight: 600"
              >%</span
            >
          </div>

          <!-- Fan Speed Out -->
          <div
            class="input-groupR fanspeedc1 component"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              top: 85.35%;
              left: 60.5%;
              width: 48px;
              height: 3%;
            "
          >
            <ng-container *ngIf="FAN_SPEED_real !== false; else emptyInput">
              <input readonly
                style="color: #d90606; font-weight: 600"
                [ngModel]="FAN_SPEED_real | number : '1.2-2'"
                (ngModelChange)="FAN_SPEED_real = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input readonly [ngModel]="''" />
            </ng-template>
            <span style="font-size: 80%; color: #d90606; font-weight: 600"
              >%</span
            >
          </div>

          <!-- Fan Speed In -->
          <div
            class="input-groupR fanspeedc2 component"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              top: 87.9%;
              left: 60.5%;
              width: 48px;
              height: 3%;
            "
          >
            <!-- <ng-container *ngIf="FAN_SPEED_out !== false; else emptyInput"> -->
              <input readonly
                style="color: #00a578; font-weight: 600"
                [ngModel]="FAN_SPEED_out | number : '1.2-2'"
                (ngModelChange)="FAN_SPEED_out = $event"
              />
            <!-- </ng-container> -->
            <!-- <ng-template #emptyInput>
              <input readonly [ngModel]="''" />
            </ng-template> -->
            <span style="font-size: 80%; color: #00a578; font-weight: 600"
              >%</span
            >
          </div>


             <!-- Fan Speed Power -->
             <div
             class="input-groupR fanspeedc2 component"
             style="
               display: flex;
               justify-content: center;
               align-items: center;
               top: 92.3%;
               left: 61.5%;
               width: 48px;
               height: 3%;
             "
           >
             <ng-container *ngIf="fan_power_real !== false; else emptyInput">
               <input readonly
                 style="color: #d90606; font-weight: 600"
                 [ngModel]="fan_power_real | number : '1.2-2'"
                 (ngModelChange)="fan_power_real = $event"
               />
             </ng-container>
             <ng-template #emptyInput>
               <input readonly [ngModel]="''" />
             </ng-template>
             <span style="font-size: 80%; color: #d90606; font-weight: 600"
               >Wh</span
             >
           </div>

          <!-- Fan Speed Power -->
          <div
            class="input-groupR fanspeedc2 component"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              top: 95.6%;
              left: 61.5%;
              width: 48px;
              height: 3%;
            "
          >
            <ng-container *ngIf="POWER_in !== false; else emptyInput">
              <input readonly
                style="color: #293AC6; font-weight: 600"
                [ngModel]="POWER_in | number : '1.2-2'"
                (ngModelChange)="POWER_in = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input readonly [ngModel]="''" />
            </ng-template>
            <span style="font-size: 80%; color: #293AC6; font-weight: 600"
              >Wh</span
            >
          </div>

          <!-- Supply DPT  -->
          <div
            class="input-groupR fanspeedc2 component"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              top: 84.8%;
              left: 78.5%;
               width: 48px;
              height: 3%;
            "
          >
            <ng-container *ngIf="SDPT_in !== false; else emptyInput">
              <input readonly
                style="color: #293AC6; font-weight: 600"
                [ngModel]="SDPT_in | number : '1.2-2'"
                (ngModelChange)="SDPT_in = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input readonly [ngModel]="''" />
            </ng-template>
            <span style="font-size: 80%; color: #293AC6; font-weight: 600"
              >Pa</span
            >
          </div>

          <div
          class="input-groupR fanspeedc2 component"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            top: 81%;
            left: 78.5%;
             width: 48px;
            height: 3%;
          "
        >
          <ng-container *ngIf="supply_dpt_real !== false; else emptyInput">
            <input readonly
              style="color: #d90606; font-weight: 600"
              [ngModel]="supply_dpt_real | number : '1.2-2'"
              (ngModelChange)="supply_dpt_real = $event"
            />
          </ng-container>
         <!-- <ng-template #emptyInput>
            <input readonly [ngModel]="''" />
          </ng-template> -->
          <span style="font-size: 80%; color: #d90606; font-weight: 600"
            >Pa</span
          >
        </div>

          <!-- SAT  -->
          <div
            class="input-groupR fanspeedc2 component"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              top: 50.15%;
              left: 77.7%;
               width: 48px;
              height: 3%;
            "
          >
            <ng-container *ngIf="SAT_in !== false; else emptyInput">
              <input readonly
                style="color: #293AC6; font-weight: 600"
                [ngModel]="SAT_in | number : '1.2-2'"
                (ngModelChange)="SAT_in = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input readonly [ngModel]="''" />
            </ng-template>
            <span style="font-size: 80%; color: #293AC6; font-weight: 600"
              >°C</span
            >
          </div>

          <div
          class="input-groupR fanspeedc2 component"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            top: 47.15%;
            left: 77.7%;
             width: 48px;
            height: 3%;
          "
        >
          <ng-container *ngIf="SAT_real !== false; else emptyInput">
            <input readonly
              style="color: #d90606; font-weight: 600"
              [ngModel]="SAT_real | number : '1.2-2'"
              (ngModelChange)="SAT_real = $event"
            />
          </ng-container>
         <!-- <ng-template #emptyInput>
            <input readonly [ngModel]="''" />
          </ng-template> -->
          <span style="font-size: 80%; color: #d90606; font-weight: 600"
            >°C</span
          >
        </div>
        </div>
      </div>
    </div>
  </div>
</div>






<div *ngIf="type == 2" style="position: relative; height: 100%; width: 100%">

  <div class="main_container">
    <!-- <mat-progress-bar mode="indeterminate" [ngClass]="{ 'show-progress': progress, 'hide-progress': !progress }"></mat-progress-bar> -->
    <div class="content-container">
      <div class="upcontainer">
        <!-- FAHU Map  -->
        <div class="map-container">
          <img class="fmap" src="/assets/updated design/AHU2.svg" />

          <!-- Components -->
          <div
            class="component fan"
            style="height: 14.7%; top: 67.8%; left: 57.45%"
          >
            <Fan [state]="state"></Fan>
          </div>
          <!-- ROOM_NAME -->

          <!-- <div clas="component"  style="top: 50%; left: 90%;"> {{room_name}}</div> -->
          <div
          style="
            position: absolute;
            top: 63%; /* Adjust this value to position the text */
            left: 90%;
            transform: translateX(-50%);
            color: var(--blue-one);
            font-weight: 600;
            font-size: 15px;
            z-index: 10;
          "
        >
          {{tag}}
        </div>
          <div
          class="input-groupR teampc component"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            top: 15.55%;
            left: 43.5%;
            width: 48px;
            height: 3%;
          "
        >
          <ng-container *ngIf="RAT_real !== false; else emptyInput">
            <input readonly
              style="color: #d90606; font-weight: 600"
              [ngModel]="RAT_real | number : '1.2-2'"
              (ngModelChange)="RAT_real = $event"
            />
          </ng-container>
         <!-- <ng-template #emptyInput>
            <input readonly [ngModel]="''" />
          </ng-template> -->
          <span style="font-size: 80%; color: #d90606; font-weight: 600"
            >°C</span
          >
        </div>
          <!-- RAT  -->
          <div
            class="input-groupR teampc component"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              top: 18.25%;
              left: 43.5%;
              width: 48px;
              height: 3%;
            "
          >
            <ng-container *ngIf="RAT_in !== false; else emptyInput">
              <input readonly
                style="color: #293AC6; font-weight: 600"
                [ngModel]="RAT_in | number : '1.2-2'"
                (ngModelChange)="RAT_in = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input readonly [ngModel]="''" />
            </ng-template>
            <span style="font-size: 80%; color: #293AC6; font-weight: 600"
              >°C</span
            >
          </div>
          <div
          class="input-groupR teampc component"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            top: 47.6%;
            left: 20.5%;
            width: 48px;
            height: 3%;
          "
        >
          <ng-container *ngIf="amb_temp_relay !== false; else emptyInput">
            <input readonly
              style="color: #d90606; font-weight: 600"
              [ngModel]="amb_temp_relay | number : '1.2-2'"
              (ngModelChange)="amb_temp_relay = $event"
            />
          </ng-container>
         <!-- <ng-template #emptyInput>
            <input readonly [ngModel]="''" />
          </ng-template> -->
          <span style="font-size: 80%; color: #d90606; font-weight: 600"
            >°C</span
          >
        </div>
          <!-- CO2  -->
          <div
            class="input-groupR teampc component"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              top: 33.7%;
              left: 10%;
              width: 4%;
              height: 3%;
            "
          >
          <input readonly
                style="color: #d90606; font-weight: 600"
                [ngModel]="damper_co2_relay | number : '1.2-2'"
                (ngModelChange)="damper_co2_relay = $event"
              />
            <!-- <input readonly style="color: #D90606; font-weight:600" [ngModel]="RAT_in | number: '1.2-2'" (ngModelChange)="RAT_in = $event" /> -->
            <span style="font-size: 80%; color: #d90606; font-weight: 600"
              >ppm</span
            >
          </div>

          <!-- Cooling Coil Valve Out -->
          <div
            class="input-groupR RHc component"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              top: 94.4%;
              left: 43.7%;
              width: 48px;
              height: 3%;
            "
          >
            <ng-container *ngIf="CHW_VALVE_real !== false; else emptyInput">
              <input readonly
                style="color: #d90606; font-weight: 600"
                [ngModel]="CHW_VALVE_real | number : '1.2-2'"
                (ngModelChange)="CHW_VALVE_real = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input readonly [ngModel]="''" />
            </ng-template>
            <span style="font-size: 80%; color: #d90606; font-weight: 600"
              >%</span
            >
          </div>

          <!-- Cooling Coil Valve In -->
          <div
            class="input-groupR RHc component"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              top: 97.4%;
              left: 43.7%;
              width: 48px;
              height: 3%;
            "
          >
            <ng-container *ngIf="CHW_VALVE_out !== false; else emptyInput">
              <input readonly
                style="color: #00a578; font-weight: 600"
                [ngModel]="CHW_VALVE_out | number : '1.2-2'"
                (ngModelChange)="CHW_VALVE_out = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input readonly [ngModel]="''" />
            </ng-template>
            <span style="font-size: 80%; color: #00a578; font-weight: 600"
              >%</span
            >
          </div>

          <!-- Fan Speed Out -->
          <div
            class="input-groupR fanspeedc1 component"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              top: 85.2%;
              left: 62.5%;
              width: 48px;
              height: 3%;
            "
          >
            <ng-container *ngIf="FAN_SPEED_real !== false; else emptyInput">
              <input readonly
                style="color: #d90606; font-weight: 600"
                [ngModel]="FAN_SPEED_real | number : '1.2-2'"
                (ngModelChange)="FAN_SPEED_real = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input readonly [ngModel]="''" />
            </ng-template>
            <span style="font-size: 80%; color: #d90606; font-weight: 600"
              >%</span
            >
          </div>

          <!-- Fan Speed In -->
          <div
            class="input-groupR fanspeedc2 component"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              top: 87.6%;
              left: 62.5%;
              width: 48px;
              height: 3%;
            "
          >
            <ng-container *ngIf="FAN_SPEED_out !== false; else emptyInput">
              <input readonly
                style="color: #00a578; font-weight: 600"
                [ngModel]="FAN_SPEED_out | number : '1.2-2'"
                (ngModelChange)="FAN_SPEED_out = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input readonly [ngModel]="''" />
            </ng-template>
            <span style="font-size: 80%; color: #00a578; font-weight: 600"
              >%</span
            >
          </div>

          <!-- Fan Speed Power -->

          <div
          class="input-groupR fanspeedc2 component"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            top: 91.8%;
            left: 62.6%;
            width: 48px;
            height: 3%;
          "
        >
          <ng-container *ngIf="fan_power_real !== false; else emptyInput">
            <input readonly
              style="color: #d90606; font-weight: 600"
              [ngModel]="fan_power_real | number : '1.2-2'"
              (ngModelChange)="fan_power_real = $event"
            />
          </ng-container>
         <!-- <ng-template #emptyInput>
            <input readonly [ngModel]="''" />
          </ng-template> -->
          <span style="font-size: 80%; color: #d90606; font-weight: 600"
            >Wh</span
          >
        </div>
          <div
            class="input-groupR fanspeedc2 component"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              top: 94.6%;
              left: 62.6%;
              width: 48px;
              height: 3%;
            "
          >
            <ng-container *ngIf="POWER_in !== false; else emptyInput">
              <input readonly
                style="color: #293AC6; font-weight: 600"
                [ngModel]="POWER_in | number : '1.2-2'"
                (ngModelChange)="POWER_in = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input readonly [ngModel]="''" />
            </ng-template>
            <span style="font-size: 80%; color: #293AC6; font-weight: 600"
              >Wh</span
            >
          </div>

          <!-- Supply DPT  -->

          <div
          class="input-groupR fanspeedc2 component"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            top: 81.4%;
            left: 78.5%;
             width: 48px;
            height: 3%;
          "
        >
          <ng-container *ngIf="supply_dpt_real !== false; else emptyInput">
            <input readonly
              style="color: #d90606; font-weight: 600"
              [ngModel]="supply_dpt_real | number : '1.2-2'"
              (ngModelChange)="supply_dpt_real = $event"
            />
          </ng-container>
         <!-- <ng-template #emptyInput>
            <input readonly [ngModel]="''" />
          </ng-template> -->
          <span style="font-size: 80%; color: #d90606; font-weight: 600"
            >Pa</span
          >
        </div>
          <div
            class="input-groupR fanspeedc2 component"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              top: 84.6%;
              left: 78.5%;
               width: 48px;
              height: 3%;
            "
          >
            <ng-container *ngIf="SDPT_in !== false; else emptyInput">
              <input readonly
                style="color: #293AC6; font-weight: 600"
                [ngModel]="SDPT_in | number : '1.2-2'"
                (ngModelChange)="SDPT_in = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input readonly [ngModel]="''" />
            </ng-template>
            <span style="font-size: 80%; color: #293AC6; font-weight: 600"
              >Pa</span
            >
          </div>

          <!-- SAT  -->

          <div
          class="input-groupR fanspeedc2 component"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            top: 47.2%;
            left: 78%;
             width: 48px;
            height: 3%;
          "
        >
          <ng-container *ngIf="SAT_real !== false; else emptyInput">
            <input readonly
              style="color: #d90606; font-weight: 600"
              [ngModel]="SAT_real | number : '1.2-2'"
              (ngModelChange)="SAT_real = $event"
            />
          </ng-container>
         <!-- <ng-template #emptyInput>
            <input readonly [ngModel]="''" />
          </ng-template> -->
          <span style="font-size: 80%; color: #d90606; font-weight: 600"
            >°C</span
          >
        </div>
          <div
            class="input-groupR fanspeedc2 component"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              top: 50.2%;
              left: 78%;
               width: 48px;
              height: 3%;
            "
          >
            <ng-container *ngIf="SAT_in !== false; else emptyInput">
              <input readonly
                style="color: #293AC6; font-weight: 600"
                [ngModel]="SAT_in | number : '1.2-2'"
                (ngModelChange)="SAT_in = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input readonly [ngModel]="''" />
            </ng-template>
            <span style="font-size: 80%; color: #293AC6; font-weight: 600"
              >°C</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>








<div *ngIf="type === 3" style="position: relative; height: 100%; width: 100%">
  <div class="main_container">
    <!-- <mat-progress-bar mode="indeterminate" [ngClass]="{ 'show-progress': progress, 'hide-progress': !progress }"></mat-progress-bar> -->
    <div class="content-container">
      <div class="upcontainer">
        <!-- FAHU Map  -->
        <!-- <div style="font-weight: 600; font-size: large; display: flex;  position: relative;   color: var(--blue-one); left: 90%; top: -30%;  " >{{tag}}</div> -->

        <div class="map-container">
          <img class="fmap" src="/assets/updated design/AHU3.svg" />
          <div
          style="
            position: absolute;
            top: 30%; /* Adjust this value to position the text */
            left: 93%;
            transform: translateX(-50%);
            color: var(--blue-one);
            font-weight: 600;
            font-size: 15px;
            z-index: 10;
          "
        >
          {{tag}}
        </div>
          <!-- Components -->
          <div
            class="component fan"
            style="height: 27%; top: 40.1%; left: 56.5%"
          >
            <Fan [state]="state"></Fan>
          </div>

                    <!-- ROOM_NAME -->

                    <!-- <div clas="component"  style="top: 50%; left: 90%;"> {{room_name}}</div> -->
                    <div
                    class="input-groupR teampc component"
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      top: 4%;
                      left: 20.5%;
                      width: 48px;
                      height: 3%;
                    "
                  >
                    <!-- <ng-container *ngIf="amb_temp_relay !== false; else emptyInput"> -->
                      <input readonly
                        style="color: #d90606; font-weight: 600"
                        [ngModel]="amb_temp_relay | number : '1.2-2'"
                        (ngModelChange)="amb_temp_relay = $event"
                      />
                    <!-- </ng-container> -->
                    <ng-template #emptyInput>
                      <input readonly [ngModel]="''" />
                    </ng-template>
                    <span style="font-size: 80%; color: #d90606; font-weight: 600"
                      >°C</span
                    >
                  </div>

          <!-- Cooling Coil Valve Out -->
          <div
            class="input-groupR RHc component"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              top: 91.3%;
              left: 42.5%;
              width: 48px;
              height: 3%;
            "
          >
            <!-- <ng-container *ngIf="CHW_VALVE_real !== false; else emptyInput"> -->
              <input readonly
                style="color: #d90606; font-weight: 600"
                [ngModel]="CHW_VALVE_real | number : '1.2-2'"
                (ngModelChange)="CHW_VALVE_real = $event"
              />
            <!-- </ng-container> -->
            <ng-template #emptyInput>
              <input readonly [ngModel]="''" />
            </ng-template>
            <span style="font-size: 80%; color: #d90606; font-weight: 600"
              >%</span
            >
          </div>

          <!-- Cooling Coil Valve In -->
          <div
            class="input-groupR RHc component"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              top: 96.6%;
              left: 42.5%;
              width: 48px;
              height: 3%;
            "
          >
            <ng-container *ngIf="CHW_VALVE_out !== false; else emptyInput">
              <input readonly
                style="color: #00a578; font-weight: 600"
                [ngModel]="CHW_VALVE_out | number : '1.2-2'"
                (ngModelChange)="CHW_VALVE_out = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input readonly [ngModel]="''" />
            </ng-template>
            <span style="font-size: 80%; color: #00a578; font-weight: 600"
              >%</span
            >
          </div>

          <!-- Fan Speed Out -->
          <div
            class="input-groupR fanspeedc1 component"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              top: 74.3%;
              left: 62.5%;
              width: 48px;
              height: 3%;
            "
          >
            <ng-container *ngIf="FAN_SPEED_real !== false; else emptyInput">
              <input readonly
                style="color: #d90606; font-weight: 600"
                [ngModel]="FAN_SPEED_real | number : '1.2-2'"
                (ngModelChange)="FAN_SPEED_real = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input readonly [ngModel]="''" />
            </ng-template>
            <span style="font-size: 80%; color: #d90606; font-weight: 600"
              >%</span
            >
          </div>

          <!-- Fan Speed In -->
          <div
            class="input-groupR fanspeedc2 component"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              top: 79.6%;
              left: 62.5%;
              width: 48px;
              height: 3%;
            "
          >
            <ng-container *ngIf="FAN_SPEED_out !== false; else emptyInput">
              <input readonly
                style="color: #00a578; font-weight: 600"
                [ngModel]="FAN_SPEED_out | number : '1.2-2'"
                (ngModelChange)="FAN_SPEED_out = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input readonly [ngModel]="''" />
            </ng-template>
            <span style="font-size: 80%; color: #00a578; font-weight: 600"
              >%</span
            >
          </div>

          <!-- Fan Speed Power -->
          <div
          class="input-groupR fanspeedc2 component"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            top: 87.3%;
            left: 62.5%;
            width: 48px;
            height: 3%;
          "
        >
          <!-- <ng-container *ngIf="fan_power_real !== false; else emptyInput"> -->
            <input readonly
              style="color: #d90606; font-weight: 600"
              [ngModel]="fan_power_real | number : '1.2-2'"
              (ngModelChange)="fan_power_real = $event"
            />
          <!-- </ng-container> -->
         <!-- <ng-template #emptyInput>
            <input readonly [ngModel]="''" />
          </ng-template> -->
          <span style="font-size: 80%; color: #d90606; font-weight: 600"
            >Wh</span
          >
        </div>
          <div
            class="input-groupR fanspeedc2 component"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              top: 92.3%;
              left: 62.5%;
              width: 48px;
              height: 3%;
            "
          >
            <ng-container *ngIf="POWER_in !== false; else emptyInput">
              <input readonly
                style="color: #293AC6; font-weight: 600"
                [ngModel]="POWER_in | number : '1.2-2'"
                (ngModelChange)="POWER_in = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input readonly [ngModel]="''" />
            </ng-template>
            <span style="font-size: 80%; color: #293AC6; font-weight: 600"
              >Wh</span
            >
          </div>

          <!-- Supply DPT  -->

          <div
          class="input-groupR fanspeedc2 component"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            top: 66.9%;
            left: 81%;
             width: 48px;
            height: 3%;
          "
        >
          <ng-container *ngIf="supply_dpt_real !== false; else emptyInput">
            <input readonly
              style="color: #d90606; font-weight: 600"
              [ngModel]="supply_dpt_real | number : '1.2-2'"
              (ngModelChange)="supply_dpt_real = $event"
            />
          </ng-container>
         <!-- <ng-template #emptyInput>
            <input readonly [ngModel]="''" />
          </ng-template> -->
          <span style="font-size: 80%; color: #d90606; font-weight: 600"
            >Pa</span
          >
        </div>
          <div
            class="input-groupR fanspeedc2 component"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              top: 71.9%;
              left: 81%;
               width: 48px;
              height: 3%;
            "
          >
            <ng-container *ngIf="SDPT_in !== false; else emptyInput">
              <input readonly
                style="color: #293AC6; font-weight: 600"
                [ngModel]="SDPT_in | number : '1.2-2'"
                (ngModelChange)="SDPT_in = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input readonly [ngModel]="''" />
            </ng-template>
            <span style="font-size: 80%; color: #293AC6; font-weight: 600"
              >Pa</span
            >
          </div>

          <!-- SAT  -->
          <div
          class="input-groupR fanspeedc2 component"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            top: 1.3%;
            left: 82%;
             width: 48px;
            height: 3%;
          "
        >
          <ng-container *ngIf="SAT_real !== false; else emptyInput">
            <input readonly
              style="color: #d90606; font-weight: 600"
              [ngModel]="SAT_real | number : '1.2-2'"
              (ngModelChange)="SAT_real = $event"
            />
          </ng-container>
         <!-- <ng-template #emptyInput>
            <input readonly [ngModel]="''" />
          </ng-template> -->
          <span style="font-size: 80%; color: #d90606; font-weight: 600"
            >°C</span
          >
        </div>
          <div
            class="input-groupR fanspeedc2 component"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              top: 6.3%;
              left: 82%;
               width: 48px;
              height: 3%;
            "
          >
            <ng-container *ngIf="SAT_in !== false; else emptyInput">
              <input readonly
                style="color: #293AC6; font-weight: 600"
                [ngModel]="SAT_in | number : '1.2-2'"
                (ngModelChange)="SAT_in = $event"
              />
            </ng-container>
            <ng-template #emptyInput>
              <input readonly [ngModel]="''" />
            </ng-template>
            <span style="font-size: 80%; color: #293AC6; font-weight: 600"
              >°C</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
