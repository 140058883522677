import { Component, Input } from '@angular/core';

@Component({
  selector: 'Chiller',
  templateUrl: './chiller.component.html',
  styleUrls: ['./chiller.component.css'],
})
export class ChillerComponent {
  @Input() state!: string;

  urlActive: string;
  urlInActive: string;
  urlTrip: string;

  constructor() {
    this.urlActive = '/assets/bert-components/active-chiller.svg';
    this.urlInActive ='/assets/bert-components/inactive-chiller.svg';
    this.urlTrip = '/assets/bert-components/triped-chiller.svg';
  }
}
