import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HomeComponent } from './pages/home/home.component';
import { AhuComponent } from './components/ahu/ahu.component';
import { ChillerPlantComponent } from './pages/chiller-plant/chiller-plant.component';
import { combineLatest } from 'rxjs';


// main block and levels
import { BlockAComponent } from './Blocks/block-a/block-a.component';
import { BlockBOneComponent } from './Blocks/block-b-one/block-b-one.component';
import { BlockBTwoEastComponent } from './Blocks/block-b-two-east/block-b-two-east.component';
import { BlockBTwoWestComponent } from './Blocks/block-b-two-west/block-b-two-west.component';
import { BlockDFiveComponent } from './Blocks/block-d-five/block-d-five.component';
import { BlockDFourComponent } from './Blocks/block-d-four/block-d-four.component';
import { BlockDThreeComponent } from './Blocks/block-d-three/block-d-three.component';
import { BlockDTwoComponent } from './Blocks/block-d-two/block-d-two.component';
import { BlockDOneComponent } from './Blocks/block-d-one/block-d-one.component';
import { BlockEComponent } from './Blocks/block-e/block-e.component';
import { BlockFComponent } from './Blocks/block-f/block-f.component';
import { UlChillerPlantComponent } from './Blocks/ul-chiller-plant/ul-chiller-plant.component';
import { AhuTemplateComponent } from './ahu-template/ahu-template.component';
import { CoolingTowerComponent } from './pages/cooling-tower/cooling-tower.component';
import { ChillerComponent } from './pages/chiller/chiller.component';
import { CondenserPumpComponent } from './pages/condenser-pump/condenser-pump.component';
import { PrimaryPumpComponent } from './pages/primary-pump/primary-pump.component';
import { SecondaryPumpComponent } from './pages/secondary-pump/secondary-pump.component';
import { CoolingTowerBComponent } from './pages/cooling-tower/cooling-tower-b/cooling-tower-b.component';
import { CoolingTower1Component } from './pages/cooling-tower/cooling-tower1/cooling-tower1.component';
import { CoolingTower2Component } from './pages/cooling-tower/cooling-tower2/cooling-tower2.component';
import { CoolingTower3Component } from './pages/cooling-tower/cooling-tower3/cooling-tower3.component';
import { CoolingTower4Component } from './pages/cooling-tower/cooling-tower4/cooling-tower4.component';
import { ZoneAComponent } from './pages/secondary-pump/zone-a/zone-a.component';
import { ZoneBComponent } from './pages/secondary-pump/zone-b/zone-b.component';
import { ZoneCComponent } from './pages/secondary-pump/zone-c/zone-c.component';
import { Chiller1Component } from './pages/chiller/chiller1/chiller1.component';
import { Chiller2Component } from './pages/chiller/chiller2/chiller2.component';
import { Chiller3Component } from './pages/chiller/chiller3/chiller3.component';
import { Chiller4Component } from './pages/chiller/chiller4/chiller4.component';
import { FChillerPlantComponent } from './pages/f-chiller-plant/f-chiller-plant.component';
import { LoadingScreenComponent } from './pages/loading-screen/loading-screen.component';
import { AuthGuard } from './guards/auth.guard';
import { LogoutDialogComponent } from './components/logout-dialog/logout-dialog.component';
import { CommandUpdateComponent } from './command-update/command-update.component';
import { PidTuningCommandComponent } from './command-update/pid-tuning-command/pid-tuning-command.component';
import { ChillerplantCommandComponent } from './command-update/chillerplant-command/chillerplant-command.component';
import { AhuscheduleCommandComponent } from './command-update/ahuschedule-command/ahuschedule-command.component';
import { ChangepasswordComponent } from './pages/changepassword/changepassword.component';
import { AlarmLogComponent } from './pages/alarm-log/alarm-log.component';
import { ViewAllErrorsComponent } from './view-all-errors/view-all-errors.component';
import { AnalyticsComponent } from './pages/analytics/analytics.component';


const routes: Routes = [

  { path: 'login', component: LoginComponent },
  { path: 'loading-screen', component: LoadingScreenComponent, canActivate: [AuthGuard] },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  {path:'change-password', component: ChangepasswordComponent },

  
 
  {
    path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard],
    children: [
      { path: 'home', component: HomeComponent, },

      { path: 'logout', component: LogoutDialogComponent },
      // 47 AHUs  + 1 Chiller Plant

      { path: 'chiller-plant', component: ChillerPlantComponent },
      { path: 'cooling-tower', component: CoolingTowerComponent },
      { path: 'condenser-pump', component: CondenserPumpComponent },
      { path: 'chiller', component: ChillerComponent },
      { path: 'primary-pump', component: PrimaryPumpComponent },
      { path: 'secondary-pump', component: SecondaryPumpComponent },
      { path: 'cooling-tower-b', component: CoolingTowerBComponent },
      { path: 'cooling-tower1', component: CoolingTower1Component },
      { path: 'cooling-tower2', component: CoolingTower2Component },
      { path: 'cooling-tower3', component: CoolingTower3Component },
      { path: 'cooling-tower4', component: CoolingTower4Component },
      { path: 'zone-a', component: ZoneAComponent },
      { path: 'zone-b', component: ZoneBComponent },
      { path: 'zone-c', component: ZoneCComponent },
      { path: 'chiller1', component: Chiller1Component },
      { path: 'chiller2', component: Chiller2Component },
      { path: 'chiller3', component: Chiller3Component },
      { path: 'chiller4', component: Chiller4Component },

      { path: 'f-chiller-plant', component: FChillerPlantComponent },

      // UL blocks 
      { path: 'block-a', component: BlockAComponent },
      { path: 'block-b1', component: BlockBOneComponent },
      { path: 'block-b2e', component: BlockBTwoEastComponent },
      { path: 'block-b2w', component: BlockBTwoWestComponent },
      { path: 'block-d5', component: BlockDFiveComponent },
      { path: 'block-d4', component: BlockDFourComponent },
      { path: 'block-d3', component: BlockDThreeComponent },
      { path: 'block-d2', component: BlockDTwoComponent },
      { path: 'block-d1', component: BlockDOneComponent },
      { path: 'block-e', component: BlockEComponent },
      { path: 'block-f', component: BlockFComponent },
      { path: 'ul-chiller-plant', component: UlChillerPlantComponent },
      {path:"analytics", component: AnalyticsComponent},

      {path:'command', component:CommandUpdateComponent},
      {path:'pid-tuning',component:PidTuningCommandComponent},
          {path:'chillerplant-command',component:ChillerplantCommandComponent},
          {path:'ahuschedule-command',component:AhuscheduleCommandComponent},
          {path:'alarm-log',component:AlarmLogComponent},
          {path:'view-all-errors',component:ViewAllErrorsComponent},



      //ahu template
      { path: 'ahu/:id', component: AhuTemplateComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
