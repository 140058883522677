import { Component } from '@angular/core';

@Component({
  selector: 'app-cooling-tower4',
  templateUrl: './cooling-tower4.component.html',
  styleUrls: ['./cooling-tower4.component.css']
})
export class CoolingTower4Component {
  CT4_C1_FAN_SPEED_GR: any = 0;
  CT4_C1_FAN_SPEED_RED: any = 0;
  CT4_C2_FAN_SPEED_GR: any = 0;
  CT4_C2_FAN_SPEED_RED: any = 0;
  CT_Water_in: any = 0;
  CT4_C1_Water_out: any = 0;
  CT4_C2_Water_out: any = 0;
  KPI: any = 0;
}
