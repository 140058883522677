<div class="b-two-w-comp">
  <svg id="b-two-w-comp" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%"
    height="100%" viewBox="0 0 324.214 282.79">
    <defs>
      <linearGradient id="linear-gradient-b2w" x1="1" y1="0.457" x2="0" y2="0.603" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#f2fafd" />
        <stop offset="1" stop-color="#e4f4fc" />
      </linearGradient>
      <linearGradient id="linear-gradient-b2w-2" x1="1.147" y1="0.421" x2="-0.321" y2="0.627"
        xlink:href="#linear-gradient-b2w" />
      <linearGradient id="linear-gradient-b2w-3" x1="1" y1="0.432" x2="-0.044" y2="0.769"
        xlink:href="#linear-gradient-b2w" />
      <linearGradient id="linear-gradient-b2w-4" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#ccdfe8" />
        <stop offset="1" stop-color="#8ea6b2" />
      </linearGradient>
      <linearGradient id="linear-gradient-b2w-5" x1="0.866" y1="0.383" x2="-0.195" y2="0.737"
        gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#fff" />
        <stop offset="1" stop-color="#e4f4fc" />
      </linearGradient>
      <filter id="Rectangle_1272" x="78.913" y="65.271" width="159" height="64" filterUnits="userSpaceOnUse">
        <feOffset dx="3" dy="3" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="4.5" result="blur" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Component_71_6" data-name="Component 71 – 6" transform="translate(0.276)">
      <g id="Group_3621" data-name="Group 3621" transform="translate(-12994.523 2348.508)">
        <path id="Path_8047" data-name="Path 8047"
          d="M1035.251,343.356l-2.95-90.88-91.01-3.976.341.212-40.946,23.645-.341.188-18.525,10.7-.13.071-70.134,40.5L792.572,334.77,750.99,358.78l132.642,76.572,41.582-24-3.86-2.224,19-10.969,3.849,2.224,70.264-40.558-3.849-2.224,18.525-10.7Z"
          transform="translate(12243.533 -2502.783)" fill="#ababab" stroke="#000" stroke-miterlimit="10"
          stroke-width="0.2" />
        <path id="Path_8048" data-name="Path 8048" d="M895.34,396.836V303.692l19.267-11.122v93.144Z"
          transform="translate(12269.076 -2489.854)" fill="#c4c4c4" stroke="#ccc" stroke-miterlimit="10"
          stroke-width="1" />
        <path id="Path_8049" data-name="Path 8049" d="M863.29,421.25V328.106l41.746-24.116v93.144Z"
          transform="translate(12263.405 -2487.833)" fill="url(#linear-gradient-b2w)" stroke="#79a7bf"
          stroke-miterlimit="10" stroke-width="1" />
        <path id="Path_8055" data-name="Path 8055" d="M990.32,348.588V255.444l41.758-24.1v93.144Z"
          transform="translate(12285.883 -2500.688)" fill="url(#linear-gradient-b2w)" stroke="#79a7bf"
          stroke-miterlimit="10" stroke-width="1" />
        <path id="Path_8056" data-name="Path 8056" d="M971.16,353.116V259.972l19.278-11.122v93.144Z"
          transform="translate(12283.765 -2498.196)" fill="#c4c4c4" stroke="#000" stroke-miterlimit="10"
          stroke-width="0.2" />
        <path id="Path_8057" data-name="Path 8057" d="M915.06,394.207V301.063l70.652-40.793v93.144Z"
          transform="translate(12272.565 -2495.569)" fill="url(#linear-gradient-b2w-3)" stroke="#79a7bf"
          stroke-miterlimit="10" stroke-width="1" />
        <path id="Path_8058" data-name="Path 8058" d="M883.493,428.984,751.18,352.6V259.88l132.313,76.4Z"
          transform="translate(12243.567 -2495.638)" fill="url(#linear-gradient-b2w-4)" stroke="#427894"
          stroke-miterlimit="10" stroke-width="1" />
        <path id="Path_8059" data-name="Path 8059"
          d="M753.36,280.55,943.991,170.481l-.341-.2,102.512,62.061,25.2,14.559-38.792,22.4-3.849-2.224-21.3,12.3,3.849,2.224-67.486,38.957-3.849-2.224L918.157,330.9l3.849,2.224-38.792,22.4Z"
          transform="translate(12243.953 -2516.343)" fill="url(#linear-gradient-b2w-5)" stroke="#427894"
          stroke-width="1" />
        <path id="Path_8060" data-name="Path 8060"
          d="M1045.154,231.5l23.834,15.516-36,20.785-2.46-1.412-1.4-.812-1.389.812-18.513,10.687-4.178,2.413,3.849,2.213-64.732,37.356-2.46-1.412-1.389-.8-1.389.8-19,10.957-4.167,2.413,3.849,2.224L883.6,354.024,756.485,280.664l37.4-21.6L812.872,248.1l70.146-40.487.118-.082,18.525-10.687.341-.2,41.017-23.515,4.178-2.354-.353-.212H945.1Zm-100.5-62.646h0l-43.97,25.458-.341.2-18.525,10.7-.13.071-70.134,40.5L792.57,256.737,751,280.794l132.642,76.572,41.546-24-3.86-2.224,19-10.969,3.849,2.224,70.264-40.569-3.849-2.213,18.513-10.7,3.86,2.224,41.57-24L946.17,168.08Z"
          transform="translate(12243.535 -2516.587)" fill="#96bdd1" />
        <path id="Path_8061" data-name="Path 8061" d="M924.93,301.552l49.255-28.612-.165,14.017L925.13,315.251Z"
          transform="translate(12274.313 -2493.327)" fill="#accee0" />
        <path id="Path_8062" data-name="Path 8062" d="M924.93,319.613l49.255-28.623-.165,13.241L925.13,332.536Z"
          transform="translate(12274.313 -2490.133)" fill="#accee0" />
        <path id="Path_8063" data-name="Path 8063" d="M924.93,337l49.255-28.612-.165,14.017L925.13,350.7Z"
          transform="translate(12274.313 -2487.054)" fill="#accee0" />
        <path id="Path_8064" data-name="Path 8064" d="M924.93,355.122l49.255-28.612-.165,14.017L925.13,368.821Z"
          transform="translate(12274.313 -2483.848)" fill="#accee0" />
        <path id="Path_8065" data-name="Path 8065" d="M871.36,330.832l24.222-13.782-.165,14.017L871.56,344.532Z"
          transform="translate(12264.833 -2485.522)" fill="#accee0" />
        <path id="Path_8066" data-name="Path 8066" d="M871.36,347.382,895.582,333.6l-.165,14.017L871.56,361.082Z"
          transform="translate(12264.833 -2482.594)" fill="#accee0" />
        <path id="Path_8067" data-name="Path 8067" d="M871.36,363.922l24.222-13.782-.165,14.017L871.56,377.622Z"
          transform="translate(12264.833 -2479.667)" fill="#accee0" />
        <path id="Path_8068" data-name="Path 8068" d="M871.36,379.684l24.222-13.794-.165,14.029L871.56,393.384Z"
          transform="translate(12264.833 -2476.88)" fill="#accee0" />
        <path id="Path_8069" data-name="Path 8069" d="M997.4,257.572l24.233-13.782-.165,14.017L997.6,271.272Z"
          transform="translate(12287.136 -2498.485)" fill="#accee0" />
        <path id="Path_8070" data-name="Path 8070" d="M997.4,274.112l24.233-13.782-.165,14.017L997.6,287.812Z"
          transform="translate(12287.136 -2495.559)" fill="#accee0" />
        <path id="Path_8071" data-name="Path 8071" d="M997.4,290.662l24.233-13.782-.165,14.017L997.6,304.362Z"
          transform="translate(12287.136 -2492.63)" fill="#accee0" />
        <path id="Path_8072" data-name="Path 8072" d="M997.4,306.412l24.233-13.782-.165,14.017L997.6,320.112Z"
          transform="translate(12287.136 -2489.843)" fill="#accee0" />
        <path id="Path_8073" data-name="Path 8073" d="M766.8,291.188l-9.639-5.567V272.78l9.639,5.555Z"
          transform="translate(12244.625 -2493.356)" fill="#92a3c3" />
        <path id="Path_8074" data-name="Path 8074" d="M766.8,307.558,757.16,302V289.15l9.639,5.567Z"
          transform="translate(12244.625 -2490.459)" fill="#92a3c3" />
        <path id="Path_8075" data-name="Path 8075" d="M766.8,307.558,757.16,302V289.15l9.639,5.567Z"
          transform="translate(12244.625 -2490.459)" fill="#92a3c3" />
        <path id="Path_8076" data-name="Path 8076" d="M766.8,323.939l-9.639-5.567V305.52l9.639,5.567Z"
          transform="translate(12244.625 -2487.562)" fill="#92a3c3" />
        <path id="Path_8077" data-name="Path 8077" d="M766.8,323.939l-9.639-5.567V305.52l9.639,5.567Z"
          transform="translate(12244.625 -2487.562)" fill="#92a3c3" />
        <path id="Path_8078" data-name="Path 8078" d="M766.8,340.308l-9.639-5.567V321.9l9.639,5.567Z"
          transform="translate(12244.625 -2484.664)" fill="#92a3c3" />
        <path id="Path_8079" data-name="Path 8079" d="M766.8,340.308l-9.639-5.567V321.9l9.639,5.567Z"
          transform="translate(12244.625 -2484.664)" fill="#92a3c3" />
        <path id="Path_8080" data-name="Path 8080" d="M785.219,301.829l-9.639-5.567V283.41l9.639,5.567Z"
          transform="translate(12247.885 -2491.475)" fill="#92a3c3" />
        <path id="Path_8081" data-name="Path 8081" d="M785.219,301.829l-9.639-5.567V283.41l9.639,5.567Z"
          transform="translate(12247.885 -2491.475)" fill="#92a3c3" />
        <path id="Path_8082" data-name="Path 8082" d="M785.219,318.2l-9.639-5.567V299.79l9.639,5.555Z"
          transform="translate(12247.885 -2488.576)" fill="#92a3c3" />
        <path id="Path_8083" data-name="Path 8083" d="M785.219,318.2l-9.639-5.567V299.79l9.639,5.555Z"
          transform="translate(12247.885 -2488.576)" fill="#92a3c3" />
        <path id="Path_8084" data-name="Path 8084" d="M785.219,334.568l-9.639-5.555V316.16l9.639,5.567Z"
          transform="translate(12247.885 -2485.68)" fill="#92a3c3" />
        <path id="Path_8085" data-name="Path 8085" d="M785.219,334.568l-9.639-5.555V316.16l9.639,5.567Z"
          transform="translate(12247.885 -2485.68)" fill="#92a3c3" />
        <path id="Path_8086" data-name="Path 8086" d="M785.219,350.949l-9.639-5.567V332.53l9.639,5.567Z"
          transform="translate(12247.885 -2482.783)" fill="#92a3c3" />
        <path id="Path_8087" data-name="Path 8087" d="M785.219,350.949l-9.639-5.567V332.53l9.639,5.567Z"
          transform="translate(12247.885 -2482.783)" fill="#92a3c3" />
        <path id="Path_8088" data-name="Path 8088" d="M803.639,312.457,794,306.891V294.05l9.639,5.555Z"
          transform="translate(12251.145 -2489.592)" fill="#92a3c3" />
        <path id="Path_8089" data-name="Path 8089" d="M803.639,312.457,794,306.891V294.05l9.639,5.555Z"
          transform="translate(12251.145 -2489.592)" fill="#92a3c3" />
        <path id="Path_8090" data-name="Path 8090" d="M803.639,328.827,794,323.272V310.42l9.639,5.567Z"
          transform="translate(12251.145 -2486.695)" fill="#92a3c3" />
        <path id="Path_8091" data-name="Path 8091" d="M803.639,328.827,794,323.272V310.42l9.639,5.567Z"
          transform="translate(12251.145 -2486.695)" fill="#92a3c3" />
        <path id="Path_8092" data-name="Path 8092" d="M803.639,345.207,794,339.641V326.8l9.639,5.555Z"
          transform="translate(12251.145 -2483.797)" fill="#92a3c3" />
        <path id="Path_8093" data-name="Path 8093" d="M803.639,345.207,794,339.641V326.8l9.639,5.555Z"
          transform="translate(12251.145 -2483.797)" fill="#92a3c3" />
        <path id="Path_8094" data-name="Path 8094" d="M803.639,361.577,794,356.01V343.17l9.639,5.567Z"
          transform="translate(12251.145 -2480.9)" fill="#92a3c3" />
        <path id="Path_8095" data-name="Path 8095" d="M803.639,361.577,794,356.01V343.17l9.639,5.567Z"
          transform="translate(12251.145 -2480.9)" fill="#92a3c3" />
        <path id="Path_8096" data-name="Path 8096" d="M822.059,323.087l-9.639-5.555V304.68l9.639,5.567Z"
          transform="translate(12254.403 -2487.711)" fill="#92a3c3" />
        <path id="Path_8097" data-name="Path 8097" d="M822.059,323.087l-9.639-5.555V304.68l9.639,5.567Z"
          transform="translate(12254.403 -2487.711)" fill="#92a3c3" />
        <path id="Path_8098" data-name="Path 8098" d="M822.059,339.467,812.42,333.9V321.06l9.639,5.555Z"
          transform="translate(12254.403 -2484.813)" fill="#92a3c3" />
        <path id="Path_8099" data-name="Path 8099" d="M822.059,339.467,812.42,333.9V321.06l9.639,5.555Z"
          transform="translate(12254.403 -2484.813)" fill="#92a3c3" />
        <path id="Path_8100" data-name="Path 8100" d="M822.059,355.837l-9.639-5.555V337.43L822.059,343Z"
          transform="translate(12254.403 -2481.916)" fill="#92a3c3" />
        <path id="Path_8101" data-name="Path 8101" d="M822.059,355.837l-9.639-5.555V337.43L822.059,343Z"
          transform="translate(12254.403 -2481.916)" fill="#92a3c3" />
        <path id="Path_8102" data-name="Path 8102" d="M822.059,372.219l-9.639-5.567V353.8l9.639,5.567Z"
          transform="translate(12254.403 -2479.019)" fill="#92a3c3" />
        <path id="Path_8103" data-name="Path 8103" d="M822.059,372.219l-9.639-5.567V353.8l9.639,5.567Z"
          transform="translate(12254.403 -2479.019)" fill="#92a3c3" />
        <path id="Path_8104" data-name="Path 8104" d="M840.479,333.727l-9.639-5.567V315.32l9.639,5.567Z"
          transform="translate(12257.663 -2485.828)" fill="#92a3c3" />
        <path id="Path_8105" data-name="Path 8105" d="M840.479,333.727l-9.639-5.567V315.32l9.639,5.567Z"
          transform="translate(12257.663 -2485.828)" fill="#92a3c3" />
        <path id="Path_8106" data-name="Path 8106" d="M840.479,350.1l-9.639-5.555V331.69l9.639,5.567Z"
          transform="translate(12257.663 -2482.932)" fill="#92a3c3" />
        <path id="Path_8107" data-name="Path 8107" d="M840.479,350.1l-9.639-5.555V331.69l9.639,5.567Z"
          transform="translate(12257.663 -2482.932)" fill="#92a3c3" />
        <path id="Path_8108" data-name="Path 8108" d="M840.479,366.479l-9.639-5.567V348.06l9.639,5.567Z"
          transform="translate(12257.663 -2480.035)" fill="#92a3c3" />
        <path id="Path_8109" data-name="Path 8109" d="M840.479,366.479l-9.639-5.567V348.06l9.639,5.567Z"
          transform="translate(12257.663 -2480.035)" fill="#92a3c3" />
        <path id="Path_8110" data-name="Path 8110" d="M840.479,382.847l-9.639-5.555V364.44l9.639,5.567Z"
          transform="translate(12257.663 -2477.136)" fill="#92a3c3" />
        <path id="Path_8111" data-name="Path 8111" d="M840.479,382.847l-9.639-5.555V364.44l9.639,5.567Z"
          transform="translate(12257.663 -2477.136)" fill="#92a3c3" />
        <path id="Path_8112" data-name="Path 8112" d="M858.9,344.369,849.26,338.8V325.95l9.639,5.567Z"
          transform="translate(12260.923 -2483.947)" fill="#92a3c3" />
        <path id="Path_8113" data-name="Path 8113" d="M858.9,344.369,849.26,338.8V325.95l9.639,5.567Z"
          transform="translate(12260.923 -2483.947)" fill="#92a3c3" />
        <path id="Path_8114" data-name="Path 8114" d="M858.9,360.737l-9.639-5.567V342.33l9.639,5.555Z"
          transform="translate(12260.923 -2481.049)" fill="#92a3c3" />
        <path id="Path_8115" data-name="Path 8115" d="M858.9,360.737l-9.639-5.567V342.33l9.639,5.555Z"
          transform="translate(12260.923 -2481.049)" fill="#92a3c3" />
        <path id="Path_8116" data-name="Path 8116" d="M858.9,377.107l-9.639-5.555V358.7l9.639,5.567Z"
          transform="translate(12260.923 -2478.152)" fill="#92a3c3" />
        <path id="Path_8117" data-name="Path 8117" d="M858.9,377.107l-9.639-5.555V358.7l9.639,5.567Z"
          transform="translate(12260.923 -2478.152)" fill="#92a3c3" />
        <path id="Path_8118" data-name="Path 8118" d="M858.9,393.489l-9.639-5.567V375.07l9.639,5.567Z"
          transform="translate(12260.923 -2475.255)" fill="#92a3c3" />
        <path id="Path_8119" data-name="Path 8119" d="M858.9,393.489l-9.639-5.567V375.07l9.639,5.567Z"
          transform="translate(12260.923 -2475.255)" fill="#92a3c3" />
      </g>
      <g transform="matrix(1, 0, 0, 1, -0.28, 0)" filter="url(#Rectangle_1272)">
        <rect id="Rectangle_1272-2" data-name="Rectangle 1272" width="132" height="37" rx="10"
          transform="translate(89.41 75.77)" fill="#529ec6" />
      </g>
      <text id="B2W_Block" data-name="B2W Block" transform="translate(88.137 83.771)" fill="#fff" font-size="18"
        font-family="Mulish-Regular, Mulish">
        <tspan x="25.084" y="16">B2W Block</tspan>
      </text>
    </g>
  </svg>
</div>