import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-block-f',
  templateUrl: './block-f.component.html',
  styleUrls: ['./block-f.component.css']
})
export class BlockFComponent {
  KPI:number =0;
  basepower:number=1409.43;
  optiResult: any;

  constructor(private http: HttpClient,private snackBar:MatSnackBar,    private router: Router,
  ) { }

  ngOnInit():void{
    this.getPredictionResult();
    setInterval(()=>{
      this.getPredictionResult();
    },600000);
  }

  async getPredictionResult() {
    this.http.get<any>(`${environment.apiBaseUrl}/auth/get_prediction_result`)
      .subscribe(
        (response: any) => {
          try {
            this.optiResult = response.result; // Directly assign the nested object

        // "fan_speed_F_L_Back_O": 27.33,
            var xfl2tfa=(this.optiResult['fan_speed_F_L2_TFA'])/50;
            var fl2tfa=Math.pow(xfl2tfa,3);
            fl2tfa=(1-fl2tfa)*100;
            console.log(fl2tfa);

            var xfl3tfa=(this.optiResult['fan_speed_F_L3_TFA'])/50;
            var fl3tfa=Math.pow(xfl3tfa,3);
            fl3tfa=(1-fl3tfa)*100;
            console.log(fl3tfa);

            var xfl4tfa=(this.optiResult['fan_speed_F_L4_TFA'])/50;
            var fl4tfa=Math.pow(xfl4tfa,3);
            fl4tfa=(1-fl4tfa)*100;
            console.log(fl4tfa);

            var xflgab=(this.optiResult['fan_speed_F_LG_A&B Wing'])/50;
            var flgab=Math.pow(xflgab,3);
            flgab=(1-flgab)*100;
            console.log(flgab);

            var xflab=(this.optiResult['fan_speed_F_L_A&B Wing'])/50;
            var flab=Math.pow(xflab,3);
            flab=(1-flab)*100;
            console.log(flab,"umaa");

            var xflcd=(this.optiResult['fan_speed_F_L_C&D Wing'])/50;
            var flcd=Math.pow(xflcd,3);
            flcd=(1-flcd)*100;
            console.log(flcd);

            var xflgcd=(this.optiResult['fan_speed_F_LG_C&D Wing'])/50;
            var flgcd=Math.pow(xflgcd,3);
            flgcd=(1-flgcd)*100;
            console.log(flgcd);

            var xflglblr3=(this.optiResult['fan_speed_F_LG_LB_LR 3'])/50;
            var flglblr3=Math.pow(xflglblr3,3);
            flglblr3=(1-flglblr3)*100;
            console.log(flglblr3);

            var xflselr1=(this.optiResult['fan_speed_F_L_SE_LR 1'])/50;
            var flselr1=Math.pow(xflselr1,3);
            flselr1=(1-flselr1)*100;
            console.log(flselr1);

            var xfltmlr2=(this.optiResult['fan_speed_F_L_TM_LR_2'])/50;
            var fltmlr2=Math.pow(xfltmlr2,3);
            fltmlr2=(1-fltmlr2)*100;
            console.log(fltmlr2);

            var xflrec=(this.optiResult['fan_speed_F_L_Rec'])/50;
            var flrec=Math.pow(xflrec,3);
            flrec=(1-flrec)*100;
            console.log(flrec);

            var xflbacko=(this.optiResult['fan_speed_F_L_Back_O'])/50;
            var flbacko=Math.pow(xflbacko,3);
            flbacko=(1-flbacko)*100;
            console.log(flbacko);

            this.KPI=(fl2tfa+fl3tfa+fl4tfa+flgab+flgcd+flab+flcd+flglblr3+flselr1+fltmlr2+flrec+flbacko)/12;
            this.KPI=(this.KPI)/(10);

          } catch (error) {
            console.error('Error processing prediction result:', error);
          }
        },
        (error) => {
          console.error('Error fetching optimized result:', error);
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
            this.openSnackBar('Session expired')

          }
          else{
            this.openSnackBar('error in fetching predicted values')

          }
        }
      );
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }
}
