<div class="f-block-comp">
  <svg id="f-block-comp" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%"
    height="100%" viewBox="0 0 238.485 280.341">
    <defs>
      <filter id="Path_4609" x="37.083" y="122.15" width="88.488" height="158.191" filterUnits="userSpaceOnUse">
        <feOffset dx="-10" dy="10" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="5" result="blur" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter id="Path_4610" x="103.628" y="114.024" width="134.856" height="166.096" filterUnits="userSpaceOnUse">
        <feOffset dy="10" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="5" result="blur-2" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur-2" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter id="Path_4611" x="0" y="65.791" width="56.963" height="155.945" filterUnits="userSpaceOnUse">
        <feOffset dx="-10" dy="10" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="5" result="blur-3" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur-3" />
        <feComposite in="SourceGraphic" />
      </filter>
      <linearGradient id="linear-gradient-f" x1="0.735" y1="0.37" x2="0.285" y2="1.128"
        gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#fff" />
        <stop offset="0.022" stop-color="#fcfcfc" />
        <stop offset="1" stop-color="#e4f4fc" />
      </linearGradient>
      <linearGradient id="linear-gradient-f-2" x1="0.5" y1="-0.065" x2="0.451" y2="1" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#fff" />
        <stop offset="1" stop-color="#8ea6b2" />
      </linearGradient>
      <filter id="Path_17500" x="42.728" y="97.29" width="94.147" height="118.15" filterUnits="userSpaceOnUse">
        <feOffset dy="10" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="3" result="blur-4" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur-4" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter id="Path_20057" x="43.567" y="59.003" width="159" height="64" filterUnits="userSpaceOnUse">
        <feOffset dx="3" dy="3" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="4.5" result="blur-5" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur-5" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Component_60_3" data-name="Component 60 – 3" transform="translate(16.92 1.18)">
      <path id="Path_4607" data-name="Path 4607"
        d="M104.465,482.045l-.177.129,103.254-53.881c-50.22-32.107-72.076-73.265-66.474-113.234L2.93,309.08H2l4.166,54.246.071.471c74.524-2.4,76,1.96,77.726,6.226L11.086,380.038H10.8l25.964,42.653H36.9l67.5-17.772-.318-.4q4.366,5.579,9.416,11.028L47.407,435.979"
        transform="translate(-2 -228.671)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="0.2" />
      <g transform="matrix(1, 0, 0, 1, -16.92, -1.18)" filter="url(#Path_4609)">
        <path id="Path_4609-2" data-name="Path 4609" d="M41.73,348.68l56.552,46.36-.93,79.738L40.8,428.418Z"
          transform="translate(21.79 -220.48)" fill="#ccdfe8" stroke="#427894" stroke-miterlimit="10"
          stroke-width="1" />
      </g>
      <g transform="matrix(1, 0, 0, 1, -16.92, -1.18)" filter="url(#Path_4610)">
        <path id="Path_4610-2" data-name="Path 4610" d="M192.692,341.59l-.459,81.127L88.85,476.033l.93-80.656Z"
          transform="translate(30.29 -221.74)" fill="#e6f7ff" stroke="#79a7bf" stroke-miterlimit="10"
          stroke-width="1" />
      </g>
      <g transform="matrix(1, 0, 0, 1, -16.92, -1.18)" filter="url(#Path_4611)">
        <path id="Path_4611-2" data-name="Path 4611" d="M35.254,344.073V423.8L9.29,381.158V301.42Z"
          transform="translate(16.21 -228.85)" fill="#ccdfe8" stroke="#427894" stroke-miterlimit="10"
          stroke-width="1" />
      </g>
      <path id="Path_4612" data-name="Path 4612" d="M2.2,240.76l4.637,54.7-.93,80.656L2.2,321.428Z"
        transform="translate(-1.965 -240.76)" fill="#ccdfe8" stroke="#427894" stroke-miterlimit="10" stroke-width="1" />
      <path id="Path_4613" data-name="Path 4613"
        d="M48.088,367.317l56.564,46.36-.177.129,103.266-53.834c-50.22-32.107-72.076-73.277-66.474-113.246L3.14,240.76H2.21l4.166,54.234.071.483,73.3-6.85A120.378,120.378,0,0,0,84.173,301.7L11.3,311.671h-.282l25.964,42.653h.129L104.628,336.5s5.708,7.062,9.051,10.593L47.593,367.541"
        transform="translate(-1.963 -240.76)" stroke="#96bdd1" stroke-width="1" fill="url(#linear-gradient-f)" />
      <path id="Path_4614" data-name="Path 4614"
        d="M48.088,367.317l56.564,46.36-.177.129,103.266-53.834c-1.406-.9-2.79-1.805-4.3-2.82-47.119-31.591-67.615-71.576-62.17-110.426L3.14,240.76H2.21l4.166,54.234.071.483,73.3-6.85A120.378,120.378,0,0,0,84.173,301.7L11.3,311.671h-.282l25.964,42.653h.129L104.628,336.5s5.708,7.062,9.051,10.593L47.593,367.541"
        transform="translate(-1.963 -240.76)" fill="none" stroke="#96bdd1" stroke-miterlimit="10" stroke-width="2.36" />
      <path id="Path_4615" data-name="Path 4615" d="M47.271,360.27l9.121,7.474L56.239,380.6l-9.11-7.474Z"
        transform="translate(5.985 -219.613)" fill="#87abbe" />
      <path id="Path_4616" data-name="Path 4616" d="M60.271,371.27l9.121,7.474L69.239,391.6l-9.11-7.474Z"
        transform="translate(8.286 -217.667)" fill="#87abbe" />
      <path id="Path_4617" data-name="Path 4617" d="M74.271,383.27l9.121,7.474L83.24,403.6l-9.11-7.474Z"
        transform="translate(10.764 -215.543)" fill="#87abbe" />
      <path id="Path_4618" data-name="Path 4618" d="M47.271,379.27l9.121,7.474L56.239,399.6l-9.11-7.474Z"
        transform="translate(5.985 -216.251)" fill="#87abbe" />
      <path id="Path_4619" data-name="Path 4619" d="M60.271,390.27l9.121,7.474L69.239,410.6l-9.11-7.474Z"
        transform="translate(8.286 -214.304)" fill="#87abbe" />
      <path id="Path_4620" data-name="Path 4620" d="M74.271,402.27l9.121,7.474L83.24,422.6l-9.11-7.474Z"
        transform="translate(10.764 -212.181)" fill="#87abbe" />
      <path id="Path_4621" data-name="Path 4621" d="M47.271,399.27l9.121,7.474L56.239,419.6l-9.11-7.474Z"
        transform="translate(5.985 -212.712)" fill="#87abbe" />
      <path id="Path_4622" data-name="Path 4622" d="M60.271,410.27l9.121,7.474L69.239,430.6l-9.11-7.474Z"
        transform="translate(8.286 -210.765)" fill="#87abbe" />
      <path id="Path_4623" data-name="Path 4623" d="M74.271,422.27l9.121,7.474L83.24,442.6l-9.11-7.474Z"
        transform="translate(10.764 -208.642)" fill="#87abbe" />
      <path id="Path_4624" data-name="Path 4624" d="M29.5,347.6V358.46L12.87,331.143V320.28Z"
        transform="translate(-0.076 -226.689)" fill="#87abbe" />
      <path id="Path_4625" data-name="Path 4625" d="M29.5,365.117V375.98L12.87,348.663V337.8Z"
        transform="translate(-0.076 -223.589)" fill="#87abbe" />
      <path id="Path_4626" data-name="Path 4626" d="M29.5,380.887V391.75L12.87,364.433V353.57Z"
        transform="translate(-0.076 -220.799)" fill="#87abbe" />
      <path id="Path_4627" data-name="Path 4627" d="M96.938,390.776l20.7-10.946-.506,11.769L96.75,402.274Z"
        transform="translate(14.766 -216.152)" fill="#accee0" />
      <path id="Path_4628" data-name="Path 4628" d="M96.508,404.666l20.7-10.946-.506,11.77L96.32,416.164Z"
        transform="translate(14.689 -213.694)" fill="#accee0" />
      <path id="Path_4629" data-name="Path 4629" d="M96.08,418.556l20.7-10.946-.506,11.77-20.4,10.675Z"
        transform="translate(14.612 -211.236)" fill="#accee0" />
      <path id="Path_4630" data-name="Path 4630" d="M95.67,431.774l20.7-10.934-.518,11.769L95.47,443.284Z"
        transform="translate(14.539 -208.895)" fill="#accee0" />
      <path id="Path_4631" data-name="Path 4631" d="M117.418,380.524l20.714-10.934-.518,11.77L117.23,392.034Z"
        transform="translate(18.39 -217.964)" fill="#accee0" />
      <path id="Path_4632" data-name="Path 4632" d="M116.988,394.414l20.7-10.934-.506,11.77L116.8,405.924Z"
        transform="translate(18.313 -215.506)" fill="#accee0" />
      <path id="Path_4633" data-name="Path 4633" d="M116.558,408.3l20.7-10.934-.506,11.77L116.37,419.814Z"
        transform="translate(18.237 -213.048)" fill="#accee0" />
      <path id="Path_4634" data-name="Path 4634" d="M116.15,421.534l20.7-10.934-.506,11.77-20.4,10.675Z"
        transform="translate(18.163 -210.707)" fill="#accee0" />
      <path id="Path_4635" data-name="Path 4635" d="M137.91,369.506l20.7-10.946-.506,11.77L137.71,381Z"
        transform="translate(22.014 -219.916)" fill="#accee0" />
      <path id="Path_4636" data-name="Path 4636" d="M137.468,383.4l20.714-10.946-.518,11.77L137.28,394.894Z"
        transform="translate(21.938 -217.458)" fill="#accee0" />
      <path id="Path_4637" data-name="Path 4637" d="M137.038,397.274l20.7-10.934-.506,11.77L136.85,408.784Z"
        transform="translate(21.861 -215)" fill="#accee0" />
      <path id="Path_4638" data-name="Path 4638" d="M136.628,410.5l20.7-10.934-.506,11.77L136.44,422.014Z"
        transform="translate(21.789 -212.659)" fill="#accee0" />
      <g transform="matrix(1, 0, 0, 1, -16.92, -1.18)" filter="url(#Path_17500)">
        <path id="Path_17500-2" data-name="Path 17500"
          d="M-4845.692-6306.407l67.459-16.894,7.322,8.669-64.594,20.406v64.97l-10.187,3.823Z"
          transform="translate(4897.92 6421.15)" stroke="#96bdd1" stroke-width="1" fill="url(#linear-gradient-f-2)" />
      </g>
      <path id="Path_17501" data-name="Path 17501"
        d="M-4845.692-6306.407l71.414-6.528,3.372,11.517-72.052,9.081-.034,65.086h-3.116Z"
        transform="translate(4851 6361.972)" stroke="#96bdd1" stroke-width="1" fill="url(#linear-gradient-f-2)" />
      <g transform="matrix(1, 0, 0, 1, -16.92, -1.18)" filter="url(#Path_20057)">
        <path id="Path_20057-2" data-name="Path 20057"
          d="M10,0H122a10,10,0,0,1,10,10V27a10,10,0,0,1-10,10H10A10,10,0,0,1,0,27V10A10,10,0,0,1,10,0Z"
          transform="translate(54.07 69.5)" fill="#529ec6" />
      </g>
      <text id="F_Block" data-name="F Block" transform="translate(102.147 75.324)" fill="#fff" font-size="18"
        font-family="Mulish-Regular, Mulish">
        <tspan x="-29.898" y="18">F Block</tspan>
      </text>
    </g>
  </svg>
</div>