import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-block-d-three',
  templateUrl: './block-d-three.component.html',
  styleUrls: ['./block-d-three.component.css']
})
export class BlockDThreeComponent {
KPI:number=0;
basepower:number=1873.55;
  optiResult: any;

  constructor(private http: HttpClient,private snackBar:MatSnackBar,private router:Router) { }

  ngOnInit():void{
    this.getPredictionResult();
    setInterval(()=>{
      this.getPredictionResult();
    },600000);
  }

  // async getPredictionResult() {
  //   this.http.get<any>(`${environment.apiBaseUrl}/auth/get_prediction_result`)
  //     .subscribe(
  //       (response: any) => {
  //         try {
  //           this.optiResult = response.result; // Directly assign the nested object
  //           // const totpow=this.optiResult['Power_D3L0']+this.optiResult['Power_D3L1']+this.optiResult['Power_D3L2']+this.optiResult['Power_D3L4'];
  //           // this.KPI=((this.basepower-(totpow))/(this.basepower))*100;

  //           var xd3l0=(this.optiResult['fan_speed_D3L0'])/50;
  //           var d3l0=Math.pow(xd3l0,3);
  //           d3l0=(1-d3l0)*100;
  //           console.log(d3l0);

  //           var xd3l1=(this.optiResult['fan_speed_D3L1'])/50;
  //           var d3l1=Math.pow(xd3l1,3);
  //           d3l1=(1-d3l1)*100;
  //           console.log(d3l1);

  //           var xd3l2=(this.optiResult['fan_speed_D3L2'])/50;
  //           var d3l2=Math.pow(xd3l2,3);
  //           d3l2=(1-d3l2)*100;
  //           console.log(d3l2);

  //           var xd3l4=(this.optiResult['fan_speed_D3L4'])/50;
  //           var d3l4=Math.pow(xd3l4,3);
  //           d3l4=(1-d3l4)*100;
  //           console.log(d3l4);

  //           this.KPI=(d3l4+d3l2+d3l1+d3l0)/4;
  //           this.KPI=(this.KPI)/(-100);

  //         } catch (error) {
  //           console.error('Error processing prediction result:', error);
  //         }
  //       },
  //       (error) => {
  //         console.error('Error fetching optimized result:', error);
  //         this.openSnackBar('error in fetching predicted values')
  //       }
  //     );
  // }
  async getPredictionResult() {

    this.http.get<any>(`${environment.apiBaseUrl}/auth/get_result`)

      .subscribe(

        (response: any) => {

          try {

            this.optiResult = response.environment; // Directly assign the nested object

            var totpow=this.optiResult['Power_D3L0']+this.optiResult['Power_D3L1']+this.optiResult['Power_D3L2']+this.optiResult['Power_D3L4'];
            totpow=totpow/4;

            this.KPI=((this.basepower-(totpow))/(this.basepower))*100;

          } catch (error) {

            console.error('Error processing prediction result:', error);

          }

        },

        (error) => {
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
            this.openSnackBar('Session expired')

          }
          console.error('Error fetching optimized result:', error);

          this.openSnackBar('error in fetching predicted values')

        }

      );

  }
  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }

}
