<div class="form-container">
    <div class="alarms-container">
      <!-- Iterate through each equipment -->
       <div *ngIf="isalarmempty()"> No faults present at the moment</div>
      <div class="alarms-container" *ngFor="let equipment of response1 | keyvalue">
        <h3>{{ equipment.key }}</h3> <!-- Display equipment name -->
        
        <!-- Iterate through alarms for each equipment -->
        <div *ngFor="let alarm of equipment.value" [ngClass]="{'alarm-card': true, 'active': alarm.isActive, 'inactive': !alarm.isActive}">
          <div class="alarm-content">
            <div class="alarm-title">{{ alarm.title }}</div>
            <div class="alarm-time">{{ alarm.time }}</div>
            <div class="alarm-description">{{ alarm.description }}</div>
          </div>
          <button class="close-btn" [disabled]="!alarm.isActive" (click)="closeAlarm(equipment.key, alarm.id,alarm)">
            {{ alarm.isActive ? 'Close' : 'Closed' }}
          </button>
        </div>
      </div>
    </div>
  </div>