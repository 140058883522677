import { Component, Input } from '@angular/core';

@Component({
  selector: 'Air-damper',
  templateUrl: './air-damper.component.html',
  styleUrls: ['./air-damper.component.css']
})
export class AirDamperComponent {
  @Input() state!:string;
  urlActive:string;
  urlInActive:string;
  urlTrip:string;
  constructor(){
    this.urlActive="/assets/bert-components/active-air-damper.svg";
    this.urlInActive="/assets/bert-components/active-air-damper.svg";
    this.urlTrip="/assets/bert-components/active-air-damper.svg";
  }
}
