<div
  style="
    height: 100vh;
    width: 100vw;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  "
>
  <div style="height: 100%; width: 100%; position: relative">
    <div
      style="
        height: 70%;
        width: 100%;
        background:url('/assets/images/UL-background.png');
        position: absolute;
        object-fit: cover;
        background-repeat: no-repeat;
        background-size: cover;
      "
    ></div>

    <div
      style="
        height: 100%;
        width: 100%;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      "
    >
      <div
        style="
          width: 90%;
          max-width: 500px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: start;
          gap: 100px;
        "
      >
       

      <img
      src="/assets/logos/uniliver-logo-login-screen.png"
      width="350px"
      style=" border-radius: 5px"
    />
    <img src="/assets/logos/powered_by.gif" alt="Powered By GIF" width="250px"
    style=" border-radius: 5px; z-index: 1; position: absolute;
    margin-top: 85px; align-items:center; justify-content:center; margin-left:0px; ">

      <!-- <span class="span-text-light">powered by:</span>

      <div style="background-color: white;border-radius: 10px;padding: 5px 10px 5px 10px;">
        <img src="/assets/logos/bertlabs-logo.png" width="200px" />

      </div> -->
      

       
        <form class="form" (ngSubmit)="handleSubmit()" style="height: 310px">
        
          <div
            style="
              width: 100%;
              display: flex;
              flex-direction: column;
              gap: 15px;
              justify-content: center;
              align-items: center;
            "
          >
          <div  style="text-align: center; color:#AAAAAA">
            <div style=" font-size: larger; color: var(--blue-two); font-weight: bolder; margin-bottom: 10px;"> Forgot Password</div>
            Enter email address associated with and we will send you link to reset your password
          </div>
            <mat-form-field style="width: 100%" appearance="outline">
              <mat-label>Email</mat-label>
              <input 
                type="text"
                matInput
                [formControl]="emailFormControl"
                [errorStateMatcher]="matcher"
                placeholder="Email"
                [(ngModel)]="email"
                style="font-size: 14px"
              />

              <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                Please enter a valid email address
              </mat-error>

              <mat-error *ngIf="emailFormControl.hasError('required')">
                Email is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <button
              mat-raised-button
              style="
                width: 100%;
                background-color: var(--blue-two);
                height: 45px;
                color: white;
                font-family: sans-serif;
              "
              type="submit"
            >
              Send Reset Link
            </button>

            <a class="hover-text-dark" [routerLink]="['/login']">Go to Login</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
