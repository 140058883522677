import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HomeComponent } from './pages/home/home.component';
import { LogoutDialogComponent } from './components/logout-dialog/logout-dialog.component';
import { MainSideBarComponent } from './components/main-side-bar/main-side-bar.component';
import { CardComponent } from './components/card/card.component';
import { AhuComponent } from './components/ahu/ahu.component';
import { BertComponentsModule } from '../../libs/bert-components/src/index';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import { MatBadgeModule } from '@angular/material/badge';
import{MatMenuModule} from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';

// angular material
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogModule,
} from '@angular/material/dialog';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { ChillerPlantComponent } from './pages/chiller-plant/chiller-plant.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { WaterMarkComponent } from './components/water-mark/water-mark.component';
import { MatDatepickerModule } from '@angular/material/datepicker';  // Import Datepicker module

import { BlockAComponent } from './Blocks/block-a/block-a.component';
import { BlockFComponent } from './Blocks/block-f/block-f.component';
import { BlockEComponent } from './Blocks/block-e/block-e.component';
import { BlockBOneComponent } from './Blocks/block-b-one/block-b-one.component';
import { BlockBTwoWestComponent } from './Blocks/block-b-two-west/block-b-two-west.component';
import { BlockBTwoEastComponent } from './Blocks/block-b-two-east/block-b-two-east.component';
import { BlockDOneComponent } from './Blocks/block-d-one/block-d-one.component';
import { BlockDTwoComponent } from './Blocks/block-d-two/block-d-two.component';
import { BlockDThreeComponent } from './Blocks/block-d-three/block-d-three.component';
import { BlockDFourComponent } from './Blocks/block-d-four/block-d-four.component';
import { BlockDFiveComponent } from './Blocks/block-d-five/block-d-five.component';
import { UlChillerPlantComponent } from './Blocks/ul-chiller-plant/ul-chiller-plant.component';
import { AhuTemplateComponent } from './ahu-template/ahu-template.component';
import { CoolingTowerComponent } from './pages/cooling-tower/cooling-tower.component';
import { ChillerComponent } from './pages/chiller/chiller.component';
import { CondenserPumpComponent } from './pages/condenser-pump/condenser-pump.component';
import { PrimaryPumpComponent } from './pages/primary-pump/primary-pump.component';
import { SecondaryPumpComponent } from './pages/secondary-pump/secondary-pump.component';
import { CoolingTower1Component } from './pages/cooling-tower/cooling-tower1/cooling-tower1.component';
import { CoolingTower2Component } from './pages/cooling-tower/cooling-tower2/cooling-tower2.component';
import { CoolingTower3Component } from './pages/cooling-tower/cooling-tower3/cooling-tower3.component';
import { CoolingTower4Component } from './pages/cooling-tower/cooling-tower4/cooling-tower4.component';
import { ZoneAComponent } from './pages/secondary-pump/zone-a/zone-a.component';
import { ZoneBComponent } from './pages/secondary-pump/zone-b/zone-b.component';
import { ZoneCComponent } from './pages/secondary-pump/zone-c/zone-c.component';
import { Chiller1Component } from './pages/chiller/chiller1/chiller1.component';
import { Chiller2Component } from './pages/chiller/chiller2/chiller2.component';
import { Chiller3Component } from './pages/chiller/chiller3/chiller3.component';
import { Chiller4Component } from './pages/chiller/chiller4/chiller4.component';
import { FChillerPlantComponent } from './pages/f-chiller-plant/f-chiller-plant.component';
import { ChillerCompComponent } from './components/home-components/chiller-comp/chiller-comp.component';
import { DBlockCompComponent } from './components/home-components/d-block-comp/d-block-comp.component';
import { ABlockCompComponent } from './components/home-components/a-block-comp/a-block-comp.component';
import { EBlockCompComponent } from './components/home-components/e-block-comp/e-block-comp.component';
import { FBlockCompComponent } from './components/home-components/f-block-comp/f-block-comp.component';
import { BOneBlockCompComponent } from './components/home-components/b-one-block-comp/b-one-block-comp.component';
import { BTwoEBlockCompComponent } from './components/home-components/b-two-e-block-comp/b-two-e-block-comp.component';
import { BTwoWBlockCompComponent } from './components/home-components/b-two-w-block-comp/b-two-w-block-comp.component';
import { DOneBlockCompComponent } from './components/home-components/d-one-block-comp/d-one-block-comp.component';
import { DTwoBlockCompComponent } from './components/home-components/d-two-block-comp/d-two-block-comp.component';
import { DThreeBlockCompComponent } from './components/home-components/d-three-block-comp/d-three-block-comp.component';
import { DFourBlockCompComponent } from './components/home-components/d-four-block-comp/d-four-block-comp.component';
import { DFiveBlockCompComponent } from './components/home-components/d-five-block-comp/d-five-block-comp.component';
import { TunnelCompComponent } from './components/home-components/tunnel-comp/tunnel-comp.component';
import { LoadingScreenComponent } from './pages/loading-screen/loading-screen.component';
import { AuthService } from './auth/auth.service';
import { AdminGuard } from './guards/admin.guard';
import { AuthGuard } from './guards/auth.guard';
import { AhuTypesComponent } from './ahu-types/ahu-types.component';
import { CoolingTowerBComponent } from './pages/cooling-tower/cooling-tower-b/cooling-tower-b.component';
import { LoadingComponent } from './loading/loading.component';
import { CommandUpdateComponent } from './command-update/command-update.component';
import { PidTuningCommandComponent } from './command-update/pid-tuning-command/pid-tuning-command.component';
import { AhuscheduleCommandComponent } from './command-update/ahuschedule-command/ahuschedule-command.component';
import { ChillerplantCommandComponent } from './command-update/chillerplant-command/chillerplant-command.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { ChangepasswordComponent } from './pages/changepassword/changepassword.component';
import { AlarmLogComponent } from './pages/alarm-log/alarm-log.component';
import { ViewAllErrorsComponent } from './view-all-errors/view-all-errors.component';
import { CommentDialogComponent } from './view-all-errors/comment-dialog/comment-dialog.component';
import { AnalyticsComponent } from './pages/analytics/analytics.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatSelectModule } from '@angular/material/select';
import { CustomSnackbarComponent } from './pages/custom-snackbar/custom-snackbar.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    DashboardComponent,
    HomeComponent,
    MainSideBarComponent,
    CardComponent,
    AhuComponent,
    ChillerPlantComponent,
    
    
    AhuTemplateComponent,
    BlockAComponent,
    BlockFComponent,
    BlockEComponent,
    BlockBOneComponent,
    BlockBTwoWestComponent,
    BlockBTwoEastComponent,
    BlockDOneComponent,
    BlockDTwoComponent,
    BlockDThreeComponent,
    BlockDFourComponent,
    BlockDFiveComponent,
    UlChillerPlantComponent,
    CoolingTowerComponent,
    ChillerComponent,
    CondenserPumpComponent,
    PrimaryPumpComponent,
    SecondaryPumpComponent,
    
    WaterMarkComponent,
    CoolingTower1Component,
    CoolingTower2Component,
    CoolingTower3Component,
    CoolingTower4Component,
    ZoneAComponent,
    ZoneBComponent,
    ZoneCComponent,
    Chiller1Component,
    Chiller2Component,
    Chiller3Component,
    Chiller4Component,
    FChillerPlantComponent,
    ChillerCompComponent,
    DBlockCompComponent,
    ABlockCompComponent,
    EBlockCompComponent,
    FBlockCompComponent,
    BOneBlockCompComponent,
    BTwoEBlockCompComponent,
    BTwoWBlockCompComponent,
    DOneBlockCompComponent,
    DTwoBlockCompComponent,
    DThreeBlockCompComponent,
    DFourBlockCompComponent,
    DFiveBlockCompComponent,
    TunnelCompComponent,
    LoadingScreenComponent,
    AhuTypesComponent,
    CoolingTowerBComponent,
    LoadingComponent,
    CommandUpdateComponent,
    PidTuningCommandComponent,
    AhuscheduleCommandComponent,
    ChillerplantCommandComponent,
    ChangepasswordComponent,
    AlarmLogComponent,
    ViewAllErrorsComponent,
    CommentDialogComponent,
    AnalyticsComponent,
    CustomSnackbarComponent
    
    
  ],
  imports: [
    LoadingSpinnerComponent,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    FormsModule,
    MatSidenavModule,
    MatListModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatInputModule,
    ReactiveFormsModule,
    NavBarComponent,
    LogoutDialogComponent,
    BertComponentsModule,
    HttpClientModule,
    MatProgressBarModule,
    MatBadgeModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxPaginationModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule

    
  ],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { hasBackdrop: true, direction: 'ltr' },
    },
    AuthService,AuthGuard,AdminGuard,
    {
      provide:HTTP_INTERCEPTORS,
      useClass:AuthInterceptor,
      multi:true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
