import { Component } from '@angular/core';

@Component({
  selector: 'f-block-comp',
  templateUrl: './f-block-comp.component.html',
  styleUrls: ['./f-block-comp.component.css']
})
export class FBlockCompComponent {

}
