<div class="b-two-e-comp">
  <svg id="b-two-e-comp" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 183.899 187.455">
    <defs>
      <linearGradient id="linear-gradient-b2e" x1="0.5" x2="-0.623" y2="0.858" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#fff" />
        <stop offset="1" stop-color="#e4f4fc" />
      </linearGradient>
      <linearGradient id="linear-gradient-b2e-2" x1="1.046" y1="0.342" x2="0.115" y2="0.52"
        gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#f2fafd" />
        <stop offset="1" stop-color="#e4f4fc" />
      </linearGradient>
    </defs>
    <g id="Component_80_1" data-name="Component 80 – 1" transform="translate(2.944 1.736)">
      <g id="Group_3639" data-name="Group 3639" transform="translate(-15240.045 2502.179)">
        <path id="Path_8144" data-name="Path 8144" d="M15057.161-2492.179l-48.116,28.5,103.474,61.222,50.228-29.122Z"
          transform="translate(231 -10)" fill="url(#linear-gradient-b2e)" stroke="#96bdd1" stroke-width="3" />
        <path id="Path_8145" data-name="Path 8145" d="M15114-2402.529l50.594-29.512.315,94.257L15114-2308.327Z"
          transform="translate(229.838 -9)" fill="url(#linear-gradient-b2e-2)" stroke="#79a7bf" stroke-width="1" />
        <path id="Path_8146" data-name="Path 8146" d="M15130.783-2402.252v13.819l23.979-13.819v-13.826Z"
          transform="translate(228.519 -7.428)" fill="#accee0" />
        <path id="Path_8147" data-name="Path 8147" d="M15130.783-2402.252v13.819l23.979-13.819v-13.826Z"
          transform="translate(228.519 12.572)" fill="#accee0" />
        <path id="Path_8148" data-name="Path 8148" d="M15130.783-2402.252v13.819l23.979-13.819v-13.826Z"
          transform="translate(228.519 32.572)" fill="#accee0" />
        <path id="Path_8149" data-name="Path 8149" d="M15130.783-2402.252v13.819l23.979-13.819v-13.826Z"
          transform="translate(228.519 51.572)" fill="#accee0" />
        <rect id="Rectangle_1287" data-name="Rectangle 1287" width="133" height="37" rx="9"
          transform="translate(15288 -2486)" fill="#529ec6" />
        <text id="B2E_Block" data-name="B2E Block" transform="translate(15308 -2477)" fill="#fff" font-size="18"
          font-family="Mulish-Regular, Mulish">
          <tspan x="6.683" y="16">B2E Block</tspan>
        </text>
      </g>
    </g>
  </svg>
</div>