import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-block-d-four',
  templateUrl: './block-d-four.component.html',
  styleUrls: ['./block-d-four.component.css']
})
export class BlockDFourComponent {
KPI:number=0;
basepower:number=939.74;
  optiResult: any;

  constructor(private http: HttpClient,private snackBar:MatSnackBar,private router: Router,) { }

  ngOnInit():void{
    this.getPredictionResult();
    setInterval(()=>{
      this.getPredictionResult();
    },600000);
  }

  // async getPredictionResult() {
  //   this.http.get<any>(`${environment.apiBaseUrl}/auth/get_prediction_result`)
  //     .subscribe(
  //       (response: any) => {
  //         try {
  //           this.optiResult = response.result; // Directly assign the nested object
  //           // const totpow=this.optiResult['Power_D4GS']+this.optiResult['Power_D4L0']+this.optiResult['Power_D4L1']+this.optiResult['Power_D4L2']+this.optiResult['Power_D4L4'];
  //           // this.KPI=((this.basepower-(totpow))/(this.basepower))*100;

  //           var xd4gs=(this.optiResult['fan_speed_D4GS'])/50;
  //           var d4gs=Math.pow(xd4gs,3);
  //           d4gs=(1-d4gs)*100;
  //           console.log(d4gs);

  //           var xd4l0=(this.optiResult['fan_speed_D4L0'])/50;
  //           var d4l0=Math.pow(xd4l0,3);
  //           d4l0=(1-d4l0)*100;
  //           console.log(d4l0);

  //           var xd4l1=(this.optiResult['fan_speed_D4L1'])/50;
  //           var d4l1=Math.pow(xd4l1,3);
  //           d4l1=(1-d4l1)*100;
  //           console.log(d4l1);

  //           var xd4l2=(this.optiResult['fan_speed_D4L2'])/50;
  //           var d4l2=Math.pow(xd4l2,3);
  //           d4l2=(1-d4l2)*100;
  //           console.log(d4l2);

  //           var xd4l4=(this.optiResult['fan_speed_D4L4'])/50;
  //           var d4l4=Math.pow(xd4l4,3);
  //           d4l4=(1-d4l4)*100;
  //           console.log(d4l4);

  //           this.KPI=(d4l4+d4l2+d4l1+d4l0+d4gs)/5;
  //           this.KPI=(this.KPI)/(100);

  //         } catch (error) {
  //           console.error('Error processing prediction result:', error);
  //         }
  //       },
  //       (error) => {
  //         console.error('Error fetching optimized result:', error);
  //         if (error.status === 401) {
  //           // Redirect to the login page
  //           this.router.navigate(['/login']); // Adjust the path as necessary
  //           sessionStorage.removeItem('accessToken');
  //           this.openSnackBar('Session expired')

  //         }
  //         else{
  //           this.openSnackBar('error in fetching predicted values')

  //         }  
  //               }
  //     );
  // }

  async getPredictionResult() {

    this.http.get<any>(`${environment.apiBaseUrl}/auth/get_result`)

      .subscribe(

        (response: any) => {

          try {

            this.optiResult = response.environment; // Directly assign the nested object

            var totpow=this.optiResult['Power_D4GS']+this.optiResult['Power_D4L0']+this.optiResult['Power_D4L1']+this.optiResult['Power_D4L2']+this.optiResult['Power_D4L4'];
            totpow=totpow/5;

            this.KPI=((this.basepower-(totpow))/(this.basepower))*100;

          } catch (error) {

            console.error('Error processing prediction result:', error);

          }

        },

        (error) => {

          console.error('Error fetching optimized result:', error);
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
            this.openSnackBar('Session expired')

          }
          this.openSnackBar('error in fetching predicted values')

        }

      );

  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }

}
