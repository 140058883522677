import { Component } from '@angular/core';

@Component({
  selector: 'b-two-e-block-comp',
  templateUrl: './b-two-e-block-comp.component.html',
  styleUrls: ['./b-two-e-block-comp.component.css']
})
export class BTwoEBlockCompComponent {

}
