<div style="position: relative; height: 100%; width: 100%">
  <div class="main_container">
    <div class="content-container">
      <div class="upcontainer">
        <div class="map-container">
          <img class="fmap" src="/assets/chiller/ac chiller.svg">
          <!-- <div class="input-groupR  component"
            style="display:flex; justify-content:center; align-items:center; top: 10%; left: 78%; width: 13.5%; height: 3%;">
            <span style="font-size: 80%; color: #00A578; font-weight:200">AC.Chiller Power</span>
          </div> -->
          <div class="input-groupR  component"
            style="display:flex; justify-content:center; align-items:center; top: 4.5%; left: 91.5%; width: 5%; height: 3%;">
            <input readonly style="color:#293AC6; font-weight:600" [ngModel]=" AC_POWER_GR | number: '1.2-2'"
              (ngModelChange)=" AC_POWER_GR = $event" />
            <span style="font-size: 80%; color: #293AC6; font-weight:600">kw</span>
          </div>

          <!-- <div class="input-groupR  component"
            style="display:flex; justify-content:center; align-items:center; top: 5%; left: 78%; width: 13.5%; height: 3%;">
            <span style="font-size: 80%; color: #D90606; font-weight:200">AC.Chiller Power</span>
          </div> -->
          <div class="input-groupR  component"
            style="display:flex; justify-content:center; align-items:center; top: -0.5%; left: 91.5%; width: 5%; height: 3%;">
            <input readonly style="color: #D90606; font-weight:600" [ngModel]=" AC_POWER_real | number: '1.2-2'"
              (ngModelChange)=" AC_POWER_real = $event" />
            <span style="font-size: 80%; color: #D90606; font-weight:600">kw</span>
          </div>

          <!-- <div class="input-groupR  component"
            style="display:flex; justify-content:center; align-items:center; top: 17%; left: 78.5%; width: 13.5%; height: 3%;">
            <span style="font-size: 80%; color: #D90606; font-weight:200">AC.Chiller set point</span>
          </div> -->
          <div class="input-groupR  component"
            style="display:flex; justify-content:center; align-items:center; top: 13%; left: 92.5%; width: 4.5%; height: 3%;">
            <input readonly style="color: #D90606; font-weight:600" [ngModel]=" AC_CHILLER_SP | number: '1.2-2'"
              (ngModelChange)=" AC_CHILLER_SP = $event" />
            <span style="font-size: 80%; color: #D90606; font-weight:600">°C</span>
          </div>

          <div class="input-groupR  component"
            style="display:flex; justify-content:center; align-items:center; top: 19%; left: 92.5%; width: 4.5%; height: 3%;">
            <input readonly style="color: #00A578; font-weight:600" [ngModel]=" AC_CHILLER_SP_GR | number: '1.2-2'"
              (ngModelChange)=" AC_CHILLER_SP = $event" />
            <span style="font-size: 80%; color: #00A578; font-weight:600">°C</span>
          </div>
          <!-- <div class="input-groupR  component"
            style="display:flex; justify-content:center; align-items:center; top: 65%; left:58%; width: 13.5%; height: 3%;">
            <span style="font-size: 80%; color: #D90606; font-weight:200">Ch.w.in</span>
          </div> -->
          <div class="input-groupR  component"
            style="display:flex; justify-content:center; align-items:center; top: 67.5%; left: 68%; width: 4.5%; height: 3%;">
            <input readonly style="color: #D90606; font-weight:600" [ngModel]=" CH_WATER_IN_real | number: '1.2-2'"
              (ngModelChange)=" CH_WATER_IN_real = $event" />
            <span style="font-size: 80%; color: #D90606; font-weight:600">°C</span>
          </div>

          <!-- <div class="input-groupR  component"
            style="display:flex; justify-content:center; align-items:center; top: 88%; left: 58%; width: 13.5%; height: 3%;">
            <span style="font-size: 80%; color: #D90606; font-weight:200">Ch.w.out</span>
          </div> -->
          <div class="input-groupR  component"
            style="display:flex; justify-content:center; align-items:center; top: 89%; left: 70.5%; width: 4.5%; height: 3%;">
            <input readonly style="color: #D90606; font-weight:600" [ngModel]=" CH_WATER_OUT_real | number: '1.2-2'"
              (ngModelChange)=" CH_WATER_OUT_real = $event" />
            <span style="font-size: 80%; color: #D90606; font-weight:600">°C</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>