import { Component } from '@angular/core';

@Component({
  selector: 'a-block-comp',
  templateUrl: './a-block-comp.component.html',
  styleUrls: ['./a-block-comp.component.css']
})
export class ABlockCompComponent {

}
