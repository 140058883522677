import { ChangeDetectorRef, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NgxPaginationModule } from 'ngx-pagination';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Router } from '@angular/router';

import { LoadingSpinnerComponent } from 'src/app/loading-spinner/loading-spinner.component';
@Component({
  selector: 'app-analytics',
  
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.css']
})
export class AnalyticsComponent {
  selectedGraph: string = 'AHU';
  selectedAhu: string = 'VAV-AHU-C4-L2-02';
  fromDate: Date | null = null;
  convfromDate: string | null = null;

  convtoDate: string | null = null;

  fromTime: string | null = '09:30';
  toDate: Date | null = null;
  toTime: string | null = '09:30';
  downloadtype: string = 'PDF';
  anyerror: boolean = true;
  showData: boolean = false;
  tableData: any[] = [];
  selectedAHUs: string = '';
  itemsPerPage: number = 50;
  currentPage: number = 1;
  itemsPerPageOptions: number[] = [10, 20, 50, 144];
  numberoflogs: boolean = true;
  aftermessage: string = '';
  selectedBlock:any='';
  selectedFloor:any='';
  selectedRoomOptions: { label: string; value: string }[] = [];
  ahus = new FormControl('');
  isloading: boolean = false;
  blockahumap:Map<string,number>= new Map<string,number>();

  blocks: string[]=['A','B1','B2W','B2E','D1','D2','D3','D4','D5','E','F']
  ahus1: string[][] = [['UB','Mezz','L2'],
                      ['GS','L0','L1','L2','L3','L4'],
                      ['MR','KK','FC','L1','L2','L3','L4'],
                      ['FC','L1','L2','L3','L4'],
                      ['L0','L1','L2','L3','L4'],
                      ['L0','L1','L2','L3','L4'],
                      ['L0','L1','L2','L4'],
                      ['GS','L0','L1','L2','L3','L4'],
                      ['GS'],
                      ['DayE','DayW','Game','Lounge','Audi1','Audi2'],
                      ['A&B Wing GF Corridor AHU','C Wing GF Corridor-Admin AHU','B Wing GF TR Hall AHU','A&B Wing 1st Corridor','B&C Wing 1st Corridor','B Wing 1F TR Hall AHU','C Wing 1F TR Hall AHU','A Wing 1F Reception AHU','A Wing 1F Reception & Back Office', 'TFA-Second Floor','TFA-Third Floor','TFA-Fourth Floor']
                    ];
  ahuList: string[] = [
    'AHU_01',
    'AHU_02',
    'AHU_03',
    'AHU_04',
    'AHU_05',
    'AHU_06',
    'AHU_07',
    'AHU_08',
    'AHU_10',
    'AHU_11',
    'AHU_12',
  ];
  downloadTypes = [
    // { value: 'XLSX', name: 'XLSX' },
    { value: 'PDF', name: 'PDF' },
  ];
  ahuRoomOptions: { [key: string]: { label: string; value: string }[] } = {
    AHU_01: [
      {
        label: 'Liquid Dispensing Staging',
        value: 'Liquid Dispensing Staging',
      },
    ],
    AHU_02: [
      { label: 'Day Store', value: 'Day Store' },
      { label: 'SFG Quranmtime', value: 'SFG Quranmtime' },
    ],
    AHU_03: [{ label: 'Suposistories', value: 'Suposistories' }],
    AHU_04: [{ label: 'Liquid Filing', value: 'Liquid Filing' }],
    AHU_05: [{ label: 'Ointment Preparation', value: 'Ointment Preparation' }],
    AHU_06: [
      { label: 'Ointment Fill and Seal', value: 'Ointment Fil and Seal' },
    ],
    AHU_07: [
      { label: 'Change Room Gents', value: 'Change Room Gents' },
      { label: 'Change Room Ladies', value: 'Change Room Ladies' },
      { label: 'Prod. Office', value: 'Prod. Office' },
      { label: 'IPQA', value: 'IPQA' },
      { label: 'Entrance Corridor', value: 'Entrance Corridor' },
      { label: 'Gents Air Lock', value: 'Gents Air Lock' },
      { label: 'Ladies Air Lock', value: 'Ladies Air Lock' },
      { label: 'Gents Hand and Foot Wash', value: 'Gents Hand and Foot Wash' },
      {
        label: 'Ladies Hand and Foot Wash',
        value: 'Ladies Hand and Foot Wash',
      },
      { label: 'Linen Room', value: 'Linen Room' },
    ],
    AHU_08: [{ label: 'Liquid Preparation', value: 'Liquid Preparation' }],
    AHU_10: [{ label: 'Sachet Filling', value: 'Sachet Filling' }],
    AHU_11: [{ label: 'Equipment Drying', value: 'Equipment Drying' }],
    AHU_12: [
      {
        label: 'Liquid Dispensing Staging',
        value: 'Liquid Dispensing Staging',
      },
    ],
  };
  times = [
    { value: '00:00', name: '00:00' },
    { value: '01:00', name: '01:00' },
    { value: '02:00', name: '02:00' },
    { value: '03:00', name: '03:00' },
    { value: '04:00', name: '04:00' },
    { value: '05:00', name: '05:00' },
    { value: '06:00', name: '06:00' },
    { value: '07:00', name: '07:00' },
    { value: '08:00', name: '08:00' },
    { value: '09:00', name: '09:00' },
    { value: '10:00', name: '10:00' },
    { value: '11:00', name: '11:00' },
    { value: '12:00', name: '12:00' },
    { value: '13:00', name: '13:00' },
    { value: '14:00', name: '14:00' },
    { value: '15:00', name: '15:00' },
    { value: '16:00', name: '16:00' },
    { value: '17:00', name: '17:00' },
    { value: '18:00', name: '18:00' },
    { value: '19:00', name: '19:00' },
    { value: '20:00', name: '20:00' },
    { value: '21:00', name: '21:00' },
    { value: '22:00', name: '22:00' },
    { value: '23:00', name: '23:00' },
  ];
  
  response1: any;
  selectedAhuRooms: { ahu: string; rooms: string[] }[] = [];
  chillerRoomOptions: { [key: string]: { label: string; value: string }[] } = {
    // 'Chiller-1 (WC)': [
    //   { label: 'Room1', value: 'Room1' },
    //   { label: 'Room2', value: 'Room2' },
    // ],
    // 'Chiller-2 (WC)': [
    //   { label: 'Room3', value: 'Room3' },
    //   { label: 'Room4', value: 'Room4' },
    // ],
    // 'Chiller-3 (WC)': [
    //   { label: 'Room3', value: 'Room3' },
    //   { label: 'Room4', value: 'Room4' },
    // ],
    // 'Chiller-4 (AC)': [
    //   { label: 'Room3', value: 'Room3' },
    //   { label: 'Room4', value: 'Room4' },
    // ],
  };

  constructor(private cdr: ChangeDetectorRef, private snackbar:MatSnackBar, private router:Router, private http: HttpClient) {}
  ngOnInit() : void{
     

    for(let i=0; i<this.blocks.length; i++){
      this.blockahumap.set(this.blocks[i],i);
    } 

    console.log(this.blockahumap);
    console.log(this.blockahumap.get("A-Block"),"helllll");
    // console.log(this.selectedBlock);
  }
  async SendData() {
    this.anyerror = false;

    // Validation for required fields
    if (!this.selectedBlock) {
      this.anyerror = true;
      alert('Block selection is required.');
      return;
    }
    if (!this.selectedFloor) {
      this.anyerror = true;
      alert('floor selection is required.');
      return;
    }

    if (!this.fromDate) {
      this.anyerror = true;
      alert('From Date is required.');
      return;
    }

    if (!this.fromTime) {
      this.anyerror = true;
      alert('From Time is required.');
      return;
    }

    if (!this.toDate) {
      this.anyerror = true;
      alert('To Date is required.');
      return;
    }

    if (!this.toTime) {
      this.anyerror = true;
      alert('To Time is required.');
      return;
    }

    if (this.fromDate > this.toDate) {
      this.anyerror = true;
      alert('From date should be less than To date');
      return;
    }
    console.log(this.fromDate, 'check2');
    const fromDatetime =
      this.fromDate && this.fromTime
        ? (() => {
            // Create a Date object from the user input
            const date = new Date(this.fromDate);

            // Increase the date by 1 day
            date.setDate(date.getDate() + 1);
            const newDateString = date.toISOString().split('T')[0];
            const formattedTime = this.convertTo24HourFormat(this.fromTime);
            return `${newDateString} ${formattedTime}`;
          })()
        : '';
this.convfromDate=    this.fromDate
? (() => {
    // Create a Date object from the user input
    const date = new Date(this.fromDate);

    // Increase the date by 1 day
    date.setDate(date.getDate() + 1);
    const newDateString = date.toISOString().split('T')[0];
    return `${newDateString}`;
  })()
: '';
    const toDatetime =
      this.toDate && this.toTime
        ? (() => {
            // Create a Date object from the user input
            const date = new Date(this.toDate);

            // Increase the date by 1 day
            date.setDate(date.getDate() + 1);

            // Format the adjusted date as "YYYY-MM-DD"
            const newDateString = date.toISOString().split('T')[0];

            // Convert the time to 24-hour format
            const formattedTime = this.convertTo24HourFormat(this.toTime);

            // Combine the adjusted date with the formatted time
            return `${newDateString} ${formattedTime}`;
          })()
        : '';

        this.convtoDate =
        this.toDate
          ? (() => {
              // Create a Date object from the user input
              const date = new Date(this.toDate);
  
              // Increase the date by 1 day
              date.setDate(date.getDate() + 1);
  
              // Format the adjusted date as "YYYY-MM-DD"
              const newDateString = date.toISOString().split('T')[0];
  
              // Convert the time to 24-hour format
  
              // Combine the adjusted date with the formatted time
              return `${newDateString}`;
            })()
          : '';

    console.log('check from', fromDatetime);
    console.log('check to', toDatetime);

    const data = {
      from_datetime: fromDatetime,
      to_datetime: toDatetime,
      deviceid: this.selectedAHUs,
    };

    this.showData = true;
    this.isloading=true;
    const fromTimestamp = new Date(fromDatetime).getTime();
    const toTimestamp = new Date(toDatetime).getTime();
    const url = `${environment.apiBaseUrl}/auth/device/historic/hul/ulhi?block=${this.selectedBlock}&floor=${this.selectedFloor}&deviceType=2&timestamp_i=${fromTimestamp}&timestamp_f=${toTimestamp}&values=supply_dpt,rat,sat,chw_valve_opening,fan_speed`;

    this.http
      .get<any[]>(url)
      .subscribe(
        (response: any[]) => {
          this.isloading=false;
          console.log('received response', response);
          console.log(data);

          this.response1 = {
            timestamps: response.map((item) =>{
             
              return this.convertTimestampToDate(item['timestamp']);
            } ),
            ahuData: response.map((item) => this.selectedBlock),
            floorData:response.map((item) => this.selectedFloor),
            ratData: response.map((item) =>
              item['rat'] !== null ? item['rat'] : 'NA'
            ),
            supplyDpt: response.map((item) => item['supply_dpt']),
            satData: response.map((item) => item['sat']),
            valvedata:response.map((item) => item['chw_valve_opening']),
            fspeeddata:response.map((item) => item['fan_speed']),

          };

          this.tableData = this.showtable(
            this.response1.timestamps,
            this.response1.ahuData,
            this.response1.floorData,
            this.response1.ratData,
            this.response1.satData,
           
            this.response1.supplyDpt,
            this.response1.valvedata,
            this.response1.fspeeddata,

          );

          if (this.tableData.length == 0) {
            this.aftermessage = 'No data in given Range';
            this.openSnackBar('No data  in given Range');
            
          }
        },

         (error) => {
          this.isloading=false;
          this.openSnackBar("Error in fetching details,Please try again!!")
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
          }
          this.numberoflogs = false;
          console.log(this.numberoflogs);
          console.log(data);
          console.error('Error:', error);
        }
      );
  }

   convertTimestampToDate(timestamp: string): string {
  // Convert the timestamp string to a number
  const date = new Date(parseInt(timestamp, 10));

  // Format the date components
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2); // Add leading zero if needed
  const day = ('0' + date.getDate()).slice(-2);          // Add leading zero if needed
  const hours = ('0' + date.getHours()).slice(-2);        // Add leading zero if needed
  const minutes = ('0' + date.getMinutes()).slice(-2);    // Add leading zero if needed
  const seconds = ('0' + date.getSeconds()).slice(-2);    // Add leading zero if needed

  // Combine components into desired format
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

  convertTo24HourFormat(time: string | null): string | null {
    if (!time) return null;

    // Split the time into hours and minutes
    let [hours, minutes] = time.split(':');
    return `${hours}:${minutes}:00`;
  }

  showtable(
    timedata: string[],
    ahuData: string[],
    floorData:any[],
    ratData: any[],
    satData: number[],
    supplyDpt:number[],
    valvedata:any[],
    fspeeddata:any[]
  
   
  ) {
    return timedata.map((time, index) => {
      const date = new Date(time);
      return {
        serialNo: index + 1,
        date: date.toLocaleDateString(), // Date in user's locale format
        time: date.toLocaleTimeString('en-IN', { hour12: false }), // Time in 24-hour format
        ahu: ahuData[index],
        sat:satData[index],
        supplyDpt:supplyDpt[index],
        rat: ratData[index],
        floor:floorData[index],
        valve:valvedata[index],
        speed:fspeeddata[index]
      };
    });
  }
  openSnackBar(message: string) {
    this.snackbar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: "start",
      duration: 5000
    });
  }
  downloadXLSX() {
    const headers = [
      'S No.',
      'Date',
      'Time',
      'Block',
      'Floor',
      'RAT (°C)',
      'SAT',
      'Supply DPT',
      'valve',
      'fan speed'
      
    ];

    // Get current time in IST
    const now = new Date();
    const istOffset = 330; // IST is UTC +5:30
    const istTime = new Date(now.getTime());
    const istDate = istTime.toLocaleDateString('en-IN', {
      day: '2-digit',    // Two-digit day
      month: '2-digit',  // Two-digit month
      year: 'numeric'    // Full year
    });
    const istTimeString = istTime.toLocaleTimeString('en-IN', { hour12: false }); // Format time as per IST

    // User information
    const username = sessionStorage.getItem('username'); // Replace with actual username variable
    const firstRow = [
      `Date: ${istDate}`,
      `Time: ${istTimeString}`,
      `Generated by: ${username}`,
    ];

    const wsData = [];
    wsData.push(firstRow); // Add first row to XLSX
    wsData.push(headers); // Add headers

    this.tableData.forEach((row) => {
      const values = [
        row.serialNo,
        row.date,
        row.time,
        row.ahu,
        row.floor,
        row.rat,
        row.sat,
        row.supplyDpt,
        row.valve,
        row.speed


      ];
      wsData.push(values);
    });

    // Create XLSX file
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(wsData);

    // Custom column widths
    ws['!cols'] = [
      { wpx: 100 },
      { wpx: 100 },
      { wpx: 80 },
      { wpx: 150 },
      { wpx: 150 },
      { wpx: 70 },
      { wpx: 70 },
      { wpx: 70 },
      { wpx: 70 },
      { wpx: 70 },
      { wpx: 70 },
    ];

    // Append worksheet to workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Data');

    // Convert to Blob
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const xlsxBlob = new Blob([wbout], { type: 'application/octet-stream' });

    // Send the XLSX file to the backend
    const formData = new FormData();
    formData.append('file', xlsxBlob, 'data.xlsx');

    // Make the HTTP POST request
    this.http
      .post(`${environment.apiBaseUrl}/encrypt/xlsx`, formData, {
        responseType: 'blob',
      })
      .subscribe(
        (response: Blob) => {
          // Create a download link for the received encrypted XLSX file
          const url = window.URL.createObjectURL(response);
          const a = document.createElement('a');
          a.href = url;
          a.download = `Ahu_history_log_${this.convfromDate}_${this.convtoDate}.xlsx`; // Encrypted file name
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        },
         (error) => {
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
          }
          console.error('Error:', error);
        }
      );
  }

  downloadPDF() {
    const doc = new jsPDF('p', 'mm', 'a4');
    const imgWidth = 200;
    const imgHeight = 200;
  
    // Set header texts for "Powered by", "Bert Labs", and "Strides"
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.text('Powered by', 10, 20);
  
    doc.setFont('helvetica', 'bold');
    doc.setTextColor(255, 0, 0);
    doc.text('Bert Labs', 35, 20);
  
    doc.setTextColor(0, 0, 255);
    doc.text('HUL', 75, 20);
  console.log("check one")
    // Get current time in user's locale
    const now = new Date();
    const istDate = now.toLocaleDateString();
    const istTimeString = now.toLocaleTimeString('en-IN', { hour12: false });
    console.log("check two")

    // User information
    const username = sessionStorage.getItem('username');
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text(`Generated by: ${username}`, 10, 30);
    doc.text(`Date: ${istDate}`, 10, 35);
    doc.text(`Time: ${istTimeString}`, 10, 40);
  
    // Headers for the table
    const headers = [
      'S No.', 'Date', 'Time', 'AHU', 'Floor', 'Supply DPT', 'RAT (°C)', 'SAT', 'Valve', 'Fan Speed'
    ];
    const columnWidths = [12, 20, 20, 20, 20, 25, 20, 25, 25, 20];
    let yPos = 60; // Starting y-position for table
    let xPos = 10; // Starting x-position for table columns
    const lineHeight = 7;
  
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
  
    // Draw table headers
    headers.forEach((header, index) => {
      doc.text(header, xPos, yPos);
      xPos += columnWidths[index];
    });
  
    yPos += lineHeight; // Move to next row
    console.log("check four")

    // Add table data
    this.tableData.forEach((row, index) => {
      const values = [
        index + 1, // Serial No.
        row.date, // Date
        row.time, // Time
        row.ahu,
        row.floor,
        parseFloat(row.sat).toFixed(2), // Restrict SAT to 2 decimal places
        parseFloat(row.supplyDpt).toFixed(2), // You can restrict supply DPT too if needed
        parseFloat(row.rat).toFixed(2),
        parseFloat(row.valve).toFixed(2),
        parseFloat(row.speed).toFixed(2)
      ];
      console.log("check three")

      xPos = 10; // Reset xPos for each new row
      values.forEach((value, i) => {
        doc.text(String(value), xPos, yPos);
        xPos += columnWidths[i];
      });
  
      yPos += lineHeight; // Move to next row
  
      // Add a new page if content exceeds page height
      if (yPos > doc.internal.pageSize.getHeight() - 20) {
        doc.addPage();
        yPos = 20;
        xPos = 10;
  
        // Add headers again on the new page
        headers.forEach((header, index) => {
          doc.text(header, xPos, yPos);
          xPos += columnWidths[index];
        });
        yPos += lineHeight;
      }
    });
  
    // Directly download the PDF
    doc.save(`Ahu_history_log_${this.convfromDate}_${this.convtoDate}.pdf`);
  }
  


  onPageChange(page: number) {
    this.currentPage = page;
  }

  onItemsPerPageChange() {
    this.currentPage = 1;
  }
}
