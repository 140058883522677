<div style="height: 100%; width: 100%">
    <ng-container *ngIf="state === 'active'; else inActiveTemplate">
      <img src="{{ urlActive }}" alt="air-damper" height="100%" width="100%" class="image"/>
    </ng-container>
    <ng-template #inActiveTemplate>
      <ng-container *ngIf="state === 'inActive' ; else tripTemplate">
          <img src={{urlInActive}} alt="air-damper" height="100%" width="100%" class="image"/>
      </ng-container>
      <ng-template #tripTemplate>
          <img src={{urlTrip}} alt="air-damper" height="100%" width="100%" class="image"/>
      </ng-template>
    </ng-template>
</div>


