import { Component } from '@angular/core';

@Component({
  selector: 'Filter-five',
  templateUrl: './filter-five.component.html',
  styleUrls: ['./filter-five.component.css']
})
export class FilterFiveComponent {

}
