<div class="d-four-comp">
  <svg id="d-four-comp" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%"
    height="100%" viewBox="0 0 168.218 110.603">
    <defs>
      <linearGradient id="linear-gradient-d4" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#ebf7fd" />
        <stop offset="1" stop-color="#8ea6b2" />
      </linearGradient>
      <linearGradient id="linear-gradient-d4-2" x1="0.839" y1="0.572" x2="-0.256" y2="0.599"
        gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#fff" />
        <stop offset="1" stop-color="#e4f4fc" />
      </linearGradient>
      <linearGradient id="linear-gradient-d4-4" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#fff" />
        <stop offset="1" stop-color="#8ea6b2" />
      </linearGradient>
      <filter id="Rectangle_1278" x="0" y="0" width="159" height="64" filterUnits="userSpaceOnUse">
        <feOffset dx="3" dy="3" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="4.5" result="blur" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Component_78_5" data-name="Component 78 – 5" transform="translate(10.5 5.826)">
      <path id="Path_4288" data-name="Path 4288" d="M746.783,199.158,704.87,175.3h0V120.5l45.277,26.14-.3,50.616Z"
        transform="translate(-679.583 -94.984)" stroke="#427894" stroke-miterlimit="10" stroke-width="1"
        fill="url(#linear-gradient-d4)" />
      <path id="Path_4289" data-name="Path 4289" d="M719.509,150.387l-9.639-5.555V131.98l9.639,5.567Z"
        transform="translate(-678.698 -92.953)" fill="#87abbe" />
      <path id="Path_4290" data-name="Path 4290" d="M719.509,166.767,709.87,161.2V148.36l9.639,5.555Z"
        transform="translate(-678.698 -90.054)" fill="#87abbe" />
      <path id="Path_4291" data-name="Path 4291" d="M719.509,175.9l-9.551-5.517-.088-5.656,9.639,5.555Z"
        transform="translate(-678.698 -87.158)" fill="#87abbe" />
      <path id="Path_4293" data-name="Path 4293" d="M738.839,161.548l-9.639-5.555V143.14l9.639,5.567Z"
        transform="translate(-675.278 -90.978)" fill="#87abbe" />
      <path id="Path_4294" data-name="Path 4294" d="M738.839,177.928l-9.639-5.567V159.52l9.639,5.555Z"
        transform="translate(-675.278 -88.08)" fill="#87abbe" />
      <path id="Path_4297" data-name="Path 4297"
        d="M723.747,155.837l8.9-5.143,8.721,5.026,10.569-6.1L706.94,123.635l40.734-23.515,86.494,49.938-60.224,34.767Z"
        transform="translate(-679.217 -98.59)" fill="url(#linear-gradient-d4-2)" />
      <path id="Path_4298" data-name="Path 4298"
        d="M748.1,101.951l83.716,48.337L792.456,173l-7.791,4.5-10.3,5.944-47.419-27.376,6.132-3.531,7.309,4.225,1.4.8,1.389-.8,7.791-4.508,4.166-2.354-4.166-2.413-40.828-23.539L748.1,102.033Zm0-3.13L704.55,123.948l45.007,25.987-7.791,4.5-8.709-5.026-11.7,6.744,52.963,30.6,11.687-6.744,7.8-4.508,43.547-25.116-89.272-51.55Z"
        transform="translate(-679.64 -98.82)" fill="#96bdd1" />
      <path id="Path_4299" data-name="Path 4299" d="M744.086,194.215l-24.43-13.73,0-31.915,51.59,29.135Z"
        transform="translate(-676.966 -90.017)" stroke="#427894" stroke-miterlimit="10" stroke-width="1"
        fill="url(#linear-gradient-d4)" />
      <path id="Path_4300" data-name="Path 4300" d="M735.257,180.327l-9.627-5.567V161.92l9.627,5.555Z"
        transform="translate(-675.91 -87.655)" fill="#87abbe" />
      <path id="Path_4301" data-name="Path 4301" d="M735.257,184.294l-9.627-5.583v-.421l9.627,5.567Z"
        transform="translate(-675.91 -84.758)" fill="#87abbe" />
      <path id="Path_4304" data-name="Path 4304" d="M744.538,185.983l.4-12.9,9.1,5.427v1.678Z"
        transform="translate(-671.942 -85.68)" fill="#87abbe" />
      <g transform="matrix(1, 0, 0, 1, -10.5, -5.83)" filter="url(#Rectangle_1278)">
        <rect id="Rectangle_1278-2" data-name="Rectangle 1278" width="132" height="37" rx="10"
          transform="translate(10.5 10.5)" fill="#529ec6" />
      </g>
      <text id="D4_Block" data-name="D4 Block" transform="translate(0 12.674)" fill="#fff" font-size="18"
        font-family="Mulish-Regular, Mulish">
        <tspan x="31.102" y="16">D4 Block</tspan>
      </text>
    </g>
  </svg>
</div>