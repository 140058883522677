import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { WebSocketService } from 'src/app/services/web-socket.service';
import { MatDialog } from '@angular/material/dialog';
import { CommentDialogComponent } from './comment-dialog/comment-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorMonitoringService } from '../services/error-monitoring.service';
@Component({
  selector: 'app-view-all-errors',
  templateUrl: './view-all-errors.component.html',
  styleUrls: ['./view-all-errors.component.css']
})
export class ViewAllErrorsComponent {
  response1: { [equipment: string]: any[] } = {};
  

    alarmlog1 : any
    alarmlog :any[]=[]  
    constructor(
      private errorMonitoringService: ErrorMonitoringService,
      private router: Router,
      private http: HttpClient,
      private snackBar: MatSnackBar,
      private webSocketService:WebSocketService,
      public dialog:MatDialog
    ) {
    
    }
  
  ngOnInit(): void {
    console.log("hii")
    
    this.errorMonitoringService.alarms$.subscribe(alarms => {
      this.response1 = alarms;
    });
    // this.alarmlog = this.errorMonitoringService.getAlarms();
    //     if (this.alarmlog.length > 0) {
    //   this.response1 = this.alarmlog;
    // }

  }
  getCurrentTimestamp(): string {
    const now = new Date();
    const isoString = now.toISOString();  // Get ISO string (YYYY-MM-DDTHH:MM:SS.SSSZ)
    const formattedString = isoString.replace('Z', '');  // Remove the trailing 'Z'
    return formattedString;
  }
  isalarmempty() {
    // Check if response1 has any keys
    if (this.response1 && Object.keys(this.response1).length > 0) {
      return false; // Not empty
    } else {
      return true; // Empty
    }
  }
  
  createidentifier(equipment:any){
    const regex = /^(A|B[1-2E]|D[1-4]|E|F)/;
    const match=equipment.match(regex);
    console.log(match);
    const prefix = match[0];
    const suffix = equipment.slice(prefix.length);
    if (suffix.length > 0) {
      // Example: For 'AL2' it becomes 'A/L2', for 'B1GS' it becomes 'B1/G', etc.
      return `${prefix}/${suffix}`;
  }

  return prefix;

  }
  closeAlarm(equipment: string, alarmId: number,alarm:any): void {
    const dialogRef = this.dialog.open(CommentDialogComponent, {
      width: '700px',
      data: { alarmId: alarmId }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {  // If a comment is provided

        console.log('Comment:', result,equipment,alarm);
        const identifier=this.createidentifier(equipment);

        const data={
          identifier:identifier,
          fault_name: alarm.title,
          comment: result,
          closing_time: this.getCurrentTimestamp()
        };
        this.http.post<any>(`${environment.apiBaseUrl}/auth/update_fault`,data).subscribe(
          (response:any)=>{
            this.openSnackBar('Successfully closed the alarm');
            const updatedAlarm = { id: alarmId, isActive: false};
            this.errorMonitoringService.updateAlarm(equipment, updatedAlarm);
          },
          (error:any)=>{
            console.log(error);
            if (error.status === 401) {
              // Redirect to the login page
              this.router.navigate(['/login']); // Adjust the path as necessary
              sessionStorage.removeItem('accessToken');
              this.openSnackBar('Session expired')
  
            }
            this.openSnackBar('please try again');
          }
        )
      }
    });
  }
  
  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: "start",
      duration: 5000
    });
  }
  // closeAlarm(alarmId: number): void {
  //   const alarm = this.alarmlog.find(a => a.id === alarmId);
  //   if (alarm) {
  //     alarm.isActive = false;
  //   }
  // }



 

 
  


  formatTimestamp(timestamp: string): string {
    const date = new Date(timestamp);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
  }



}
