<div class="tunnel-comp">
  <svg id="tunnel-comp" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%"
    height="100%" viewBox="0 0 578.223 443.096">
    <defs>
      <linearGradient id="linear-gradient-t" x1="0.895" y1="0.146" x2="0.184" y2="0.904"
        gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#f5f5f5" />
        <stop offset="1" stop-color="#e4f4fc" />
      </linearGradient>
      <linearGradient id="linear-gradient-t-2" x1="0.5" y1="0.372" x2="0.616" y2="0.776"
        gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#ccdfe8" />
        <stop offset="1" stop-color="#bbced8" />
      </linearGradient>
      <filter id="Path_20320" x="65.439" y="40.495" width="512.784" height="402.601" filterUnits="userSpaceOnUse">
        <feOffset dy="10" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="5" result="blur" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
      <linearGradient id="linear-gradient-t-3" x1="1" y1="0.068" x2="0.402" y2="1.079"
        gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#fff" />
        <stop offset="1" stop-color="#e4f4fc" />
      </linearGradient>
      <linearGradient id="linear-gradient-t-4" x1="0.5" y1="0" x2="0.332" y2="1" xlink:href="#linear-gradient-t-3" />
      <linearGradient id="linear-gradient-t-5" x1="0.5" y1="0" x2="0.259" y2="0.822"
        xlink:href="#linear-gradient-t-3" />
    </defs>
    <g id="Group_6038" data-name="Group 6038" transform="translate(-7907.278 6354.095)">
      <g id="Group_6037" data-name="Group 6037">
        <g id="Group_6036" data-name="Group 6036">
          <path id="Path_20291" data-name="Path 20291" d="M892.812,206.494,841.85,235.918V133.994l50.962-29.424Z"
            transform="translate(7495.328 -6457.613)" fill="#fff" stroke="#000" stroke-miterlimit="10"
            stroke-width="0.2" />
          <path id="Path_20292" data-name="Path 20292" d="M738.839,210.679l-9.639-5.567V192.26l9.639,5.567Z"
            transform="translate(7475.726 -6442.547)" fill="#92a3c3" />
          <path id="Path_20293" data-name="Path 20293" d="M735.257,213.079l-9.627-5.567V194.66l9.627,5.567Z"
            transform="translate(7475.094 -6442.121)" fill="#92a3c3" />
          <path id="Path_20294" data-name="Path 20294" d="M735.257,229.447l-9.627-5.567V211.04l9.627,5.555Z"
            transform="translate(7475.094 -6439.223)" fill="#92a3c3" />
          <path id="Path_20295" data-name="Path 20295" d="M754.587,207.857,744.96,202.3V189.45l9.627,5.567Z"
            transform="translate(7478.515 -6443.043)" fill="#92a3c3" />
          <path id="Path_20296" data-name="Path 20296" d="M754.587,224.239l-9.627-5.567V205.82l9.627,5.567Z"
            transform="translate(7478.515 -6440.146)" fill="#92a3c3" />
          <path id="Path_20297" data-name="Path 20297" d="M754.587,240.607l-9.627-5.567V222.2l9.627,5.555Z"
            transform="translate(7478.515 -6437.248)" fill="#92a3c3" />
          <path id="Path_20298" data-name="Path 20298" d="M827.029,235.3,763.98,271.691v-92.72l63.049-36.391Z"
            transform="translate(7481.88 -6451.338)" fill="#fff" stroke="#000" stroke-miterlimit="10"
            stroke-width="0.2" />
          <path id="Path_20299" data-name="Path 20299" d="M653.929,270.859l-9.639-5.567V252.44l9.639,5.567Z"
            transform="translate(7461.036 -6431.033)" fill="#92a3c3" />
          <path id="Path_20300" data-name="Path 20300" d="M673.259,249.267,663.62,243.7V230.86l9.639,5.555Z"
            transform="translate(7464.456 -6434.852)" fill="#92a3c3" />
          <path id="Path_20301" data-name="Path 20301" d="M673.259,265.637l-9.639-5.555V247.23l9.639,5.567Z"
            transform="translate(7464.456 -6431.955)" fill="#92a3c3" />
          <path id="Path_20302" data-name="Path 20302" d="M673.259,282.019l-9.639-5.567V263.6l9.639,5.567Z"
            transform="translate(7464.456 -6429.058)" fill="#92a3c3" />
          <path id="Path_20303" data-name="Path 20303" d="M749.039,280.41l-63.049,36.4v-92.72l63.049-36.4Z"
            transform="translate(7468.709 -6442.23)" fill="#fff" stroke="#000" stroke-miterlimit="10"
            stroke-width="0.2" />
          <path id="Path_20304" data-name="Path 20304" d="M561.059,327.357l-9.639-5.567V308.95l9.639,5.555Z"
            transform="translate(7444.558 -6421.817)" fill="#92a3c3" />
          <path id="Path_20305" data-name="Path 20305" d="M580.377,305.768,570.75,300.2V287.36l9.627,5.567Z"
            transform="translate(7447.979 -6425.638)" fill="#92a3c3" />
          <path id="Path_20306" data-name="Path 20306" d="M580.377,322.138l-9.627-5.555V303.73l9.627,5.567Z"
            transform="translate(7447.979 -6422.741)" fill="#92a3c3" />
          <path id="Path_20307" data-name="Path 20307" d="M580.377,338.518l-9.627-5.567V320.11l9.627,5.555Z"
            transform="translate(7447.979 -6419.843)" fill="#92a3c3" />
          <path id="Path_20308" data-name="Path 20308" d="M656.079,334.56,593.03,370.951v-92.72l63.049-36.391Z"
            transform="translate(7451.921 -6433.693)" fill="#fff" stroke="#000" stroke-miterlimit="10"
            stroke-width="0.2" />
          <path id="Path_20316" data-name="Path 20316" d="M478.22,430.634l.059-92.414,80.127,46.254-.059,92.414Z"
            transform="translate(7429.399 -6414.426)" fill="#fff" />
          <path id="Path_20317" data-name="Path 20317"
            d="M478.405,338.43l79.833,46.089v92.2l-79.833-46.089Zm-.235-.4v92.72l80.3,46.36v-92.7l-80.3-46.36Z"
            transform="translate(7429.256 -6413.721)" />
          <path id="Path_20318" data-name="Path 20318" d="M480.29,380.018l479-276.548,77.514,44.759-479,276.548Z"
            transform="translate(7429.766 -6455.964)" fill="url(#linear-gradient-t)" />
          <path id="Path_20319" data-name="Path 20319"
            d="M959.7,105.323l74.736,43.147L558.222,423.406,483.5,380.259Zm0-3.213L477.93,380.259l80.292,46.36,154.422-89.155L1039.994,148.47Z"
            transform="translate(7429.348 -6456.205)" fill="#96bdd1" />
          <g transform="matrix(1, 0, 0, 1, 7907.28, -6354.1)" filter="url(#Path_20320)">
            <path id="Path_20320-2" data-name="Path 20320" d="M1028.484,234.22,546.7,512.369v-92.72L1028.484,141.5Z"
              transform="translate(-465.76 -95.14)" stroke="#79a7bf" stroke-miterlimit="10" stroke-width="1"
              fill="url(#linear-gradient-t-2)" />
          </g>
          <path id="Path_20321" data-name="Path 20321" d="M706.71,299.787l38.145-22.127-.177,14.017L706.91,313.5Z"
            transform="translate(7469.83 -6425.141)" fill="#a3c5d6" />
          <path id="Path_20322" data-name="Path 20322" d="M706.71,317.127,744.855,295l-.177,14.017L706.91,330.826Z"
            transform="translate(7469.83 -6422.072)" fill="#a3c5d6" />
          <path id="Path_20323" data-name="Path 20323" d="M706.71,333.667l38.145-22.127-.177,14.017L706.91,347.366Z"
            transform="translate(7469.83 -6419.146)" fill="#a3c5d6" />
          <path id="Path_20324" data-name="Path 20324" d="M706.71,351l38.145-22.127-.177,14.017L706.91,364.7Z"
            transform="translate(7469.83 -6416.08)" fill="#a3c5d6" />
          <path id="Path_20325" data-name="Path 20325" d="M557.04,382.832l24.222-13.782-.165,14.017-23.869,13.464Z"
            transform="translate(7443.346 -6408.97)" fill="#a3c5d6" />
          <path id="Path_20326" data-name="Path 20326" d="M557.04,399.372l24.222-13.782-.165,14.017-23.869,13.464Z"
            transform="translate(7443.346 -6406.043)" fill="#a3c5d6" />
          <path id="Path_20327" data-name="Path 20327" d="M557.04,415.912l24.222-13.782-.165,14.017-23.869,13.464Z"
            transform="translate(7443.346 -6403.116)" fill="#a3c5d6" />
          <path id="Path_20328" data-name="Path 20328" d="M557.04,431.672l24.222-13.782-.165,14.017-23.869,13.464Z"
            transform="translate(7443.346 -6400.328)" fill="#a3c5d6" />
          <path id="Path_20329" data-name="Path 20329" d="M556.891,336.43l-25.657,14.818-16.583-9.58,25.657-14.818Z"
            transform="translate(7435.845 -6416.438)" fill="#a9c8d8" />
          <path id="Path_20330" data-name="Path 20330" d="M541.163,327.35l-25.658,14.818-16.595-9.58,25.669-14.818Z"
            transform="translate(7433.06 -6418.043)" fill="#a9c8d8" />
          <path id="Path_20331" data-name="Path 20331" d="M572.683,345.55l-25.658,14.806-16.6-9.569L556.1,335.97Z"
            transform="translate(7438.638 -6414.823)" fill="#a9c8d8" />
          <path id="Path_20332" data-name="Path 20332" d="M588.472,354.66l-25.657,14.818-16.6-9.58,25.658-14.818Z"
            transform="translate(7441.432 -6413.211)" fill="#a9c8d8" />
          <path id="Path_20333" data-name="Path 20333" d="M581.232,322.379,555.563,337.2l-16.583-9.58,25.658-14.806Z"
            transform="translate(7440.15 -6418.922)" fill="#a9c8d8" />
          <path id="Path_20334" data-name="Path 20334" d="M565.5,313.3l-25.669,14.818-16.583-9.58,25.658-14.818Z"
            transform="translate(7437.367 -6420.529)" fill="#a9c8d8" />
          <path id="Path_20335" data-name="Path 20335" d="M597.011,331.5l-25.658,14.818-16.583-9.58,25.657-14.818Z"
            transform="translate(7442.944 -6417.31)" fill="#a9c8d8" />
          <path id="Path_20336" data-name="Path 20336" d="M612.8,340.609l-25.657,14.818-16.6-9.58,25.669-14.806Z"
            transform="translate(7445.736 -6415.695)" fill="#a9c8d8" />
          <path id="Path_20337" data-name="Path 20337" d="M605.563,308.329,579.9,323.146l-16.595-9.569,25.669-14.818Z"
            transform="translate(7444.455 -6421.408)" fill="#a9c8d8" />
          <path id="Path_20338" data-name="Path 20338" d="M589.832,299.249l-25.657,14.818-16.595-9.58,25.657-14.806Z"
            transform="translate(7441.672 -6423.014)" fill="#a9c8d8" />
          <path id="Path_20339" data-name="Path 20339" d="M621.352,317.45,595.7,332.268l-16.6-9.58,25.658-14.818Z"
            transform="translate(7447.25 -6419.795)" fill="#a9c8d8" />
          <path id="Path_20340" data-name="Path 20340" d="M637.143,326.559l-25.669,14.818L594.89,331.8l25.658-14.806Z"
            transform="translate(7450.044 -6418.182)" fill="#a9c8d8" />
          <path id="Path_20341" data-name="Path 20341" d="M629.892,294.29,604.235,309.1l-16.595-9.569,25.669-14.818Z"
            transform="translate(7448.761 -6423.894)" fill="#a9c8d8" />
          <path id="Path_20342" data-name="Path 20342" d="M614.162,285.2l-25.657,14.818-16.6-9.58,25.669-14.806Z"
            transform="translate(7445.978 -6425.5)" fill="#a9c8d8" />
          <path id="Path_20343" data-name="Path 20343" d="M645.682,303.4l-25.657,14.818-16.6-9.58L629.1,293.82Z"
            transform="translate(7451.555 -6422.281)" fill="#a9c8d8" />
          <path id="Path_20344" data-name="Path 20344" d="M661.473,312.52l-25.658,14.806-16.6-9.569,25.658-14.818Z"
            transform="translate(7454.349 -6420.668)" fill="#a9c8d8" />
          <path id="Path_20345" data-name="Path 20345" d="M654.232,280.24l-25.669,14.818-16.583-9.58,25.658-14.818Z"
            transform="translate(7453.067 -6426.38)" fill="#a9c8d8" />
          <path id="Path_20346" data-name="Path 20346" d="M638.5,271.16l-25.669,14.806L596.25,276.4l25.657-14.818Z"
            transform="translate(7450.284 -6427.986)" fill="#a9c8d8" />
          <path id="Path_20347" data-name="Path 20347" d="M670.012,289.35l-25.657,14.818-16.595-9.58,25.669-14.818Z"
            transform="translate(7455.86 -6424.768)" fill="#a9c8d8" />
          <path id="Path_20348" data-name="Path 20348" d="M685.8,298.47l-25.657,14.818-16.6-9.58,25.669-14.818Z"
            transform="translate(7458.654 -6423.154)" fill="#a9c8d8" />
          <path id="Path_20349" data-name="Path 20349" d="M678.563,266.19,652.9,281.008l-16.595-9.58,25.657-14.818Z"
            transform="translate(7457.373 -6428.866)" fill="#a9c8d8" />
          <path id="Path_20350" data-name="Path 20350" d="M662.833,257.11l-25.669,14.806-16.583-9.569,25.658-14.818Z"
            transform="translate(7454.59 -6430.473)" fill="#a9c8d8" />
          <path id="Path_20351" data-name="Path 20351" d="M694.353,275.3l-25.669,14.818-16.583-9.58,25.658-14.806Z"
            transform="translate(7460.167 -6427.252)" fill="#a9c8d8" />
          <path id="Path_20352" data-name="Path 20352" d="M710.133,284.42l-25.658,14.818-16.595-9.58,25.669-14.818Z"
            transform="translate(7462.959 -6425.641)" fill="#a9c8d8" />
          <path id="Path_20353" data-name="Path 20353" d="M702.892,252.14l-25.657,14.818-16.595-9.58,25.669-14.818Z"
            transform="translate(7461.678 -6431.352)" fill="#a9c8d8" />
          <path id="Path_20354" data-name="Path 20354" d="M687.163,243.06l-25.657,14.818-16.6-9.58,25.669-14.818Z"
            transform="translate(7458.895 -6432.959)" fill="#a9c8d8" />
          <path id="Path_20355" data-name="Path 20355" d="M718.682,261.249l-25.657,14.818-16.6-9.58,25.657-14.806Z"
            transform="translate(7464.472 -6429.738)" fill="#a9c8d8" />
          <path id="Path_20356" data-name="Path 20356" d="M734.473,270.37,708.8,285.188l-16.583-9.58,25.658-14.818Z"
            transform="translate(7467.267 -6428.127)" fill="#a9c8d8" />
          <path id="Path_20357" data-name="Path 20357" d="M727.233,238.09l-25.669,14.818-16.583-9.58,25.658-14.818Z"
            transform="translate(7465.985 -6433.838)" fill="#a9c8d8" />
          <path id="Path_20358" data-name="Path 20358" d="M711.491,229.01l-25.657,14.818-16.583-9.58,25.657-14.818Z"
            transform="translate(7463.201 -6435.445)" fill="#a9c8d8" />
          <path id="Path_20359" data-name="Path 20359" d="M743.012,247.21l-25.657,14.818-16.595-9.58,25.669-14.818Z"
            transform="translate(7468.777 -6432.225)" fill="#a9c8d8" />
          <path id="Path_20360" data-name="Path 20360" d="M758.8,256.32l-25.658,14.818-16.6-9.58,25.669-14.818Z"
            transform="translate(7471.571 -6430.612)" fill="#a9c8d8" />
          <path id="Path_20361" data-name="Path 20361" d="M751.563,224.039l-25.669,14.818-16.583-9.58,25.657-14.806Z"
            transform="translate(7470.29 -6436.322)" fill="#a9c8d8" />
          <path id="Path_20362" data-name="Path 20362" d="M735.832,214.96l-25.669,14.818L693.58,220.2l25.657-14.818Z"
            transform="translate(7467.507 -6437.932)" fill="#a9c8d8" />
          <path id="Path_20363" data-name="Path 20363" d="M767.352,233.16l-25.669,14.818L725.1,238.4l25.658-14.818Z"
            transform="translate(7473.084 -6434.711)" fill="#a9c8d8" />
          <path id="Path_20364" data-name="Path 20364" d="M783.133,242.27l-25.658,14.818-16.595-9.58,25.669-14.818Z"
            transform="translate(7475.877 -6433.099)" fill="#a9c8d8" />
          <path id="Path_20365" data-name="Path 20365" d="M775.892,209.989l-25.657,14.818-16.595-9.58,25.669-14.806Z"
            transform="translate(7474.596 -6438.809)" fill="#a9c8d8" />
          <path id="Path_20366" data-name="Path 20366" d="M760.163,200.91l-25.658,14.818-16.6-9.58,25.658-14.818Z"
            transform="translate(7471.812 -6440.418)" fill="#a9c8d8" />
          <path id="Path_20367" data-name="Path 20367" d="M791.683,219.11l-25.658,14.818-16.6-9.58,25.658-14.818Z"
            transform="translate(7477.39 -6437.197)" fill="#a9c8d8" />
          <path id="Path_20368" data-name="Path 20368" d="M807.472,228.219,781.8,243.036l-16.583-9.58,25.658-14.806Z"
            transform="translate(7480.184 -6435.583)" fill="#a9c8d8" />
          <path id="Path_20369" data-name="Path 20369" d="M800.234,195.95l-25.669,14.806-16.6-9.569,25.669-14.818Z"
            transform="translate(7478.9 -6441.295)" fill="#a9c8d8" />
          <path id="Path_20370" data-name="Path 20370" d="M784.493,186.859l-25.657,14.818-16.6-9.58,25.669-14.806Z"
            transform="translate(7476.117 -6442.902)" fill="#a9c8d8" />
          <path id="Path_20371" data-name="Path 20371" d="M816.012,205.06l-25.657,14.818L773.76,210.3l25.669-14.818Z"
            transform="translate(7481.694 -6439.684)" fill="#a9c8d8" />
          <path id="Path_20372" data-name="Path 20372" d="M831.8,214.18l-25.658,14.806-16.6-9.58L815.207,204.6Z"
            transform="translate(7484.488 -6438.069)" fill="#a9c8d8" />
          <path id="Path_20373" data-name="Path 20373" d="M824.563,181.9l-25.669,14.818-16.583-9.58,25.658-14.818Z"
            transform="translate(7483.208 -6443.781)" fill="#a9c8d8" />
          <path id="Path_20374" data-name="Path 20374" d="M808.832,172.809l-25.669,14.818-16.583-9.58,25.657-14.806Z"
            transform="translate(7480.424 -6445.388)" fill="#a9c8d8" />
          <path id="Path_20375" data-name="Path 20375" d="M840.341,191.01l-25.657,14.818-16.583-9.58,25.658-14.818Z"
            transform="translate(7486.002 -6442.169)" fill="#a9c8d8" />
          <path id="Path_20376" data-name="Path 20376" d="M856.133,200.13l-25.658,14.806-16.595-9.569,25.669-14.818Z"
            transform="translate(7488.794 -6440.555)" fill="#a9c8d8" />
          <path id="Path_20377" data-name="Path 20377" d="M848.892,167.85l-25.657,14.818-16.595-9.58L832.3,158.27Z"
            transform="translate(7487.513 -6446.268)" fill="#a9c8d8" />
          <path id="Path_20378" data-name="Path 20378" d="M833.162,158.77l-25.657,14.818-16.6-9.58,25.658-14.818Z"
            transform="translate(7484.729 -6447.874)" fill="#a9c8d8" />
          <path id="Path_20379" data-name="Path 20379" d="M864.682,176.96l-25.669,14.818L822.43,182.2l25.657-14.818Z"
            transform="translate(7490.307 -6444.655)" fill="#a9c8d8" />
          <path id="Path_20380" data-name="Path 20380" d="M880.473,186.08,854.8,200.9l-16.583-9.58L863.878,176.5Z"
            transform="translate(7493.101 -6443.041)" fill="#a9c8d8" />
          <path id="Path_20381" data-name="Path 20381" d="M873.223,153.8l-25.657,14.818-16.6-9.58,25.669-14.818Z"
            transform="translate(7491.818 -6448.754)" fill="#a9c8d8" />
          <path id="Path_20382" data-name="Path 20382" d="M857.493,144.72l-25.658,14.818-16.6-9.58,25.669-14.818Z"
            transform="translate(7489.034 -6450.359)" fill="#a9c8d8" />
          <path id="Path_20383" data-name="Path 20383" d="M889.013,162.909l-25.658,14.818-16.595-9.569,25.669-14.818Z"
            transform="translate(7494.612 -6447.14)" fill="#a9c8d8" />
          <path id="Path_20384" data-name="Path 20384" d="M904.8,172.03l-25.669,14.818-16.583-9.58,25.657-14.818Z"
            transform="translate(7497.406 -6445.527)" fill="#a9c8d8" />
          <path id="Path_20385" data-name="Path 20385" d="M897.563,139.75l-25.669,14.818-16.583-9.58,25.657-14.818Z"
            transform="translate(7496.125 -6451.24)" fill="#a9c8d8" />
          <path id="Path_20386" data-name="Path 20386" d="M881.832,130.67l-25.669,14.818-16.583-9.58,25.658-14.818Z"
            transform="translate(7493.342 -6452.846)" fill="#a9c8d8" />
          <path id="Path_20387" data-name="Path 20387" d="M913.342,148.87l-25.657,14.806-16.595-9.569,25.669-14.818Z"
            transform="translate(7498.917 -6449.626)" fill="#a9c8d8" />
          <path id="Path_20388" data-name="Path 20388" d="M929.133,157.98,903.475,172.8l-16.595-9.58L912.538,148.4Z"
            transform="translate(7501.711 -6448.014)" fill="#a9c8d8" />
          <path id="Path_20389" data-name="Path 20389" d="M921.893,125.7l-25.657,14.818-16.595-9.58L905.3,116.12Z"
            transform="translate(7500.43 -6453.726)" fill="#a9c8d8" />
          <path id="Path_20390" data-name="Path 20390" d="M906.162,116.62l-25.657,14.818-16.595-9.58,25.657-14.818Z"
            transform="translate(7497.646 -6455.332)" fill="#a9c8d8" />
          <path id="Path_20391" data-name="Path 20391" d="M937.683,134.82l-25.669,14.818-16.583-9.58,25.657-14.818Z"
            transform="translate(7503.225 -6452.111)" fill="#a9c8d8" />
          <path id="Path_20392" data-name="Path 20392" d="M953.462,143.93,927.8,158.748l-16.595-9.58,25.669-14.818Z"
            transform="translate(7506.017 -6450.5)" fill="#a9c8d8" />
          <path id="Path_20393" data-name="Path 20393"
            d="M668.8,317.247c1.741-3.115-13.365-14.872-33.741-26.26s-38.305-18.093-40.046-14.978,13.365,14.872,33.741,26.259S667.061,320.362,668.8,317.247Z"
            transform="translate(7450.042 -6425.568)" fill="url(#linear-gradient-t-3)" />
          <path id="Path_20394" data-name="Path 20394"
            d="M765.807,260.434c1.742-3.114-13.365-14.872-33.741-26.259S693.761,216.08,692.021,219.2s13.365,14.872,33.741,26.26S764.066,263.549,765.807,260.434Z"
            transform="translate(7467.207 -6435.621)" fill="url(#linear-gradient-t-4)" />
          <path id="Path_20395" data-name="Path 20395"
            d="M863.488,204.047c1.741-3.115-13.365-14.872-33.742-26.26s-38.3-18.093-40.046-14.978,13.365,14.871,33.742,26.259S861.746,207.161,863.488,204.047Z"
            transform="translate(7484.491 -6445.6)" fill="url(#linear-gradient-t-5)" />
          <path id="Path_20489" data-name="Path 20489" d="M-4360.283-6382.854l4.366-3.854v10.012l-4.366,3.319Z"
            transform="translate(12420 53.585)" fill="#e4f4fc" stroke="#96bdd1" stroke-width="1" />
        </g>
      </g>
    </g>
  </svg>
</div>