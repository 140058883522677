import { Component } from '@angular/core';

@Component({
  selector: 'Water-mark',
  templateUrl: './water-mark.component.html',
  styleUrls: ['./water-mark.component.css']
})
export class WaterMarkComponent {

}
