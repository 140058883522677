<div style="position: relative;height: 100%;width: 100%;">
  <div
style="
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 5px;
  position: absolute;
  width: 100%;
  height: 100%;
  align-items: flex-end;
"
>
<span class="span-text-dark" style="font-size: 12px;">powered by </span>
<img
  src="/assets/logos/bertlabs-logo.png"
  height="20px"
  width="100px"

/>
</div>
  <div
  style="
    width: 100%;
    height: 100%;
    min-width: 1200px;
    display: flex;
    flex-direction: column;
    position: absolute;
  "
>
<mat-progress-bar mode="indeterminate"  [ngClass]="{'show-progress': progress,'hide-progress':!progress}"></mat-progress-bar>
  <div
    style="
      height: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px;
      align-items: center;
      gap: 10px;
    "
  >
    <div
      style="
        height: 350px;
        width: 1000px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
      "
    >
      <div
        style="
          height: 350px;
          width: 1000px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
        "
      >
        <img
          src="/assets/images/ahu-connections.svg"
          width="1000px"
          height="350px"
        />
      </div>

      <!-- componets -->
      <div style="height: 350px; width: 1000px; position: absolute">

        
        <div
          style="
            height: 70px;
            width: 70px;
            margin-top: 169px;
            margin-left: 455px;
          "
        >
          <Fan [state]="'active'"></Fan>
        </div>
      

        <div
          class="input-group"
          style="width: 100px; margin-top: -183px; margin-left: 146px"
        >
          <img src="/assets/images/temperature-celcius.png" />
          <input readonly [ngModel]="RAT_out | number : '1.2-2'" (ngModelChange)="RAT_out = $event" />
        </div>

        <div
          class="input-group"
          style="width: 100px; margin-top: 6px; margin-left: 146px"
        >
          <img src="/assets/images/percentage.png" />
          <input readonly [ngModel]="RAH_out | number : '1.2-2'" (ngModelChange)="RAH_out = $event" />
        </div>

        <div
          class="input-group"
          style="width: 115px; margin-top: 41px; margin-left: 78px"
        >
          <img src="/assets/images/carbon-dioxide.png" />
          <input readonly [ngModel]="RACO2_out | number : '1.2-2'" (ngModelChange)="RACO2_out = $event" />
          <span>ppm</span>
        </div>

        <div
          class="input-group"
          style="width: 100px; margin-top: 8px; margin-left: 78px"
        >
          <img src="/assets/images/cfm.png" />
          <input readonly [ngModel]="FAF_out | number : '1.2-2'" (ngModelChange)="FAF_out = $event" />
        </div>

        <div
          class="input-group"
          style="width: 100px; margin-top: 31px; margin-left: 288px"
        >
          <img src="/assets/images/percentage.png" />
          <input readonly [ngModel]="CHWVF_out | number : '1.2-2'" (ngModelChange)="CHWVF_out = $event" />
        </div>

        <div
          class="input-group"
          style="width: 100px; margin-top: -208px; margin-left: 376px"
        >
          <img src="/assets/images/percentage.png" />
          <input readonly [ngModel]="VFDF_out | number : '1.2-2'" (ngModelChange)="VFDF_out = $event" />
          <!-- <span>Hz</span> -->
        </div>

        <div
          class="input-group"
          style="width: 110px; margin-top: -30px; margin-left: 503px"
        >
          <img src="/assets/images/power.png" />
          <input readonly [ngModel]="FP_out | number : '1.2-2'" (ngModelChange)="FP_out = $event" />
          <span>kWh</span>
        </div>

        <div
          class="input-group"
          style="width: 100px; margin-top: 14px; margin-left: 702px"
        >
          <img src="/assets/images/temperature-celcius.png" />
          <input readonly [ngModel]="SAT_out | number : '1.2-2'" (ngModelChange)="SAT_out = $event" />
        </div>

        <div
          class="input-group"
          style="width: 110px; margin-top: 23px; margin-left: 635px"
        >
          <img src="/assets/images/differential-pressure-transmitter.png" />
          <input readonly [ngModel]="DP_out | number : '1.2-2'" (ngModelChange)="DP_out = $event" />
          <span>Pa</span>
        </div>

        <div
        
        style="width: 100px; margin-top: -76px;
        margin-left: 0px;"
      >
       <span class="span-text-dark">FRESH <br>AIR</span>
      </div>



         
       
      </div>
    </div>
  </div>

  <div
    style="
      height: auto;
      width: 100%;
      min-width: 1000px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 7px;
      align-items: center;
      gap: 10px;
    "
  >
    <div
      style="
        width: 100%;
        display: flex;
        flex-direction: row;
        height: auto;
        justify-content: center;
      "
    >
      <div style="width: 475px; height: 100%; margin: 0px 20px 0px 50px">
        <div
          class="form"
          style="
            padding: 20px;
            flex-direction: row;
            gap: 30px;
            align-items: start;
          "
        >
          <div
            style="width: 50%; display: flex; flex-direction: column; gap: 10px"
          >
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 12px; font-weight: 500"
                >RAT</span
              >

              <div class="input-group" style="width: 100px">
                <img src="/assets/images/temperature-celcius.png" />
                <input readonly [(ngModel)]="RAT " />
              </div>
            </div>

            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 12px; font-weight: 500"
                >CHW VALVE</span
              >

              <div class="input-group" style="width: 100px">
                <img src="/assets/images/percentage.png" />
                <input readonly [(ngModel)]="CHWVF" />
              </div>
            </div>

            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 12px; font-weight: 500"
                >RRH</span
              >

              <div class="input-group" style="width: 100px">
                <img src="/assets/images/percentage.png" />
                <input readonly [(ngModel)]="RAH" />
              </div>
            </div>

           
          </div>

          <div
            style="
              width: 50%;
              display: flex;
              flex-direction: column;
              gap: 10px;
              justify-content: space-between;
            "
          >
            <div style="display: flex; flex-direction: column; gap: 10px">

              <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
              "
            >
              <span
                class="span-text-dark"
                style="font-size: 12px; font-weight: 500"
                >SAT</span
              >

              <div class="input-group" style="width: 100px">
                <img src="/assets/images/temperature-celcius.png" />
                <input readonly [(ngModel)]="SAT" />
              </div>
            </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <span
                  class="span-text-dark"
                  style="font-size: 12px; font-weight: 500"
                  >FAF</span
                >

                <div class="input-group" style="width: 100px">
                  <img src="/assets/images/cfm.png" />
                  <input readonly [(ngModel)]="FAF" />
                </div>
              </div>

              <!-- <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <span
                  class="span-text-dark"
                  style="font-size: 12px; font-weight: 500"
                  >TD</span
                >

                <div class="input-group" style="width: 100px">
                  <img src="/assets/images/percentage.png" />
                  <input readonly [(ngModel)]="TD" />
                </div>
              </div> -->

              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <span
                  class="span-text-dark"
                  style="font-size: 12px; font-weight: 500"
                  >FAN SPEED</span
                >

                <div class="input-group" style="width: 100px">
                  <img src="/assets/images/percentage.png" />
                  <input readonly [(ngModel)]="VFDF" />
                </div>
              </div>
            </div>

            <div
              style="display: flex; flex-direction: row; width: 100%; gap: 10px;margin-top: 10px;"
            >
              <button
                style="
                  width: 50%;
                  background-color: var(--blue-one);
                  border-radius: 20px;
                  border: none;
                  height: auto;
                  padding: 7px;
                  color: white;
                  cursor: pointer;
                "
                (click)="predictData()"
              >
                Predict
              </button>
              <button
                style="
                  width: 50%;
                  border-radius: 20px;
                  border: none;
                  height: auto;
                  padding: 7px;
                  color: white;
                  cursor: pointer;
                "
                (click)="optimiseData()"
                [ngClass]="{'button-disabled': disableOptimise,'button-enabled':!disableOptimise}"
                [disabled]="disableOptimise"
              >
                Optimise
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- KPI -->



      <div class="form" style="height: 200px; width: 250px;">

        <span class="span-text-dark" style="font-weight: bold;text-align: center;">KEY PERFORMANCE INDICATOR</span>

        <div style="height: 30px;"></div>
        <div
      style="
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      "
    >
      <span
        class="span-text-dark"
        style="font-size: 12px; font-weight: 500"
        >FAN POWER SAVING</span
      >

      <div class="input-group" style="width: 120px">
        <img src="/assets/images/power.png" />
        <input readonly [ngModel]="KPI | number : '1.2-2'" (ngModelChange)="KPI = $event" />
        <span >kWh</span>
      </div>
    </div>
      </div>
      



    </div>
  </div>
</div>
  
</div>




