<div style="position: relative; height: 100%; width: 100%">
    <div class="main_container">
        <div class="content-container">
            <div class="upcontainer">
                <div class="map-container">
                    <img class="fmap" src="/assets/chiller/chillers.svg" />
                    <div class="kpi-form">
                        <span class="kpi-title">KEY PERFORMANCE INDICATOR</span>
                        <div class="form-content">
                          <span class="form-label">Chiller Plant Energy  Saving</span>
                          <div class="input-container">
                            <input readonly 
                              type="text" 
                              class="kpi-input" 
                              [ngModel]="KPI | number : '1.2-2'" 
                              (ngModelChange)="KPI = $event" 
                            />
                          </div>
                          <span class="percentage-symbol">%</span>
                        </div>
                      </div>
                    <div class="component" style="width: 10.5%; height: 6.5%; top: 54%; left: 38.5%; font-size: 120%;">
                        <button style="
                            border-radius: 5px;
                            background-color: #427894;
                            border: none;
                            height: 70%;
                            width: 100%;
                            font-size: 70%;       
                            color: white;
                            cursor: pointer;
                          
                            z-index: 999;
                            " routerLink="../chiller1">
                            CHILLER-1
                        </button>
                    </div>
                    <div class="component" style="width: 10.5%; height: 6.5%; top: 39%; left: 22.5%; font-size: 120%;">
                        <button style="
                            border-radius: 5px;
                            background-color: #427894;
                            border: none;
                            height: 70%;
                            width: 100%;
                            font-size: 70%;       
                            color: white;
                            cursor: pointer;
                          
                            z-index: 999;
                            " routerLink="../chiller2">
                            CHILLER-2
                        </button>
                    </div>
                    <div class="component" style="width: 7.5%; height: 4.5%; top: 30%; left: 11%; font-size: 120%;">
                        <button style="
                            border-radius: 5px;
                            background-color: #427894;
                            border: none;
                            height: 70%;
                            width: 100%;
                            font-size: 70%;       
                            color: white;
                            cursor: pointer;
                          
                            z-index: 999;
                            " routerLink="../chiller3">
                            CHILLER-3
                        </button>
                    </div>
                    <div class="component" style="width: 5.5%; height: 4.5%; top: 19.5%; left: 3.5%; font-size: 120%;">
                        <button style="
                            border-radius: 5px;
                            background-color: #427894;
                            border: none;
                            height: 70%;
                            width: 100%;
                            font-size: 70%;       
                            color: white;
                            cursor: pointer;
                          
                            z-index: 999;
                            " routerLink="../chiller4">
                            CHILLER-4
                        </button>
                    </div>
                    <!-- <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 14%; left: 73%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #00A578; font-weight:200">WC.Chiller1 Power</span>
                    </div> -->
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 13%; left: 70%; width: 5%; height: 3%;">
                        <input readonly style="color:#293AC6; font-weight:600" [ngModel]=" C1_POWER_env | number: '1.2-2'"
                            (ngModelChange)=" C1_POWER_env = $event" />
                        <span style="font-size: 80%; color: #293AC6; font-weight:600">kw</span>
                    </div>

                    <!-- <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 10.5%; left: 73%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200">WC.Chiller1 Power</span>
                    </div> -->
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 10.5%; left: 70%; width: 5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" C1_POWER_RED | number: '1.2-2'"
                            (ngModelChange)=" C1_POWER_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">kw</span>
                    </div>

                    <!-- <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 18%; left: 73%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200">WC.Ch.w set point</span>
                    </div> -->
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 16.5%; left: 70%; width: 4.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" C1_W_SP | number: '1.2-2'"
                            (ngModelChange)=" C1_W_SP = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">°C</span>
                    </div>
                    <div class="input-groupR  component"
                    style="display:flex; justify-content:center; align-items:center; top: 18.9%; left: 70%; width: 4.5%; height: 3%;">
                    <input readonly style="color: #00a578; font-weight:600" [ngModel]=" C1_W_SP_opti | number: '1.2-2'"
                        (ngModelChange)=" C1_W_SP = $event" />
                    <span style="font-size: 80%; color: #00a578; font-weight:600">°C</span>
                </div>
                    <!-- <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 10.5%; left: 48%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #00A578; font-weight:200">WC.Chiller2 Power</span>
                    </div> -->
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 8.5%; left: 48%; width: 5%; height: 3%;">
                        <input readonly style="color:#293AC6; font-weight:600" [ngModel]=" C2_POWER_env | number: '1.2-2'"
                            (ngModelChange)=" C2_POWER_env = $event" />
                        <span style="font-size: 80%; color: #293AC6; font-weight:600">kw</span>
                    </div>

                    <!-- <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 7%; left: 48%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200">WC.Chiller2 Power</span>
                    </div> -->
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 6%; left: 48%; width: 5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" C2_POWER_RED | number: '1.2-2'"
                            (ngModelChange)=" C2_POWER_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">kw</span>
                    </div>

                    <!-- <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 14.5%; left: 48%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200">WC.Ch.w set point</span>
                    </div> -->
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 12.3%; left: 48%; width: 4.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" C2_W_SP | number: '1.2-2'"
                            (ngModelChange)=" C2_W_SP = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">°C</span>
                    </div>

                    <div class="input-groupR  component"
                    style="display:flex; justify-content:center; align-items:center; top: 14.7%; left: 48%; width: 4.5%; height: 3%;">
                    <input readonly style="color: #00a578; font-weight:600" [ngModel]=" C2_W_SP_opti | number: '1.2-2'"
                        (ngModelChange)=" C2_W_SP_opti = $event" />
                    <span style="font-size: 80%; color: #00a578; font-weight:600">°C</span>
                </div>

                    <!-- <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 7%; left: 24%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #00A578; font-weight:200">WC.Chiller3 Power</span>
                    </div> -->
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 7%; left: 29%; width: 5%; height: 3%;">
                        <input readonly style="color:#293AC6; font-weight:600" [ngModel]=" C3_POWER_env | number: '1.2-2'"
                            (ngModelChange)=" C3_POWER_env = $event" />
                        <span style="font-size: 80%; color: #293AC6; font-weight:600">kw</span>
                    </div>

                    <!-- <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 3.5%; left: 24%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200">WC.Chiller3 Power</span>
                    </div> -->
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 4.6%; left: 29%; width: 5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" C3_POWER_RED | number: '1.2-2'"
                            (ngModelChange)=" C3_POWER_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">kw</span>
                    </div>

                    <!-- <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 11%; left: 24%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200">WC.Ch.w set point</span>
                    </div> -->
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 10.5%; left: 29%; width: 4.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" C3_W_SP | number: '1.2-2'"
                            (ngModelChange)=" C3_W_SP = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">°C</span>
                    </div>

                    <div class="input-groupR  component"
                    style="display:flex; justify-content:center; align-items:center; top: 13%; left: 29%; width: 4.5%; height: 3%;">
                    <input readonly style="color: #00a578; font-weight:600" [ngModel]=" C3_W_SP_opti | number: '1.2-2'"
                        (ngModelChange)=" C3_W_SP_opti = $event" />
                    <span style="font-size: 80%; color: #00a578; font-weight:600">°C</span>
                </div>
                    <!-- <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 3.5%; left: 0%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #00A578; font-weight:200">WC.Chiller4 Power</span>
                    </div> -->
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 1.8%; left: 14%; width: 5%; height: 3%;">
                        <input readonly style="color:#293AC6; font-weight:600" [ngModel]=" C4_POWER_env | number: '1.2-2'"
                            (ngModelChange)=" C4_POWER_env = $event" />
                        <span style="font-size: 80%; color: #293AC6; font-weight:600">kw</span>
                    </div>

                    <!-- <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 0%; left: 0%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200">WC.Chiller4 Power</span>
                    </div> -->
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: -0.8%; left: 14%; width: 5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" C4_POWER_RED | number: '1.2-2'"
                            (ngModelChange)=" C4_POWER_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">kw</span>
                    </div>
<!-- 
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 7%; left: 0%; width: 13.5%; height: 3%;">
                        <span style="font-size: 80%; color: #D90606; font-weight:200">WC.Ch.w set point</span>
                    </div> -->

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 5%; left: 14%; width: 4.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" C4_W_SP | number: '1.2-2'"
                            (ngModelChange)=" C4_W_SP = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">°C</span>
                    </div>

                    <div class="input-groupR  component"
                    style="display:flex; justify-content:center; align-items:center; top: 7.2%; left: 14%; width: 4.5%; height: 3%;">
                    <input readonly style="color: #00a578; font-weight:600" [ngModel]=" C4_W_SP_opti | number: '1.2-2'"
                        (ngModelChange)=" C4_W_SP_opti = $event" />
                    <span style="font-size: 80%; color: #00a578; font-weight:600">°C</span>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>