<div class="main-container">
  <div class="sidebar">
    <div class="menu-items">
      <div class="menu-item" routerLink="../command">AHU Command</div>
      <div class="menu-item" routerLink="../pid-tuning">PID Tuning Command </div>
      <div class="menu-item" routerLink="../chillerplant-command">Chiller Plant Command</div>
      <div class="menu-item" routerLink="../ahuschedule-command">AHU Schedule Command</div>
    </div>
  </div>
  <app-loading-spinner *ngIf="isloading" ></app-loading-spinner>

  <div class="content">
    <div class="command-section">
      <h2>PID TUNING COMMAND</h2>
      <div class="command-input-container">
        <input  [(ngModel)]="command" placeholder="Please enter Command key!" type="password" class="password-input" />
        <button class="show-command-btn" (click)="showCommand()">Show Command</button>
      </div>
      <div *ngIf="errorMessage" class="error-message" >{{errorMessage}}</div>

      <div *ngIf="showDetails">
        <p>You selected: {{selectedBlock}} - {{ selectedAHU }}</p>
        <div class="form-row">
          <label>BLOCK</label>
          <select [(ngModel)]="selectedBlock">
            <option *ngFor="let block of blocks">{{block}}</option>
          </select>
        </div>

        

        <div class="form-row">
          <label>FLOOR</label>
          <select [(ngModel)]="selectedAHU">
            <option *ngFor="let ahu of ahus[blockahumap.get(selectedBlock) ?? 0]">{{ahu}}</option>
          </select>
        </div>

        <div class="form-row">
          <label>PID Loop</label>
          <select [(ngModel)]="loopname">
            <option *ngFor="let name of loopnames">{{ name }}</option>
          </select>
        </div>

        <div class="form-row">
          <label>BODY:-</label>
          <!-- <select [(ngModel)]="selectedAHU">
            <option *ngFor="let ahu of ahus">{{ ahu }}</option>
          </select> -->
        </div>

        <div class="form-row">
          <label>Set Point</label>
          <input  type="number"  [(ngModel)]="set_point"  />
        </div>

        <div class="form-row">
          <label>Min Limit</label>
          <input  type="number" [(ngModel)]="min_limit"  />
        </div>

        <div class="form-row">
          <label>Max Limit</label>
          <input  type="number" [(ngModel)]="max_limit"  />
        </div>

        <button (click)="sendCommand()" >Send</button>
        <p class="waiting-text" *ngIf="isLoading">Waiting for the Command!</p>
        <p *ngIf="responseMessage"  
        [ngClass]="{
          'response-message': responseMessage.startsWith('Command sent successfully!!'), 
          'error-message': !responseMessage.startsWith('Command sent successfully!!')
        }">
       {{responseMessage}}
     </p>
    </div>
  </div>
</div>