import { Component,Input } from '@angular/core';

@Component({
  selector: 'Fan',
  templateUrl: './fan.component.html',
  styleUrls: ['./fan.component.css']
})
export class FanComponent {
  @Input() state!:string;
  urlActive: string;
  urlInActive: string;
  urlTrip: string;
  constructor(){
    this.state='inActive'
    this.urlActive="/assets/bert-components/bg_1.gif";
    this.urlInActive="/assets/bert-components/inactive-fan.svg";
    this.urlTrip="/assets/bert-components/trip-fan.svg"
  }

}
