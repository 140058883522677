import { Component } from '@angular/core';

@Component({
  selector: 'app-ul-chiller-plant',
  templateUrl: './ul-chiller-plant.component.html',
  styleUrls: ['./ul-chiller-plant.component.css']
})
export class UlChillerPlantComponent {

}
