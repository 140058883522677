<div class="d-three-comp">
  <svg id="d-three-comp" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%"
    height="100%" viewBox="0 0 214.293 163.279">
    <defs>
      <linearGradient id="linear-gradient-d3" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#ebf7fd" />
        <stop offset="1" stop-color="#8ea6b2" />
      </linearGradient>
      <linearGradient id="linear-gradient-d3-2" x1="0.809" y1="0.617" x2="0.653" y2="1"
        gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#fff" />
        <stop offset="1" stop-color="#e4f4fc" />
      </linearGradient>
      <filter id="Rectangle_1275" x="22.384" y="36.772" width="159" height="64" filterUnits="userSpaceOnUse">
        <feOffset dx="3" dy="3" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="4.5" result="blur" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Component_74_4" data-name="Component 74 – 4" transform="translate(0.5)">
      <g id="Group_3625" data-name="Group 3625" transform="translate(-13357.572 3488.92)">
        <path id="Path_4309" data-name="Path 4309" d="M646.609,254.024,558.16,202.46h0V125.82l86.282,49.82Z"
          transform="translate(12799.412 -3589.071)" stroke="#427894" stroke-miterlimit="10" stroke-width="1"
          fill="url(#linear-gradient-d3)" />
        <path id="Path_4310" data-name="Path 4310" d="M576.749,160.877l-9.639-5.567V142.47l9.639,5.555Z"
          transform="translate(12800.996 -3586.125)" fill="#87abbe" />
        <path id="Path_4311" data-name="Path 4311" d="M576.749,177.247l-9.639-5.555V158.84l9.639,5.567Z"
          transform="translate(12800.996 -3583.228)" fill="#87abbe" />
        <path id="Path_4312" data-name="Path 4312" d="M576.749,193.629l-9.639-5.567V175.21l9.639,5.567Z"
          transform="translate(12800.996 -3580.331)" fill="#87abbe" />
        <path id="Path_4313" data-name="Path 4313" d="M576.749,202.042l-9.639-5.618V191.59l9.639,5.555Z"
          transform="translate(12800.996 -3577.433)" fill="#87abbe" />
        <path id="Path_4314" data-name="Path 4314" d="M596.079,172.038l-9.639-5.567V153.63l9.639,5.555Z"
          transform="translate(12804.417 -3584.15)" fill="#87abbe" />
        <path id="Path_4315" data-name="Path 4315" d="M596.079,188.408l-9.639-5.555V170l9.639,5.567Z"
          transform="translate(12804.417 -3581.253)" fill="#87abbe" />
        <path id="Path_4316" data-name="Path 4316" d="M596.079,204.789l-9.639-5.567V186.37l9.639,5.567Z"
          transform="translate(12804.417 -3578.356)" fill="#87abbe" />
        <path id="Path_4317" data-name="Path 4317" d="M596.079,213.314l-9.639-5.585v-4.98l9.639,5.555Z"
          transform="translate(12804.417 -3575.458)" fill="#87abbe" />
        <path id="Path_4318" data-name="Path 4318" d="M615.409,183.2l-9.639-5.567V164.79l9.639,5.555Z"
          transform="translate(12807.837 -3582.175)" fill="#87abbe" />
        <path id="Path_4319" data-name="Path 4319" d="M615.409,199.568l-9.639-5.555V181.16l9.639,5.567Z"
          transform="translate(12807.837 -3579.278)" fill="#87abbe" />
        <path id="Path_4320" data-name="Path 4320" d="M615.409,215.949l-9.639-5.567V197.53l9.639,5.567Z"
          transform="translate(12807.837 -3576.382)" fill="#87abbe" />
        <path id="Path_4321" data-name="Path 4321" d="M615.409,224.812l-9.639-10.9,9.639,5.555Z"
          transform="translate(12807.837 -3573.483)" fill="#87abbe" />
        <path id="Path_4322" data-name="Path 4322"
          d="M610.289,180.081l8.909-5.143,16.595,9.58,10.581-6.108L560.61,128.9l40.746-23.515,167.351,96.628-60.225,34.767Z"
          transform="translate(12799.846 -3592.688)" fill="url(#linear-gradient-d3-2)" />
        <path id="Path_4323" data-name="Path 4323"
          d="M601.762,107.223,766.347,202.25,727,224.965l-7.8,4.5-10.31,5.885-95.415-55.081,6.132-3.531,15.206,8.78,1.389.8,1.4-.8,7.791-4.5,4.166-2.413-4.155-2.377L563.84,129.15,601.8,107.235Zm.035-3.2L558.25,129.15l85.764,49.514-7.8,4.508-16.595-9.592-11.687,6.756L708.9,238.606l11.7-6.744,7.791-4.508,43.547-25.116L601.762,104.01Z"
          transform="translate(12799.429 -3592.93)" fill="#96bdd1" />
        <path id="Path_4324" data-name="Path 4324" d="M654.153,255.144,601.14,224.387h0V169.38l100.818,58.212Z"
          transform="translate(12807.018 -3581.363)" stroke="#427894" stroke-miterlimit="10" stroke-width="1"
          fill="url(#linear-gradient-d3)" />
        <path id="Path_4325" data-name="Path 4325" d="M615.269,199.408l-9.639-5.555V181l9.639,5.567Z"
          transform="translate(12807.813 -3579.307)" fill="#87abbe" />
        <path id="Path_4326" data-name="Path 4326" d="M615.269,215.788l-9.639-5.567V197.38l9.639,5.555Z"
          transform="translate(12807.813 -3576.408)" fill="#87abbe" />
        <path id="Path_4327" data-name="Path 4327" d="M615.492,224.76l-9.862-5.65v-5.36l9.639,5.567Z"
          transform="translate(12807.813 -3573.511)" fill="#87abbe" />
        <path id="Path_4329" data-name="Path 4329" d="M634.6,210.579l-9.639-5.567V192.16l9.639,5.567Z"
          transform="translate(12811.232 -3577.332)" fill="#87abbe" />
        <path id="Path_4330" data-name="Path 4330" d="M634.6,226.947l-9.639-5.567V208.54L634.6,214.1Z"
          transform="translate(12811.232 -3574.433)" fill="#87abbe" />
        <path id="Path_4331" data-name="Path 4331" d="M634.6,235.918l-9.639-5.626V224.91l9.639,5.567Z"
          transform="translate(12811.232 -3571.537)" fill="#87abbe" />
        <path id="Path_4333" data-name="Path 4333" d="M653.929,221.739l-9.639-5.567V203.32l9.639,5.567Z"
          transform="translate(12814.653 -3575.357)" fill="#87abbe" />
        <path id="Path_4334" data-name="Path 4334" d="M653.929,238.107l-9.639-5.567V219.7l9.639,5.555Z"
          transform="translate(12814.653 -3572.458)" fill="#87abbe" />
        <path id="Path_4335" data-name="Path 4335" d="M646.534,242.779l-2.244-1.288V236.07l6.93,3.982Z"
          transform="translate(12814.653 -3569.562)" fill="#87abbe" />
        <path id="Path_4337" data-name="Path 4337" d="M670.12,230.992l-6.5-3.66V214.48l9.639,5.567v9.2Z"
          transform="translate(12818.073 -3573.382)" fill="#87abbe" />
        <path id="Path_4338" data-name="Path 4338" d="M663.62,231.893V230.86l.948.493Z"
          transform="translate(12818.073 -3570.484)" fill="#87abbe" />
      </g>
      <g transform="matrix(1, 0, 0, 1, -0.5, 0)" filter="url(#Rectangle_1275)">
        <rect id="Rectangle_1275-2" data-name="Rectangle 1275" width="132" height="37" rx="10"
          transform="translate(32.88 47.27)" fill="#529ec6" />
      </g>
      <text id="D3_Block" data-name="D3 Block" transform="translate(32.384 55.272)" fill="#fff" font-size="18"
        font-family="Mulish-Regular, Mulish">
        <tspan x="31.172" y="16">D3 Block</tspan>
      </text>
    </g>
  </svg>
</div>