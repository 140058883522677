<mat-progress-bar mode="indeterminate" [ngClass]="{ 'show-progress': progress, 'hide-progress': !progress }"
  style="width: 100%"></mat-progress-bar>

<div style="display: flex; flex-direction: row; height: 99%; width: 100%; position: relative;">
  <!-- <Water-mark></Water-mark> -->
  <div #widgetsContent style="
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      overflow-y: scroll;
      overflow-x: scroll;
      scroll-behavior: smooth;
      display: flex;
      overflow-x: 100px;
      scroll-snap-points-x: 200px;
      flex-direction: column;
    ">
    <!-- chiller plant connections slab-->

    <div style="width: 1100px; height: 600px; position: absolute; scale: 0.9">
      <img src="../../../assets/images/chiller-plant-hotel(2).svg" alt="connections" height="600px" width="1200px" />
      <img src="../../../assets/chiller/chw.svg" alt="connections" height="100px" width="150px"
        style="margin-top: -625px; margin-left: 755px; position: absolute;" />
    </div>



    <Cooling-tower
      style="width: 90px; height: 450px; position: absolute; margin-top: -150px; margin-left: 190px;"></Cooling-tower>
    <Cooling-tower
      style="width: 90px; height: 450px; position: absolute; margin-top: -150px; margin-left: 299px;"></Cooling-tower>
    <Cooling-tower
      style="width: 90px; height: 450px; position: absolute; margin-top: -150px; margin-left: 408px;"></Cooling-tower>
    <Cooling-tower
      style="width: 90px; height: 450px; position: absolute; margin-top: -150px; margin-left: 516px;"></Cooling-tower>

    <Chiller style="width: 92px; height: 450px; position: absolute; margin-top: 7px; margin-left: 270px;"></Chiller>
    <Chiller style="width: 92px; height: 450px; position: absolute; margin-top: 7px; margin-left: 443px;"></Chiller>
    <Chiller style="width: 93px; height: 450px; position: absolute; margin-top: 96px; margin-left: 269px;"></Chiller>
    <Chiller style="width: 92px; height: 450px; position: absolute; margin-top: 96px; margin-left: 443px;"></Chiller>

    <Primary-pump
      style="width: 60px; height: 450px; position: absolute; margin-top: 255px; margin-left: 190px; transform: rotate(-180deg)"></Primary-pump>
    <Primary-pump
      style="width: 60px; height: 450px; position: absolute; margin-top: 255px; margin-left: 259px; transform: rotate(-180deg)"></Primary-pump>
    <Primary-pump
      style="width: 60px; height: 450px; position: absolute; margin-top: 255px; margin-left: 329px; transform: rotate(-180deg)"></Primary-pump>
    <Primary-pump
      style="width: 60px; height: 450px; position: absolute; margin-top: 255px; margin-left: 397px; transform: rotate(-180deg)"></Primary-pump>

    <Condensor-pump
      style="width: 48px; height: 450px; position: absolute; margin-top: -80.2px; margin-left: 931px;"></Condensor-pump>
    <Condensor-pump
      style="width: 48px; height: 450px; position: absolute; margin-top: -80.2px; margin-left: 829px;"></Condensor-pump>
    <Condensor-pump
      style="width: 48px; height: 450px; position: absolute; margin-top: -80.2px; margin-left: 880px;"></Condensor-pump>
    <Condensor-pump
      style="width: 48px; height: 450px; position: absolute; margin-top: -80.2px; margin-left: 981px;"></Condensor-pump>

    <Secondary-pump
      style="width: 48px; height: 450px; position: absolute; margin-top: 10px; margin-left: 757px; transform: rotate(-90deg)"></Secondary-pump>
    <Secondary-pump
      style="width: 48px; height: 450px; position: absolute; margin-top: 10px; margin-left: 858px; transform: rotate(-90deg)"></Secondary-pump>
    <Secondary-pump
      style="width: 48px; height: 450px; position: absolute; margin-top: 10px; margin-left: 960px; transform: rotate(-90deg)"></Secondary-pump>
    <Secondary-pump
      style="width: 48px; height: 450px; position: absolute; margin-top: 66px; margin-left: 757px; transform: rotate(-90deg)"></Secondary-pump>
    <Secondary-pump
      style="width: 48px; height: 450px; position: absolute; margin-top: 66px; margin-left: 858px; transform: rotate(-90deg)"></Secondary-pump>
    <Secondary-pump
      style="width: 48px; height: 450px; position: absolute; margin-top: 66px; margin-left: 960px; transform: rotate(-90deg)"></Secondary-pump>
    <Secondary-pump
      style="width: 48px; height: 450px; position: absolute; margin-top: 120px; margin-left: 757px; transform: rotate(-90deg)"></Secondary-pump>
    <Secondary-pump
      style="width: 48px; height: 450px; position: absolute; margin-top: 120px; margin-left: 858px; transform: rotate(-90deg)"></Secondary-pump>
    <Secondary-pump
      style="width: 48px; height: 450px; position: absolute; margin-top: 120px; margin-left: 960px; transform: rotate(-90deg)"></Secondary-pump>
    <!-- buttons for 5 sections -->

    <div style="display:flex; margin-top: -70px; margin-left: -240px;">
      <button style="
          border-radius: 10px;
          background-color: var(--blue-one);
          border: none;
          height: auto;
          height: 30px;
          width: 100px;
          color: white;
          cursor: pointer;
          margin-top: 200px;
          margin-left: 595px;
          z-index: 999;
          " routerLink="../cooling-tower">
        Cooling Tower
      </button>
    </div>

    <div style="display:flex; margin-top: -70px; margin-left: -240px;">
      <button style="
          border-radius: 10px;
          background-color: var(--blue-one);
          border: none;
          height: auto;
          height: 30px;
          width: 100px;
          color: white;
          cursor: pointer;
          margin-top: 270px;
          margin-left: 600px;
          z-index: 999;
          " routerLink="../chiller">
        Chiller
      </button>
    </div>

    <div style="display:flex; margin-top: -70px; margin-left: -240px;">
      <button style="
          border-radius: 10px;
          background-color: var(--blue-one);
          border: none;
          height: auto;
          height: 30px;
          width: 120px;
          color: white;
          cursor: pointer;
          margin-top: -240px;
          margin-left: 1095px;
          z-index: 999;
          " routerLink="../condenser-pump">
        Condenser Pump
      </button>
    </div>

    <div style="display:flex; margin-top: -70px; margin-left: -240px;">
      <button style="
          border-radius: 10px;
          background-color: var(--blue-one);
          border: none;
          height: auto;
          height: 30px;
          width: 100px;
          color: white;
          cursor: pointer;
          margin-top: 175px;
          margin-left: 525px;
          z-index: 999;
          " routerLink="../primary-pump">
        Primary Pump
      </button>
    </div>

    <div style="display:flex; margin-top: -235px; margin-left: 300px;">
      <button style="
          border-radius: 10px;
          background-color: var(--blue-one);
          border: none;
          height: auto;
          height: 30px;
          width: 120px;
          color: white;
          cursor: pointer;
          margin-top: 195px;
          margin-left: 515px;
          z-index: 999;
          " routerLink="../secondary-pump">
        Secondary Pump
      </button>
    </div>

    <!-- chiller-plant-component-slab -->
    <div style="width: 1100px; height: 600px; position: absolute; scale: 0.9">


      <div class="input-group" style="width: 100px; margin-top: 472px; margin-left: 690px">
        <img src="/assets/images/differential-pressure-transmitter.png" />
        <input readonly [ngModel]="Diff_Pressure_Sec_Pump_Zone_A_out | number : '1.2-2'"
          (ngModelChange)="Diff_Pressure_Sec_Pump_Zone_A_out = $event" />
        <span>Pa</span>
      </div>

      <div class="input-group" style="
          width: 100px;
          margin-left: 800px;
          width: 100px;
          margin-top: -30px;
        ">
        <img src="/assets/images/differential-pressure-transmitter.png" />
        <input readonly [ngModel]="Diff_Pressure_Sec_Pump_Zone_B_out | number : '1.2-2'"
          (ngModelChange)="Diff_Pressure_Sec_Pump_Zone_B_out = $event" />
        <span>Pa</span>
      </div>

      <div class="input-group" style="
          width: 100px;
          width: 100px;
          margin-top: -31px;
          margin-left: 910px;
        ">
        <img src="/assets/images/differential-pressure-transmitter.png" />
        <input readonly [ngModel]="Diff_Pressure_Sec_Pump_Zone_C_out | number : '1.2-2'"
          (ngModelChange)="Diff_Pressure_Sec_Pump_Zone_C_out = $event" />
        <span>Pa</span>
      </div>

      <div class="input-group" style="width: 80px; margin-top: -115px; margin-left: 741px">
        <img src="/assets/images/temperature-celcius.png" />
        <input readonly [ngModel]="Supply_temp_After_Sec_Pump_Zone_A_out | number : '1.2-2'"
          (ngModelChange)="Supply_temp_After_Sec_Pump_Zone_A_out = $event" />
      </div>


      <div class="input-group" style="width: 80px; margin-top: -29px; margin-left: 852px">
        <img src="/assets/images/temperature-celcius.png" />
        <input readonly [ngModel]="Supply_temp_After_Sec_Pump_Zone_B_out | number : '1.2-2'"
          (ngModelChange)="Supply_temp_After_Sec_Pump_Zone_B_out = $event" />
      </div>

      <div class="input-group" style="width: 80px; margin-top: -31px; margin-left: 961px">
        <img src="/assets/images/temperature-celcius.png" />
        <input readonly [ngModel]="Supply_temp_After_Sec_Pump_Zone_C_out | number : '1.2-2'"
          (ngModelChange)="Supply_temp_After_Sec_Pump_Zone_C_out = $event" />
      </div>

      <div class="input-group" style="width: 90px; margin-top: 120px; margin-left: 145px">
        <img src="/assets/images/percentage.png" />
        <input readonly [ngModel]="VFD_Feedback_Pr_Pump_1_out | number : '1.2-2'"
          (ngModelChange)="VFD_Feedback_Pr_Pump_1_out = $event" />
      </div>

      <div class="input-group" style="width: 90px; margin-top: -30px; margin-left: 238px">
        <img src="/assets/images/percentage.png" />
        <input readonly [ngModel]="VFD_Feedback_Pr_Pump_2_out | number : '1.2-2'"
          (ngModelChange)="VFD_Feedback_Pr_Pump_2_out = $event" />
      </div>

      <div class="input-group" style="width: 80px; margin-top: -45px; margin-left: 556px">
        <img src="/assets/images/temperature-celcius.png" />
        <input readonly [ngModel]="
            Common_Header_Return_temp_After_Decoupler_Line_out
              | number : '1.2-2'
          " (ngModelChange)="
            Common_Header_Return_temp_After_Decoupler_Line_out = $event
          " />
      </div>

      <div class="input-group" style="width: 80px; margin-top: 45px; margin-left: 605px">
        <img src="/assets/images/temperature-celcius.png" />
        <input readonly [ngModel]="
            Common_Header_Return_temp_Before_Decoupler_Line_out
              | number : '1.2-2'
          " (ngModelChange)="
            Common_Header_Return_temp_Before_Decoupler_Line_out = $event
          " />
      </div>

      <div class="input-group" style="width: 80px; margin-top: -340px; margin-left: 605px">
        <img src="/assets/images/temperature-celcius.png" />
        <input readonly [ngModel]="
          Common_Header_Supply_temp_After_Decoupler_Line_out
              | number : '1.2-2'
          " (ngModelChange)="
          Common_Header_Supply_temp_After_Decoupler_Line_out = $event
          " />
      </div>

      <div class="input-group" style="width: 80px; margin-top: 5px;
        margin-left: 555px;">
        <img src="/assets/images/temperature-celcius.png" />
        <input readonly [ngModel]="
          Common_Header_Supply_temp_Before_Decoupler_Line_out
              | number : '1.2-2'
          " (ngModelChange)="
          Common_Header_Supply_temp_Before_Decoupler_Line_out = $event
          " />
      </div>



      <div class="input-group" style="width: 90px; margin-top: -13px; margin-left: 697px;">
        <img src="/assets/images/percentage.png" />
        <input readonly [ngModel]="VFD_Feedback_Zone_A_Sec_Pump_1_out | number : '1.2-2'"
          (ngModelChange)="VFD_Feedback_Zone_A_Sec_Pump_1_out = $event" />
      </div>
      <div class="input-group" style="width: 90px; margin-top: -95px; margin-left: 697px">
        <img src="/assets/images/percentage.png" />
        <input readonly [ngModel]="VFD_Feedback_Zone_A_Sec_Pump_2_out | number : '1.2-2'"
          (ngModelChange)="VFD_Feedback_Zone_A_Sec_Pump_2_out = $event" />
      </div>

      <div class="input-group" style="width: 90px; margin-top: 25px; margin-left: 817px">
        <img src="/assets/images/percentage.png" />
        <input readonly [ngModel]="VFD_Feedback_Zone_B_Sec_Pump_1_out | number : '1.2-2'"
          (ngModelChange)="VFD_Feedback_Zone_B_Sec_Pump_1_out = $event" />
      </div>
      <div class="input-group" style="width: 90px; margin-top: -30px; margin-left: 930px">
        <img src="/assets/images/percentage.png" />
        <input readonly [ngModel]="VFD_Feedback_Zone_C_Sec_Pump_1_out | number : '1.2-2'"
          (ngModelChange)="VFD_Feedback_Zone_C_Sec_Pump_1_out = $event" />
      </div>

      <!-- chiller power saving   -->
      <div class="input-group" style="width: 120px; margin-top: -96px;
      margin-left: 140px;">
        <img src="/assets/images/power.png" />
        <input readonly [ngModel]="Chilled_water_Power_out | number : '1.2-2'"
          (ngModelChange)="Chilled_water_Power_out = $event" />
        <span>kWh</span>
      </div>

      <!-- secondary pump power saving  -->
      <div class="input-group" style="width: 120px; margin-top: -15px;
      margin-left: 1070px;">
        <img src="/assets/images/power.png" />
        <input readonly [ngModel]="Total_Sec_Pump_Power_out | number : '1.2-2'"
          (ngModelChange)="Total_Sec_Pump_Power_out = $event" />
        <span>kWh</span>
      </div>


      <!-- Chiller water saving  capsule-->
      <div class="input-group" style="width: 80px; margin-top: -298px;
        margin-left: 905px;">
        <img src="/assets/images/temperature-celcius.png" />
        <input readonly [ngModel]="
          Chilled_Water_Set_Point_out
              | number : '1.2-2'
          " (ngModelChange)="
          Chilled_Water_Set_Point_out = $event
          " />
      </div>


      <!-- <div style="
      height: 30px;
      width: 30px;
      margin-top: 49px;
    margin-left: 622px;
    transform: rotate(90deg)
    ">
      <Valve></Valve>
      </div> -->

      <!-- <div class="field" style="margin-top: 0px;
        margin-left: 0px;"><span>{{VFD_Feedback_Zone_A_Sec_Pump_3_out| number : '1.2-2'}}</span></div> -->
      <!-- <div class="field"><span>{{VFD_Feedback_Zone_B_Sec_Pump_2_out| number : '1.2-2'}}</span></div>
      <div class="field"><span>{{VFD_Feedback_Zone_B_Sec_Pump_3_out| number : '1.2-2'}}</span></div>
      <div class="field"><span>{{VFD_Feedback_Zone_C_Sec_Pump_2_out| number : '1.2-2'}}</span></div>
      <div class="field"><span>{{VFD_Feedback_CP_Pump_3_out| number : '1.2-2'}}</span></div> 
      -->
    </div>

  </div>
</div>