<div class="d-one-comp">
  <svg id="d-one-comp" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%"
    height="100%" viewBox="0 0 177.54 195.851">
    <defs>
      <linearGradient id="linear-gradient-d1" x1="0.5" x2="0.299" y2="0.894" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#fff" />
        <stop offset="1" stop-color="#8ea6b2" />
      </linearGradient>
      <linearGradient id="linear-gradient-d1-2" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#ebf7fd" />
        <stop offset="1" stop-color="#8ea6b2" />
      </linearGradient>
      <linearGradient id="linear-gradient-d1-3" x1="0.816" y1="0.356" x2="0.218" y2="1.3"
        gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#fff" />
        <stop offset="1" stop-color="#e4f4fc" />
      </linearGradient>
      <filter id="Rectangle_1270" x="0" y="1.879" width="159" height="64" filterUnits="userSpaceOnUse">
        <feOffset dx="3" dy="3" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="4.5" result="blur" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Component_69_4" data-name="Component 69 – 4" transform="translate(10.5 0)">
      <g id="Group_3619" data-name="Group 3619" transform="translate(-13169.521 1898.993)">
        <path id="Path_4371" data-name="Path 4371" d="M457.217,399.793,400.17,366.85V274.13l57.047,32.943Z"
          transform="translate(12781.327 -2147.312)" stroke="#427894" stroke-miterlimit="10" stroke-width="1"
          fill="url(#linear-gradient-d1-2)" />
        <path id="Path_4372" data-name="Path 4372" d="M414.977,304.628l-9.627-5.567V286.22l9.627,5.555Z"
          transform="translate(12782.244 -2145.173)" fill="#87abbe" />
        <path id="Path_4373" data-name="Path 4373" d="M414.977,321l-9.627-5.555V302.59l9.627,5.567Z"
          transform="translate(12782.244 -2142.276)" fill="#87abbe" />
        <path id="Path_4374" data-name="Path 4374" d="M414.977,337.378l-9.627-5.567V318.97l9.627,5.555Z"
          transform="translate(12782.244 -2139.378)" fill="#87abbe" />
        <path id="Path_4375" data-name="Path 4375" d="M414.977,353.747l-9.627-5.567V335.34l9.627,5.555Z"
          transform="translate(12782.244 -2136.481)" fill="#87abbe" />
        <path id="Path_4376" data-name="Path 4376" d="M434.319,315.788l-9.639-5.567V297.38l9.639,5.567Z"
          transform="translate(12785.664 -2143.198)" fill="#87abbe" />
        <path id="Path_4377" data-name="Path 4377" d="M434.319,332.158,424.68,326.6V313.75l9.639,5.567Z"
          transform="translate(12785.664 -2140.301)" fill="#87abbe" />
        <path id="Path_4378" data-name="Path 4378" d="M434.319,348.539l-9.639-5.567V330.12l9.639,5.567Z"
          transform="translate(12785.664 -2137.405)" fill="#87abbe" />
        <path id="Path_4379" data-name="Path 4379" d="M434.319,364.908l-9.639-5.567V346.5l9.639,5.567Z"
          transform="translate(12785.664 -2134.506)" fill="#87abbe" />
        <path id="Path_4380" data-name="Path 4380"
          d="M427.976,315.646l8.91-5.143,10.969,6.344,10.581-6.108L401.73,278l40.734-23.515L552.18,317.823l-60.224,34.767Z"
          transform="translate(12781.604 -2150.789)" stroke="#96bdd1" stroke-width="1"
          fill="url(#linear-gradient-d1-3)" />
        <path id="Path_4381" data-name="Path 4381"
          d="M442.887,255.307,549.825,317.05l-39.357,22.715-7.791,4.5-10.3,5.955-61.2-35.308,6.132-3.531,9.58,5.532,1.389.8,1.389-.8,7.8-4.5,4.166-2.354-4.166-2.413-52.539-30.33L442.887,255.4Zm0-3.107L399.34,277.316l56.694,32.743-7.791,4.5-10.969-6.332-11.7,6.756,66.768,38.545,11.687-6.756,7.8-4.5,43.547-25.128Z"
          transform="translate(12781.181 -2151.193)" fill="#96bdd1" />
        <path id="Path_4382" data-name="Path 4382" d="M490.68,437.865l-67.63-38.486V306.67l67.63,38.486Z"
          transform="translate(12784.408 -2141.866)" stroke="#427894" stroke-miterlimit="10" stroke-width="1"
          fill="url(#linear-gradient-d1-2)" />
        <path id="Path_4383" data-name="Path 4383" d="M439.849,337.877l-9.639-5.555V319.47l9.639,5.567Z"
          transform="translate(12786.643 -2139.289)" fill="#87abbe" />
        <path id="Path_4384" data-name="Path 4384" d="M439.849,354.257l-9.639-5.567V335.85l9.639,5.555Z"
          transform="translate(12786.643 -2136.391)" fill="#87abbe" />
        <path id="Path_4385" data-name="Path 4385" d="M439.849,370.627l-9.639-5.567V352.22l9.639,5.567Z"
          transform="translate(12786.643 -2133.494)" fill="#87abbe" />
        <path id="Path_4386" data-name="Path 4386" d="M439.849,387.009l-9.639-5.567V368.59l9.639,5.567Z"
          transform="translate(12786.643 -2130.598)" fill="#87abbe" />
        <path id="Path_4387" data-name="Path 4387" d="M459.179,349.037l-9.639-5.555V330.63l9.639,5.567Z"
          transform="translate(12790.063 -2137.314)" fill="#87abbe" />
        <path id="Path_4388" data-name="Path 4388" d="M459.179,365.417l-9.639-5.567V347.01l9.639,5.555Z"
          transform="translate(12790.063 -2134.416)" fill="#87abbe" />
        <path id="Path_4389" data-name="Path 4389" d="M459.179,381.787l-9.639-5.555V363.38l9.639,5.567Z"
          transform="translate(12790.063 -2131.52)" fill="#87abbe" />
        <path id="Path_4390" data-name="Path 4390" d="M459.179,398.158,449.54,392.6V379.75l9.639,5.567Z"
          transform="translate(12790.063 -2128.623)" fill="#87abbe" />
      </g>
      <g transform="matrix(1, 0, 0, 1, -10.5, 0)" filter="url(#Rectangle_1270)">
        <rect id="Rectangle_1270-2" data-name="Rectangle 1270" width="132" height="37" rx="10"
          transform="translate(10.5 12.38)" fill="#529ec6" />
      </g>
      <text id="D1_Block" data-name="D1 Block" transform="translate(0 20.378)" fill="#fff" font-size="18"
        font-family="Mulish-Regular, Mulish">
        <tspan x="28.672" y="16">D1 Block</tspan>
      </text>
    </g>
  </svg>
</div>