import { Component } from '@angular/core';

@Component({
  selector: 'b-two-w-block-comp',
  templateUrl: './b-two-w-block-comp.component.html',
  styleUrls: ['./b-two-w-block-comp.component.css']
})
export class BTwoWBlockCompComponent {

}
