<div class="a-block-comp">
  <svg id="a-block-comp" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%"
    height="100%" viewBox="0 0 221.995 262.979">
    <defs>
      <linearGradient id="hover-gradient-a" x1="0.945" y1="0.925" x2="0.163" y2="0.965"
        gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#97CAE5" />
        <stop offset="1" stop-color="#387290" />
      </linearGradient>
      <linearGradient id="hover-gradient-a-2" x1="0.886" y1="0.783" x2="0.095" y2="0.786"
        xlink:href="#hover-gradient-a" />
      <linearGradient id="hover-gradient-a-3" x1="0.864" y1="0.832" x2="0.132" y2="0.821"
        xlink:href="#hover-gradient-a" />
      <linearGradient id="linear-gradient-a" x1="0.945" y1="0.925" x2="0.163" y2="0.965"
        gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#e6f7ff" />
        <stop offset="1" stop-color="#b2cad5" />
      </linearGradient>
      <filter id="Path_20023" x="0" y="66.704" width="221.995" height="166.093" filterUnits="userSpaceOnUse">
        <feOffset dx="-10" dy="10" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="5" result="blur" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
      <linearGradient id="linear-gradient-a-2" x1="0.886" y1="0.783" x2="0.095" y2="0.786"
        xlink:href="#linear-gradient-a" />
      <linearGradient id="linear-gradient-a-3" x1="0.864" y1="0.832" x2="0.132" y2="0.821"
        xlink:href="#linear-gradient-a" />
      <linearGradient id="linear-gradient-a-4" x1="0.746" y1="0.114" x2="0.147" y2="1.365"
        gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#fff" />
        <stop offset="1" stop-color="#e4f4fc" />
      </linearGradient>
      <filter id="Path_15407" x="42.652" y="31.582" width="159" height="64" filterUnits="userSpaceOnUse">
        <feOffset dx="3" dy="3" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="4.5" result="blur-2" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur-2" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Group_6335" data-name="Group 6335" transform="translate(-8462.48 10108.664)">
      <g id="Group_6334" data-name="Group 6334" transform="translate(6821 -10378)">
        <g id="Group_6333" data-name="Group 6333" transform="translate(13.925 -118)">
          <g id="Group_6332" data-name="Group 6332" transform="translate(829.163 -366.313)" opacity="0.216">
            <g id="Ellipse_20346" data-name="Ellipse 20346" transform="translate(822 853)" fill="#fff" stroke="#707070"
              stroke-width="1">
              <ellipse cx="96" cy="55.5" rx="96" ry="55.5" stroke="none" />
              <ellipse cx="96" cy="55.5" rx="95.5" ry="55" fill="none" />
            </g>
            <path id="Path_21057" data-name="Path 21057"
              d="M7214.524-9645.9v64.483s1.387,9.741,10.16,18.931,10.356,10.606,24.929,17.827,16.532,7.88,33.363,11.057,44.108,1.2,44.108,1.2,27.851-3.619,50.02-15.985,28.509-33.03,28.509-33.03V-9645.9s4.14,7.893-2.987,18.4-4.1,13.617-25.521,23.63-60.167,16.421-60.167,16.421-26.022-.783-42.58-3.65-23.652-7.818-23.652-7.818-20.049-8.208-29.094-19.953S7214.524-9645.9,7214.524-9645.9Z"
              transform="translate(-6391.376 10547.763)" fill="#fff" stroke="#707070" stroke-width="1"
              stroke-dasharray="5" />
          </g>
          <path id="Path_21653" data-name="Path 21653" d="M7232.058-9539.055v54.109"
            transform="translate(-5559 10113.566)" fill="none" stroke="#707070" stroke-width="1" stroke-dasharray="5"
            opacity="0.22" />
          <path id="Path_21654" data-name="Path 21654" d="M7232.058-9539.055v54.632"
            transform="translate(-5531.177 10127.566)" fill="none" stroke="#707070" stroke-width="1"
            stroke-dasharray="5" opacity="0.22" />
          <path id="Path_21655" data-name="Path 21655" d="M7232.058-9539.055v54.724"
            transform="translate(-5496.339 10133.566)" fill="none" stroke="#707070" stroke-width="1"
            stroke-dasharray="5" opacity="0.22" />
          <path id="Path_21656" data-name="Path 21656" d="M7232.058-9539.054v57.519"
            transform="translate(-5460.171 10129.604)" fill="none" stroke="#707070" stroke-width="1"
            stroke-dasharray="5" opacity="0.22" />
          <path id="Path_21657" data-name="Path 21657" d="M7232.058-9539.056v56.017"
            transform="translate(-5425.333 10119.583)" fill="none" stroke="#707070" stroke-width="1"
            stroke-dasharray="5" opacity="0.22" />
          <path id="Path_21658" data-name="Path 21658" d="M7232.058-9539.055v52.09"
            transform="translate(-5397.121 10101.993)" fill="none" stroke="#707070" stroke-width="1"
            stroke-dasharray="5" opacity="0.22" />
        </g>
        <g id="Component_58_6" data-name="Component 58 – 6" transform="translate(1666.98 270.336)">
          <path id="Path_20003" data-name="Path 20003" d="M434.319,364.908l-9.639-5.567V346.5l9.639,5.567Z"
            transform="translate(-378.858 -311.425)" fill="#92a3c3" />
          <path id="Path_20004" data-name="Path 20004" d="M439.849,354.257l-9.639-5.567V335.85l9.639,5.555Z"
            transform="translate(-377.879 -313.31)" fill="#92a3c3" />
          <path id="Path_20005" data-name="Path 20005" d="M439.849,370.627l-9.639-5.567V352.22l9.639,5.567Z"
            transform="translate(-377.879 -310.413)" fill="#92a3c3" />
          <path id="Path_20006" data-name="Path 20006" d="M439.849,387.009l-9.639-5.567V368.59l9.639,5.567Z"
            transform="translate(-377.879 -307.517)" fill="#92a3c3" />
          <path id="Path_20007" data-name="Path 20007" d="M459.179,365.417l-9.639-5.567V347.01l9.639,5.555Z"
            transform="translate(-374.458 -311.335)" fill="#92a3c3" />
          <path id="Path_20008" data-name="Path 20008" d="M459.179,381.787l-9.639-5.555V363.38l9.639,5.567Z"
            transform="translate(-374.458 -308.439)" fill="#92a3c3" />
          <path id="Path_20009" data-name="Path 20009" d="M459.179,398.158,449.54,392.6V379.75l9.639,5.567Z"
            transform="translate(-374.458 -305.542)" fill="#92a3c3" />
          <path id="Path_20010" data-name="Path 20010" d="M478.22,430.634l.059-92.414,80.127,46.254-.059,92.414Z"
            transform="translate(-371.123 -311.655)" fill="#fff" />
          <path id="Path_20011" data-name="Path 20011"
            d="M478.405,338.43l79.833,46.089v92.2l-79.833-46.089Zm-.235-.4v92.72l80.3,46.36v-92.7l-80.3-46.36Z"
            transform="translate(-371.266 -310.951)" />
          <g transform="matrix(1, 0, 0, 1, -25.5, -1)" filter="url(#Path_20023)">
            <path id="Path_20023-2" data-name="Path 20023"
              d="M575.265,382.47h-191v80.3h.118c.129,13.994,9.416,27.976,27.929,38.663,37.25,21.5,97.687,21.5,134.9,0,18.5-10.687,27.811-24.716,27.929-38.663h.118Z"
              transform="translate(-358.77 -310.27)" stroke="#427894" stroke-miterlimit="10" stroke-width="1"
              fill="url(#linear-gradient-a)" />
          </g>
          <path id="Path_20024" data-name="Path 20024"
            d="M479.752,499.629c52.684,0,95.392-24.655,95.392-55.07s-42.708-55.069-95.392-55.069-95.392,24.656-95.392,55.069S427.068,499.629,479.752,499.629Z"
            transform="translate(-384.148 -319.333)" stroke="#427894" stroke-miterlimit="10" stroke-width="1"
            fill="url(#linear-gradient-a-2)" />
          <path id="Path_20025" data-name="Path 20025"
            d="M479.752,480.719c52.684,0,95.392-24.656,95.392-55.069s-42.708-55.07-95.392-55.07-95.392,24.656-95.392,55.07S427.068,480.719,479.752,480.719Z"
            transform="translate(-383.606 -333.779)" stroke="#427894" stroke-miterlimit="10" stroke-width="1"
            fill="url(#linear-gradient-a-3)" />
          <path id="Path_20026" data-name="Path 20026" d="M399.63,406.87v83.446"
            transform="translate(-381.552 -306.948)" fill="none" stroke="#427894" stroke-miterlimit="10"
            stroke-width="2" />
          <path id="Path_20027" data-name="Path 20027" d="M423.27,419.91v83.446" transform="translate(-377.37 -304.641)"
            fill="none" stroke="#427894" stroke-miterlimit="10" stroke-width="2" />
          <path id="Path_20028" data-name="Path 20028" d="M452.41,426.43v83.446"
            transform="translate(-372.213 -303.486)" fill="none" stroke="#427894" stroke-miterlimit="10"
            stroke-width="2" />
          <path id="Path_20029" data-name="Path 20029" d="M483.14,425.62V508.1" transform="translate(-366.776 -303.63)"
            fill="none" stroke="#427894" stroke-miterlimit="10" stroke-width="2" />
          <path id="Path_20030" data-name="Path 20030" d="M510.71,418.28v82.492"
            transform="translate(-361.897 -304.929)" fill="none" stroke="#427894" stroke-miterlimit="10"
            stroke-width="2" />
          <path id="Path_20031" data-name="Path 20031" d="M536.71,402.8v81.527" transform="translate(-357.295 -307.668)"
            fill="none" stroke="#427894" stroke-miterlimit="10" stroke-width="2" />
          <path id="Path_20032" data-name="Path 20032"
            d="M479.541,444.511c-25.234,0-48.938-5.661-66.757-15.948-17.56-10.134-27.235-23.539-27.235-37.733s9.675-27.6,27.235-37.733c17.819-10.287,41.523-15.948,66.757-15.948s48.949,5.661,66.757,15.948c17.56,10.134,27.235,23.539,27.235,37.733,0,8.8-3.719,17.3-10.929,25.114A69.4,69.4,0,0,1,546.3,428.564C528.491,438.791,504.787,444.511,479.541,444.511Z"
            transform="translate(-384.043 -319.285)" fill="url(#linear-gradient-a-4)" />
          <path id="Path_20033" data-name="Path 20033"
            d="M479.761,338.7c24.987,0,48.443,5.6,66.05,15.759,17.113,9.886,26.54,22.856,26.54,36.485s-9.416,26.646-26.54,36.485c-17.654,10.157-41.064,15.759-66.062,15.759s-48.455-5.6-66.05-15.759c-17.125-9.886-26.552-22.856-26.552-36.485s9.416-26.646,26.552-36.485C431.353,344.3,454.751,338.7,479.761,338.7Zm0-2.778c-24.41,0-48.82,5.379-67.451,16.124-37.25,21.515-37.25,56.376,0,77.891,18.631,10.746,43.041,16.124,67.451,16.124s48.832-5.379,67.451-16.124c37.25-21.515,37.25-56.376,0-77.891C528.581,341.3,504.171,335.92,479.761,335.92Z"
            transform="translate(-384.252 -319.503)" fill="#427894" />
          <path id="Path_20034" data-name="Path 20034"
            d="M473.885,438.05c-23.715,0-45.993-5.32-62.739-14.988-16.5-9.524-25.6-22.122-25.6-35.462s9.092-25.939,25.6-35.462c16.747-9.668,39.024-14.988,62.739-14.988s46,5.32,62.739,14.988c16.5,9.524,25.6,22.122,25.6,35.462,0,8.271-3.5,16.257-10.272,23.6a65.227,65.227,0,0,1-15.324,11.859C519.888,432.675,497.611,438.05,473.885,438.05Z"
            transform="translate(-378.999 -330.638)" fill="none" stroke="#689bb5" stroke-width="2" />
          <path id="Path_20035" data-name="Path 20035"
            d="M480.994,451.075c-25.624,0-49.694-6.007-67.789-16.923-17.832-10.753-27.656-24.978-27.656-40.04s9.824-29.287,27.656-40.04c18.094-10.915,42.165-16.923,67.789-16.923s49.706,6.007,67.789,16.923c17.832,10.753,27.656,24.978,27.656,40.04,0,9.338-3.776,18.355-11.1,26.649a71,71,0,0,1-16.558,13.39C530.7,445.005,506.63,451.075,480.994,451.075Z"
            transform="translate(-385.549 -337.15)" fill="none" stroke="#689bb5" stroke-width="2" />
          <path id="Path_20036" data-name="Path 20036"
            d="M400.009,353.666a20.881,20.881,0,0,1-10.27-2.453c-2.7-1.559-4.19-3.621-4.19-5.8s1.488-4.246,4.19-5.8a22.721,22.721,0,0,1,20.539,0c2.7,1.559,4.19,3.621,4.19,5.8a5.679,5.679,0,0,1-1.681,3.863,10.677,10.677,0,0,1-2.508,1.941A20.969,20.969,0,0,1,400.009,353.666Z"
            transform="translate(-306.967 -292.513)" fill="none" stroke="#96bdd1" stroke-width="2" />
          <path id="Path_20037" data-name="Path 20037"
            d="M404.589,358.9a27.494,27.494,0,0,1-13.523-3.23c-3.557-2.053-5.517-4.768-5.517-7.643s1.96-5.591,5.517-7.643a29.918,29.918,0,0,1,27.045,0c3.557,2.053,5.517,4.768,5.517,7.643a7.479,7.479,0,0,1-2.214,5.087,14.062,14.062,0,0,1-3.3,2.556A27.612,27.612,0,0,1,404.589,358.9Z"
            transform="translate(-311.635 -294.847)" fill="none" stroke="#96bdd1" stroke-width="2" />
          <path id="Path_20038" data-name="Path 20038" d="M-4331.874-6287.984l14.924-34.543h1.729l-15.028,34.543Z"
            transform="translate(4430.478 6330.472)" fill="none" stroke="#96bdd1" stroke-width="1" />
          <path id="Path_20039" data-name="Path 20039" d="M-4340.043-6266.92H-4338l-11.39,49.22h-2.586Z"
            transform="translate(4430.193 6331.065)" fill="none" stroke="#96bdd1" stroke-width="1" />
          <path id="Path_20040" data-name="Path 20040" d="M-4335.8-6289.355l1.368-35.213h1.629l-1.629,35.213Z"
            transform="translate(4429.478 6331.065)" fill="none" stroke="#96bdd1" stroke-width="1" />
          <path id="Path_20041" data-name="Path 20041" d="M-4325.238-6287.333l27.636-32.74,2.34.248-28.506,33.462Z"
            transform="translate(4429.478 6331.065)" fill="none" stroke="#96bdd1" stroke-width="1" />
          <path id="Path_20042" data-name="Path 20042" d="M-4341.914-6288.805l1.3-.249-12.792-33.922h-1.574Z"
            transform="translate(4429.478 6331.065)" fill="none" stroke="#96bdd1" stroke-width="1" />
          <path id="Path_20043" data-name="Path 20043" d="M-4343.942-6268.11l2.311.937-36.161,43.652-2.528-.917Z"
            transform="translate(4429.478 6331.065)" fill="none" stroke="#96bdd1" stroke-width="1" />
          <path id="Path_20044" data-name="Path 20044" d="M-4335.914-6266.343h1.393l3.77,48.867h-2.348Z"
            transform="translate(4429.478 6331.065)" fill="none" stroke="#96bdd1" stroke-width="1" />
          <path id="Path_20045" data-name="Path 20045" d="M-4331.827-6266.854l1.81-.242,28.939,46.052-2.757.721Z"
            transform="translate(4429.478 6331.065)" fill="none" stroke="#96bdd1" stroke-width="1" />
          <path id="Path_20046" data-name="Path 20046" d="M-4349.577-6269.992l2.16.943-59.4,31-2.97-2.214Z"
            transform="translate(4429.478 6331.065)" fill="none" stroke="#96bdd1" stroke-width="1" />
          <path id="Path_20047" data-name="Path 20047" d="M-4355.456-6276.124l.937,2.369-71.558,5.032.379-2.237Z"
            transform="translate(4429.478 6331.065)" fill="none" stroke="#96bdd1" stroke-width="1" />
          <path id="Path_20048" data-name="Path 20048" d="M-4354.4-6280.722l1.039-2.081-53.994-19.768-1.43,1.589Z"
            transform="translate(4429.478 6331.065)" fill="none" stroke="#96bdd1" stroke-width="1" />
          <path id="Path_20049" data-name="Path 20049" d="M-4348.787-6286.646l1.468-.865-29.974-31.044-1.826,1.014Z"
            transform="translate(4429.478 6331.065)" fill="none" stroke="#96bdd1" stroke-width="1" />
          <path id="Path_20050" data-name="Path 20050" d="M-4325.244-6268.727l51.791,37.811-2.483.99-50.624-37.893"
            transform="translate(4429.478 6331.065)" fill="none" stroke="#96bdd1" stroke-width="1" />
          <path id="Path_20051" data-name="Path 20051" d="M-4319.944-6283.388l1.208,1.48,40.732-20.3-2.544-1.521Z"
            transform="translate(4429.478 6331.065)" fill="none" stroke="#96bdd1" stroke-width="1" />
          <path id="Path_20052" data-name="Path 20052" d="M-4316.872-6278.586v1.973l68.425-4.382-1.621-2.333Z"
            transform="translate(4429.478 6331.065)" fill="none" stroke="#96bdd1" stroke-width="1" />
          <path id="Path_20053" data-name="Path 20053" d="M-4320.105-6271.782l67.066,27.76,1.579-1.914-67.371-27.116Z"
            transform="translate(4429.478 6331.065)" fill="none" stroke="#96bdd1" stroke-width="1" />
          <g id="Group_5788" data-name="Group 5788" transform="translate(-468.348 -895.918)">
            <g transform="matrix(1, 0, 0, 1, 442.85, 894.92)" filter="url(#Path_15407)">
              <path id="Path_15407-2" data-name="Path 15407"
                d="M10,0H122a10,10,0,0,1,10,10V27a10,10,0,0,1-10,10H10A10,10,0,0,1,0,27V10A10,10,0,0,1,10,0Z"
                transform="translate(53.15 42.08)" fill="#529ec6" />
            </g>
            <text id="A_Block" data-name="A Block" transform="translate(556 944)" fill="#fff" font-size="18"
              font-family="Mulish-Regular, Mulish">
              <tspan x="-31.455" y="18">A Block</tspan>
            </text>
          </g>
        </g>
      </g>
    </g>
  </svg>
</div>