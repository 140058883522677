import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'd-block-comp',
  templateUrl: './d-block-comp.component.html',
  styleUrls: ['./d-block-comp.component.css']
})
export class DBlockCompComponent {
  @Input() item:any;
  @Input() title!:string;
  @Input() image!:string;
  @Input() disabled!:boolean;
  
  constructor(private router:Router){
      
  }
  isHovered = false;

  onHover() {
    this.isHovered = true;
  }

  onLeave() {
    this.isHovered = false;
  }
}
