

<div class="scaffold">
  <div style="height: 100%; width: 100%; position: relative">
    <div
      style="
        height: 80%;
        width: 100%;
        background: 
          url('/assets/images/UL-background.png');
        position: absolute;
        object-fit: cover;
        background-repeat: no-repeat;
        background-size: cover;
      "
    ></div>

    <div
      style="
        height: 100%;
        width: 100%;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      "
    >
      <div
        style="
          /* width: 90%; */
          /* max-width: 500px; */
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: start;
          /* gap: 140px; */
        "
        class="client-logo"
      >
     <app-loading-spinner *ngIf="isloading"></app-loading-spinner>
   
<div style="width: 500px; border-radius: 20px ;height: 23vh; display: flex; justify-content: center; align-items: center;">
  <!-- <div style="align-items: center; "> -->
    <img src="/assets/logos/uniliver-logo-login-screen.png" style=" width:450px ;height: auto; object-fit: cover;" />
  <!-- </div> -->
  <div style="position: absolute; top: 78%; left: 50%; transform: translate(-50%, -50%);">
    <img src="/assets/logos/powered_by.gif" width="280px" />
  </div>
</div>
      
      
        <form (ngSubmit)="handleLogin()"  class="form">
          <!-- <mat-form-field style="width: 100%" appearance="outline">
            <mat-label>Username</mat-label> -->
          <input  type="text"
          matInput
          [formControl]="usernameFormControl"
          [errorStateMatcher]="matcher"
          [(ngModel)]="email"
          placeholder="Username" class="form-item">
          <!-- <mat-error *ngIf="usernameFormControl.hasError('required')">
            Username is <strong>required</strong>
          </mat-error>
        </mat-form-field> -->
        <!-- <mat-form-field style="width: 100%" appearance="outline">
          <mat-label>Password</mat-label> -->
          <div class="pass">
          <input  type="{{type}}"
          matInput
          [formControl]="passwordFormControl"
          [errorStateMatcher]="matcher"
          [(ngModel)]="password"
          placeholder="Password" class="form-item" /> 
          <span (click)="hideShowPass()" class="fa {{eyeIcon}}"></span>
        </div>
          <!-- <mat-error *ngIf="passwordFormControl.hasError('required')">
            Password is <strong>required</strong>
          </mat-error>
        </mat-form-field> -->

          <button mat-raised-button class="btn"  type="submit"style="
          color: white;
          font-family: sans-serif;
        ">Login</button>
          <a
              class="hover-text-dark"
              [routerLink]="['/forgot-password']"
              style="margin-top: 15px; color:black; margin-bottom: 0px"
              >Forgot Password?</a
            >
      </form>

      </div>
    </div>
  </div>
</div>
