import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { Location, NgIf } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'moonshot-logout-dialog',
  templateUrl: './logout-dialog.component.html',
  standalone: true,
  imports: [MatDialogModule, NgIf],
  styleUrls: ['./logout-dialog.component.css'],
})
export class LogoutDialogComponent {
  // constructor(
  //   public dialogRef: MatDialogRef<LogoutDialogComponent>,
  //   private router: Router
  // ) {}

  // logout(): void {
  //   localStorage.clear();
  //   this.router.navigate(['/login']);
  // }


  constructor(private router: Router, private location: Location) { }

  cancel() {
    // Navigate to the previous or home page
    this.router.navigate(['/dashboard/home']); // Adjust the path as needed
  }
  goback() {
    this.location.back();
  }
  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }
}
