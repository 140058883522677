<div class="main-container" style="background-color: #FFF9F4; height: 100%">
    <app-loading-spinner *ngIf="isloading" ></app-loading-spinner>
  
    <div
      style="
        display: flex;
        flex-direction: column;
        gap: 2vh;
        justify-content: space-between;
        align-items: center;
        padding-top: 4vh;
      "
    >
      <!-- Title -->
      <div style="color: var(--blue-two); font-size: 4vh; font-weight: bold">
         AHU History Log
      </div>
  
      <div
        style="
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          padding-top: 3vh;
          justify-content: center;
          gap: 2vh;
        "
      >
        <div>
          <label
            style="
              color: var(--blue-two);
              font-size: 2.25vh;
              font-weight: 550;
              padding-right: 0.5vw;
            "
            >Block :</label
          >
          <mat-form-field appearance="outline" class="custom-mat-form-field">
            <!-- <mat-select [formControl]="ahus" multiple> -->
            <mat-select [(ngModel)]="selectedBlock">
              <mat-option *ngFor="let ahu of blocks" [value]="ahu">
                {{ ahu }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>


      <div
        style="
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          padding-top: 3vh;
          justify-content: center;
          gap: 2vh;
          border: black;
        "
      >
        <div>
          <label
            style="
              color: var(--blue-two);
              font-size: 2.25vh;
              font-weight: 550;
              padding-right: 0.5vw;
            "
            >Floor :</label
          >
          <select [(ngModel)]="selectedFloor">
            <option *ngFor="let ahu of ahus1[blockahumap.get(selectedBlock) ?? 0]">{{ahu}}</option>
          </select>
        </div>
      </div>
  
      <!-- Date and Time Selectors -->
      <div
        style="
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: center;
          gap: 2vw;
        "
      >
        <div style="display: flex; align-items: center">
          <label
            style="
              color: var(--blue-two);
              font-size: 2.25vh;
              font-weight: 550;
              padding-right: 0.5vw;
            "
            >From Date :</label
          >
          <input readonly
            matInput
            [matDatepicker]="fromDatePicker"
            [(ngModel)]="fromDate"
            placeholder="Choose a date"
            style="
              background-color: white;
              border: 1.2px solid var(--blue-two);
              height: 32px;
              border-radius: 7px;
              padding-left: 10px;
            "
            readonly
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="fromDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #fromDatePicker></mat-datepicker>
        </div>
        <div>
          <label
            style="
              color: var(--blue-two);
              font-size: 2.25vh;
              font-weight: 550;
              padding-right: 0.5vw;
            "
            >Time :</label
          >
          <select
            [(ngModel)]="fromTime"
            style="background-color: white; border-color: var(--blue-two)"
          >
            <option *ngFor="let time of times" [value]="time.value">
              {{ time.name }}
            </option>
          </select>
        </div>
        <div style="display: flex; align-items: center">
          <label
            style="
              color: var(--blue-two);
              font-size: 2.25vh;
              font-weight: 550;
              padding-right: 0.5vw;
            "
            >To Date :</label
          >
          <input readonly
            matInput
            [matDatepicker]="toDatePicker"
            [(ngModel)]="toDate"
            placeholder="Choose a date"
            style="
              background-color: white;
              border: 1.2px solid var(--blue-two);
              height: 32px;
              border-radius: 7px;
              padding-left: 10px;
            "
            readonly
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="toDatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #toDatePicker></mat-datepicker>
        </div>
        <div>
          <label
            style="
              color: var(--blue-two);
              font-size: 2.25vh;
              font-weight: 550;
              padding-right: 0.5vw;
            "
            >Time :</label
          >
          <select
            [(ngModel)]="toTime"
            style="background-color: white; border-color: var(--blue-two)"
          >
            <option
              *ngFor="let time of times"
              [value]="time.value"
              style="max-height: 1000px; overflow-y: auto"
            >
              {{ time.name }}
            </option>
          </select>
        </div>
      </div>
  
      <!-- Type Selector -->
      <div
        style="
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: center;
          gap: 2vw;
        "
      >
        <!-- <div>
        <label
          style="
            color: var(--blue-two);
            font-size: 2.25vh;
            font-weight: 550;
            padding-right: 0.5vw;
          "
          >PMV / Zonal Temp :</label
        >
        <select
          [(ngModel)]="selectedType"
          style="background-color: white; border-color: var(--blue-two)"
        >
          <option *ngFor="let type of types" [value]="type.value">
            {{ type.name }}
          </option>
        </select>
      </div> -->
      </div>
      <button class="databtn" (click)="SendData()">Show Data</button>
    </div>
    <div
      *ngIf="showData === true"
      style="
      background-color: #FFF9F4; 
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding-top: 5vh;
        gap: 5vh;
      "
    >
      <div style="display: flex; padding-bottom: 2vh">
        <select
          [(ngModel)]="downloadtype"
          style="background-color: white; border-color:  var(--blue-two)"
        >
          <option *ngFor="let type of downloadTypes" [value]="type.value">
            {{ type.name }}
          </option>
        </select>
  
        <button
          (click)="downloadtype === 'XLSX' ? downloadXLSX() : downloadPDF()"
          style="
            width: 10vw;
            font-size: medium;
            border-radius: 5px;
            padding: 5px;
            color: white;
            background-color: var(--blue-two);
            border: none;
            cursor: pointer;
            margin-left: 2vh;
          "
        >
          Download
        </button>
      </div>
      <div style="padding-bottom: 2%">
        <label
          for="itemsPerPage"
          style="
            color: var(--blue-two);
            font-size: 2.25vh;
            font-weight: 550;
            padding-right: 0.5vw;
          "
          >Items per page: &nbsp;&nbsp;
        </label>
      
        <select
          id="itemsPerPage"
          [(ngModel)]="itemsPerPage"
          style="background-color: white; border-color: var(--blue-two)"
          (change)="onItemsPerPageChange()"
        >
          <option *ngFor="let option of itemsPerPageOptions" [value]="option">
            {{ option }}
          </option>
        </select>
      </div>
      <pagination-controls
    
      (pageChange)="currentPage = $event"
    ></pagination-controls>
      <table
        style="
          width: 80%;
          border-collapse: collapse;
          border: 2px solid  var(--blue-two);
          margin-bottom: 5%;
        "
      >
        <thead>
          <!-- Table Header -->
          <tr
            style="background-color: #fdfcf7; color:  var(--blue-two); text-align: center"
          >
            <th
              style="
                padding: 1.5vh;
                border-bottom: 2px solid  var(--blue-two);
                border-right: 1px solid  var(--blue-two);
              "
            >
              S.No
            </th>
            <th
              style="
                padding: 1.5vh;
                border-bottom: 2px solid  var(--blue-two);
                border-right: 1px solid  var(--blue-two);
              "
            >
              Date
            </th>
            <th
              style="
                padding: 1.5vh;
                border-bottom: 2px solid  var(--blue-two);
                border-right: 1px solid  var(--blue-two);
              "
            >
              Time
            </th>
            <th
              style="
                padding: 1.5vh;
                border-bottom: 2px solid  var(--blue-two);
                border-right: 1px solid  var(--blue-two);
              "
            >
              Block
            </th>
            <th
              style="
                padding: 1.5vh;
                border-bottom: 2px solid  var(--blue-two);
                border-right: 1px solid  var(--blue-two);
              "
            >
              Floor
            </th>
            <th
              style="
                padding: 1.5vh;
                border-bottom: 2px solid  var(--blue-two);
                border-right: 1px solid  var(--blue-two);
              "
              matTooltip="Set Point Lower (°C)"
            >
              RAT (°C)
            </th>
         
            <th
              style="
                padding: 1.5vh;
                border-bottom: 2px solid  var(--blue-two);
                border-right: 1px solid  var(--blue-two);
              "
              matToolTip="Set Point Upper ((°C))"
            >
              SAT (%)
            </th>
            <th
              style="
                padding: 1.5vh;
                border-bottom: 2px solid  var(--blue-two);
                border-right: 1px solid  var(--blue-two);
              "
              matToolTip="Set Point Upper ((°C))"
            >
              Supply Dpt 
            </th>
            <th
              style="
                padding: 1.5vh;
                border-bottom: 2px solid  var(--blue-two);
                border-right: 1px solid  var(--blue-two);
              "
              matToolTip="Set Point Upper ((°C))"
            >
              Ch_valve_opening
            </th>
            <th
              style="
                padding: 1.5vh;
                border-bottom: 2px solid  var(--blue-two);
                border-right: 1px solid  var(--blue-two);
              "
              matToolTip="Set Point Upper ((°C))"
            >
              Fan speed
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let row of tableData
                | paginate
                  : {
                      itemsPerPage: itemsPerPage,
                      currentPage: currentPage
                    };
              let i = index
            "
            [style.background-color]="i % 2 === 0 ? '#fffcea' : '#fdfcf7'"
          >
            <td
              style="
                padding: 1.5vh;
                text-align: center;
                border-right: 1px solid #d3d3d3;
              "
            >
              {{ row.serialNo }}
            </td>
            <td
              style="
                padding: 1.5vh;
                text-align: center;
                border-right: 1px solid #d3d3d3;
              "
            >
              {{ row.date }}
            </td>
            <td
              style="
                padding: 1.5vh;
                text-align: center;
                border-right: 1px solid #d3d3d3;
              "
            >
              {{ row.time }}
            </td>
            <td
              style="
                padding: 1.5vh;
                text-align: center;
                border-right: 1px solid #d3d3d3;
              "
            >
              {{ row.ahu }}
            </td>
            <td
              style="
                padding: 1.5vh;
                text-align: center;
                border-right: 1px solid #d3d3d3;
              "
            >
              {{row.floor}}
            </td>
            <td
              style="
                padding: 1.5vh;
                text-align: center;
                border-right: 1px solid #d3d3d3;
              "
            >
            {{ row.rat ? (row.rat | number:'1.2-2') : 'NA' }}
          </td>
            <td
              style="
                padding: 1.5vh;
                text-align: center;
                border-right: 1px solid #d3d3d3;
              "
            >
              {{ row.sat | number:'1.2-2' }}
            </td>
            <td
              style="
                padding: 1.5vh;
                text-align: center;
                border-right: 1px solid #d3d3d3;
              "
            >
              {{ row.supplyDpt ? row.supplyDpt : "NA" }}
            </td>
            <td
            style="
              padding: 1.5vh;
              text-align: center;
              border-right: 1px solid #d3d3d3;
            "
          >
            {{ row.valve | number:'1.2-2' }}
          </td>
          <td
          style="
            padding: 1.5vh;
            text-align: center;
            border-right: 1px solid #d3d3d3;
          "
        >
          {{ row.speed | number:'1.2-2' }}
        </td>
  
            <!-- <td
              style="
                padding: 1.5vh;
                text-align: center;
                border-right: 1px solid #d3d3d3;
              "
            >
              {{ row.humidity }}
            </td> -->
            <!-- <td
              style="
                padding: 1.5vh;
                text-align: center;
                border-right: 1px solid #d3d3d3;
              "
            >
              {{ row.dp }}
            </td> -->
            <!-- <td style="padding: 1.5vh; text-align: center">
              {{ row.acph }}
            </td> -->
          </tr>
        </tbody>
      </table>
   
    </div>
  
  </div>
  