<div style="position: relative; height: 100%; width: 100%">
    <div class="main_container">
        <div class="content-container">
            <div class="upcontainer">
                <div class="map-container">
                    <img class="fmap" src="/assets/updated design/CONDENSER PUMPS.svg" />

              
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 39%; left: 19%; width: 4%; height: 3%;">
                        <input readonly style="color:#293AC6; font-weight:600" [ngModel]=" POWER1_IN | number: '1.2-2'"
                            (ngModelChange)=" POWER1_IN = $event" />
                        <span style="font-size: 80%; color: #293AC6; font-weight:600">kw</span>
                    </div>

              
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 35%; left: 19%; width: 4%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" POWER1_RED | number: '1.2-2'"
                            (ngModelChange)=" POWER1_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">kw</span>
                    </div>

                 
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 39%; left: 11.7%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #00A578; font-weight:600" [ngModel]=" CP1_VFD_GR | number: '1.2-2'"
                            (ngModelChange)=" CP1_VFD_GR = $event" />
                        <span style="font-size: 80%; color: #00A578; font-weight:600">%</span>
                    </div>

                
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 35%; left: 11.7%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" CP1_VFD_RED | number: '1.2-2'"
                            (ngModelChange)=" CP1_VFD_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">%</span>
                    </div>

           
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 96%; left: 44.5%; width: 4%; height: 3%;">
                        <input readonly style="color:#293AC6; font-weight:600" [ngModel]=" POWER2_IN | number: '1.2-2'"
                            (ngModelChange)=" POWER2_IN = $event" />
                        <span style="font-size: 80%; color: #293AC6; font-weight:600">kw</span>
                    </div>

                
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 92%; left: 44.5%; width: 4%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" POWER2_RED | number: '1.2-2'"
                            (ngModelChange)=" POWER2_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">kw</span>
                    </div>

                    
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 96%; left: 37.4%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #00A578; font-weight:600" [ngModel]=" CP2_VFD_GR | number: '1.2-2'"
                            (ngModelChange)=" CP2_VFD_GR = $event" />
                        <span style="font-size: 80%; color: #00A578; font-weight:600">%</span>
                    </div>

              
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 92%; left: 37.4%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" CP2_VFD_RED | number: '1.2-2'"
                            (ngModelChange)=" CP2_VFD_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">%</span>
                    </div>

                  
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 96%; left: 74.5%; width: 4%; height: 3%;">
                        <input readonly style="color:#293AC6; font-weight:600" [ngModel]=" POWER3_IN | number: '1.2-2'"
                            (ngModelChange)=" POWER3_IN = $event" />
                        <span style="font-size: 80%; color: #293AC6; font-weight:600">kw</span>
                    </div>

                 
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 92%; left: 74.5%; width: 4%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" POWER3_RED | number: '1.2-2'"
                            (ngModelChange)=" POWER3_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">kw</span>
                    </div>

              
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 96%; left: 67%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #00A578; font-weight:600" [ngModel]=" CP3_VFD_GR | number: '1.2-2'"
                            (ngModelChange)=" CP3_VFD_GR = $event" />
                        <span style="font-size: 80%; color: #00A578; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 92%; left: 67%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" CP3_VFD_RED | number: '1.2-2'"
                            (ngModelChange)=" CP3_VFD_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">%</span>
                    </div>

              
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 35.6%; left: 100.4%; width: 4%; height: 3%;">
                        <input readonly style="color:#293AC6; font-weight:600" [ngModel]=" POWER4_IN | number: '1.2-2'"
                            (ngModelChange)=" POWER4_IN = $event" />
                        <span style="font-size: 80%; color: #293AC6; font-weight:600">kw</span>
                    </div>

               
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 31.6%; left: 100.4%; width: 4%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" POWER4_RED | number: '1.2-2'"
                            (ngModelChange)=" POWER4_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">kw</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 35.6%; left: 93%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #00A578; font-weight:600" [ngModel]=" CP4_VFD_GR | number: '1.2-2'"
                            (ngModelChange)=" CP4_VFD_GR = $event" />
                        <span style="font-size: 80%; color: #00A578; font-weight:600">%</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 31.6%; left: 93%; width: 3.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" CP4_VFD_RED | number: '1.2-2'"
                            (ngModelChange)=" CP4_VFD_RED = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">%</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>