import { Component, Input } from '@angular/core';

@Component({
  selector: 'Kiln',
  templateUrl: './kiln.component.html',
  styleUrls: ['./kiln.component.css']
})
export class KilnComponent {
  @Input() state!:string;
  urlPreHeating:string;
  urlOxidation:string;
  urlReduction:string;
  urlCooling: string;
  constructor(){
    this.state='active'
    this.urlPreHeating="/assets/bert-components/pre-heating-kiln.svg";
    this.urlOxidation="/assets/bert-components/oxidation-kiln.svg";
    this.urlReduction="/assets/bert-components/reduction-kiln.svg";
    this.urlCooling="/assets/bert-components/cooling-kiln.svg";
  }
}
