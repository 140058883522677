import { Component, Input } from '@angular/core';

@Component({
  selector: 'Cyclone-separator',
  templateUrl: './cyclone-separator.component.html',
  styleUrls: ['./cyclone-separator.component.css']
})
export class CycloneSeparatorComponent {
  @Input() state!:string;
  
  urlActive:string;
  urlInActive:string;

  constructor(){
    this.state = 'active';
    this.urlActive="/assets/bert-components/active-cyclone-separator.svg";
    this.urlInActive="/assets/bert-components/inactive-cyclone-separator.svg";
  }
}
