import { Component } from '@angular/core';

@Component({
  selector: 'app-cooling-tower1',
  templateUrl: './cooling-tower1.component.html',
  styleUrls: ['./cooling-tower1.component.css']
})
export class CoolingTower1Component {
  CT1_C1_FAN_SPEED_GR: any = 0;
  CT1_C1_FAN_SPEED_RED: any = 0;
  CT1_C2_FAN_SPEED_GR: any = 0;
  CT1_C2_FAN_SPEED_RED: any = 0;
  CT1_C3_FAN_SPEED_GR: any = 0;
  CT1_C3_FAN_SPEED_RED: any = 0;
  CT1_C1_Water_out: any = 0;
  CT1_C2_Water_out: any = 0;
  CT1_C3_Water_out: any = 0;
  CT_Water_in: any = 0;
  KPI: any = 0;
}
