import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-block-a',
  templateUrl: './block-a.component.html',
  styleUrls: ['./block-a.component.css']
})
export class BlockAComponent {
  KPI:number =0;
  basepower:number=1409.43;
  optiResult: any;
isloading:boolean=false;
  constructor(private http: HttpClient,private snackBar:MatSnackBar,    private router: Router,
  ) { }

  ngOnInit():void{
    this.getPredictionResult();
    setInterval(()=>{
      this.getPredictionResult();
    },600000);
  }

  // async getPredictionResult() {
  //   this.http.get<any>(`${environment.apiBaseUrl}/auth/get_result`)
  //     .subscribe(
  //       (response: any) => {
  //         try {
  //           this.optiResult = response.result; // Directly assign the nested object
            
  //           var xal2=(this.optiResult['fan_speed_AL2'])/50;
  //           var al2=Math.pow(xal2,3);
  //           al2=(1-al2)*100;
  //           console.log(al2,"powal");


  //           var xamezz=(this.optiResult['fan_speed_AMezz'])/50;
  //           var amezz=Math.pow(xamezz,3);       
            
  //           amezz=(1-amezz)*100;
  //           console.log(amezz,"poamezz");
  //           var xaub=(this.optiResult['fan_speed_AUB'])/50;
  //           var aub=Math.pow(xaub,3);
           
  //           aub=(1-aub)*100;
  //           console.log(aub,"powaub");
  //           // const totpow=this.optiResult['Power_AL2']+this.optiResult['Power_AMezz']+this.optiResult['Power_AUB'];
  //           this.KPI=(al2+amezz+aub)/3;
  //           this.KPI=(this.KPI)/(-100);
  //         } catch (error) {
  //           console.error('Error processing prediction result:', error);
  //         }
  //       },
  //       (error) => {
  //         console.error('Error fetching optimized result:', error);
  //         if (error.status === 401) {
  //           // Redirect to the login page
  //           this.router.navigate(['/login']); // Adjust the path as necessary
  //           sessionStorage.removeItem('accessToken');
  //           this.openSnackBar('Session expired')

  //         }
  //         else{
  //           this.openSnackBar('error in fetching predicted values')

  //         }
  //       }
  //     );
  // }

  async getPredictionResult() {
    this.isloading=true;
    this.http.get<any>(`${environment.apiBaseUrl}/auth/get_result`)

      .subscribe(

        (response: any) => {
          this.isloading=false;
          try {

            this.optiResult = response.environment; // Directly assign the nested object

            var totpow=this.optiResult['Power_AL2']+this.optiResult['Power_AMezz']+this.optiResult['Power_AUB'];
            totpow=totpow/3;
            console.log(this.optiResult['Power_AL2'],this.optiResult['Power_AMezz'],this.optiResult['Power_AUB']);
            this.KPI=((this.basepower-(totpow))/(this.basepower))*100;

          } catch (error) {

            console.error('Error processing prediction result:', error);

          }

        },

        (error) => {
          this.isloading=false;
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
            this.openSnackBar('Session expired')

          }
          console.error('Error fetching optimized result:', error);

          this.openSnackBar('error in fetching predicted values')

        }

      );

  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }

  
}
