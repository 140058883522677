import { Component } from '@angular/core';

@Component({
  selector: 'Filter-two',
  templateUrl: './filter-two.component.html',
  styleUrls: ['./filter-two.component.css']
})
export class FilterTwoComponent {

}
