<div style="position: relative; height: 100%; width: 100%">
    <div class="main_container">
        <div class="content-container">
            <div class="upcontainer">
                <button routerLink="/dashboard/chiller2"
                    style="z-index: 99999; height: 60px; width: 60px; border-radius: 30px; border: 3px solid; cursor: pointer;">
                    <img src="assets/logos/left-arrow.png" alt="" style="width: 30px;">
                </button>
                <div class="map-container">
                    <img class="fmap" src="/assets/chiller/chiller3.svg" />

                    <div class="input-groupR  component"
                    style="display:flex; justify-content:center; align-items:center; top: 6.4%; left: 70.3%; width: 5%; height: 3%;">
                    <input readonly style="color:#293AC6; font-weight:600" [ngModel]=" C3_POWER_IN | number: '1.2-2'"
                        (ngModelChange)=" C3_POWER_IN = $event" />
                    <span style="font-size: 80%; color: #293AC6; font-weight:600">kw</span>
                </div>

                <div class="input-groupR1  component"
                    style="display:flex; justify-content:center; align-items:center; top: 18.7%; left: 44.3%; width: 48px; height: 3%;">
                    <input readonly style="color: #183772;; font-weight:200" [ngModel]=" C3_POWER_RED | number: '1.2-2'"
                        (ngModelChange)=" C3_POWER_RED = $event" />
                    <!-- <span style="font-size: 80%; color: #D90606; font-weight:600">kw</span> -->
                </div>

                <div class="input-groupR1  component"
                    style="display:flex; justify-content:center; align-items:center; top: 21.2%; left: 44.3%; width: 48px; height: 3%;">
                    <input readonly style="color: #183772;; font-weight:200" [ngModel]=" C3_W_SP | number: '1.2-2'"
                        (ngModelChange)=" C3_W_SP = $event" />
                    <!-- <span style="font-size: 80%; color: #D90606; font-weight:600">°C</span> -->
                </div>

                <div class="input-groupR  component"
                    style="display:flex; justify-content:center; align-items:center; top: 10.5%; left: 70.3%; width: 4.5%; height: 3%;">
                    <input readonly style="color: #00A578; font-weight:600" [ngModel]=" C3_W_SP_GR | number: '1.2-2'"
                        (ngModelChange)=" C3_W_SP_GR = $event" />
                    <span style="font-size: 80%; color: #00A578; font-weight:600">°C</span>
                </div>
<!-- 
                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 34%; left: 78%; width: 4.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" CH_WATER_IN | number: '1.2-2'"
                            (ngModelChange)=" CH_WATER_IN = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">°C</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 49%; left: 79%; width: 4.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" CH_WATER_OUT | number: '1.2-2'"
                            (ngModelChange)=" CH_WATER_OUT = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">°C</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 78%; left: 79%; width: 4.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" COND_WATER_IN | number: '1.2-2'"
                            (ngModelChange)=" COND_WATER_IN = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">°C</span>
                    </div>

                    <div class="input-groupR  component"
                        style="display:flex; justify-content:center; align-items:center; top: 56%; left: 79%; width: 4.5%; height: 3%;">
                        <input readonly style="color: #D90606; font-weight:600" [ngModel]=" COND_WATER_OUT | number: '1.2-2'"
                            (ngModelChange)=" COND_WATER_OUT = $event" />
                        <span style="font-size: 80%; color: #D90606; font-weight:600">°C</span>
                    </div> -->
                </div>
                <button routerLink="/dashboard/chiller4"
                    style="z-index: 99999; height: 60px; width: 60px; border-radius: 30px; border: 3px solid; cursor: pointer;">
                    <img src="assets/logos/right-arrow.png" alt="" style="width: 30px;">
                </button>
            </div>
        </div>
    </div>
</div>