<div class="chiller-comp">




  <svg id="chiller-comp" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%"
    height="100%" viewBox="0 0 168.505 188.507">
    <defs>
      <filter id="Rectangle_1278" x="0" y="0" width="159" height="64" filterUnits="userSpaceOnUse">
        <feOffset dx="3" dy="3" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="4.5" result="blur" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Component_78_2" data-name="Component 78 – 2" transform="translate(10.5 5.826)">
      <path id="Path_4288" data-name="Path 4288" d="M750.147,239.36,704.87,213.22V120.5l45.277,26.14Z"
        transform="translate(-679.583 -94.984)" fill="#eef1f8" stroke="#ccc" stroke-miterlimit="10" stroke-width="1" />
      <path id="Path_4289" data-name="Path 4289" d="M719.509,150.387l-9.639-5.555V131.98l9.639,5.567Z"
        transform="translate(-678.698 -92.953)" fill="#92a3c3" />
      <path id="Path_4290" data-name="Path 4290" d="M719.509,166.767,709.87,161.2V148.36l9.639,5.555Z"
        transform="translate(-678.698 -90.054)" fill="#92a3c3" />
      <path id="Path_4291" data-name="Path 4291" d="M719.509,183.137l-9.639-5.567V164.73l9.639,5.555Z"
        transform="translate(-678.698 -87.158)" fill="#92a3c3" />
      <path id="Path_4292" data-name="Path 4292" d="M719.509,199.507l-9.639-5.555V181.1l9.639,5.567Z"
        transform="translate(-678.698 -84.261)" fill="#92a3c3" />
      <path id="Path_4293" data-name="Path 4293" d="M738.839,161.548l-9.639-5.555V143.14l9.639,5.567Z"
        transform="translate(-675.278 -90.978)" fill="#92a3c3" />
      <path id="Path_4294" data-name="Path 4294" d="M738.839,177.928l-9.639-5.567V159.52l9.639,5.555Z"
        transform="translate(-675.278 -88.08)" fill="#92a3c3" />
      <path id="Path_4295" data-name="Path 4295" d="M738.839,194.3l-9.639-5.567V175.89l9.639,5.567Z"
        transform="translate(-675.278 -85.183)" fill="#92a3c3" />
      <path id="Path_4296" data-name="Path 4296" d="M738.839,210.679l-9.639-5.567V192.26l9.639,5.567Z"
        transform="translate(-675.278 -82.286)" fill="#92a3c3" />
      <path id="Path_4297" data-name="Path 4297"
        d="M723.747,155.837l8.9-5.143,8.721,5.026,10.569-6.1L706.94,123.635l40.734-23.515,86.494,49.938-60.224,34.767Z"
        transform="translate(-679.217 -98.59)" fill="#f5f5f5" />
      <path id="Path_4298" data-name="Path 4298"
        d="M748.1,101.951l83.716,48.337L792.456,173l-7.791,4.5-10.3,5.944-47.419-27.376,6.132-3.531,7.309,4.225,1.4.8,1.389-.8,7.791-4.508,4.166-2.354-4.166-2.413-40.828-23.539L748.1,102.033Zm0-3.13L704.55,123.948l45.007,25.987-7.791,4.5-8.709-5.026-11.7,6.744,52.963,30.6,11.687-6.744,7.8-4.508,43.547-25.116-89.272-51.55Z"
        transform="translate(-679.64 -98.82)" fill="#ccc" />
      <path id="Path_4299" data-name="Path 4299" d="M772.564,271.832l-52.9-30.542V148.57l52.9,30.554Z"
        transform="translate(-676.966 -90.017)" fill="#eef1f8" stroke="#ccc" stroke-miterlimit="10" stroke-width="1" />
      <path id="Path_4300" data-name="Path 4300" d="M735.257,180.327l-9.627-5.567V161.92l9.627,5.555Z"
        transform="translate(-675.91 -87.655)" fill="#92a3c3" />
      <path id="Path_4301" data-name="Path 4301" d="M735.257,196.7l-9.627-5.555V178.29l9.627,5.567Z"
        transform="translate(-675.91 -84.758)" fill="#92a3c3" />
      <path id="Path_4302" data-name="Path 4302" d="M735.257,213.079l-9.627-5.567V194.66l9.627,5.567Z"
        transform="translate(-675.91 -81.862)" fill="#92a3c3" />
      <path id="Path_4303" data-name="Path 4303" d="M735.257,229.447l-9.627-5.567V211.04l9.627,5.555Z"
        transform="translate(-675.91 -78.963)" fill="#92a3c3" />
      <path id="Path_4304" data-name="Path 4304" d="M754.587,191.488l-9.627-5.567V173.08l9.627,5.555Z"
        transform="translate(-672.489 -85.68)" fill="#92a3c3" />
      <path id="Path_4305" data-name="Path 4305" d="M754.587,207.857,744.96,202.3V189.45l9.627,5.567Z"
        transform="translate(-672.489 -82.783)" fill="#92a3c3" />
      <path id="Path_4306" data-name="Path 4306" d="M754.587,224.239l-9.627-5.567V205.82l9.627,5.567Z"
        transform="translate(-672.489 -79.887)" fill="#92a3c3" />
      <path id="Path_4307" data-name="Path 4307" d="M754.587,240.607l-9.627-5.567V222.2l9.627,5.555Z"
        transform="translate(-672.489 -76.988)" fill="#92a3c3" />
      <path id="Path_4308" data-name="Path 4308" d="M827.029,235.3,763.98,271.691v-92.72l63.049-36.391Z"
        transform="translate(-669.124 -91.077)" fill="#fff" stroke="#000" stroke-miterlimit="10" stroke-width="0.2" />
      <g transform="matrix(1, 0, 0, 1, -10.5, -5.83)" filter="url(#Rectangle_1278)">
        <rect id="Rectangle_1278-2" data-name="Rectangle 1278" width="132" height="37" rx="10"
          transform="translate(10.5 10.5)" fill="#09295f" />
      </g>
      <text id="D4_Block" data-name="D4 Block" transform="translate(0 12.674)" fill="#fff" font-size="18"
        font-family="Mulish-Regular, Mulish">
        <tspan x="31.102" y="16">D4 Block</tspan>
      </text>
    </g>
  </svg>











</div>