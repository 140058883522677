import { Component } from '@angular/core';

@Component({
  selector: 'tunnel-comp',
  templateUrl: './tunnel-comp.component.html',
  styleUrls: ['./tunnel-comp.component.css']
})
export class TunnelCompComponent {

}
