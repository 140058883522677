import { Component } from '@angular/core';

@Component({
  selector: 'd-one-block-comp',
  templateUrl: './d-one-block-comp.component.html',
  styleUrls: ['./d-one-block-comp.component.css']
})
export class DOneBlockCompComponent {

}
