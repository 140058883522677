import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-command-update',
  templateUrl: './command-update.component.html',
  styleUrls: ['./command-update.component.css']
})

export class CommandUpdateComponent {
  command: string = '';
  showDetails: boolean = false;
  errorMessage: string = '';
  selectedAHUStatus:number=0;
  chw_valve:number=0;
  wrongpass:boolean=false;
  fan_speed:number=0;
  fa_damper:number=0;
  responseMessage: string = '';
  isLoading: boolean = false;
  isloading:boolean=false;
  selectedBlock: string = 'A-Block'; // Default block
  blocks: string[]=['A','B1','B2W','B2E','D1','D2','D3','D4','D5','E','F']
  selectedAHU: string = '';
  ahus: string[][] = [['UB','Mezz','L2'],
                      ['GS','L0','L1','L2','L3','L4'],
                      ['MR','KK','FC','L1','L2','L3','L4'],
                      ['FC','L1','L2','L3','L4'],
                      ['L0','L1','L2','L3','L4'],
                      ['L0','L1','L2','L3','L4'],
                      ['L0','L1','L2','L4'],
                      ['GS','L0','L1','L2','L3','L4'],
                      ['GS'],
                      ['DayE','DayW','Game','Lounge','Audi1','Audi2'],
                      ['A&B Wing GF Corridor AHU','C Wing GF Corridor-Admin AHU','B Wing GF TR Hall AHU','A&B Wing 1st Corridor','B&C Wing 1st Corridor','B Wing 1F TR Hall AHU','C Wing 1F TR Hall AHU','A Wing 1F Reception AHU','A Wing 1F Reception & Back Office', 'TFA-Second Floor','TFA-Third Floor','TFA-Fourth Floor']
                    ];
    blockahumap:Map<string,number>= new Map<string,number>();
    constructor(private http:HttpClient,       private router: Router,
      private snackBar: MatSnackBar,
    ){

    }
  ngOnInit() : void{
     

    for(let i=0; i<this.blocks.length; i++){
      this.blockahumap.set(this.blocks[i],i);
    } 

    console.log(this.blockahumap);
    console.log(this.blockahumap.get("A-Block"),"helllll");
    console.log(this.selectedBlock);
  }

  onAhuChange(selectedAhu: string): void {
    console.log('Selected AHU:', this.selectedBlock);
    // Add any other logic you need to handle when the AHU changes
  }
  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: "start",
      duration: 5000
    });
  }
  sendCommand(){
 
    this.isLoading = true;
  
      const data = {
        client: 'hul',
          site: 'ulhi',
          action:1,
          id1: this.selectedBlock,
          // id2: this.selectedfloor,
          id2: this.selectedAHU,
          user: "bert",
          body:{
            on_off_val:this.selectedAHUStatus,
            chw_val:this.chw_valve,
            fan_val: this.fan_speed,
            heating_coil: this.fa_damper
          }
      };
      console.log(data);
      this.isloading=true;
  
      this.http.post(`${environment.apiBaseUrl}/auth/sendCommand`,data).subscribe(
        (response:any)=>{
          this.isLoading=false;
          this.isloading=false;
            this.responseMessage='Command sent successfully!!';
            console.log(data);
            console.log('post response',response);

            if(response.status=="failed"){
              this.responseMessage='Command sent successfully but there is error in input please try again!!';
              this.openSnackBar(response.msg);
            }

            // if(response.sta)
        },
        (error)=>{
          this.isloading=false;
          
          this.isLoading=false;
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
            this.openSnackBar('Session expired')

          }
          this.responseMessage='Failed to send command.please try again!';
          this.openSnackBar('failed to send command please try again');

          console.log('post response',error);
        }
      )
    
    }
  showCommand() {
    console.log(this.ahus);
    if (this.command === 'bertlabs') {
      this.showDetails = true;
      this.errorMessage = '';
    } else {
      console.log("hi ")
      this.errorMessage = 'Wrong command';
      this.showDetails = false;
      this.wrongpass=true;
    }
  }
  
}
