import { Component } from '@angular/core';

@Component({
  selector: 'app-zone-c',
  templateUrl: './zone-c.component.html',
  styleUrls: ['./zone-c.component.css']
})
export class ZoneCComponent {

}
