import { Component } from '@angular/core';

@Component({
  selector: 'b-one-block-comp',
  templateUrl: './b-one-block-comp.component.html',
  styleUrls: ['./b-one-block-comp.component.css']
})
export class BOneBlockCompComponent {

}
