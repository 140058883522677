import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-block-b-two-west',
  templateUrl: './block-b-two-west.component.html',
  styleUrls: ['./block-b-two-west.component.css']
})
export class BlockBTwoWestComponent {
KPI:number=0;
basepower:number=1600.92;
  optiResult: any;

  constructor(private http: HttpClient,private snackBar:MatSnackBar,private router: Router,) { }

  ngOnInit():void{
    this.getPredictionResult();
    setInterval(()=>{
      this.getPredictionResult();
    },600000);
  }

  // async getPredictionResult() {
  //   this.http.get<any>(`${environment.apiBaseUrl}/auth/get_prediction_result`)
  //     .subscribe(
  //       (response: any) => {
  //         try {
  //           this.optiResult = response.result; // Directly assign the nested object
  //           // const totpow=this.optiResult['Power_B2WFC']+this.optiResult['Power_B2WKK']+this.optiResult['Power_B2WL1']+this.optiResult['Power_B2WL2']+this.optiResult['Power_B2WL3']+this.optiResult['Power_B2WL4']+this.optiResult['Power_B2WMR'];
  //           // this.KPI=((this.basepower-(totpow))/(this.basepower))*100;

  //           var xb2wfc=(this.optiResult['fan_speed_B2WFC'])/50;
  //           var b2wfc=Math.pow(xb2wfc,3);
  //           b2wfc=(1-b2wfc)*100;
  //           console.log(b2wfc);

  //           var xb2wkk=(this.optiResult['fan_speed_B2WKK'])/50;
  //           var b2wkk=Math.pow(xb2wkk,3);
  //           b2wkk=(1-b2wkk)*100;
  //           console.log(b2wkk);

  //           var xb2wl1=(this.optiResult['fan_speed_B2WL1'])/50;
  //           var b2wl1=Math.pow(xb2wl1,3);
  //           b2wl1=(1-b2wl1)*100;
  //           console.log(b2wl1);

  //           var xb2wl2=(this.optiResult['fan_speed_B2WL2'])/50;
  //           var b2wl2=Math.pow(xb2wl2,3);
  //           b2wl2=(1-b2wl2)*100;
  //           console.log(b2wl2);

  //           var xb2wl3=(this.optiResult['fan_speed_B2WL3'])/50;
  //           var b2wl3=Math.pow(xb2wl3,3);
  //           b2wl3=(1-b2wl3)*100;
  //           console.log(b2wl3);

  //           var xb2wl4=(this.optiResult['fan_speed_B2WL4'])/50;
  //           var b2wl4=Math.pow(xb2wl4,3);
  //           b2wl4=(1-b2wl4)*100;
  //           console.log(b2wl4);

  //           var xb2wmr=(this.optiResult['fan_speed_B2WMR'])/50;
  //           var b2wmr=Math.pow(xb2wmr,3);
  //           b2wmr=(1-b2wmr)*100;
  //           console.log(b2wmr);

  //           this.KPI=(b2wmr+b2wl4+b2wl3+b2wl2+b2wl1+b2wkk+b2wfc)/7;
  //           this.KPI=(this.KPI)/(-100);

  //         } catch (error) {
  //           console.error('Error processing prediction result:', error);
  //         }
  //       },
  //       (error) => {
  //         console.error('Error fetching optimized result:', error);
  //         if (error.status === 401) {
  //           // Redirect to the login page
  //           this.router.navigate(['/login']); // Adjust the path as necessary
  //           sessionStorage.removeItem('accessToken');
  //           this.openSnackBar('Session expired')

  //         }
  //         else{
  //           this.openSnackBar('error in fetching predicted values')

  //         }
  //       }
  //     );
  // }

  async getPredictionResult() {

    this.http.get<any>(`${environment.apiBaseUrl}/auth/get_result`)

      .subscribe(

        (response: any) => {

          try {

            this.optiResult = response.environment; // Directly assign the nested object

            var totpow=this.optiResult['Power_B2WFC']+this.optiResult['Power_B2WKK']+this.optiResult['Power_B2WL1']+this.optiResult['Power_B2WL2']+this.optiResult['Power_B2WL3']+this.optiResult['Power_B2WL4']+this.optiResult['Power_B2WMR'];
            totpow=totpow/7;

            this.KPI=((this.basepower-(totpow))/(this.basepower))*100;

          } catch (error) {

            console.error('Error processing prediction result:', error);

          }

        },

        (error) => {

          console.error('Error fetching optimized result:', error);
          if (error.status === 401) {
            // Redirect to the login page
            this.router.navigate(['/login']); // Adjust the path as necessary
            sessionStorage.removeItem('accessToken');
            this.openSnackBar('Session expired')

          }
          this.openSnackBar('error in fetching predicted values')

        }

      );

  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }

}
