import { ViewportScroller } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'Chiller-plant',
  templateUrl: './chiller-plant.component.html',
  styleUrls: ['./chiller-plant.component.css'],
})
export class ChillerPlantComponent {
  chillerOneStatus: string;
  fieldstatus: boolean;
  isToggled = false;
  progress: boolean = false;
  localVariableList = [
    'Common_Header_Supply_temp_After_Decoupler_Line', 
    'VFD_Feedback_Zone_C_Sec_Pump_1_predict_out', 
    'Supply_temp_After_Sec_Pump_Zone_B', 
    'Diff_Pressure_Sec_Pump_Zone_B', 
    'Common_Header_Return_temp_After_Decoupler_Line', 
    'VFD_Feedback_Pr_Pump_1_predict_out', 
    'Common_Header_Supply_temp_Before_Decoupler_Line', 
    'Common_Header_Return_temp_Before_Decoupler_Line', 
    'Diff_Pressure_Sec_Pump_Zone_A', 
    'Chilled_water_Power', 
    'Diff_Pressure_Sec_Pump_Zone_C', 
    'Supply_temp_After_Sec_Pump_Zone_C', 
    'Supply_temp_After_Sec_Pump_Zone_A', 
    'VFD_Feedback_Zone_A_Sec_Pump_1_predict_out', 
    'VFD_Feedback_Zone_B_Sec_Pump_1_predict_out', 
    'Chilled_Water_Set_Point', 
    'VFD_Feedback_Pr_Pump_2_predict_out', 
    'VFD_Feedback_Zone_A_Sec_Pump_2_predict_out', 
    'Total_Sec_Pump_Power'
  ];

  predict_input1:any={};
  predict_input2:any={};
  predict_input3:any={};
  predict_response1: any = {};
  predict_response2: any = {};
  predict_response3: any = {};
 
  
  optimise_input:any={};
  optimise_response: any = {};
  disableOptimise: boolean = false;

  //48. predict input-fields initialisationc for chiller side 
  Supply_temp_After_Sec_Pump_Zone_A: number = 0;
  Supply_temp_After_Sec_Pump_Zone_B: number = 0;
  Supply_temp_After_Sec_Pump_Zone_C: number = 0;
  VFD_Feedback_Pr_Pump_1: number = 0;
  VFD_Feedback_Pr_Pump_2: number = 0;
  VFD_Feedback_Zone_A_Sec_Pump_1: number = 0;
  VFD_Feedback_Zone_A_Sec_Pump_2: number = 0;
  VFD_Feedback_Zone_B_Sec_Pump_1: number = 0;
  VFD_Feedback_Zone_C_Sec_Pump_1: number = 0;
  Diff_Pressure_Sec_Pump_Zone_A: number = 0;
  Diff_Pressure_Sec_Pump_Zone_B: number = 0;
  Diff_Pressure_Sec_Pump_Zone_C: number = 0;
  Common_Header_Supply_temp_Before_Decoupler_Line: number = 0;
  Common_Header_Supply_temp_After_Decoupler_Line: number = 0;
  Common_Header_Return_temp_Before_Decoupler_Line: number = 0;
  Common_Header_Return_temp_After_Decoupler_Line: number = 0;
  Chilled_Water_Set_Point:number = 0;
  model_no: number = 0;

  TOTAL_POWER_out: number = 0;
  kpi: number = 0;

  
  model_no_2 : number = 0;

  //predict output fields initialisation

  Common_Header_Return_temp_After_Decoupler_Line_out: number = 0;
  Common_Header_Return_temp_Before_Decoupler_Line_out: number = 0;
  Common_Header_Supply_temp_After_Decoupler_Line_out: number = 0;
  Common_Header_Supply_temp_Before_Decoupler_Line_out: number = 0;
  Diff_Pressure_Sec_Pump_Zone_A_out: number = 0;
  Diff_Pressure_Sec_Pump_Zone_B_out: number = 0;
  Diff_Pressure_Sec_Pump_Zone_C_out: number = 0;
  Supply_temp_After_Sec_Pump_Zone_A_out: number = 0;
  Supply_temp_After_Sec_Pump_Zone_B_out: number = 0;
  Supply_temp_After_Sec_Pump_Zone_C_out: number = 0;
  VFD_Feedback_Pr_Pump_1_out: number = 0;
  VFD_Feedback_Pr_Pump_2_out: number = 0;
  VFD_Feedback_Zone_A_Sec_Pump_1_out: number = 0;
  VFD_Feedback_Zone_A_Sec_Pump_2_out: number = 0;
  VFD_Feedback_Zone_B_Sec_Pump_1_out: number = 0;
  VFD_Feedback_Zone_C_Sec_Pump_1_out: number = 0;

  Chilled_water_Power_out: number = 0;

  Total_Sec_Pump_Power_out: number = 0;


  Chilled_Water_Set_Point_out: number = 0;
  // kpi variables
  chiller_power_savings:number=0;
  secondary_pump_power:number=0;
  predictionResult: any;
  interfaceResult: any;
  jsonData: any = {};
  FAN_SPEED_out: any;
  tag: any;
  CHW_VALVE_out: any;
  VFD_Feedback_Pr_Pump_3_out: number=0;
  VFD_Feedback_Pr_Pump_4_out: number=0;
  VFD_Feedback_Zone_B_Sec_Pump_2_out: number=0;
  VFD_Feedback_Zone_A_Sec_Pump_3_out: number=0;
  VFD_Feedback_Zone_B_Sec_Pump_3_out: number=0;
  VFD_Run_Status_Cond_Pump_3_in: number=0;
  VFD_Run_Status_Pr_Pump_4_in: number=0;
  VFD_Run_Status_Sec_P2_Zone_A_in: number=0;
  VFD_Run_Status_Sec_P2_Zone_B_in: number=0;
  VFD_Run_Status_Sec_P2_Zone_C_in: number=0;
  VFD_Run_Status_Sec_P3_Zone_B_in: number=0;
  VFD_Trip_Status_Sec_P2_Zone_A_in: number=0;
  Chilled_Water_Set_Point_in: number=0;
  Common_Header_Return_temp_After_Decoupler_Line_in: number=0;
  Common_Header_Return_temp_Before_Decoupler_Line_in: number=0;
  Common_Header_Supply_temp_After_Decoupler_Line_in: number=0;
  Common_Header_Supply_temp_Before_Decoupler_Line_in: number=0;
  Diff_Pressure_Sec_Pump_Zone_A_in: number=0;
  Diff_Pressure_Sec_Pump_Zone_B_in: number=0;
  Diff_Pressure_Sec_Pump_Zone_C_in: number=0;
  Supply_temp_After_Sec_Pump_Zone_A_in: number=0;
  Supply_temp_After_Sec_Pump_Zone_B_in: number=0;
  Supply_temp_After_Sec_Pump_Zone_C_in: number=0;
  VFD_Feedback_Pr_Pump_1_in: number=0;
  VFD_Feedback_Pr_Pump_2_in: number=0;
  VFD_Feedback_Pr_Pump_3_in: number=0;
  VFD_Feedback_Pr_Pump_4_in: number=0;
  VFD_Feedback_Zone_A_Sec_Pump_1_in: number=0;
  VFD_Feedback_Zone_B_Sec_Pump_1_in: number=0;
  VFD_Feedback_Zone_C_Sec_Pump_1_in: number=0;
  VFD_Feedback_Zone_A_Sec_Pump_2_in: number=0;
  VFD_Feedback_Zone_B_Sec_Pump_2_in: number=0;
  VFD_Feedback_Zone_A_Sec_Pump_3_in: number=0;
  VFD_Feedback_Zone_B_Sec_Pump_3_in: number=0;






  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar) 
    {
    this.chillerOneStatus = 'active';
    this.fieldstatus = false;


    this.http.get('assets/ahus.json', { responseType: 'text' })
    .subscribe(data => {
      const ahusData = JSON.parse(data);
  
      for (const key in ahusData) {
        if (ahusData.hasOwnProperty(key)) {
          const ahu = JSON.parse(localStorage.getItem(key) || '{}');
          if (!ahu['isDisabled']) {
            if (ahu.hasOwnProperty('predict_response')){
              const predict_response = ahu['predict_response'];
              this.optimise_input[`rat_${ahu.tag}`] = predict_response[`rat_${ahu.tag}`];
              this.optimise_input[`Supply Air Temp_${ahu.tag}`] = predict_response[`Supply Air Temp_${ahu.tag}`];
              this.optimise_input[`supply_dpt_${ahu.tag}`] = predict_response[`supply_dpt_${ahu.tag}`];
              this.optimise_input[`Power_${ahu.tag}`] = predict_response[`Power_${ahu.tag}`];
              this.optimise_input[`chw_valve_opening_${ahu.tag}`] = predict_response[`CHW_VALVE`];
              this.optimise_input[`fan_speed_${ahu.tag}`] = predict_response[`FAN_SPEED`];
    
              console.log(predict_response);
            } else {
              console.log(key);
              this.openSnackBar(`Values Not Predicted For: ${ahu.tag}`)
              this.disableOptimise = true;
            }
          }
        }
      }
    });  

    for (var local_name in this.localVariableList) {
      if (localStorage.getItem(this.localVariableList[local_name]) == null){
        console.log(this.localVariableList[local_name]);
        this.disableOptimise = true;
      }
    }
    if(localStorage.getItem('predictionResult')){
      this.predictionResult = localStorage.getItem('predictionResult');
      this.predictionResult = JSON.parse(this.predictionResult);
      this.Chilled_Water_Set_Point_out = this.predictionResult['Chilled Water Set Point'];
      localStorage.setItem('Chilled Water Set Point', String(this.Chilled_Water_Set_Point_out));
      this.VFD_Feedback_Pr_Pump_1_out = this.predictionResult['VFD Feedback Pr Pump 1'];
      this.VFD_Feedback_Pr_Pump_2_out = this.predictionResult['VFD Feedback Pr Pump 2'];
      this.VFD_Feedback_Zone_A_Sec_Pump_1_out = this.predictionResult['VFD Feedback Zone A Sec Pump 1'];
      this.VFD_Feedback_Zone_A_Sec_Pump_2_out = this.predictionResult['VFD Feedback Zone A Sec Pump 2'];
      this.VFD_Feedback_Zone_B_Sec_Pump_1_out = this.predictionResult['VFD Feedback Zone B Sec Pump 1'];
      this.VFD_Feedback_Zone_C_Sec_Pump_1_out = this.predictionResult['VFD Feedback Zone C Sec Pump 1'];
      
    }
    if (localStorage.getItem('predict_input1') && localStorage.getItem('predict_input2') && localStorage.getItem('predict_input3')){
      this.predict_input1 = localStorage.getItem('predict_input1')
      this.predict_input1 = JSON.parse(this.predict_input1)

      this.predict_input2 = localStorage.getItem('predict_input2')
      this.predict_input2 = JSON.parse(this.predict_input2)

      this.predict_input3 = localStorage.getItem('predict_input3')
      this.predict_input3 = JSON.parse(this.predict_input3)

      this.Supply_temp_After_Sec_Pump_Zone_A = this.predict_input1['Supply temp After Sec Pump Zone A'];
      this.Supply_temp_After_Sec_Pump_Zone_B = this.predict_input1['Supply temp After Sec Pump Zone B'];
      this.Supply_temp_After_Sec_Pump_Zone_C = this.predict_input1['Supply temp After Sec Pump Zone C'];
      this.VFD_Feedback_Pr_Pump_1 = this.predict_input1['VFD Feedback Pr Pump 1'];
      this.VFD_Feedback_Pr_Pump_2 = this.predict_input1['VFD Feedback Pr Pump 2'];
      this.VFD_Feedback_Zone_A_Sec_Pump_1 = this.predict_input1['VFD Feedback Zone A Sec Pump 1'];
      this.VFD_Feedback_Zone_A_Sec_Pump_2 = this.predict_input1['VFD Feedback Zone A Sec Pump 2'];
      this.VFD_Feedback_Zone_B_Sec_Pump_1 = this.predict_input1['VFD Feedback Zone B Sec Pump 1'];
      this.VFD_Feedback_Zone_C_Sec_Pump_1 = this.predict_input1['VFD Feedback Zone C Sec Pump 1'];
      this.Diff_Pressure_Sec_Pump_Zone_A = this.predict_input1['Diff Pressure Sec Pump Zone A'];
      this.Diff_Pressure_Sec_Pump_Zone_B = this.predict_input1['Diff Pressure Sec Pump Zone B'];
      this.Diff_Pressure_Sec_Pump_Zone_C = this.predict_input1['Diff Pressure Sec Pump Zone C'];
      this.Common_Header_Supply_temp_Before_Decoupler_Line = this.predict_input1['Common Header Supply temp Before Decoupler Line'];
      this.Common_Header_Supply_temp_After_Decoupler_Line = this.predict_input1['Common Header Supply temp After Decoupler Line'];
      this.Common_Header_Return_temp_Before_Decoupler_Line = this.predict_input1['Common Header Return temp Before Decoupler Line'];
      this.Common_Header_Return_temp_After_Decoupler_Line = this.predict_input1['Common Header Return temp After Decoupler Line'];
      this.Chilled_Water_Set_Point = this.predict_input1['Chilled Water Set Point'];
      
      
    }

    if (localStorage.getItem('predict_response1') && localStorage.getItem('predict_response2') && localStorage.getItem('predict_response3')){
      this.predict_response1 = localStorage.getItem('predict_response1')
      this.predict_response1 = JSON.parse(this.predict_response1)
      console.log(this.predict_response1)

      this.predict_response2 = localStorage.getItem('predict_response2')
      this.predict_response2 = JSON.parse(this.predict_response2)

      this.predict_response3 = localStorage.getItem('predict_response3')
      this.predict_response3 = JSON.parse(this.predict_response3)


      this.Chilled_water_Power_out = this.predict_response2['Chilled-water Power'];
      this.Total_Sec_Pump_Power_out = this.predict_response3['Total Sec Pump Power'];
      this.VFD_Feedback_Pr_Pump_1_out = this.predict_input1['VFD Feedback Pr Pump 1'];
      this.VFD_Feedback_Pr_Pump_2_out = this.predict_input1['VFD Feedback Pr Pump 2'];
      this.Chilled_Water_Set_Point_out = this.predict_response1['Chilled_Water_Set_Point_predict_out'];
      this.Diff_Pressure_Sec_Pump_Zone_A_out = this.predict_response1['Diff Pressure Sec Pump Zone A'];
      this.Diff_Pressure_Sec_Pump_Zone_B_out = this.predict_response1['Diff Pressure Sec Pump Zone B'];
      this.Diff_Pressure_Sec_Pump_Zone_C_out = this.predict_response1['Diff Pressure Sec Pump Zone C'];
      this.Common_Header_Return_temp_After_Decoupler_Line_out = this.predict_response1['Common Header Return temp After Decoupler Line'];
      this.Common_Header_Return_temp_Before_Decoupler_Line_out = this.predict_response1['Common Header Return temp Before Decoupler Line'];
      this.VFD_Feedback_Zone_A_Sec_Pump_1_out = this.predict_input1['VFD Feedback Zone A Sec Pump 1'];
      this.VFD_Feedback_Zone_A_Sec_Pump_2_out = this.predict_input1['VFD Feedback Zone A Sec Pump 2'];
      this.VFD_Feedback_Zone_B_Sec_Pump_1_out = this.predict_input1['VFD Feedback Zone B Sec Pump 1'];
      this.VFD_Feedback_Zone_C_Sec_Pump_1_out = this.predict_input1['VFD Feedback Zone C Sec Pump 1'];
      this.Supply_temp_After_Sec_Pump_Zone_A_out = this.predict_response1['Supply temp After Sec Pump Zone A'];
      this.Supply_temp_After_Sec_Pump_Zone_B_out = this.predict_response1['Supply temp After Sec Pump Zone B'];
      this.Supply_temp_After_Sec_Pump_Zone_C_out = this.predict_response1['Supply temp After Sec Pump Zone C'];
      this.Common_Header_Supply_temp_After_Decoupler_Line_out = this.predict_response1['Common Header Supply temp After Decoupler Line'];
      this.Common_Header_Supply_temp_Before_Decoupler_Line_out = this.predict_response1['Common Header Supply temp Before Decoupler Line'];
    }
      
      if (localStorage.getItem('Chilled Water Set Point')){
            this.Chilled_Water_Set_Point_out = Number(localStorage.getItem('Chilled Water Set Point'));
            // this.Total_Sec_Pump_Power_out = Number(localStorage.getItem('sp_power'));
            // this.Chilled_water_Power_out = Number(localStorage.getItem('pp_power'));
            // this.VFD_Feedback_Pr_Pump_1_out = Number(localStorage.getItem('VFD Feedback Pr Pump 1'));
            // this.VFD_Feedback_Pr_Pump_2_out = Number(localStorage.getItem('VFD Feedback Pr Pump 2'));
            // this.VFD_Feedback_Zone_A_Sec_Pump_1_out = Number(localStorage.getItem('VFD Feedback Zone A Sec Pump 1'));
            // this.VFD_Feedback_Zone_A_Sec_Pump_2_out = Number(localStorage.getItem('VFD Feedback Zone A Sec Pump 2'));
            // this.VFD_Feedback_Zone_B_Sec_Pump_1_out = Number(localStorage.getItem('VFD Feedback Zone B Sec Pump 1'));
            // this.VFD_Feedback_Zone_C_Sec_Pump_1_out = Number(localStorage.getItem('VFD Feedback Zone C Sec Pump 1'));
      }else{
            this.Chilled_Water_Set_Point_out = 0;
      }
  }




  ngOnInit(): void {
    
    this.getInputResult();
    this.getPredictionResult();
  }
  // http://hul-reinforcement:6100/get_prediction_ret
  async getPredictionResult() {
    this.http.get<any>('http://localhost:5000/get_prediction_result')
      .subscribe(
        (response: { result: any }) => {
          try {
            this.predictionResult = response.result; // Directly assign the nested object
            // console.log('Prediction result:', this.predictionResult);
            this.Chilled_Water_Set_Point_out = this.predictionResult['Chilled Water Set Point'];
            localStorage.setItem('Chilled Water Set Point', String(this.Chilled_Water_Set_Point_out));
            this.VFD_Feedback_Pr_Pump_1_out = this.predictionResult['VFD Feedback Pr Pump 1'];
            this.VFD_Feedback_Pr_Pump_2_out = this.predictionResult['VFD Feedback Pr Pump 2'];
            this.VFD_Feedback_Zone_A_Sec_Pump_1_out = this.predictionResult['VFD Feedback Zone A Sec Pump 1'];
            this.VFD_Feedback_Zone_A_Sec_Pump_2_out = this.predictionResult['VFD Feedback Zone A Sec Pump 2'];
            this.VFD_Feedback_Zone_B_Sec_Pump_1_out = this.predictionResult['VFD Feedback Zone B Sec Pump 1'];
            this.VFD_Feedback_Zone_C_Sec_Pump_1_out = this.predictionResult['VFD Feedback Zone C Sec Pump 1'];
      
            console.log(this.jsonData);
            localStorage.setItem('predictionResult', JSON.stringify(this.predictionResult));
            console.log('Optimised Output', this.predictionResult);
          } catch (error) {
            console.error('Error processing prediction result:', error);
          }
        },
        (error) => {
          console.error('Error fetching prediction result:', error);
        }
      );
  }
  

  async getInputResult() {
    this.http.get<any>('http://localhost:8080/get_result')
      .subscribe(
        (response: { answer: any }) => {
          try {
            this.interfaceResult = response.answer; // Directly assign the nested object
            console.log('Interface result:', this.interfaceResult);
            this.Chilled_Water_Set_Point_in = this.interfaceResult['Chilled Water Set Point'];
            console.log("Chilled Water Set Point", this.Chilled_Water_Set_Point_in);
            this.Common_Header_Return_temp_After_Decoupler_Line_in = this.interfaceResult['Common Header Return temp After Decoupler Line'];
            this.Common_Header_Return_temp_Before_Decoupler_Line_in = this.interfaceResult['Common Header Return temp Before Decoupler Line'];
            this.Common_Header_Supply_temp_After_Decoupler_Line_in = this.interfaceResult['Common Header Supply temp After Decoupler Line'];
            this.Common_Header_Supply_temp_Before_Decoupler_Line_in = this.interfaceResult['Common Header Supply temp Before Decoupler Line'];
            this.Diff_Pressure_Sec_Pump_Zone_A_in = this.interfaceResult['Diff Pressure Sec Pump Zone A'];
            this.Diff_Pressure_Sec_Pump_Zone_B_in = this.interfaceResult['Diff Pressure Sec Pump Zone B'];
            this.Diff_Pressure_Sec_Pump_Zone_C_in = this.interfaceResult['Diff Pressure Sec Pump Zone C'];
            this.Supply_temp_After_Sec_Pump_Zone_A_in = this.interfaceResult['Supply temp After Sec Pump Zone A'];
            this.Supply_temp_After_Sec_Pump_Zone_B_in = this.interfaceResult['Supply temp After Sec Pump Zone B'];
            this.Supply_temp_After_Sec_Pump_Zone_C_in = this.interfaceResult['Supply temp After Sec Pump Zone C'];
            this.VFD_Feedback_Pr_Pump_1_in = this.interfaceResult['VFD Feedback Pr Pump 1'];
            this.VFD_Feedback_Pr_Pump_2_in = this.interfaceResult['VFD Feedback Pr Pump 2'];
            this.VFD_Feedback_Pr_Pump_3_in = this.interfaceResult['VFD Feedback Pr Pump 3'];
            this.VFD_Feedback_Pr_Pump_4_in = this.interfaceResult['VFD Feedback Pr Pump 4'];
            this.VFD_Feedback_Zone_A_Sec_Pump_1_in = this.interfaceResult['VFD Feedback Sec Pump 1 Zone A'];
            this.VFD_Feedback_Zone_B_Sec_Pump_1_in = this.interfaceResult['VFD Feedback Sec Pump 1 Zone B'];
            this.VFD_Feedback_Zone_C_Sec_Pump_1_in = this.interfaceResult['VFD Feedback Sec Pump 1 Zone C'];
            this.VFD_Feedback_Zone_A_Sec_Pump_2_in = this.interfaceResult['VFD Feedback Sec Pump 2 Zone A'];
            this.VFD_Feedback_Zone_B_Sec_Pump_2_in = this.interfaceResult['VFD Feedback Sec Pump 2 Zone B'];
            this.VFD_Feedback_Zone_A_Sec_Pump_3_in = this.interfaceResult['VFD Feedback Sec Pump 3 Zone A'];
            this.VFD_Feedback_Zone_B_Sec_Pump_3_in = this.interfaceResult['VFD Feedback Sec Pump 3 Zone B'];
            this.VFD_Run_Status_Cond_Pump_3_in = this.interfaceResult['VFD Run Status Cond Pump 3'];
            this.VFD_Run_Status_Pr_Pump_4_in = this.interfaceResult['VFD Run Status Pr Pump 4'];
            this.VFD_Run_Status_Sec_P2_Zone_A_in = this.interfaceResult['VFD Run Status Sec P2 Zone A'];
            this.VFD_Run_Status_Sec_P2_Zone_B_in = this.interfaceResult['VFD Run Status Sec P2 Zone B'];
            this.VFD_Run_Status_Sec_P2_Zone_C_in = this.interfaceResult['VFD Run Status Sec P2 Zone C'];
            this.VFD_Run_Status_Sec_P3_Zone_B_in = this.interfaceResult['VFD Run Status Sec P3 Zone B'];
            this.VFD_Trip_Status_Sec_P2_Zone_A_in = this.interfaceResult['VFD Trip Status Sec P2 Zone A'];
              this.jsonData['interfaceResult'] = this.interfaceResult;
            // console.log(this.jsonData);
            localStorage.setItem('interfaceResult', JSON.stringify(this.interfaceResult));
            // console.log('Optimised Output', this.predictionResult);
          } catch (error) {
            console.error('Error processing interface result:', error);
          }
        },
        (error) => {
          console.error('Error fetching interface result:', error);
        }
      );
  }



















  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  validate(): boolean {
    // if (this.RAT < 22 || this.RAT > 25) {
    //   this.openSnackBar('RAT should be in range [22,25]');
    //   return false;
    // } else if (this.CHWVF < 10 || this.CHWVF > 100) {
    //   this.openSnackBar('CHWF should be in range [10,100]');
    //   return false;
    // } else if (this.RAH < 30 || this.RAH > 83) {
    //   this.openSnackBar('RAH should be in range [30,83]');
    //   return false;
    // } else if (this.TD < 0.5 || this.TD > 8) {
    //   this.openSnackBar('TD should be in range [0.5,8]');
    //   return false;
    // } else if (this.VFDF < 55 || this.VFDF > 100) {
    //   this.openSnackBar('FAN SPEED should be in range [55,100]');
    //   return false;
    // } else if (this.FAF < 104 || this.FAF > 890) {
    //   this.openSnackBar('FAF should be in range [104,890]');
    //   return false;
    // }else if(this.SAT < 12 || this.SAT > 16){
    //   this.openSnackBar('SAT should be in range [12,16]');
    //   return false;
    // }

    return true;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'OK', {
      panelClass: ['snackbar'],
      horizontalPosition: 'start',
      duration: 5000,
    });
  }

  async predictData() {
    if (this.validate()) {
      this.progress = true;

      this.predict_input1 = {
        "Supply temp After Sec Pump Zone A": Number(this.Supply_temp_After_Sec_Pump_Zone_A),
        "Supply temp After Sec Pump Zone B" : Number(this.Supply_temp_After_Sec_Pump_Zone_B),
        "Supply temp After Sec Pump Zone C" : Number(this.Supply_temp_After_Sec_Pump_Zone_C),
        "VFD Feedback Pr Pump 1" : Number(this.VFD_Feedback_Pr_Pump_1),
        "VFD Feedback Pr Pump 2" : Number(this.VFD_Feedback_Pr_Pump_2),
        "VFD Feedback Zone A Sec Pump 1" : Number(this.VFD_Feedback_Zone_A_Sec_Pump_1),
        "VFD Feedback Zone A Sec Pump 2" : Number(this.VFD_Feedback_Zone_A_Sec_Pump_2),
        "VFD Feedback Zone B Sec Pump 1" : Number(this.VFD_Feedback_Zone_B_Sec_Pump_1),
        "VFD Feedback Zone C Sec Pump 1" : Number(this.VFD_Feedback_Zone_C_Sec_Pump_1),
        "Diff Pressure Sec Pump Zone A" : Number(this.Diff_Pressure_Sec_Pump_Zone_A),
        "Diff Pressure Sec Pump Zone B" : Number(this.Diff_Pressure_Sec_Pump_Zone_B),
        "Diff Pressure Sec Pump Zone C" : Number(this.Diff_Pressure_Sec_Pump_Zone_C),
        "Common Header Supply temp Before Decoupler Line" : Number(this.Common_Header_Supply_temp_Before_Decoupler_Line),
        "Common Header Supply temp After Decoupler Line" : Number(this.Common_Header_Supply_temp_After_Decoupler_Line),
        "Common Header Return temp Before Decoupler Line" : Number(this.Common_Header_Return_temp_Before_Decoupler_Line),
        "Common Header Return temp After Decoupler Line" : Number(this.Common_Header_Return_temp_After_Decoupler_Line),
        "Chilled Water Set Point" :  Number(this.Chilled_Water_Set_Point),
        "model_no." : 48
      };
      // 38

      this.predict_input2 = {
        "VFD Feedback Pr Pump 1" : Number(this.VFD_Feedback_Pr_Pump_1),
        "VFD Feedback Pr Pump 2" : Number(this.VFD_Feedback_Pr_Pump_2),
        "Common Header Supply temp After Decoupler Line" : Number(this.Common_Header_Supply_temp_After_Decoupler_Line),
        "Common Header Return temp After Decoupler Line" : Number(this.Common_Header_Return_temp_After_Decoupler_Line),
        "model_no." : 49
      };
      
      this.predict_input3 = {
        "VFD Feedback Zone A Sec Pump 1" : Number(this.VFD_Feedback_Zone_A_Sec_Pump_1),
        "VFD Feedback Zone A Sec Pump 2" : Number(this.VFD_Feedback_Zone_A_Sec_Pump_2),
        "VFD Feedback Zone B Sec Pump 1" : Number(this.VFD_Feedback_Zone_B_Sec_Pump_1),
        "VFD Feedback Zone C Sec Pump 1" : Number(this.VFD_Feedback_Zone_C_Sec_Pump_1),
        "model_no." : 50
      };

      console.log("input1 chiller predict",this.predict_input1);
      console.log("input2 chiller predict",this.predict_input2);
      console.log("input3 chiller predict",this.predict_input3);
      try {
        const response1 = await this.http
          .post<any>(
            'https://bpsliveathotelshvac.bertlabs.com/sec_48',
            this.predict_input1,
            this.httpOptions
          )
          .toPromise();

          this.predict_response1 = response1;
          console.log(this.predict_response1);

          const response2 = await this.http
          .post<any>(
            'https://bpsliveathotelshvac.bertlabs.com/sec_49',
            this.predict_input2,
            this.httpOptions
          )
          .toPromise();

          this.predict_response2 = response2;
          console.log(this.predict_response2);

          const response3 = await this.http
          .post<any>(
            'https://bpsliveathotelshvac.bertlabs.com/sec_50/',
            this.predict_input3,
            this.httpOptions
          )
          .toPromise();
          this.predict_response3 = response3;
          console.log(this.predict_response3);
          localStorage.setItem('predict_input1', JSON.stringify(this.predict_input1));
          localStorage.setItem('predict_input2', JSON.stringify(this.predict_input2));
          localStorage.setItem('predict_input3', JSON.stringify(this.predict_input3));

        this.Common_Header_Return_temp_After_Decoupler_Line_out = Number(this.predict_response1['Common Header Return temp After Decoupler Line']);                                                                                                               
        this.Common_Header_Return_temp_Before_Decoupler_Line_out = Number(this.predict_response1['Common Header Return temp Before Decoupler Line']);                                                                                                               
        this.Common_Header_Supply_temp_After_Decoupler_Line_out = Number(this.predict_response1['Common Header Supply temp After Decoupler Line']);                                                                                                               
        this.Common_Header_Supply_temp_Before_Decoupler_Line_out = Number(this.predict_response1['Common Header Supply temp Before Decoupler Line']);                                                                                                               
        this.Diff_Pressure_Sec_Pump_Zone_A_out = Number(this.predict_response1['Diff Pressure Sec Pump Zone A']);                                                                                                               
        this.Diff_Pressure_Sec_Pump_Zone_B_out = Number(this.predict_response1['Diff Pressure Sec Pump Zone B']);                                                                                                               
        this.Diff_Pressure_Sec_Pump_Zone_C_out = Number(this.predict_response1['Diff Pressure Sec Pump Zone C']);                                                                                                               
        this.Supply_temp_After_Sec_Pump_Zone_A_out = Number(this.predict_response1['Supply temp After Sec Pump Zone A']);                                                                                                               
        this.Supply_temp_After_Sec_Pump_Zone_B_out = Number(this.predict_response1['Supply temp After Sec Pump Zone B']);                                                                                                               
        this.Supply_temp_After_Sec_Pump_Zone_C_out = Number(this.predict_response1['Supply temp After Sec Pump Zone C']);                                                                                                               

        this.Chilled_water_Power_out = Number(this.predict_response2['Chilled-water Power']);                                                                       

        this.Total_Sec_Pump_Power_out = Number(this.predict_response3['Total Sec Pump Power']);                                                                       

        this.VFD_Feedback_Pr_Pump_1_out = this.VFD_Feedback_Pr_Pump_1;
        this.VFD_Feedback_Pr_Pump_2_out = this.VFD_Feedback_Pr_Pump_2;
        this.VFD_Feedback_Zone_A_Sec_Pump_1_out = this.VFD_Feedback_Zone_A_Sec_Pump_1;
        this.VFD_Feedback_Zone_A_Sec_Pump_2_out = this.VFD_Feedback_Zone_A_Sec_Pump_2;
        this.VFD_Feedback_Zone_B_Sec_Pump_1_out = this.VFD_Feedback_Zone_B_Sec_Pump_1;
        this.VFD_Feedback_Zone_C_Sec_Pump_1_out = this.VFD_Feedback_Zone_C_Sec_Pump_1;
        

        localStorage.setItem('Common_Header_Return_temp_After_Decoupler_Line', String(this.predict_response1['Common Header Return temp After Decoupler Line']))
        localStorage.setItem('Common_Header_Return_temp_Before_Decoupler_Line', String(this.predict_response1['Common Header Return temp Before Decoupler Line']))
        localStorage.setItem('Common_Header_Supply_temp_After_Decoupler_Line', String(this.predict_response1['Common Header Supply temp After Decoupler Line']))
        localStorage.setItem('Common_Header_Supply_temp_Before_Decoupler_Line', String(this.predict_response1['Common Header Supply temp Before Decoupler Line']))
        localStorage.setItem('Diff_Pressure_Sec_Pump_Zone_A', String(this.predict_response1['Diff Pressure Sec Pump Zone A']))
        localStorage.setItem('Diff_Pressure_Sec_Pump_Zone_B', String(this.predict_response1['Diff Pressure Sec Pump Zone B']))
        localStorage.setItem('Diff_Pressure_Sec_Pump_Zone_C', String(this.predict_response1['Diff Pressure Sec Pump Zone C']))
        localStorage.setItem('Supply_temp_After_Sec_Pump_Zone_A', String(this.predict_response1['Supply temp After Sec Pump Zone A']))
        localStorage.setItem('Supply_temp_After_Sec_Pump_Zone_B', String(this.predict_response1['Supply temp After Sec Pump Zone B']))
        localStorage.setItem('Supply_temp_After_Sec_Pump_Zone_C', String(this.predict_response1['Supply temp After Sec Pump Zone C']))
        localStorage.setItem('Chilled_water_Power', String(this.predict_response2['Chilled-water Power']))
        localStorage.setItem('Total_Sec_Pump_Power', String(this.predict_response3['Total Sec Pump Power']))
        

        localStorage.setItem('VFD_Feedback_Pr_Pump_1_predict_out', String(this.VFD_Feedback_Pr_Pump_1_out))
        localStorage.setItem('VFD_Feedback_Pr_Pump_2_predict_out', String(this.VFD_Feedback_Pr_Pump_2_out))
        localStorage.setItem('VFD_Feedback_Zone_A_Sec_Pump_1_predict_out', String(this.VFD_Feedback_Zone_A_Sec_Pump_1_out))
        localStorage.setItem('VFD_Feedback_Zone_A_Sec_Pump_2_predict_out', String(this.VFD_Feedback_Zone_A_Sec_Pump_2_out))
        localStorage.setItem('VFD_Feedback_Zone_B_Sec_Pump_1_predict_out', String(this.VFD_Feedback_Zone_B_Sec_Pump_1_out))
        localStorage.setItem('VFD_Feedback_Zone_C_Sec_Pump_1_predict_out', String(this.VFD_Feedback_Zone_C_Sec_Pump_1_out))
        
        localStorage.setItem('Chilled_Water_Set_Point',  String(this.Chilled_Water_Set_Point))

        this.openSnackBar('Predicted Succesfully');
        // console.log('resp', this.CT1_VFD_Feedback_F1_out);
        localStorage.setItem('VFD_Feedback_Pr_Pump_1_predict_out', String(this.VFD_Feedback_Pr_Pump_1_out))
        localStorage.setItem('VFD_Feedback_Pr_Pump_2_predict_out', String(this.VFD_Feedback_Pr_Pump_2_out))
        localStorage.setItem('VFD_Feedback_Zone_A_Sec_Pump_1_predict_out', String(this.VFD_Feedback_Zone_A_Sec_Pump_1_out))
        localStorage.setItem('VFD_Feedback_Zone_A_Sec_Pump_2_predict_out', String(this.VFD_Feedback_Zone_A_Sec_Pump_2_out))
        localStorage.setItem('VFD_Feedback_Zone_B_Sec_Pump_1_predict_out', String(this.VFD_Feedback_Zone_B_Sec_Pump_1_out))
        localStorage.setItem('VFD_Feedback_Zone_C_Sec_Pump_1_predict_out', String(this.VFD_Feedback_Zone_C_Sec_Pump_1_out))
        localStorage.setItem('predict_response1', JSON.stringify(this.predict_response1));
        localStorage.setItem('predict_response2', JSON.stringify(this.predict_response2));
        localStorage.setItem('predict_response3', JSON.stringify(this.predict_response3));
        this.progress = false;


        this.http.get('assets/ahus.json', { responseType: 'text' })
        .subscribe(data => {
          const ahusData = JSON.parse(data);
          this.disableOptimise = false;
          for (const key in ahusData) {
            if (ahusData.hasOwnProperty(key)) {
              const ahu = JSON.parse(localStorage.getItem(key) || '{}');
              if (!ahu['isDisabled']) {
                if (ahu.hasOwnProperty('predict_response')){
                  const predict_response = ahu['predict_response'];
                  this.optimise_input[`rat_${ahu.tag}`] = predict_response[`rat_${ahu.tag}`];
                  this.optimise_input[`Supply Air Temp_${ahu.tag}`] = predict_response[`Supply Air Temp_${ahu.tag}`];
                  this.optimise_input[`supply_dpt_${ahu.tag}`] = predict_response[`supply_dpt_${ahu.tag}`];
                  this.optimise_input[`Power_${ahu.tag}`] = predict_response[`Power_${ahu.tag}`];
                  this.optimise_input[`chw_valve_opening_${ahu.tag}`] = predict_response[`CHW_VALVE`];
                  this.optimise_input[`fan_speed_${ahu.tag}`] = predict_response[`FAN_SPEED`];
        
                  console.log(predict_response);
                } else {
                  console.log(key);
                  // this.openSnackBar(`Values Not Predicted For: ${ahu.tag}`)
                  this.disableOptimise = true;
                }
              }
            }
          }
        })


      } catch (error) {
        console.error(
          'Error occurred while making predict API request:',
          error
        );
      }
    }
  }
  





  async optimiseData() {
    this.progress = true;
    let extraValues = {
      "Ambient Temp": 25,
      "Common Header Supply temp After Decoupler Line":Number(localStorage.getItem('Common_Header_Supply_temp_After_Decoupler_Line')) || 0,
      "VFD Feedback Zone C Sec Pump 1":Number(localStorage.getItem('VFD_Feedback_Zone_C_Sec_Pump_1_predict_out')) || 0,
      "Supply temp After Sec Pump Zone B": Number(localStorage.getItem('Supply_temp_After_Sec_Pump_Zone_B')) || 0,
      "Diff Pressure Sec Pump Zone B": Number(localStorage.getItem('Diff_Pressure_Sec_Pump_Zone_B')) || 0,
      "Common Header Return temp After Decoupler Line": Number(localStorage.getItem('Common_Header_Return_temp_After_Decoupler_Line')) || 0,
      "VFD Feedback Pr Pump 1": Number(localStorage.getItem('VFD_Feedback_Pr_Pump_1_predict_out')) || 0,
      "Common Header Supply temp Before Decoupler Line": Number(localStorage.getItem('Common_Header_Supply_temp_Before_Decoupler_Line')) || 0,
      "Common Header Return temp Before Decoupler Line": Number(localStorage.getItem('Common_Header_Return_temp_Before_Decoupler_Line')) || 0,
      "Diff Pressure Sec Pump Zone A": Number(localStorage.getItem('Diff_Pressure_Sec_Pump_Zone_A')) || 0,
      "Chilled-water Power": Number(localStorage.getItem('Chilled_water_Power')) || 0,
      "Diff Pressure Sec Pump Zone C": Number(localStorage.getItem('Diff_Pressure_Sec_Pump_Zone_C')) || 0,
      "Supply temp After Sec Pump Zone C": Number(localStorage.getItem('Supply_temp_After_Sec_Pump_Zone_C')) || 0,
      "Supply temp After Sec Pump Zone A": Number(localStorage.getItem('Supply_temp_After_Sec_Pump_Zone_A')) || 0,
      "VFD Feedback Zone A Sec Pump 1": Number(localStorage.getItem('VFD_Feedback_Zone_A_Sec_Pump_1_predict_out')) || 0,
      "VFD Feedback Zone B Sec Pump 1": Number(localStorage.getItem('VFD_Feedback_Zone_B_Sec_Pump_1_predict_out')) || 0,
      "Chilled Water Set Point": Number(localStorage.getItem('Chilled_Water_Set_Point')) || 0,
      "VFD Feedback Pr Pump 2": Number(localStorage.getItem('VFD_Feedback_Pr_Pump_2_predict_out')) || 0,
      "VFD Feedback Zone A Sec Pump 2": Number(localStorage.getItem('VFD_Feedback_Zone_A_Sec_Pump_2_predict_out')) || 0,
      "Total Sec Pump Power": Number(localStorage.getItem('Total_Sec_Pump_Power')) || 0
   }

   this.optimise_input = {
    ...extraValues,
    ...this.optimise_input
  };
    console.log('optimise input: ', this.optimise_input);
    console.log('optimise api call');

    try {
      const response = await this.http
        .post<any>(
          'https://bpsliveathotelshvac.bertlabs.com/optimise',
          this.optimise_input,
          this.httpOptions
        )
        .toPromise();

      this.optimise_response = response;
      // console.log('optimise input: ', this.optimise_input);

      console.log('optimise output', this.optimise_response);
      



      // localStorage.setItem('account-office-ahu-FAN_SPEED_optimise_out', String(response['fan_speed_AL2']));
      // localStorage.setItem('account-office-ahu-CHW_VALVE_optimise_out', String(response['chw_valve_opening_AL2']));
      // localStorage.setItem('account-office-ahu-POWER_optimise_out', String(response['Power_AL2']));
      localStorage.setItem('pp_power', String(response['pp_power']));
      localStorage.setItem('sp_power', String(response['sp_power']));
      localStorage.setItem('Chilled Water Set Point', String(response['Chilled Water Set Point']));
      localStorage.setItem('VFD Feedback Pr Pump 1', String(response['VFD Feedback Pr Pump 1']));
      localStorage.setItem('VFD Feedback Pr Pump 2', String(response['VFD Feedback Pr Pump 2']));
      localStorage.setItem('VFD Feedback Zone A Sec Pump 1', String(response['VFD Feedback Zone A Sec Pump 1']));
      localStorage.setItem('VFD Feedback Zone A Sec Pump 2', String(response['VFD Feedback Zone A Sec Pump 2']));
      localStorage.setItem('VFD Feedback Zone B Sec Pump 1', String(response['VFD Feedback Zone B Sec Pump 1']));
      localStorage.setItem('VFD Feedback Zone C Sec Pump 1', String(response['VFD Feedback Zone C Sec Pump 1']));
      localStorage.setItem('Chilled Water Set Point', String(response['Chilled Water Set Point']));
          
      this.Chilled_Water_Set_Point_out = Number(localStorage.getItem('Chilled Water Set Point'));
      this.Total_Sec_Pump_Power_out = Number(localStorage.getItem('sp_power'));
      this.Chilled_water_Power_out = Number(localStorage.getItem('pp_power'));
      this.VFD_Feedback_Pr_Pump_1_out = Number(localStorage.getItem('VFD Feedback Pr Pump 1'));
      this.VFD_Feedback_Pr_Pump_2_out = Number(localStorage.getItem('VFD Feedback Pr Pump 2'));
      this.VFD_Feedback_Zone_A_Sec_Pump_1_out = Number(localStorage.getItem('VFD Feedback Zone A Sec Pump 1'));
      this.VFD_Feedback_Zone_A_Sec_Pump_2_out = Number(localStorage.getItem('VFD Feedback Zone A Sec Pump 2'));
      this.VFD_Feedback_Zone_B_Sec_Pump_1_out = Number(localStorage.getItem('VFD Feedback Zone B Sec Pump 1'));
      this.VFD_Feedback_Zone_C_Sec_Pump_1_out = Number(localStorage.getItem('VFD Feedback Zone C Sec Pump 1'));

      this.TOTAL_POWER_out = response['pp_power']*1000 + response['sp_power']*1000 + response['Power_AL2'] + response['Power_AMezz'] + response['Power_AUB'] + response['Power_B1GS'] + response['Power_B1L0'] + response['Power_B1L1'] + response['Power_B1L2'] + response['Power_B1L3'] + response['Power_B1L4'] + response['Power_B2EFC'] + response['Power_B2EL1'] + response['Power_B2EL2'] + response['Power_B2EL3'] + response['Power_B2EL4'] + response['Power_B2WFC'] + response['Power_B2WKK'] + response['Power_B2WL1'] + response['Power_B2WL2'] + response['Power_B2WL3'] + response['Power_B2WL4'] + response['Power_B2WMR'] + response['Power_D1L0'] + response['Power_D1L1'] + response['Power_D1L2'] + response['Power_D1L3'] + response['Power_D1L4'] + response['Power_D2L0'] + response['Power_D2L1'] + response['Power_D2L2'] + response['Power_D2L3'] + response['Power_D2L4'] + response['Power_D3L0'] + response['Power_D3L1'] + response['Power_D3L2'] + response['Power_D3L4'] + response['Power_D4GS'] + response['Power_D4L0'] + response['Power_D4L1'] + response['Power_D4L2'] + response['Power_D4L4'] + response['Power_D5GS'] + response['Power_EAudi1'] + response['Power_EAudi2'] + response['Power_EDayE'] + response['Power_EDayW'] + response['Power_EGame'] + response['Power_ELounge']
      localStorage.setItem('TOTAL_POWER_optimise_out', String(this.TOTAL_POWER_out));

      this.kpi = ((665830 - this.TOTAL_POWER_out) * 100 ) / 665830;
      localStorage.setItem('kpi', String(this.kpi));

      localStorage.setItem('optimise-response', JSON.stringify(response));

      this.openSnackBar('Optimised Succesfully');

      // localStorage.setItem('account-office-ahu-FAN_SPEED_optimise_out', String(response['fan_speed_AL2']));
      // localStorage.setItem('account-office-ahu-CHW_VALVE_optimise_out', String(response['chw_valve_opening_AL2']));
      // localStorage.setItem('account-office-ahu-POWER_optimise_out', String(response['Power_AL2']));


      
      this.progress = false;
    } catch (error) {
      console.error('Error occurred while making predict API request:', error);
    }
  }
}
