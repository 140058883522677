import { Component, Input } from '@angular/core';

@Component({
  selector: 'Evaporator',
  templateUrl: './evaporator.component.html',
  styleUrls: ['./evaporator.component.css']
})
export class EvaporatorComponent {
  @Input() state!:string;

  urlActive:string;

  urlInActive:string;

  constructor(){
    this.state = 'active';
    this.textValue = ""
    this.urlActive="/assets/bert-components/active-evaporator.svg";
    this.urlInActive="/assets/bert-components/inactive-evaporator.svg";
  }

  @Input() textValue: string;

}
