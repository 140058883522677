import { Component } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'; // Import the spinner module

@Component({
  selector: 'app-loading-spinner',
  standalone: true,
  imports:[
    MatProgressSpinnerModule
  ],
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.css']
})
export class LoadingSpinnerComponent {
  ngOnInit():void{
    console.log("loading spinner")
  }
}
