import { Component } from '@angular/core';

@Component({
  selector: 'd-two-block-comp',
  templateUrl: './d-two-block-comp.component.html',
  styleUrls: ['./d-two-block-comp.component.css']
})
export class DTwoBlockCompComponent {

}
